import { useEffect, useState, useCallback, useRef } from "react";
import { useDataProvider, useAuthenticated } from "react-admin";
import { useSelector, useDispatch } from "react-redux";
import { UserDoc } from "../types";
import { Loading } from "../layout";
import { IDataProvider } from "../../firebase/providers/DataProvider";
import { updateUser } from "../../persistence/actions";
import { WalletListUI } from "../../components/wallets";
import { Empty } from "../layout";
import { environment } from "../../components/common";

/**
 * @summary The top level component of the wallet page.
 *
 * @returns {JSX.Element}
 */
const WalletList = () => {
  const [profile, setProfile] = useState<UserDoc>();
  const [loading, setLoading] = useState<boolean>(true);
  const dataProvider: IDataProvider = useDataProvider();
  const dispatch = useDispatch();
  useAuthenticated();

  const user: UserDoc = useSelector((state: any) => state.user);
  const mountedRef = useRef(true);

  const updateProfile = useCallback(async () => {
    const { data } = await dataProvider.getOne<UserDoc>(
      `${environment("profiles")}`,
      { id: user.ActiveProfile }
    );

    // Cancel request if the user changed page before it finished.
    if (!mountedRef.current) return null;

    const { Tracking, Untracked, Wallets, Tags } = data;
    dispatch(updateUser({ ...user, Tracking, Untracked, Wallets, Tags }));
    setProfile(data);
    data && setLoading(false);
  }, []);

  useEffect(() => {
    updateProfile();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  if (loading) return <Loading />;

  return profile?.Tracking && profile.Tracking.length > 0 ? (
    <WalletListUI profile={profile} />
  ) : (
    <Empty label="data" />
  );
};

export default WalletList;
