import { useRef, useState, useEffect } from "react";
import {
  SimpleForm,
  TextInput,
  useNotify,
  useMutation,
  useRedirect,
  Create,
  ReduxState,
  required,
  useAuthProvider,
} from "react-admin";
import { useDispatch } from "react-redux";
import Loading from "../layout/Loading";
import { useSelector } from "react-redux";
import walletStyles from "../../components/wallets/walletStyles";
import { environment } from "../../components/common";
import { Grid } from "@material-ui/core";
import { SanitizedGrid } from "../../components/form";
import { addProfile } from "../../components/wallets/methods";
import { triggerProfileProcessing } from "../../components/profile/methods";

/**
 * @summary Renders the form to create a new profile.
 *
 * @param props
 * @returns {JSX.Element}
 */
const ProfilesCreate = () => {
  const notify = useNotify();
  const [mutate] = useMutation();
  const dispatch = useDispatch();
  const redirectTo = useRedirect();
  const loading = useSelector<ReduxState>((state) => state.admin.loading > 0);
  const user = useSelector((state: any) => state.user);
  const classes = walletStyles();
  const [token, setToken] = useState<string>();
  const authProvider = useAuthProvider();

  const userRef = useRef(user);

  const transform = (data: any) => ({
    ...data,
    Name: data.Name,
    Email: userRef.current.Email,
    Organizations: [],
    Tracking: [],
    Untracked: [],
    Wallets: [],
    Notifications: [],
    IsAdmin: false,
  });

  useEffect(() => {
    authProvider.getJWTToken().then((token: string) => {
      setToken(token);
    });
  }, []);

  if (loading || !token) return <Loading />;

  return (
    <div style={{ marginTop: 16 }}>
      <Create
        resource={`${environment("profiles")}`}
        basePath={`${environment("profiles")}`}
        transform={transform}
        onSuccess={(record) => {
          triggerProfileProcessing(record.data, token);
          addProfile(
            user,
            record.data.id,
            record.data.Name,
            mutate,
            dispatch
          ).then(() => {
            notify("pos.profile.switching", { type: "success" });
            redirectTo("/portfolio");
          });
        }}
      >
        <SimpleForm mutationMode="pessimistic">
          <SanitizedGrid
            container
            spacing={2}
            className={classes.formContainer}
          >
            <Grid item xs={12} className={classes.formRow}>
              <TextInput
                source="Name"
                className={classes.inputField}
                margin="normal"
                label="Portfolio Name"
                validate={required()}
              />
            </Grid>
          </SanitizedGrid>
        </SimpleForm>
      </Create>
    </div>
  );
};

export default ProfilesCreate;
