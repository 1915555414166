import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Refresh from "@material-ui/icons/NotInterested";
import History from "@material-ui/icons/History";
import { useAuthenticated, useTranslate, Title, ReduxState } from "react-admin";
import { useSelector } from "react-redux";
import { Loading } from ".";

const useStyles = makeStyles(
  (theme) => ({
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    icon: {
      width: "9em",
      height: "9em",
    },
    message: {
      textAlign: "center",
      fontFamily: "Roboto, sans-serif",
      opacity: 0.5,
      margin: "0 1em",
    },
    toolbar: {
      textAlign: "center",
      marginTop: "2em",
    },
  }),
  { name: "RaNotFound" }
);

const NotFound = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const loading = useSelector<ReduxState>((state) => state.admin.loading > 0);
  useAuthenticated();
  if (loading) return <Loading />;
  return (
    <div className={classes.container}>
      <Title title="Not Found" />
      <div className={classes.message}>
        <Refresh className={classes.icon} />
        <h1>{translate("ra.page.not_found")}</h1>
        <div>{translate("ra.message.not_found")}.</div>
      </div>
      <div className={classes.toolbar}>
        <Button
          variant="contained"
          startIcon={<History />}
          onClick={() => window.history.go(-1)}
        >
          {translate("ra.action.back")}
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
