import React from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  IconButton,
  Divider,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { validatorStyles } from ".";
import { Validator } from "../../views/types";
import { chainConfig } from "../staking/chainConfig";

/**
 * @summary Renders the validator cards visible on the validators view
 *
 * @param props
 * @returns {JSX.Element}
 */
const ValidatorCard = ({ validator }: Props) => {
  const classes = validatorStyles();
  return (
    <Grid container item xs={12} sm={6} md={4} lg={3} xl={2}>
      <Card className={classes.card} variant="outlined">
        <CardHeader
          avatar={
            <Avatar src={`/images/${validator.Symbol.toLowerCase()}.svg`} />
          }
          title={validator.Name}
          titleTypographyProps={{ noWrap: true }}
          action={<PopperMenu validator={validator} />}
        />
        <Divider />
        <CardContent>
          <div>
            <span className={classes.cardSectionLabel}>Uptime: </span>
            <span className={classes.coinCardData}>
              {validator.Uptime.toFixed(2)}%
            </span>
          </div>
          <div style={{ height: 15 }} />
          <div>
            <span className={classes.cardSectionLabel}>Fee: </span>
            <span className={classes.coinCardData}>
              {validator.Fee.toFixed(2)}%
            </span>
          </div>
          <div>
            <span className={classes.cardSectionLabel}>Staked: </span>
            <span className={classes.coinCardData}>
              {validator.Staked.toFixed(2)}
            </span>
          </div>
          <div>
            <span className={classes.cardSectionLabel}>Delegated: </span>
            <span className={classes.coinCardData}>
              {validator.Delegated.toFixed(2)}
            </span>
          </div>
          <div style={{ height: 15 }} />
          <div>
            <span className={classes.cardSectionLabel}>Staking Rewards: </span>
            <span className={classes.coinCardData}>
              {validator.ProjectedStakingRewards.toFixed(2)}
            </span>
          </div>
          <div>
            <span className={classes.cardSectionLabel}>
              Delegation Rewards:{" "}
            </span>
            <span className={classes.coinCardData}>
              {validator.ProjectedDelegationRewards.toFixed(2)}
            </span>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

/**
 * @summary Renders the animated pop out menu on the validator cards
 *
 * @param props
 * @returns {JSX.Element}
 */
function PopperMenu({ validator }: Props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const history = useHistory();
  return (
    <div>
      <IconButton
        aria-label="settings"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => history.push(`delegate&${validator.Symbol}`)}
          disabled={
            //@ts-ignore
            chainConfig[validator.Symbol] && window.keplr ? false : true
          }
        >
          Delegate
        </MenuItem>
        <MenuItem
          onClick={() => history.push(`validator/${validator.id}/metrics`)}
          disabled={true}
        >
          Metrics
        </MenuItem>
        <MenuItem
          onClick={() =>
            history.push(`validator/${validator.id}/validator-history`)
          }
          disabled={true}
        >
          History
        </MenuItem>
      </Menu>
    </div>
  );
}

interface Props {
  validator: Validator;
}

export default ValidatorCard;
