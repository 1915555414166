import { useCallback, useEffect, useState } from "react";
import {
  SimpleForm,
  SelectInput,
  Toolbar,
  ToolbarProps,
  SaveButton,
  useNotify,
  useRedirect,
  Create,
  CreateProps,
  useMutation,
  useDataProvider,
  NumberInput,
} from "react-admin";
import walletStyles from "../../components/wallets/walletStyles";
import { environment } from "../../components/common";
import { Grid, CircularProgress } from "@material-ui/core";
import { SanitizedGrid } from "../../components/form";
import keplrInit from "../../components/staking/keplrInit";
import { chainConfig } from "../../components/staking/chainConfig";
import { Loading } from "..";
import SendIcon from "@material-ui/icons/Send";

/**
 * @summary Renders the form used to add a shared profile by profile id.
 *
 * @param props
 * @returns {JSX.Element}
 */
const Delegate = ({ symbol, ...props }: DelegateProps) => {
  const [accounts, setAccounts] = useState<string[]>();
  const [sending, setSending] = useState<boolean>(false);
  const notify = useNotify();
  const redirectTo = useRedirect();
  const classes = walletStyles();
  const dataProvider = useDataProvider();
  const [mutate] = useMutation();

  useEffect(() => {
    keplrInit(setAccounts, chainConfig[symbol]);
  }, []);

  const handleResult = useCallback((success: boolean, message: string) => {
    if (!success) {
      notify(message, { type: "error" });
      setSending(false);
    } else {
      notify(message, { type: "success" });
      setSending(false);
      redirectTo("/validators");
    }
  }, []);

  const save = useCallback(
    (values) => {
      const errors: any = {};
      const { Address, Validator, Amount } = values;
      if (!Address) errors.Address = "ra.validation.required";
      if (!Validator) errors.Validator = "ra.validation.required";
      if (!Amount || Amount < 0) errors.Amount = "ra.validation.required";

      if (Object.keys(errors).length > 0) return errors;
      setSending(true);
      import("../../components/staking/stake").then(({ stake }) => {
        stake(
          chainConfig[symbol],
          Validator,
          Address,
          Math.floor(Amount * Math.pow(10, chainConfig[symbol].decimals)),
          handleResult
        );
      });
    },
    [mutate, dataProvider]
  );

  const SaveBar = (props: ToolbarProps) => (
    <Toolbar {...props}>
      <SaveButton
        disabled={props.pristine || sending}
        label="Stake"
        icon={
          sending ? (
            <CircularProgress color="inherit" size={18} thickness={7} />
          ) : (
            <SendIcon />
          )
        }
      />
    </Toolbar>
  );

  if (!accounts) return <Loading />;

  const addresses = accounts.map((account: any) => ({
    id: account.address,
    name: account.address,
  }));

  const validators = chainConfig[symbol].validators.map((address: string) => ({
    id: address,
    name: address,
  }));

  return (
    <div style={{ marginTop: 16 }}>
      <Create
        {...props}
        basePath={`/validators`}
        resource={`${environment("validators")}`}
      >
        <SimpleForm
          mutationMode="pessimistic"
          toolbar={<SaveBar />}
          save={save}
        >
          <SanitizedGrid
            container
            spacing={2}
            className={classes.formContainer}
          >
            <Grid item xs={12} className={classes.formRow}>
              <SelectInput
                source="Address"
                className={classes.inputField}
                choices={addresses}
                defaultValue={addresses[0].id}
                label="Address"
                disabled={sending}
              />
              <SelectInput
                source="Validator"
                className={classes.inputField}
                choices={validators}
                defaultValue={validators[0].id}
                size="medium"
                disabled={sending}
              />
              <NumberInput
                source="Amount"
                className={classes.inputField}
                margin="normal"
                label="Amount"
                size="small"
                disabled={sending}
              />
            </Grid>
          </SanitizedGrid>
        </SimpleForm>
      </Create>
    </div>
  );
};

interface DelegateProps extends CreateProps {
  symbol: string;
}

export default Delegate;
