import { useRef, useLayoutEffect, useEffect } from 'react'
import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5percent from '@amcharts/amcharts5/percent'
import { useTheme, makeStyles } from '@material-ui/core'
import { Distribution } from '../../views/types'
import { sortArray } from '../../firebase/misc'
import { getChartTheme } from './methods'
import { blackCoins, alternativeBlacks, republicBlues } from '../../Resources'
import { useMediaQuery, Theme } from '@material-ui/core'

const PortfolioBalancePie = ({ distribution, totalValue, mobile }: Props) => {
  const rootRef = useRef<am5.Root | null>(null)
  const chartRef = useRef<am5percent.PieChart | null>(null)
  const seriesRef = useRef<am5percent.PieSeries | null>(null)
  const balanceRef = useRef<am5.Label | null>(null)
  const theme = useTheme()
  const chartTheme = getChartTheme(theme)
  const classes = pieStyle()

  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

  const isXLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'))

  sortArray(distribution, 'value', 'desc')

  useEffect(() => {
    return () => {
      chartRef.current && chartRef.current.dispose()
    }
  }, [])

  useLayoutEffect(() => {
    if (!rootRef.current && !chartRef.current && !seriesRef.current) {
      rootRef.current = am5.Root.new('balancepiediv')

      // Set the font and positive/negative colors.
      const myTheme = am5.Theme.new(rootRef.current)
      myTheme.rule('Label').setAll(chartTheme.fontStyle)
      myTheme.rule('InterfaceColors').setAll({
        positive: chartTheme.positive,
        negative: chartTheme.negative,
      })
      rootRef.current.setThemes([am5themes_Animated.new(rootRef.current), myTheme])

      chartRef.current = rootRef.current.container.children.push(
        am5percent.PieChart.new(rootRef.current, {
          innerRadius: isXSmall ? 90 : 110,
          layout: rootRef.current.verticalLayout,
        })
      )

      seriesRef.current = chartRef.current.series.push(
        am5percent.PieSeries.new(rootRef.current, {
          valueField: 'value',
          categoryField: 'symbol',
        })
      )

      seriesRef!.current!.ticks.template.setAll({
        forceHidden: true,
      })

      seriesRef!.current!.labels.template.setAll({
        forceHidden: true,
      })

      let blueIndex = 0
      let blackIndex = 0

      seriesRef!.current!.get('colors')?.set(
        'colors',
        //@ts-ignore
        distribution.map(d => {
          if (d.color) {
            if (blackCoins.includes(d.symbol) && theme.palette.type === 'dark') {
              blackIndex++
              if (blackIndex > alternativeBlacks.length - 1) blackIndex = 1
              return alternativeBlacks[blackIndex - 1]
            }
            return d.color
          } else {
            blueIndex++
            if (blueIndex > republicBlues.length - 1) blueIndex = 1
            return republicBlues[blueIndex - 1]
          }
        })
      )

      // Add label
      balanceRef.current = rootRef.current.tooltipContainer.children.push(
        am5.Label.new(rootRef.current, {
          x: am5.p50,
          y: am5.p50,
          centerX: am5.p50,
          centerY: am5.p50,
          fill: chartTheme.themeFillStroke,
          fontSize: isXSmall ? 20 : 24,
        })
      )
      // balanceRef.current.set("text", `${toCurrencyString(totalValue)}`);

      seriesRef!.current!.data.setAll(distribution)
      seriesRef!.current!.appear(1000, 100)
    }
  }, [])

  useEffect(() => {
    if (isXSmall) {
      balanceRef.current?.setAll({
        fontSize: 20,
      })
      chartRef.current?.setAll({
        innerRadius: 95,
      })
    }
    if (isXLarge) {
      balanceRef.current?.setAll({
        fontSize: 25,
      })
      chartRef.current?.setAll({
        innerRadius: 120,
      })
    }
    if (!isXSmall && !isXLarge) {
      balanceRef.current?.setAll({
        fontSize: 24,
      })
      chartRef.current?.setAll({
        innerRadius: 110,
      })
    }
  }, [isXSmall, isXLarge])

  return <div id='balancepiediv' className={classes.container} style={{ opacity: 0.85 }}></div>
}

const pieStyle = makeStyles(theme => ({
  container: {
    height: 370,
    width: 370,
    marginTop: -20,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.between('sm', 'lg')]: {
      height: 333,
      width: 333,
    },
    [theme.breakpoints.down('xs')]: {
      height: 280,
      width: 280,
      marginTop: 10,
    },
  },
}))

interface Props {
  distribution: Distribution[]
  totalValue: number
  mobile: boolean
}

export default PortfolioBalancePie
