import { UserDoc } from "../../views/types";

/**
 * @summary Trigger an update after adding a new profile.
 *
 * @param record
 * @param token
 * @returns {() => void}
 */
export const triggerProfileProcessing = (record: UserDoc, token: string) => {
  const url =
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? "https://radar-proxy.republic.com/Event/profile"
      : "https://radar-proxy.republic-beta.app/Event/profile";
  const body = {
    profileId: record.id,
  };
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };

  return fetch(url, options).catch((err) => console.log(err));
};
