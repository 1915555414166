import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  useAuthenticated,
  Title,
  LoadingIndicator,
  ReduxState,
} from "react-admin";
import { useSelector } from "react-redux";
import R from "../../components/layout/R.svg";

/**
 * @summary An interim loading page till we find/make something cooler
 *
 * @returns {JSX.Element}
 */
const Loading = () => {
  const [showRefresh, setShowRefresh] = useState<boolean>(false);
  const classes = useStyles();
  const loading = useSelector<ReduxState>((state) => state.admin.loading > 0);

  useEffect(() => {
    let timer: any;
    if (!loading) timer = setTimeout(() => setShowRefresh(true), 1000);
    return () => {
      timer && clearTimeout(timer);
    };
  }, [loading]);

  useAuthenticated();
  return (
    <div className={classes.container}>
      <Title title="Loading" />
      <div className={classes.loadingContainer}>
        <div className={classes.logoContainer}>
          <img src={R} className={`shimmer ${classes.logo}`} alt={"Loading"} />
        </div>
        <div className={classes.spinnerContainer}>
          {showRefresh && <LoadingIndicator />}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: 28,
    height: "100%",
  },
  loadingContainer: {
    textAlign: "center",
    margin: "0 1em",
    paddingBottom: "10vh",
  },
  logo: {
    [theme.breakpoints.down("xs")]: {
      height: 55,
    },
    [theme.breakpoints.up("sm")]: {
      height: 60,
    },
    [theme.breakpoints.up("md")]: {
      height: 70,
    },
    [theme.breakpoints.up("lg")]: {
      height: 75,
    },
    [theme.breakpoints.up("xl")]: {
      height: 80,
    },
  },
  logoContainer: { opacity: 0.75 },
  spinnerContainer: { height: 30, paddingTop: 5, opacity: 0.75 },
}));

export default Loading;
