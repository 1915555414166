import React from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Button,
  IconButton,
  Divider,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { portfolioStyles } from ".";
import { Wallet, CoinConfig } from "../../views/types";
import { toCurrencyString } from "../common";
import { environment } from "../common";
import { useTranslate } from "react-admin";

/**
 * @summary Renders the wallet cards visible on the portfolio view
 *
 * @param props
 * @returns {JSX.Element}
 */
const PortfolioWalletCard = ({ wallet, coinConfig, user }: Props) => {
  const classes = portfolioStyles();
  const history = useHistory();
  const translate = useTranslate();
  const balance = wallet.Balance;
  const staked = wallet.Staked ? wallet.Staked : 0;
  const pendingRewards = wallet.Rewards ? wallet.Rewards : 0;
  const quote = wallet.Manual ? 0 : coinConfig.Quote;
  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
      <Card className={classes.card} variant="outlined">
        <CardHeader
          avatar={
            <Avatar
              src={`/images/${
                wallet.Manual ? "republic-icon" : wallet.Symbol.toLowerCase()
              }.svg`}
            />
          }
          title={wallet.Alias}
          subheader={wallet.Symbol}
          action={<PopperMenu user={user} wallet={wallet} />}
          titleTypographyProps={{ noWrap: true }}
        />
        <Divider />
        <CardContent>
          <div style={{ height: 85 }}>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td>{translate("pos.wallets.available")}:</td>
                  <td style={{ textAlign: "right", fontSize: "0.87rem" }}>
                    {balance.toLocaleString("en-US")} {wallet.Symbol}
                  </td>
                </tr>
                {staked > 0 && (
                  <tr>
                    <td>{translate("pos.wallets.staked")}:</td>
                    <td style={{ textAlign: "right", fontSize: "0.87rem" }}>
                      {staked.toLocaleString("en-US")} {wallet.Symbol}
                    </td>
                  </tr>
                )}
                {pendingRewards > 0 && (
                  <tr>
                    <td>{translate("pos.wallets.rewards")}:</td>
                    <td style={{ textAlign: "right", fontSize: "0.87rem" }}>
                      {pendingRewards.toLocaleString("en-US")} {wallet.Symbol}
                    </td>
                  </tr>
                )}
                <tr>
                  <td>{translate("pos.wallets.value")}: </td>
                  <th style={{ textAlign: "right", fontSize: "0.87rem" }}>
                    {toCurrencyString(
                      wallet.Manual
                        ? wallet.Value
                        : (balance + staked + pendingRewards) * quote
                    )}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={() =>
              wallet.Manual
                ? history.push(`/edit-untracked&${wallet.Symbol}`)
                : history.push(`/${wallet.Symbol}`)
            }
          >
            {translate("pos.wallets.price_data")}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

/**
 * @summary Renders the animated pop out menu on the wallet cards
 *
 * @param props
 * @returns {JSX.Element}
 */
function PopperMenu({ user, wallet }: PopperProps) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const history = useHistory();

  const walletPath = !wallet.Manual ? wallet.id.split("/") : false;
  return (
    <div>
      <IconButton
        aria-label="settings"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() =>
            history.push(
              !wallet.Manual
                ? `${environment("profiles")}&${user.ActiveProfile}&data&${
                    walletPath[0]
                  }&${walletPath[1]}/${walletPath[2]}`
                : `edit-untracked&${wallet.Symbol}`
            )
          }
        >
          Edit
        </MenuItem>
        <MenuItem onClick={() => history.push(`/wallets`)}>Manage</MenuItem>
      </Menu>
    </div>
  );
}

interface Props {
  wallet: Wallet;
  coinConfig: CoinConfig;
  user: { id: string };
}

interface PopperProps {
  user: any;
  wallet: any;
}

export default PortfolioWalletCard;
