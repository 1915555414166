import {
  DeleteWithConfirmButtonProps,
  Button,
  useMutation,
  useDelete,
  useNotify,
  useDataProvider,
} from "react-admin";
import { useDispatch } from "react-redux";
import { UserDoc } from "../../views/types";
import { walletInfo } from "../../components/common";
import { removeWalletRef } from "./methods";
import { useHistory } from "react-router-dom";
import { IDataProvider } from "../../firebase/providers/DataProvider";
import { environment } from "../../components/common";
import DeleteIcon from "@material-ui/icons/Delete";
import { walletStyles } from ".";

/**
 * @summary Returns a button that will delete a wallet and associated tags from a users profile.
 *
 * @param props
 * @returns {JSX.Element}
 */
const WalletDeleteButton = ({
  record,
  user,
  docRef,
  setLoading,
}: WalletDeleteButtonProps) => {
  const [mutate] = useMutation();
  const [deleteOne] = useDelete();
  const dispatch = useDispatch();
  const notify = useNotify();
  const dataProvider: IDataProvider = useDataProvider();
  const history = useHistory();

  const ref = docRef ? docRef : record ? `${record.id}` : "";
  const { symbol, network, address } = walletInfo(ref);
  const classes = walletStyles();

  // Hack to to reload the page without having to load resources.
  const forceReset = () => {
    history.push("/");
    history.push("wallets");
  };

  // Remove references and if successful remove the wallet.
  const handleDelete = () => {
    setLoading(true);
    removeWalletRef(user, ref, mutate, dispatch, dataProvider)
      .then(() => {
        deleteOne(
          `${environment("profiles")}/${
            user.ActiveProfile
          }/data/${symbol}/${network}`,
          address,
          { ...record, id: address },
          {
            onSuccess: () => {
              forceReset();
              notify("pos.wallets.deleted", { type: "error" });
            },
            onFailure: () => {
              forceReset();
              notify("pos.wallets.error", { type: "error" });
            },
          }
        );
      })
      .catch((err) => {
        console.log(err);
        forceReset();
        notify("pos.wallets.error", { type: "error" });
      });
  };

  return (
    <div className={classes.deleteButton}>
      <Button label="Delete" onClick={() => handleDelete()} color="inherit">
        <DeleteIcon color="error" />
      </Button>
    </div>
  );
};

interface WalletDeleteButtonProps extends DeleteWithConfirmButtonProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  docRef?: string;
  user: UserDoc;
}

export default WalletDeleteButton;
