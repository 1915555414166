import { TranslationMessages } from "react-admin";
import frenchMessages from "ra-language-french";

const customFrenchMessages: TranslationMessages = {
  ...frenchMessages,
  pos: {
    search: "Rechercher",
    configuration: "Configuration",
    language: "Langue",
    theme: {
      name: "Theme",
      light: "Clair",
      dark: "Obscur",
    },
    integration: "Intégrations",
    share_keys: "Partager",
    add_new: "Ajouter",
    remove: "Supprimer",
    add_key: "Ajouter une clé",
    add_shared: "Ajouter Partagé",
    create_new: "Créer un Nouveau",
    edit: "Éditer",
    copy: "Copie",
    dashboard: {
      welcome: {
        title: "Bienvenue dans l'outil de gestion du Trésor Republic!",
        subtitle:
          "Cette application est encore en phase alpha, vous pouvez donc rencontrer des problèmes, mais n'hésitez pas à explorer et à faire part de vos recommandations.",
        ra_button: "Site web de react-admin",
        demo_button: "Code source de cette démo",
      },
      total_portfolio_value: "Valeur Totale du Portefeuille",
      based_on_value: "Basé sur la Valeur Actuelle",
      performance_comparison: "Comparaison des Performances",
      compared_to_bitcoin: "Par Rapport à la Détention de Bitcoin",
      current_position: "Position Actuelle",
      based_on_balances: "Basé sur les Soldes Actuels",
      portfolio_performance: "Performances du Portefeuille",
      based_on_history: "Depuis la Création du Compte",
      total_portfolio: "Portefeuille Total",
    },
    menu: {
      staking: "Jalonnement",
      trades: "Métiers",
      admin: "Administrateur",
      price_sources: "Sources de Prix",
      symbols: "Symboles",
      portfolio: "Portefeuille",
      rewards: "Récompenses",
      market_data: "Données",
      validator_performance: "Validateurs",
      news: "Nouvelles",
      wallets: "Portefeuilles",
      settings: "Paramètres",
    },
    table: {
      delete_validator_title: "Supprimer le Validateur?",
      delete_price_source_name: "Supprimer la Source du Prix?",
      delete_price_source_content: "Delete price data?",
      networks: "Réseaux",
      token_contracts: "Contrats de Jetons",
      decimals: "Décimales",
      contract_address: "Adresse du Contrat",
      start_time: "Heure de Début",
      end_time: "Heure de Fin",
      staked: "Jalonné",
      delegated: "Délégué",
      name: "Nom",
      symbol: "Symbole",
      alias: "Alias",
      address: "Adresse",
      headers: "En-Têtes",
      baseUri: "URI de Base",
      manual: "Manuel",
      description: "La Fescription",
      parent: "Parent",
      source: "La Source",
      id: "Id",
      volume_24: "24h Volume",
      volume_24_short: "Vol",
      market_cap: "Capitalisation",
      diluted_market_cap: "Diluted Market Cap",
      market_cap_short: "Cap",
      price: "Prix",
      total_supply: "Total Supply",
      max_supply: "Max Supply",
      rank: "Rank",
      last_seen: "Été Vu",
      last_paid: "Dernier Payé",
      next_payment: "Prochain Paiement",
      node_address: "Node Adresse",
      pending: "En Attendant",
      uptime: "Uptime",
      network: "Réseau",
      date: "Date",
      destination_address: "Adresse Dest",
      voa: "V.O.A",
      quantity: "Quantité",
      delete_wallet_title: "Voulez-vous vraiment supprimer cette adresse?",
      delete_wallet_content:
        "Si cette adresse a été utilisée pour staking et nécessite un rapport, vous devriez considérer ce choix.",
      risk_metrics: "Mesures de Risque",
      value_at_risk: "Valeur à Risque",
      gain_pain_ratio: "Gain Pain Rapport",
      ulcer_index: "Ulcer Indice",
      start_date: "Date de début",
      end_date: "Date de fin",
      closing_price: "Dernier prix",
      seven_day_av_volume: "7 Day Av. Volume",
      thirty_day_av_volume: "30 Day Av. Volume",
    },
    wallets: {
      deleted: "Portefeuille supprimé",
      created: "L'affichage des soldes peut prendre quelques minutes",
      error: "Impossible de mettre à jour le profil",
      create_wallet: "Ajouter",
      created_manual_wallet: "Portefeuille créé",
      updated: "Portefeuille mis à jour",
      available: "Disponible",
      staked: "Jalonné",
      rewards: "Récompenses",
      value: "Valeur",
      price_data: "Données de prix",
    },
    profile: {
      switching: "Portefeuille ajouté. Commutation...",
      already_exists: "Portfolio déjà ajouté",
      not_found: "Le portefeuille n'existe pas",
      check_failed: "Problème pour vérifier si le portefeuille existe",
      removed: "Profil partagé supprimé",
      delete_failed: "Échec de la suppression du profil partagé",
      updated: "Portefeuille mis à jour",
      table_view: "Vue de Tableau",
    },
    integrations: {
      created:
        "Intégration créée. L'affichage des soldes peut prendre une minute.",
    },
    app: {
      no_connection: "Pas de connexion Internet",
      save_failed: "Problème de sauvegarde des données",
    },
    graph: {
      show_legend: "Afficher la Légende",
      hide_legend: "Masquer la Légende",
      portfolio: "Portefeuille",
      all: "Tout",
      hide_all: "Cacher Tout",
      show_all: "Afficher Tout",
    },
  },
  resources: {},
};

export default customFrenchMessages;
