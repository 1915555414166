import { useState, useEffect, useRef, useCallback, MouseEvent } from 'react'
import { setCoinConfigState } from '../../components/common'
import { useSelector } from 'react-redux'
import { CoinConfig } from '../types'
import Loading from '../layout/Loading'
import { useDataProvider, useRedirect } from 'react-admin'
import { IDataProvider } from '../../firebase/providers/DataProvider'
import { portfolioStyles } from '../../components/portfolio'
import {
  DataGridPro,
  GridColDef,
  GridRowData,
  GridCellValue,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid-pro'
import { Paper, Box, useMediaQuery, Theme, useTheme, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import FilterIcon from '@material-ui/icons/CallSplit'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { toCurrencyString } from '../../components/common'
import { tagMap } from '../../Resources'
import { sortArray } from '../../firebase/misc'

const MarketDataUI = () => {
  const [coinConfig, setCoinConfig] = useState<{ [key: string]: CoinConfig }>()
  const [data, setData] = useState<GridRowData[]>()
  const [loading, setLoading] = useState<boolean>(true)
  const [tags, setTags] = useState<{ name: string; type: string; id: string }[]>()
  const [activeTag, setActiveTag] = useState<{
    name: string
    type: string
    id: string
  }>({ name: 'All', type: 'Default', id: 'all' })
  const [density, setDensity] = useState<'compact' | 'standard' | 'comfortable'>('compact')

  const mountedRef = useRef(true)
  const isMounted = useCallback(() => mountedRef.current, [mountedRef.current])
  const cache: any = useSelector((state: any) => state.admin)
  const theme = useTheme()

  const dataProvider: IDataProvider = useDataProvider()
  const redirectTo = useRedirect()
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const classes = useStyles()

  useEffect(() => {
    setCoinConfigState(undefined, cache, dataProvider, setCoinConfig, isMounted)

    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    if (coinConfig && Object.keys(coinConfig).length > 0) {
      const tagData: { name: string; type: string; id: string }[] = [
        { name: 'All', type: 'Default', id: 'all' },
      ]
      const rows = Object.keys(coinConfig).map(key => {
        if (coinConfig[key].Tags && coinConfig[key].Tags.length > 0) {
          coinConfig[key].Tags.forEach((tag: string) => {
            if (tagMap[tag] && !tagData.find(t => t.name === tagMap[tag].name))
              tagData.push({ ...tagMap[tag], id: tag })
          })
        }
        return {
          ...coinConfig[key],
          id: key,
          IconURL: `/images/${key.toLowerCase()}.svg`,
        }
      })
      sortArray(rows, 'MarketCapitalization', 'desc')
      setData(rows)
      setTags(tagData)
      setLoading(false)
    }
  }, [coinConfig])

  useEffect(() => {
    if (coinConfig && activeTag) {
      const rows = Object.keys(coinConfig)
        .map(key => ({
          ...coinConfig[key],
          id: key,
          IconURL: `/images/${key.toLowerCase()}.svg`,
        }))
        .filter(d =>
          activeTag.name === 'All' ? true : d.Tags && d.Tags.find(t => t === activeTag.id),
        )
      sortArray(rows, 'MarketCapitalization', 'desc')
      setData(rows)
    }
  }, [activeTag])

  const handleRowClick = (evt: any) => {
    redirectTo(`/${evt.id}`)
  }

  if (loading || !data) return <Loading />

  const columns: GridColDef[] = [
    {
      field: 'IconURL',
      headerName: ' ',
      renderHeader: () => null,
      disableColumnMenu: true,
      width: density === 'compact' ? 20 : 26,
      minWidth: density === 'compact' ? (isXSmall ? 30 : 40) : 40,
      sortable: false,
      disableExport: true,
      renderCell: (params: any) => {
        return (
          <img
            src={params.value}
            style={{
              height: density === 'compact' ? 20 : 26,
              width: density === 'compact' ? 20 : 26,
            }}
            alt={' '}
          />
        )
      },
    },
    {
      field: 'Name',
      headerName: 'Name',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      flex: 1,
      minWidth: 100,
      editable: true,
    },
    {
      field: 'Symbol',
      headerName: 'Ticker',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      minWidth: 90,
      hide: isXSmall,
    },
    {
      field: 'Quote',
      headerName: 'Price',
      disableColumnMenu: true,
      hideSortIcons: true,
      type: 'number',
      minWidth: 100,
      valueFormatter: ({ value }: any) => toCurrencyString(value),
    },
    {
      field: 'PercentChange',
      headerName: '24hr',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      minWidth: 100,
      renderCell: params => addDirectionColor(params.value, theme, 'percent'),
    },
    {
      field: 'PercentChange_7d',
      headerName: '7d',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      minWidth: 100,
      renderCell: params => addDirectionColor(params.value, theme, 'percent'),
    },
    {
      field: 'PercentChange_30d',
      headerName: '30d',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      minWidth: 100,
      renderCell: params => addDirectionColor(params.value, theme, 'percent'),
    },
    {
      field: 'Volume',
      headerName: 'Volume',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      flex: 1,
      minWidth: 100,
      valueFormatter: ({ value }: any) => toCurrencyString(value),
    },
    {
      field: 'MarketCapitalization',
      headerName: 'Market Cap',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      flex: 1,
      minWidth: 100,
      valueFormatter: ({ value }: any) => toCurrencyString(value),
    },
    {
      field: 'FullyDilutedMarketCapitalization',
      headerName: 'Fully Diluted',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      flex: 1,
      minWidth: 100,
      valueFormatter: ({ value }: any) => toCurrencyString(value),
    },
    {
      field: 'TotalSupply',
      headerName: 'Total Supply',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      flex: 1,
      valueFormatter: ({ value }: any) =>
        value !== 0 ? new Intl.NumberFormat('en-US').format(value) : 'N/K',
      minWidth: isXSmall ? 80 : 100,
    },
    {
      field: 'MaxSupply',
      headerName: 'Max Supply',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      flex: 1,
      valueFormatter: ({ value }: any) =>
        new Intl.NumberFormat('en-US').format(value !== 0 ? value : Infinity),
      minWidth: isXSmall ? 80 : 100,
    },
  ]

  const PortfolioSummaryListTopbar = () => {
    const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
    return (
      <div className={classes.container}>
        <GridToolbarContainer className={classes.flexContainer}>
          <div style={{ marginRight: 'auto' }}>
            {!isXSmall && <GridToolbarColumnsButton />}
            {!isXSmall && <GridToolbarDensitySelector />}
            {!isXSmall && <GridToolbarFilterButton />}
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <FilterMenu tags={tags} setActiveTag={setActiveTag} activeTag={activeTag} />
            <GridToolbarExport
              csvOptions={{
                fileName: `Market Snapshot ${new Date().toDateString()}`,
                allColumns: isXSmall,
              }}
            />
          </div>
        </GridToolbarContainer>
      </div>
    )
  }

  const handleDensityChange = (state: any) => {
    if (state.density.value !== density) {
      setDensity(state.density?.value)
    }
  }

  return (
    <>
      <div className={classes.topSpacer}>
        <Paper variant='outlined' style={{ flexGrow: 1 }}>
          <Box
            style={{
              height: 'calc(90vh - 80px)',
              minHeight: 300,
              width: '100%',
            }}
          >
            <DataGridPro
              rows={data}
              columns={columns}
              components={{
                Toolbar: PortfolioSummaryListTopbar,
              }}
              loading={loading}
              checkboxSelection={false}
              disableSelectionOnClick
              onRowClick={handleRowClick}
              density='compact'
              autoPageSize
              classes={{ row: classes.clickableRow }}
              onStateChange={(state: any) => handleDensityChange(state)}
            />
          </Box>
        </Paper>
      </div>
    </>
  )
}

const addDirectionColor = (
  value: GridCellValue | undefined,
  theme: Theme,
  type: 'number' | 'percent' | 'currency',
) => {
  if (typeof value === 'undefined') return null
  if (isNaN(Number(value))) return <span>N/A</span>
  return (
    <span
      style={{
        color: value && value < 0 ? theme.palette.error.main : theme.palette.success.main,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}
    >
      {type === 'number' && `${new Intl.NumberFormat('en-US').format(Number(value))}`}
      {type === 'percent' && `${new Intl.NumberFormat('en-US').format(Number(value))}%`}
      {type === 'currency' && `${toCurrencyString(Number(value))}`}
    </span>
  )
}

function FilterMenu({
  tags,
  activeTag,
  setActiveTag,
}: {
  tags: { name: string; type: string; id: string }[] | undefined
  activeTag: { name: string; type: string; id: string }
  setActiveTag: any
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [defaultTags, setDefaultTags] = useState<{ name: string; type: string; id: string }[]>()
  const [utilityTags, setUtilityTags] = useState<{ name: string; type: string; id: string }[]>()
  const [ecosystemTags, setEcosystemTags] = useState<{ name: string; type: string; id: string }[]>()
  const [portfolioTags, setPortfolioTags] = useState<{ name: string; type: string; id: string }[]>()

  const classes = portfolioStyles()

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (tag: { name: string; type: string; id: string }) => {
    setActiveTag(tag)
    handleClose()
  }

  useEffect(() => {
    setDefaultTags(tags?.filter(t => t.type === 'Default'))
    setUtilityTags(tags?.filter(t => t.type === 'Utility'))
    setEcosystemTags(tags?.filter(t => t.type === 'Ecosystem'))
    setPortfolioTags(tags?.filter(t => t.type === 'Portfolio'))
  }, [tags])

  return (
    <div>
      <Button
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
        size='small'
        color='primary'
        startIcon={<FilterIcon />}
        className={classes.filterButton}
        style={{ marginRight: 5 }}
      >
        {activeTag.name}
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {defaultTags?.map(tag => {
          return (
            <MenuItem
              onClick={() => handleChange(tag)}
              disabled={tag.name === activeTag.name}
              key={tag.name}
            >
              {tag.name}
            </MenuItem>
          )
        })}

        {utilityTags && (
          <Box>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <Box style={{ paddingLeft: 15, paddingBottom: 5 }}>
              <small style={{ opacity: 0.6 }}>Utility</small>
            </Box>
          </Box>
        )}

        {utilityTags?.map(tag => {
          return (
            <MenuItem
              onClick={() => handleChange(tag)}
              disabled={tag.name === activeTag.name}
              key={tag.name}
            >
              {tag.name}
            </MenuItem>
          )
        })}

        {ecosystemTags && (
          <Box>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <Box style={{ paddingLeft: 15, paddingBottom: 5 }}>
              <small style={{ opacity: 0.6 }}>Ecosystem</small>
            </Box>
          </Box>
        )}

        {ecosystemTags?.map(tag => {
          return (
            <MenuItem
              onClick={() => handleChange(tag)}
              disabled={tag.name === activeTag.name}
              key={tag.name}
            >
              {tag.name}
            </MenuItem>
          )
        })}

        {portfolioTags && (
          <Box>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <Box style={{ paddingLeft: 15, paddingBottom: 5 }}>
              <small style={{ opacity: 0.6 }}>Venture Funds</small>
            </Box>
          </Box>
        )}

        {portfolioTags?.map(tag => {
          return (
            <MenuItem
              onClick={() => handleChange(tag)}
              disabled={tag.name === activeTag.name}
              key={tag.name}
            >
              {tag.name}
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  topSpacer: {
    marginTop: 70,
    [theme.breakpoints.down('xs')]: {
      marginTop: 65,
    },
  },
  container: {
    position: 'absolute',
    top: -53,
    left: 0,
    zIndex: 10,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      top: -61,
    },
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 5,
    paddingBottom: 12,
  },
  clickableRow: {
    cursor: 'pointer',
  },
}))

export default MarketDataUI
