import {
  Create,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  SelectInput,
  ReferenceInput,
} from "react-admin";
import { CreateProps } from "react-admin";
import { environment } from "../../components/common";

/**
 * @summary The edit view of the validator endpoint.
 *
 * @param props
 * @returns {JSX.Element}
 */
const ValidatorEdit = (props: CreateProps) => {
  return (
    <Create title="Edit a Validator" {...props} actions={<PostEditActions />}>
      <SimpleForm>
        <TextInput
          source="Name"
          label="The name of the validator."
          placeholder="Republic AVAX-1"
        />
        <TextInput
          source="Address"
          label="The id/address of the validator."
          placeholder="abcd1234efgh5678"
        />
        <ReferenceInput
          label="Symbol"
          source="Symbol"
          reference={`${environment("coin-config")}`}
        >
          <SelectInput optionText="Name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

const PostEditActions = () => (
  <TopToolbar>
    <ListButton basePath={`/${environment("validators")}`} />
  </TopToolbar>
);

export default ValidatorEdit;
