/**
 * @summary Returns the network/token type for a selected currency.
 *
 * @param currencySymbol
 * @param networkSymbol
 * @param networkName
 * @returns {string}
 */
export const networkType = (currencySymbol: string, networkSymbol: string, networkName: string) => {
  if (currencySymbol === networkSymbol) {
    if (networkSymbol === 'BNB') return 'Binance Smart Chain'
    return networkName
  }
  if (networkSymbol === 'ETH') return 'Ethereum (ERC20)'
  if (networkSymbol === 'BNB') return 'Binance Smart Chain (BEP20)'
  if (networkSymbol === 'AVAX') return 'Avalanche (ERC20)'
  if (networkSymbol === 'SOL') return 'Solana (SPL)'
  if (networkSymbol === 'TRON') return 'TRON (ERC20)'
  return networkName
}

export const addressValidation: { [key: string]: RegExp } = {
  BTC: /^(?:[13]{1}[a-km-zA-HJ-NP-Z1-9]{26,33}|bc1[a-z0-9]{39,59})$/,
  BCH: /^(?:[13]{1}[a-km-zA-HJ-NP-Z1-9]{26,33}|((bitcoincash|bchreg|bchtest):)?(q|p)[a-z0-9]{41})$/,
  BSV: /^(?:[13]{1}[a-km-zA-HJ-NP-Z1-9]{26,33}|((bitcoincash|bchreg|bchtest):)?(q|p)[a-z0-9]{41})$/,
  ETH: /^0x[a-fA-F0-9]{40}$/,
  BNB: /^0x[a-fA-F0-9]{40}$/,
  MATIC: /^0x[a-fA-F0-9]{40}$/,
  LTC: /^(?:[LM3]{1}[a-km-zA-HJ-NP-Z1-9]{26,33}|ltc1[a-z0-9]{39,59})$/,
  TRON: /^0x[a-fA-F0-9]{40}$/,
  AVAX: /^(([XPC]|[a-km-zA-HJ-NP-Z1-9]{36,72})-[a-zA-Z]{1,83}1[qpzry9x8gf2tvdw0s3jn54khce6mua7l]{38})|0x[a-fA-F0-9]{40}$/,
  SOL: /^[1-9A-HJ-NP-Za-km-z]{32,44}$/,
  REGEN: /^regen[a-zA-Z0-9]{39}$/,
  SCRT: /^secret[a-zA-Z0-9]{39}$/,
  ATOM: /^cosmos[a-zA-Z0-9]{39}$/,
  ADA: /^(?:^addr1[a-z0-9]{98}|[1-9A-HJ-NP-Za-km-z]{104})$/,
  DOGE: /^D{1}[5-9A-HJ-NP-U]{1}[1-9A-HJ-NP-Za-km-z]{32}$/,
  XMR: /^4[0-9AB][1-9A-HJ-NP-Za-km-z]{93}$/,
  ALOG: /^[a-zA-Z0-9]{58}$/,
  FIO: /^[a-zA-Z0-9]{12}$/,
}

/**
 * @summary Validates the form values of a new wallet before allowing a user to submit.
 *
 * @param values
 * @returns {Object}
 */
export const validateNewWallet = async (values: any) => {
  const errors: any = {}
  const { Symbol, Network, Alias, Address } = values
  const regex = addressValidation[Network]

  if (!Symbol) errors.Symbol = 'ra.validation.required'
  if (!Network) errors.Network = 'ra.validation.required'
  if (!Alias) errors.Alias = 'ra.validation.required'
  if (!Address) errors.Address = 'ra.validation.required'

  if (regex) {
    if (!regex.test(values.Address)) {
      errors.Address = 'Invalid address'
    }
  }

  return errors
}
