import { sortArray } from '../../firebase/misc'
import {
  calculateValueAtRisk,
  calculateConditionalValueAtRisk,
  calculateAnnualizedVolatility,
  calculateUlcerIndex,
} from '../../analytics'
import { CoinConfig, EquityCurves, Wallet } from '../../views/types'
import { coinInfo } from '../../Resources'

/**
 * @summary Returns the row data required for the portfolio summary table.
 *
 * @param wallets
 * @param equityCurves
 * @param totalPortfolioValue
 * @param coinConfig
 * @returns {Object}
 */
const generateRows = (
  wallets: Wallet[],
  equityCurves: EquityCurves,
  totalPortfolioValue: number,
  coinConfig: { [key: string]: CoinConfig }
) => {
  let totalValueAtRisk = 0
  let totalConValueAtRisk = 0

  const data = wallets.map((wallet: Wallet) => {
    const assetData = equityCurves[wallet.id].holdingsData
    sortArray(assetData, 'Timestamp', 'asc')
    const equityCurve = assetData
      .map((day: any) => day.Quote)
      .splice(assetData.length - 31, assetData.length - 1)

    // Currency info.
    const Currency = wallet.Manual ? wallet.Name : coinConfig[wallet.Symbol].Name
    const IconURL = coinInfo[wallet.Symbol]
      ? `/images/${wallet.Symbol?.toLowerCase()}.svg`
      : '/images/republic-icon.svg'

    // Price data.
    const Value = wallet.Manual
      ? equityCurve[equityCurve.length - 1]
      : (wallet.Balance + wallet.Staked) * coinConfig[wallet.Symbol].Quote

    const PercentOfTotal = (Value / totalPortfolioValue) * 100
    const Change24hPercent = wallet.Manual
      ? (Value / equityCurve[equityCurve.length - 2] - 1) * 100
      : coinConfig[wallet.Symbol].PercentChange
    const Change24hValue = wallet.Manual
      ? Value - equityCurve[equityCurve.length - 2]
      : Value * (coinConfig[wallet.Symbol].PercentChange / 100)
    const Change7dPercent = wallet.Manual
      ? (Value / equityCurve[equityCurve.length - 8] - 1) * 100
      : coinConfig[wallet.Symbol].PercentChange_7d
    const Change7dValue = wallet.Manual
      ? Value - equityCurve[equityCurve.length - 8]
      : Value * (coinConfig[wallet.Symbol].PercentChange_7d / 100)
    const Change30dPercent = wallet.Manual
      ? (Value / equityCurve[equityCurve.length - 31] - 1) * 100
      : coinConfig[wallet.Symbol].PercentChange_30d
    const Change30dValue = wallet.Manual
      ? Value - equityCurve[equityCurve.length - 31]
      : Value * (coinConfig[wallet.Symbol].PercentChange_30d / 100)

    // Risk metrics.
    const ValueAtRiskPercentDecimal = calculateValueAtRisk(equityCurve)
    const ValueAtRiskPercentPosition = ValueAtRiskPercentDecimal * 100
    const ValueAtRisk = Value * ValueAtRiskPercentDecimal
    totalValueAtRisk += isNaN(ValueAtRisk) ? 0 : Math.abs(ValueAtRisk)
    const conValueAtRiskPercentDecimal = calculateConditionalValueAtRisk(equityCurve, 0.95)
    const ConValueAtRiskPercentPosition = conValueAtRiskPercentDecimal * 100
    const ConValueAtRisk = Value * conValueAtRiskPercentDecimal
    totalConValueAtRisk += isNaN(ConValueAtRisk) ? 0 : Math.abs(ConValueAtRisk)
    const AnnualizedVol = wallet.Manual ? 0 : calculateAnnualizedVolatility(equityCurve) * 100
    const UlcerIndex = calculateUlcerIndex(equityCurve) * 100
    const KnipperIndex = wallet.Manual ? 0 : Value / (coinConfig[wallet.Symbol].Volume_30d / 30)

    return {
      ...wallet,
      id: (wallet.Alias ? wallet.Alias : wallet.Address) + String(Math.random() * 100),
      Alias: wallet.Alias ? wallet.Alias : wallet.Address,
      IconURL,
      Currency,
      Value,
      Price:
        coinConfig?.[wallet.Symbol]?.Quote ??
        wallet?.PriceHistory?.[wallet.PriceHistory.length - 1]?.Quote ??
        0,
      Change24hPercent,
      Change24hValue,
      Change7dPercent,
      Change7dValue,
      Change30dPercent,
      Change30dValue,
      PercentOfTotal,
      AnnualizedVol,
      ValueAtRiskPercentPosition,
      ValueAtRisk,
      ConValueAtRiskPercentPosition,
      ConValueAtRisk,
      UlcerIndex,
      KnipperIndex,
    }
  })

  const rows = data.map((row: any) => {
    return {
      ...row,
      ValueAtRiskPercentTotal: (Math.abs(row.ValueAtRisk) / totalValueAtRisk) * 100,
      ConValueAtRiskPercentTotal: (Math.abs(row.ConValueAtRisk) / totalConValueAtRisk) * 100,
    }
  })

  return rows
}

const combineAssetRows = (rows: any[], coinConfig: { [key: string]: CoinConfig }) => {
  const assetData: { [key: string]: any } = {}

  rows.forEach((row: any) => {
    if (!assetData[row.Symbol]) {
      assetData[row.Symbol] = { ...row }
    } else {
      assetData[row.Symbol].Balance += row.Balance
      assetData[row.Symbol].Value += row.Value
      assetData[row.Symbol].Change24hValue += row.Change24hValue
      assetData[row.Symbol].Change7dValue += row.Change7dValue
      assetData[row.Symbol].Change30dValue += row.Change30dValue
      assetData[row.Symbol].PercentOfTotal += row.PercentOfTotal
      assetData[row.Symbol].ValueAtRiskPercentPosition +=
        assetData[row.Symbol].ValueAtRiskPercentPosition
      assetData[row.Symbol].ValueAtRisk += row.ValueAtRisk
      assetData[row.Symbol].ConValueAtRiskPercentPosition +=
        assetData[row.Symbol].ConValueAtRiskPercentPosition
      assetData[row.Symbol].ConValueAtRisk += row.ConValueAtRisk
    }
  })

  return Object.keys(assetData).map((key: string) => ({ ...assetData[key] }))
}

export { generateRows, combineAssetRows }
