import React from 'react'
import { Button, ToolbarProps, makeStyles } from '@material-ui/core'
import {
  TextInput,
  useNotify,
  SimpleForm,
  Edit,
  AutocompleteArrayInput,
  Toolbar,
  SaveButton,
} from 'react-admin'
import { WalletDeleteButton } from '../../components/wallets'
import Dialog from '@material-ui/core/Dialog'
import walletStyles from '../../components/wallets/walletStyles'
import { validateNewWallet } from '../../components/form'
import { useSelector } from 'react-redux'
import { UserDoc } from '../types'
import { environment } from '../../components/common'
import { useHistory } from 'react-router-dom'
import { triggerWalletProcessing } from '../../components/wallets/methods'

interface Props {
  url: string
  id: string
  basePath: string
  open: boolean
  setOpen: (value: React.SetStateAction<boolean>) => void
  updateAfterChange: () => Promise<void>
}

function WalletEditModal({ url, id, basePath, open, setOpen, updateAfterChange }: Props) {
  const user: UserDoc = useSelector((state: any) => state.user)
  const notify = useNotify()
  const history = useHistory()

  const handleCloseClick = () => {
    setOpen(false)
  }

  const classes = walletStyles()

  return (
    <>
      <Dialog fullWidth open={open} onClose={handleCloseClick} aria-label='Create post'>
        <Edit
          resource={url}
          basePath={basePath}
          id={id}
          mutationMode='pessimistic'
          onSuccess={async record => {
            notify('pos.wallets.created', { type: 'success' })
            await triggerWalletProcessing(user, record.data)
            await updateAfterChange()
            handleCloseClick()
          }}
          classes={{
            main: classes.formWithCustomToolbar,
            root: classes.root,
            card: classes.root,
          }}
        >
          <SimpleForm
            validate={validateNewWallet}
            style={{ width: '100%' }}
            toolbar={<BottomBar setLoading={() => {}} />}
          >
            <TextInput
              source='Name'
              label='Currency'
              className={classes.inputField}
              disabled={true}
            />
            {!basePath.includes('INTEGRATION') && (
              <TextInput
                source='Network'
                label='Network'
                className={classes.inputField}
                disabled={true}
              />
            )}
            <TextInput source='Alias' className={classes.inputField} margin='normal' fullWidth />
            <TextInput source='Address' className={classes.inputField} margin='normal' fullWidth />
            {user.Tags && user.Tags.length > 0 ? (
              <>
                <AutocompleteArrayInput
                  source='Tags'
                  choices={
                    user.Tags
                      ? user.Tags.map((tag: string, i: number) => ({
                          id: tag,
                          name: tag,
                        }))
                      : []
                  }
                />
                <Button
                  color='primary'
                  onClick={() => history.push(`/${environment('profiles')}/edit`)}
                >
                  Manage Tags
                </Button>
              </>
            ) : (
              <>
                <span>
                  You don't have any strategy tags yet.
                  <Button
                    color='primary'
                    onClick={() => history.push(`/${environment('profiles')}/edit`)}
                    style={{ marginLeft: 15 }}
                    size='small'
                  >
                    Create Tags
                  </Button>
                </span>
              </>
            )}
          </SimpleForm>
        </Edit>
      </Dialog>
    </>
  )
}

const BottomBar = ({ setLoading, ...rest }: EditBottomBar) => {
  const user = useSelector((state: any) => state.user)
  const { record } = rest
  const docRef = `${record.Symbol}/${record.Network}/${record.Address}`
  return (
    <Toolbar {...rest} classes={useStyles()}>
      <SaveButton />
      <WalletDeleteButton user={user} docRef={docRef} record={record} setLoading={() => {}} />
    </Toolbar>
  )
}

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

interface EditBottomBar extends ToolbarProps {
  record?: any
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export default WalletEditModal
