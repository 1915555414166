import { TranslationMessages } from "react-admin";
import englishMessages from "ra-language-english";

const customEnglishMessages: TranslationMessages = {
  ...englishMessages,
  pos: {
    search: "Search",
    configuration: "Configuration",
    language: "Language",
    theme: {
      name: "Theme",
      light: "Light",
      dark: "Dark",
    },
    integration: "Integrations",
    share_keys: "Share Keys",
    add_new: "Add New",
    remove: "Remove",
    add_key: "Add Key",
    add_shared: "Add Shared",
    create_new: "Create New",
    edit: "Edit",
    copy: "Copy",
    dashboard: {
      welcome: {
        title: "Welcome to the Republic Treasury management tool!",
        subtitle:
          "This app is still in the alpha phase so you may run in to some issues, but feel free to explore and reach out with any recommendations.",
        ra_button: "react-admin site",
        demo_button: "Source for this demo",
      },
      total_portfolio_value: "Total Portfolio Value",
      based_on_value: "Based on Current Value",
      performance_comparison: "Performance Comparison",
      compared_to_bitcoin: "Compared to Holding Bitcoin",
      current_position: "Current Position",
      based_on_balances: "Based on Current Balances",
      portfolio_performance: "Portfolio Performance",
      based_on_history: "Since Account Creation",
      total_portfolio: "Total Portfolio",
    },
    menu: {
      trades: "Trades",
      staking: "Staking",
      admin: "Admin",
      price_sources: "Price Sources",
      symbols: "Symbols",
      portfolio: "Portfolio",
      rewards: "Rewards",
      market_data: "Market Data",
      validator_performance: "Validators",
      news: "News & Events",
      wallets: "My Wallets",
      settings: "Settings",
    },
    table: {
      delete_validator_title: "Delete Validator?",
      delete_price_source_name: "Delete Price Source?",
      delete_price_source_content: "Delete price data?",
      staking_rewards: "Staking Rewards",
      delegation_rewards: "Delegation Rewards",
      networks: "Networks",
      token_contracts: "Token Contracts",
      decimals: "Decimals",
      contract_address: "Contract Address",
      start_time: "Start Time",
      end_time: "End Time",
      staked: "Staked",
      delegated: "Delegated",
      name: "Name",
      symbol: "Symbol",
      alias: "Alias",
      address: "Address",
      headers: "Headers",
      baseUri: "Base URI",
      manual: "Manual",
      description: "Description",
      parent: "Parent",
      source: "Source",
      id: "Id",
      volume_24: "24h Volume",
      volume_24_short: "Vol",
      market_cap: "Market Cap",
      diluted_market_cap: "Diluted Market Cap",
      market_cap_short: "Cap",
      price: "Price",
      total_supply: "Total Supply",
      max_supply: "Max Supply",
      rank: "Rank",
      last_seen: "Last Seen",
      last_paid: "Last Paid",
      next_payment: "Next Payment",
      node_address: "Node Address",
      pending: "Pending",
      uptime: "Uptime",
      network: "Network",
      date: "Date",
      destination_address: "Dest Address",
      voa: "V.O.A",
      quantity: "Quantity",
      delete_wallet_title: "Are you sure you want to remove this address?",
      delete_wallet_content:
        "If this address has been used for staking and requires reporting you should consider this choice.",
      risk_metrics: "Risk Metrics",
      value_at_risk: "Value at Risk",
      gain_pain_ratio: "Gain Pain Ratio",
      ulcer_index: "Ulcer Index",
      start_date: "Start date",
      end_date: "End date",
      closing_price: "Last price",
      seven_day_av_volume: "7 Day Av. Volume",
      thirty_day_av_volume: "30 Day Av. Volume",
    },
    wallets: {
      deleted: "Wallet deleted",
      created: "It may take a few minutes for balances to be displayed",
      error: "Could not update the profile",
      create_wallet: "Add wallet",
      created_manual_wallet: "Wallet created",
      updated: "Wallet updated",
      available: "Available",
      staked: "Staked",
      rewards: "Rewards",
      value: "Value",
      price_data: "Price Data",
    },
    profile: {
      switching: "Portfolio added. Switching...",
      already_exists: "Portfolio already added",
      not_found: "The portfolio doesn't exist",
      check_failed: "Problem checking if the portfolio exists",
      removed: "Shared profile removed",
      delete_failed: "Failed to remove shared profile",
      updated: "Portfolio updated",
      table_view: "Table View",
    },
    integrations: {
      created:
        "Integration created. It may take a minute for balances to show.",
    },
    app: {
      no_connection: "No internet connection",
      save_failed: "Problem saving the data",
    },
    graph: {
      show_legend: "Show Legend",
      hide_legend: "Hide Legend",
      portfolio: "Portfolio",
      all: "All",
      hide_all: "Hide All",
      show_all: "Show All",
    },
  },
  resources: {},
};

export default customEnglishMessages;
