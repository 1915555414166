import { useEffect } from 'react'
import { Resource, adminSaga, AdminContext, AdminUI, useNotify } from 'react-admin'
import { Route } from 'react-router-dom'
import i18nProvider from './i18n/i18nProvider'
import { Provider } from 'react-redux'
import { createHashHistory } from 'history'
import { dataProvider, authProvider } from './firebase'
import { PersistGate } from 'redux-persist/integration/react'
import { all, fork } from 'redux-saga/effects'
import { store, persistor, sagaMiddleware } from './persistence/createStore'
import { useSelector } from 'react-redux'
import { UserDoc } from './views/types'
import { environment, walletInfo } from './components/common'
import {
  Configuration,
  Layout,
  Login,
  PriceList,
  RewardsList,
  ValidatorList,
  ValidatorCardList,
  ValidatorCreate,
  ValidatorEdit,
  WalletList,
  WalletEdit,
  WalletCreate,
  NotFound,
  PriceSourceList,
  PriceSourceCreate,
  PriceSourceEdit,
  PriceHistory,
  ProfilesCreate,
  ProfilesAdd,
  SymbolList,
  SymbolCreate,
  SymbolEdit,
  PortfolioPositionSummary,
  PortfolioPositionSummary2,
} from './views'
import MarketDataUI from './views/marketdata/MarketDataUI'
import { coinInfo } from './Resources'
import ManualWalletAdd from './views/wallets/ManualWalletCreate'
import ManualWalletEdit from './views/wallets/ManualWalletEdit'
import ProfilesEdit from './views/profiles/ProfilesEdit'
import { chainConfig } from './components/staking/chainConfig'
import * as am5 from '@amcharts/amcharts5'
import Delegate from './views/validators/Delegate'
import IntegrationEdit from './views/integrations/IntegrationEdit'
import IntegrationCreate from './views/integrations/IntegrationCreate'
import { LicenseInfo } from '@mui/x-license-pro'

am5.addLicense('AM5C314272773')
LicenseInfo.setLicenseKey(
  '8f625828c96fc20cda3c503c305964c4T1JERVI6NDMyMjIsRVhQSVJZPTE2ODM0NDg4MzAwMDAsS0VZVkVSU0lPTj0x',
)

const history = createHashHistory()

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AdminContext
          dataProvider={dataProvider}
          history={history}
          i18nProvider={i18nProvider}
          authProvider={authProvider}
        >
          <Resources />
        </AdminContext>
      </PersistGate>
    </Provider>
  )
}

/**
 * @summary This update allows us to add resources dynamically
 *
 * @returns {JSX.Element}
 */
const Resources = () => {
  const notify = useNotify()
  const user: UserDoc = useSelector((state: any) => state.user)

  // Set the global config then conditionally add admin pieces based on user context.
  const resourceConfig: { name: string; [key: string]: any }[] = [
    {
      name: 'rewards',
      list: RewardsList,
      options: { label: 'rewards' },
    },
    {
      name: `${environment('profiles')}`,
      create: ProfilesCreate,
      edit: ProfilesEdit,
    },
    {
      name: `add-portfolio`,
      edit: ProfilesAdd,
    },
    {
      name: `add-untracked`,
      create: ManualWalletAdd,
    },
    {
      name: `${environment('profiles')}&${user.ActiveProfile}`,
    },
    {
      name: `${environment('profiles')}&${user.ActiveProfile}&data`,
    },
    {
      name: `${environment('profiles')}&${user.ActiveProfile}&integrations`,
      list: null,
      create: IntegrationCreate,
      edit: IntegrationEdit,
    },
  ]

  // Add wallet resources dynamically to account for profile change.
  user &&
    user.Wallets &&
    user.Wallets.forEach((docRef: string) => {
      const { symbol, network } = walletInfo(docRef)
      const resourceName = `${environment('profiles')}&${
        user.ActiveProfile
      }&data&${symbol}&${network}`
      const exists = resourceConfig.find(r => r.name === resourceName)
      !exists &&
        resourceConfig.push({
          name: resourceName,
          edit: WalletEdit,
        })
    })

  user &&
    user.Tracking &&
    user.Tracking.map((symbol: string) =>
      resourceConfig.push({
        name: `${environment('profiles')}&${user.ActiveProfile}&data&${symbol}`,
      }),
    )

  var customRoutes = [
    <Route exact path='/configuration' render={() => <Configuration />} />,
    <Route exact path='/portfolio' render={() => <PortfolioPositionSummary2 />} />,
    <Route exact path='/position-summary' render={() => <PortfolioPositionSummary />} />,
    <Route exact path='/position-summary2' render={() => <PortfolioPositionSummary2 />} />,
    <Route exact path='/wallets' render={() => <WalletList />} />,
    <Route exact path='/create-wallet' render={() => <WalletCreate />} />,
    <Route exact path='/create-validator' render={() => <ValidatorCreate />} />,
    <Route exact path='/market-data' render={() => <MarketDataUI />} />,
  ]

  Object.keys(chainConfig).forEach((key: string) => {
    customRoutes.push(
      <Route exact path={`/delegate&${key}`} render={() => <Delegate symbol={key} />} />,
    )
  })

  user &&
    user.Untracked &&
    user.Untracked.forEach((wallet: any) => {
      customRoutes.push(
        <Route
          exact
          path={`/edit-untracked&${wallet.Symbol}`}
          render={() => <ManualWalletEdit symbol={wallet.Symbol} />}
        />,
      )
    })

  Object.keys(coinInfo).forEach(symbol => {
    customRoutes.push(
      <Route exact path={`/${symbol}`} render={() => <PriceHistory target={symbol} />} />,
    )
  })

  // Setup coin-config based on admin status.
  if (user.IsAdmin) {
    resourceConfig.push(
      {
        name: `${environment('coin-config')}`,
        list: SymbolList,
        create: SymbolCreate,
        edit: SymbolEdit,
        options: { label: 'symbols' },
      },
      {
        name: 'price_sources',
        list: PriceSourceList,
        create: PriceSourceCreate,
        edit: PriceSourceEdit,
        options: { label: 'sources' },
      },
      {
        name: `${environment('validators')}`,
        list: ValidatorList,
        create: ValidatorCreate,
        edit: ValidatorEdit,
        options: { label: ' validators' },
      },
    )
    customRoutes.push(
      <Route
        exact
        path='/validators'
        render={props => (
          <ValidatorList
            resource={`${environment('validators')}`}
            basePath={props.match.url}
            {...props}
          />
        )}
      />,
    )
  } else {
    resourceConfig.unshift(
      {
        name: `${environment('coin-config')}`,
        list: PriceList,
        options: { label: 'market data' },
      },
      {
        name: `price-history`,
      },
      {
        name: `quotes`,
      },
      {
        name: `quotes-hourly`,
      },
      {
        name: `balance-history`,
      },
      {
        name: `wallets`,
      },
      {
        name: `symbol-snapshot`,
      },
      {
        name: `${environment('validators')}`,
        list: ValidatorCardList,
        options: { label: 'validators' },
      },
    )
    customRoutes.push(
      <Route
        exact
        path='/validators'
        render={props => (
          <ValidatorCardList
            resource={`${environment('validators')}`}
            basePath={props.match.url}
            {...props}
          />
        )}
      />,
    )
  }

  useEffect(() => {
    window.addEventListener('offline', () => {
      notify('pos.app.no_connection', { type: 'error' })
    })
  }, [])

  return (
    <AdminUI
      loginPage={Login}
      layout={Layout}
      catchAll={NotFound}
      customRoutes={customRoutes}
      dashboard={PortfolioPositionSummary2}
    >
      {resourceConfig.map(rc => (
        <Resource {...rc} key={rc.name} />
      ))}
    </AdminUI>
  )
}

const saga = function* rootSaga() {
  yield all([adminSaga(dataProvider, authProvider)].map(fork))
}
sagaMiddleware.run(saga)

export default App
