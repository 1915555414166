import { useState, useEffect, useRef, useCallback, MouseEvent } from 'react'
import { useMediaQuery, Theme, Divider, Box, Paper } from '@material-ui/core'
import FilterIcon from '@material-ui/icons/CallSplit'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useSelector } from 'react-redux'
import { useDataProvider, TopToolbar } from 'react-admin'
import { calculateEquityCurves, calculateTotalValueAndDistribution } from '../portfolio/methods'
import { PortfolioOverview2, portfolioStyles } from '../portfolio2'
import { generateRows, combineAssetRows } from './portfolioSummaryRowGenerator'
import { setBalanceHistoryState, setCoinConfigState, setPriceHistoryState } from '../common'
import { DataGridPro, GridColDef, GridRowData, GridSortModel } from '@mui/x-data-grid-pro'
import { GenerateWalletColumns, GenerateAssetColumns } from './PortfolioSummaryColumnGenerator'
import { PortfolioSummaryListTopbar } from '.'
import {
  Wallet,
  CoinConfig,
  PriceHistory,
  UserDoc,
  BalanceRecord,
  EquityCurves,
  Distribution,
} from '../../views/types'
import Loading from '../../views/layout/Loading'
import WalletCreateButton from '../../views/wallets/WalletCreateButtonModal'
import ManualWalletCreateButton from '../../views/wallets/ManualWalletCreateButtonModal'
import ManualWalletEditModal from '../../views/wallets/ManualWalletEditModal'
import WalletEditModal from '../../views/wallets/WalletEditModal'
import { proxyMapToMapState, proxyArrayToMapState, cacheIsValid, environment } from '../common'
import { sortArray } from '../../firebase/misc'
import { tagMap } from '../../Resources'
import { useTheme } from '@material-ui/core'
import { IDataProvider } from '../../firebase/providers/DataProvider'
import { calculateUlcerIndex, calculateValueAtRisk } from '../../analytics'

/**
 * @summary The Big Bertha of the portfolio view.
 *
 * @returns {JSX.Element}
 */
const PortfolioUI2 = ({ profile, updateProfile }: any) => {
  // Data from API.
  const [coinConfig, setCoinConfig] = useState<{ [key: string]: CoinConfig }>()
  const [wallets, setWallets] = useState<Wallet[]>()
  const [filteredWallets, setFilteredWallets] = useState<Wallet[]>()
  const [tags, setTags] = useState<{ name: string; type: string }[]>()
  const [activeTag, setActiveTag] = useState<string>('All')
  const [balanceHistory, setBalanceHistory] = useState<{ [key: string]: BalanceRecord[] }>()
  const [priceHistory, setPriceHistory] = useState<{ [key: string]: PriceHistory[] }>()
  const [density, setDensity] = useState<'compact' | 'standard' | 'comfortable'>('compact')
  const [columns, setColumns] = useState<GridColDef[]>()
  const [dataView, setDataView] = useState<'summary' | 'risk' | 'all' | 'custom'>('summary')
  const [viewChanged, setViewChanged] = useState<boolean>(false)
  const [walletData, setWalletData] = useState<GridRowData[]>()
  const [assetData, setAssetData] = useState<GridRowData[]>()
  const [groupByAsset, setGroupByAsset] = useState<boolean>(false)

  // const [manualEditOpen, setManualEditOpen] = useState<boolean>(false)

  const theme = useTheme()

  // Calculated values.
  const [totalPortfolioValue, setTotalPortfolioValue] = useState<number>(0)
  const [totalStake, setTotalStake] = useState<number>(0)
  const [totalPending, setTotalPendingRewards] = useState<number>(0)
  const [totalSelectionValue, setTotalSelectionValue] = useState<number>(0)
  const [equityCurves, setEquityCurves] = useState<EquityCurves>()
  const [totalDistribution, setTotalDistribution] = useState<Distribution[]>()
  const [totalValueAtRisk, setTotalValueAtRisk] = useState<number>(0)
  const [totalUlcerIndex, setTotalUlcerIndex] = useState<number>(0)

  // Modals
  const [walletEditBasePath, setWalletEditBasePath] = useState<string>()
  const [walletEditURL, setWalletEditURL] = useState<string>()
  const [walletEditOpen, setWalletEditOpen] = useState<boolean>(false)
  const [walletEditId, setWalletEditId] = useState<string | undefined>()
  const [manualWalletEditId, setManualWalletEditId] = useState<string | undefined>()
  const [manualWalletEditOpen, setManualWalletEditOpen] = useState<boolean>(false)

  // Sorting
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'Value',
      sort: 'desc',
    },
  ])

  const endDate = new Date()
  const startDate = new Date(new Date().setMonth(new Date().getMonth() - 3))
  const mountedRef = useRef(true)
  const isMounted = useCallback(() => mountedRef.current, [mountedRef.current])

  // Set true to hide loading screen.
  const [loading, setLoading] = useState<boolean>(true)

  // Init hooks.
  const dataProvider: IDataProvider = useDataProvider()
  const user: UserDoc = useSelector((state: any) => state.user)
  const cache: any = useSelector((state: any) => state.admin)
  const classes = portfolioStyles()
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

  // Set refs to avoid refresh.
  const userRef = useRef(profile)
  const walletRef = useRef(profile.Wallets)
  const trackingRef = useRef(
    profile.Tracking.includes('BTC') && profile.Tracking.includes('ETH')
      ? profile.Tracking
      : profile.Tracking.includes('BTC')
      ? [...profile.Tracking, 'ETH']
      : [...profile.Tracking, 'BTC', 'ETH'],
  )

  /**
   * @description Fetch all of the data from the store, if available, or from Firebase on mount.
   */
  useEffect(() => {
    setCoinConfigState(trackingRef.current, cache, dataProvider, setCoinConfig, isMounted)
    fetchPriceData()
    fetchBalanceData()
    fetchWalletData()

    // Cancel if the component unmounts/the page changes.
    return () => {
      mountedRef.current = false
    }
  }, [])

  /**
   * @description Set the row data using the current filtered wallets, eq curves and coin snapshot.
   */
  useEffect(() => {
    if (coinConfig && filteredWallets && totalPortfolioValue && equityCurves) {
      setWalletData(generateRows(filteredWallets, equityCurves, totalPortfolioValue, coinConfig))

      const assetData = equityCurves['Total']?.holdingsData ?? []
      sortArray(assetData, 'Timestamp', 'asc')
      const equityCurve = assetData
        .map((day: any) => day.Quote)
        .splice(assetData.length - 31, assetData.length - 1)

      setTotalValueAtRisk(calculateValueAtRisk(equityCurve ?? []))
      setTotalUlcerIndex(calculateUlcerIndex(equityCurve) * 100)

      setLoading(false)
    }
  }, [coinConfig, filteredWallets, totalPortfolioValue, equityCurves])

  /**
   * @description Combine wallet rows into asset curves.
   */
  useEffect(() => {
    if (coinConfig && walletData && walletData.length > 0) {
      setAssetData(combineAssetRows(walletData, coinConfig))
    }
  }, [walletData, coinConfig])

  /**
   *  @description Reset the columns when the data view is changed.
   */
  useEffect(() => {
    setColumns(GenerateWalletColumns(isXSmall, density, dataView, theme))
  }, [dataView])

  /**
   *  @description Change columns for wallet/asset view.
   */
  useEffect(() => {
    setColumns(
      groupByAsset
        ? GenerateAssetColumns(isXSmall, density, dataView, theme)
        : GenerateWalletColumns(isXSmall, density, dataView, theme),
    )
  }, [groupByAsset])

  const fetchPriceData = useCallback(async () => {
    if (cacheIsValid(trackingRef.current, cache?.resources?.['price-history']?.validity)) {
      setPriceHistory(proxyMapToMapState(cache.resources['price-history'].data))
    } else {
      const data: any = await getList('price-history', {
        symbols: trackingRef.current,
        startDate: startDate,
        endDate: endDate,
      })
      setPriceHistory(proxyArrayToMapState(data))
    }
  }, [])

  const getList = useCallback(
    (resource: string, params: { [kay: string]: any }) => {
      return new Promise(async resolve => {
        const { data }: any = await dataProvider.getList(resource, {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'id', order: 'DESC' },
          filter: params,
        })

        // Cancel request if the user changed page before it complete.
        if (!mountedRef.current) return null
        resolve(data)
      })
    },
    [dataProvider, mountedRef],
  )

  const fetchBalanceData = useCallback(async () => {
    if (cacheIsValid(walletRef.current, cache?.resources?.['balance-history']?.validity)) {
      setBalanceHistory(proxyMapToMapState(cache.resources['balance-history'].data))
    } else {
      const data: any = await getList('balance-history', {
        profileId: user.ActiveProfile,
        startDate: startDate,
        endDate: endDate,
      })
      setBalanceHistory(proxyArrayToMapState(data))
    }
  }, [])

  const fetchWalletData = useCallback(async () => {
    const data: any = await getList('wallets', {
      profileId: user.ActiveProfile,
    })

    const walletData = data

    if (userRef.current.Untracked && userRef.current.Untracked.length > 0) {
      userRef.current.Untracked.forEach((wallet: any) => {
        const walletHistory = [...wallet.PriceHistory].map(wallet => ({
          ...wallet,
          Timestamp: new Date(wallet.Timestamp),
          id: new Date(wallet.Timestamp).toISOString().substring(0, 10),
        }))
        sortArray(walletHistory, 'Timestamp', 'asc')
        walletData.push({
          id: wallet.id
            ? wallet.id
            : (new Date().getTime() + Math.floor(Math.random() * 1000000 + 1)).toString(),
          Name: wallet.Name,
          Alias: wallet.Alias,
          Symbol: wallet.Symbol,
          Tags: wallet.Tags,
          Balance: walletHistory[walletHistory.length - 1].Balance,
          Value:
            walletHistory[walletHistory.length - 1].Balance *
            walletHistory[walletHistory.length - 1].Quote,
          PriceHistory: walletHistory,
          Manual: true,
        })
      })
    }

    const activeTags: { name: string; type: string }[] = [{ name: 'All', type: 'Default' }]

    walletData.forEach((wallet: Wallet) => {
      if (wallet.Tags && wallet.Tags.length > 0) {
        wallet.Tags.forEach((tag: string) => {
          if (!activeTags.find(t => t.name === tag)) activeTags.push({ name: tag, type: 'User' })
        })
      }
    })

    setWallets(walletData)
    setTags(activeTags)
    setFilteredWallets(walletData)
  }, [userRef, getList, sortArray, setWallets, setTags, setFilteredWallets])

  /**
   *  @description Update the local state when a user changes the density setting.
   */
  const handleDensityChange = useCallback(
    (state: any) => {
      if (state.density.value !== density) {
        setDensity(state.density?.value)
      }
    },
    [density],
  )

  useEffect(() => {
    setColumns(
      groupByAsset
        ? GenerateAssetColumns(isXSmall, density, dataView, theme)
        : GenerateWalletColumns(isXSmall, density, dataView, theme),
    )
    setCoinConfigState(trackingRef.current, cache, dataProvider, setCoinConfig, isMounted)
    setPriceHistoryState(
      trackingRef.current,
      startDate,
      endDate,
      cache,
      dataProvider,
      setPriceHistory,
      isMounted,
    )
    setBalanceHistoryState(
      user.ActiveProfile,
      startDate,
      endDate,
      walletRef.current,
      cache,
      dataProvider,
      setBalanceHistory,
      isMounted,
    )

    return () => {
      mountedRef.current = false
    }
  }, [])

  const updateAfterEdit = useCallback(async () => {
    const profileId = user.ActiveProfile

    const { data: portfolio } = await dataProvider.getOne<UserDoc>(`${environment('profiles')}`, {
      id: profileId,
    })
    profile = portfolio

    const walletData: any = await getList('wallets', {
      profileId,
    })

    userRef.current = portfolio
    if (userRef.current.Untracked && userRef.current.Untracked.length > 0) {
      userRef.current.Untracked.forEach((wallet: any) => {
        const walletHistory = [...wallet.PriceHistory].map(wallet => ({
          ...wallet,
          Timestamp: new Date(wallet.Timestamp),
          id: new Date(wallet.Timestamp).toISOString().substring(0, 10),
        }))
        sortArray(walletHistory, 'Timestamp', 'asc')

        walletData.push({
          id: wallet.id
            ? wallet.id
            : (new Date().getTime() + Math.floor(Math.random() * 1000000 + 1)).toString(),
          Name: wallet.Name,
          Alias: wallet.Alias,
          Symbol: wallet.Symbol,
          Tags: wallet.Tags,
          Balance: walletHistory[walletHistory.length - 1].Balance,
          Value:
            walletHistory[walletHistory.length - 1].Balance *
            walletHistory[walletHistory.length - 1].Quote,
          PriceHistory: walletHistory,
          Manual: true,
        })
      })
    }

    const activeTags: { name: string; type: string }[] = [{ name: 'All', type: 'Default' }]

    walletData.forEach((wallet: Wallet) => {
      if (wallet.Tags && wallet.Tags.length > 0) {
        wallet.Tags.forEach((tag: string) => {
          if (!activeTags.find(t => t.name === tag)) activeTags.push({ name: tag, type: 'User' })
        })
      }
    })

    const tagData = [...tags!]

    walletData.forEach((wallet: Wallet, i: number) => {
      if (!wallet.Manual) {
        coinConfig![wallet.Symbol].Tags &&
          coinConfig![wallet.Symbol].Tags.forEach((tag: string) => {
            if (tagMap[tag] && !tagData.find(t => t.name === tagMap[tag].name))
              tagData.push(tagMap[tag])

            if (!walletData[i].Tags) {
              walletData[i].Tags = []
            }
            if (tagMap[tag]) walletData[i].Tags.push(tagMap[tag].name)
          })
      }
    })

    setTags(tagData)
    setWallets(walletData)
    setTags(activeTags)

    const filtered =
      activeTag === 'All'
        ? walletData
        : walletData.filter((wallet: Wallet) => wallet.Tags && wallet.Tags.includes(activeTag))

    setLoading(true)
    setFilteredWallets(filtered)

    const { totalValue, distribution, totalStake, totalPendingRewards } =
      calculateTotalValueAndDistribution(filtered, coinConfig!, theme.palette.type)

    setTotalPortfolioValue(totalValue)
    setTotalSelectionValue(totalValue)
    setTotalStake(totalStake)
    setTotalPendingRewards(totalPendingRewards)
    setTotalDistribution(
      Object.keys(distribution).map(key => {
        return distribution[key]
      }),
    )
  }, [
    fetchBalanceData,
    fetchWalletData,
    getList,
    coinConfig,
    filteredWallets,
    dataProvider,
    tags,
    userRef.current,
    activeTag,
    user,
  ])

  useEffect(() => {
    if (wallets && coinConfig && tags) {
      const walletData: Wallet[] = [...wallets]
      const tagData = [...tags]
      wallets.forEach((wallet: Wallet, i: number) => {
        if (!wallet.Manual) {
          coinConfig[wallet.Symbol].Tags &&
            coinConfig[wallet.Symbol].Tags.forEach((tag: string) => {
              if (tagMap[tag] && !tagData.find(t => t.name === tagMap[tag].name))
                tagData.push(tagMap[tag])

              if (!walletData[i].Tags) {
                walletData[i].Tags = []
              }
              if (tagMap[tag]) walletData[i].Tags.push(tagMap[tag].name)
            })
        }
      })
      setTags(tagData)
      setWallets(walletData)
    }
    if (filteredWallets && coinConfig) {
      const { totalValue, distribution, totalStake, totalPendingRewards } =
        calculateTotalValueAndDistribution(filteredWallets, coinConfig, theme.palette.type)

      !totalPortfolioValue && setTotalPortfolioValue(totalValue)
      setTotalSelectionValue(totalValue)
      setTotalDistribution(
        Object.keys(distribution).map(key => {
          return distribution[key]
        }),
      )
      setTotalStake(totalStake)
      setTotalPendingRewards(totalPendingRewards)
    }
  }, [coinConfig, filteredWallets])

  useEffect(() => {
    if (filteredWallets && balanceHistory && priceHistory && coinConfig) {
      setEquityCurves(
        calculateEquityCurves(
          filteredWallets,
          priceHistory,
          balanceHistory,
          coinConfig,
          startDate,
          endDate,
          theme.palette.type,
        ),
      )
      setLoading(false)
    }
  }, [priceHistory, filteredWallets, balanceHistory, coinConfig])

  useEffect(() => {
    if (wallets && activeTag) {
      setLoading(true)
      const filtered =
        activeTag === 'All'
          ? wallets
          : wallets.filter((wallet: Wallet) => wallet.Tags && wallet.Tags.includes(activeTag))
      setFilteredWallets(filtered)
    }
  }, [activeTag])

  const handleRowClick = (evt: any) => {
    if (groupByAsset) {
      alert('Click "Show Wallets" in the top right and select a vault to edit')
      return
    }
    const walletPath = evt ? `${evt.id}`.split('/') : ''
    const isManual = !walletPath[1]
    if (!isManual) {
      const basePath = `/${environment('profiles')}&${user.ActiveProfile}&data&${walletPath[0]}&${
        walletPath[1]
      }`
      const editURL = `${environment('profiles')}&${user.ActiveProfile}&data&${walletPath[0]}&${
        walletPath[1]
      }`
      setWalletEditId(walletPath[2])
      setWalletEditBasePath(basePath)
      setWalletEditURL(editURL)
      setWalletEditOpen(true)
    } else {
      setManualWalletEditId(evt.id)
      setManualWalletEditOpen(true)
    }
  }

  if (loading || !columns || !walletData || !assetData) return <Loading />

  return (
    <>
      <TopToolbar className={classes.topBarStyle}>
        <div style={{ marginRight: 'auto' }}>
          <FilterMenu tags={tags} setActiveTag={setActiveTag} activeTag={activeTag} />
        </div>
        <WalletCreateButton updateAfterChange={updateAfterEdit} />
        <ManualWalletCreateButton updateAfterChange={updateAfterEdit} />
      </TopToolbar>
      <div className={classes.headerSpacing}>
        {isXSmall &&
          equityCurves &&
          totalDistribution &&
          priceHistory &&
          totalSelectionValue > 0 &&
          totalDistribution.length > 0 && (
            <>
              <PortfolioOverview2
                distribution={totalDistribution}
                equityCurves={equityCurves}
                totalPortfolioValue={totalPortfolioValue}
                totalSelectionValue={totalSelectionValue}
                priceHistory={priceHistory}
                activeTag={activeTag}
              />
            </>
          )}

        <div style={{ marginBottom: 10 }}>
          {!isXSmall &&
            equityCurves &&
            totalDistribution &&
            priceHistory &&
            totalSelectionValue > 0 &&
            totalDistribution.length > 0 && (
              <PortfolioOverview2
                distribution={totalDistribution}
                equityCurves={equityCurves}
                totalPortfolioValue={totalPortfolioValue}
                totalSelectionValue={totalSelectionValue}
                priceHistory={priceHistory}
                activeTag={activeTag}
                totalStake={totalStake}
                totalPendingRewards={totalPending}
                totalValueAtRisk={totalValueAtRisk}
                totalUlcerIndex={totalUlcerIndex}
              />
            )}
        </div>

        <div className={classes.topSpacer}>
          <Paper variant='outlined' style={{ flexGrow: 1 }}>
            <Box style={{ minHeight: 300, width: '100%' }}>
              <DataGridPro
                rows={groupByAsset ? assetData : walletData}
                columns={columns}
                components={{
                  Toolbar: PortfolioSummaryListTopbar,
                }}
                componentsProps={{
                  toolbar: {
                    setDataView: setDataView,
                    currentView: dataView,
                    viewChanged: viewChanged,
                    setGrouped: setGroupByAsset,
                    isGrouped: groupByAsset,
                  },
                }}
                loading={loading}
                checkboxSelection={false}
                disableSelectionOnClick
                density='compact'
                hideFooter
                autoHeight
                onStateChange={(state: any) => handleDensityChange(state)}
                onColumnVisibilityChange={() => setViewChanged(true)}
                sortModel={sortModel}
                onSortModelChange={model => setSortModel(model)}
                columnBuffer={5}
                onRowClick={evt => handleRowClick(evt)}
                classes={{ row: groupByAsset ? classes.unclickableRow : classes.clickableRow }}
              />
            </Box>
          </Paper>
        </div>
        {walletEditURL && walletEditBasePath && walletEditOpen && walletEditId && (
          <WalletEditModal
            open={walletEditOpen}
            url={walletEditURL!}
            id={walletEditId}
            basePath={walletEditBasePath}
            setOpen={setWalletEditOpen}
            updateAfterChange={updateAfterEdit}
          />
        )}
        {manualWalletEditId && manualWalletEditOpen && (
          <ManualWalletEditModal
            open={manualWalletEditOpen}
            id={manualWalletEditId!}
            setOpen={setManualWalletEditOpen}
            updateAfterChange={updateAfterEdit}
          />
        )}
      </div>
    </>
  )
}

function FilterMenu({
  tags,
  activeTag,
  setActiveTag,
}: {
  tags: { name: string; type: string }[] | undefined
  activeTag: string
  setActiveTag: any
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [defaultTags, setDefaultTags] = useState<{ name: string; type: string }[]>()
  const [userTags, setUserTags] = useState<{ name: string; type: string }[]>()
  const [utilityTags, setUtilityTags] = useState<{ name: string; type: string }[]>()
  const [ecosystemTags, setEcosystemTags] = useState<{ name: string; type: string }[]>()

  const classes = portfolioStyles()

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (tag: string) => {
    setActiveTag(tag)
    handleClose()
  }

  useEffect(() => {
    setDefaultTags(tags?.filter(t => t.type === 'Default'))
    setUserTags(tags?.filter(t => t.type === 'User'))
    setUtilityTags(tags?.filter(t => t.type === 'Utility'))
    setEcosystemTags(tags?.filter(t => t.type === 'Ecosystem'))
  }, [tags])

  return (
    <div>
      <Button
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
        color='primary'
        variant='contained'
        startIcon={<FilterIcon />}
        className={classes.filterButton}
        style={{ borderRadius: 20 }}
      >
        {activeTag}
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {defaultTags?.map(tag => {
          return (
            <MenuItem
              onClick={() => handleChange(tag.name)}
              disabled={tag.name === activeTag}
              key={tag.name}
            >
              {tag.name}
            </MenuItem>
          )
        })}

        {userTags && userTags.length > 0 && (
          <Box>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <Box style={{ paddingLeft: 15, paddingBottom: 5 }}>
              <small style={{ opacity: 0.6 }}>Strategies</small>
            </Box>
          </Box>
        )}

        {userTags?.map(tag => {
          return (
            <MenuItem
              onClick={() => handleChange(tag.name)}
              disabled={tag.name === activeTag}
              key={tag.name}
            >
              {tag.name}
            </MenuItem>
          )
        })}

        {utilityTags && (
          <Box>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <Box style={{ paddingLeft: 15, paddingBottom: 5 }}>
              <small style={{ opacity: 0.6 }}>Utility</small>
            </Box>
          </Box>
        )}

        {utilityTags?.map(tag => {
          return (
            <MenuItem
              onClick={() => handleChange(tag.name)}
              disabled={tag.name === activeTag}
              key={tag.name}
            >
              {tag.name}
            </MenuItem>
          )
        })}

        {ecosystemTags && (
          <Box>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <Box style={{ paddingLeft: 15, paddingBottom: 5 }}>
              <small style={{ opacity: 0.6 }}>Ecosystem</small>
            </Box>
          </Box>
        )}

        {ecosystemTags?.map(tag => {
          return (
            <MenuItem
              onClick={() => handleChange(tag.name)}
              disabled={tag.name === activeTag}
              key={tag.name}
            >
              {tag.name}
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}

export default PortfolioUI2
