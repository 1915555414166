import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "./en";
import frenchMessages from "./fr";

let browserLocale = "";
const storedLocale = localStorage.getItem("locale");
const i18nProvider = polyglotI18nProvider(
  (locale) => {
    browserLocale = locale;
    if (locale === "fr") {
      return frenchMessages;
    }
    return englishMessages;
  },
  storedLocale === "fr"
    ? "fr"
    : browserLocale === "fr" && storedLocale !== "en"
    ? "fr"
    : "en",
  { allowMissing: true }
);

export default i18nProvider;
