import React from "react";
import UpIcon from "@material-ui/icons/ArrowUpward";
import DownIcon from "@material-ui/icons/ArrowDownward";
import { useTheme, makeStyles, Theme } from "@material-ui/core";

const PercentChange = ({ change }: Props) => {
  const theme = useTheme();
  const classes = styles();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color:
          change >= 0 ? theme.palette.success.dark : theme.palette.error.dark,
      }}
    >
      {change >= 0 ? (
        <UpIcon className={classes.icon} />
      ) : (
        <DownIcon className={classes.icon} />
      )}
      <span className={classes.text}>{Math.abs(change).toFixed(2)}%</span>
    </div>
  );
};

const styles = makeStyles((theme: Theme) => ({
  text: {
    fontSize: "0.97rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.92rem",
    },
  },
  icon: {
    height: "0.97rem",
    [theme.breakpoints.down("xs")]: {
      height: "0.92rem",
    },
  },
}));

interface Props {
  change: number;
}

export default PercentChange;
