import { useState } from "react";
import { Theme, Grid, CircularProgress, Paper } from "@material-ui/core";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import {
  defaultTheme,
  useDataProvider,
  useAuthProvider,
  Login,
} from "react-admin";
import { ThemeProvider } from "@material-ui/styles";
import firebase from "firebase/compat/app";
import { RouteComponentProps } from "react-router";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IDataProvider } from "../../firebase/providers/DataProvider";
import { config } from "./config";

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    backgroundImage: `linear-gradient(to top, #00c6fb 0%, #005bea 100%);`,
  },
  hideAvatar: {
    display: "none",
  },
  hideDefaultCard: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "visible",
  },
  formCard: {
    minWidth: 300,
    justifyItems: "center",
    borderRadius: 15,
    paddingTop: 25,
    paddingBottom: 25,
    height: 237,
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
  logo: {
    height: 110,
    marginTop: 5,
    marginBottom: 5,
  },
  logoContainer: {
    paddingTop: 20,
    paddingBottom: 30,
  },
  loadingContainer: {
    paddingTop: 12,
    paddingBottom: 30,
  },
}));

const FirebaseLogin = (props: RouteComponentProps) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const dataProvider: IDataProvider = useDataProvider();
  const authProvider = useAuthProvider();
  const [loading, setLoading] = useState(true);
  return (
    <ThemeProvider theme={createTheme(defaultTheme)}>
      <Paper className={classes.formCard} elevation={15} variant="outlined">
        <Grid container alignItems="center" justifyContent="center">
          <Grid item className={classes.loadingContainer}>
            <img
              src="/images/republic-r.svg"
              alt="Republic"
              className={classes.logo}
            />
          </Grid>
        </Grid>
        {loading && (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            className={classes.loadingContainer}
          >
            <CircularProgress color="secondary" size={30} thickness={4} />
          </Grid>
        )}

        <StyledFirebaseAuth
          uiConfig={config(
            dataProvider,
            authProvider,
            history,
            dispatch,
            setLoading
          )}
          firebaseAuth={firebase.auth()}
        />
      </Paper>
      <p
        style={{
          color: "white",
          fontSize: 13,
          textAlign: "center",
          paddingTop: 5,
        }}
      >
        Need help logging in?{" "}
        <a style={{ color: "#00EFE9" }} href="mailto:hossein@republic.co">
          Get Support
        </a>
      </p>
    </ThemeProvider>
  );
};

const LoginPage = (props: any) => {
  const classes = useStyles();
  return (
    <Login
      {...props}
      backgroundImage=""
      classes={{
        main: classes.main,
        avatar: classes.hideAvatar,
        card: classes.hideDefaultCard,
      }}
    >
      <FirebaseLogin {...props} />
    </Login>
  );
};

export default LoginPage;
