import { makeStyles } from "@material-ui/core/styles";

const tableStyles = makeStyles((theme) => ({
  tableHeader: {
    fontWeight: 700,
  },
  priceHistoryNameCell: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "16px!important",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "10px!important",
    },
  },
  priceHistoryRankCell: {
    textAlign: "left",
    paddingLeft: "16px!important",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "10px!important",
    },
  },
  validatorHeaderSpacing: {
    marginTop: "calc(1em + 16px)",
    [theme.breakpoints.down("xs")]: {
      marginTop: 16,
    },
  },
  dateSearchHeaderSpacing: {
    marginTop: 10,
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
  },
  priceHistoryToolbar: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: 14,
    marginBottom: 9,
    [theme.breakpoints.down("xs")]: {
      marginTop: 18,
      marginBottom: 8,
    },
  },
  inlineText: {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  icon: {
    height: 26,
    width: 26,
    [theme.breakpoints.down("xs")]: { height: 20, width: 20 },
  },
  historyTitleIcon: {
    height: 26,
    width: 26,
    [theme.breakpoints.down("xs")]: { height: 28, width: 28, paddingLeft: 5 },
  },
  networkNameContainer: {
    marginLeft: 8,
    display: "flex",
    maxWidth: 200,
    minWidth: 0,
    [theme.breakpoints.down("xl")]: {
      maxWidth: 350,
    },
    [theme.breakpoints.down("lg")]: {
      maxWidth: 275,
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: 150,
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: 105,
    },
  },
  networkName: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  historyTitle: { fontSize: 27, marginLeft: 8 },
  historyTitleSymbol: { fontSize: 17 },
  lastPriceLabel: {
    marginBottom: 10,
    marginTop: 5,
    [theme.breakpoints.down("xs")]: { marginTop: 15, fontSize: 15 },
  },
  lastPrice: {
    marginTop: 0,
    marginBottom: 15,
    [theme.breakpoints.down("xs")]: { marginBottom: 0 },
  },
  priceTitleContainer: {
    paddingBottom: 0,
    paddingTop: 5,
    [theme.breakpoints.down("xs")]: { paddingTop: 0 },
  },
  priceSummaryContainer: {
    marginLeft: 0,
    marginRight: 15,
    [theme.breakpoints.down("xs")]: { marginLeft: 5 },
  },
  priceHistoryPriceInfo: { width: 180, marginLeft: 10, marginRight: 10 },
  lineChartContainer: {
    height: 270,
    marginTop: 25,
    marginBottom: 0,
    marginRight: 0,
    [theme.breakpoints.down("xs")]: { marginRight: 0, marginTop: 0 },
  },
  riskMetricsTitle: {
    marginTop: 5,
    marginBottom: 10,
    marginLeft: 0,
    [theme.breakpoints.down("xs")]: { marginLeft: 5, marginTop: 10 },
  },
  tooltip: {
    fontSize: "0.8em",
    padding: "7px 20px",
    background:
      theme.palette.type === "dark" ? theme.palette.background.paper : "#fff",
    color: theme.palette.type === "dark" ? "#fff" : "#000",
    border: "1px solid rgba(0, 0, 0, .1)",
  },
  eye: { fontSize: 16, marginRight: 14, opacity: 0.7 },
  confidence: { fontSize: 12 },
  pageGutter: { paddingBottom: 35, marginBottom: 35 },
  loadingContainer: {
    backgroundColor: theme.palette.type === "light" ? "#fcfcfe" : "#0e1117",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 100,
  },
  dateRangePicker: {
    background: theme.palette.background.paper,
    color: theme.palette.type === "dark" ? "white" : "#333",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& ..MuiIconButton-sizeSmall.MuiIconButton-edgeEnd": {
      color: theme.palette.type === "dark" ? "white" : "#333",
    },
    "& .MuiPickersDay-root": {
      color: theme.palette.type === "dark" ? "white" : "#333",
    },
    "& .MuiTypography-caption": {
      color: theme.palette.type === "dark" ? "white" : "#333",
    },
    "& .MuiSvgIcon-fontSizeMedium": {
      color: theme.palette.type === "dark" ? "white" : "#333",
    },
    "& .MuiDateRangePickerDay-day.Mui-selected": {
      backgroundColor: "#1860F9",
    },
    "& .MuiDateRangePickerDay-rangeIntervalDayHighlight": {
      backgroundColor: "#117FFF",
    },
  },
  riskTable: {
    "& td": {
      padding: "3px 10px 3px 5px",
    },
    "& th": {
      padding: "3px 10px 3px 5px",
    },
  },
  tableHeaderContainer: {
    borderBottom:
      theme.palette.type === "dark"
        ? "1px solid rgba(255, 255, 255, 0.12)"
        : "1px solid rgba(0, 0, 0, 0.12)",
  },
  tableHeaderTitle: { paddingTop: 15, paddingBottom: 15 },
  candleMobile: {
    height: 550,
    [theme.breakpoints.down("xs")]: {
      transform: "scale(0.7)",
      width: "142.857143%",
      transformOrigin: "0 0",
      height: 385,
      pointerEvents: "none",
    },
  },
}));

export default tableStyles;
