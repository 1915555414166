import { makeStyles } from "@material-ui/core";

const portfolioStyles = makeStyles((theme) => ({
  label: { width: "10em", display: "inline-block" },
  button: { margin: "1em" },
  headerSpacing: {
    marginTop: -8,
    // marginBottom: 35,
    [theme.breakpoints.down("xs")]: {
      marginTop: -10,
    },
  },
  coinCardData: { marginBottom: "10px", fontSize: 14 },
  priceChange: { marginBottom: "0px", fontSize: 14 },
  networksHeading: { marginTop: 0, marginBottom: "0.5em" },
  portfolioOverviewHeading: { marginTop: 20, marginLeft: 10 },
  card: {
    height: "235px",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      height: 230,
    },
  },
  cardHeaderTitle: {
    width: "180px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  filterButton: {
    [theme.breakpoints.down("xs")]: {
      height: 48,
    },
  },
}));

export default portfolioStyles;
