import {
  List,
  Datagrid,
  TextField,
  NumberField,
  EditButton,
  CreateButton,
  DeleteWithConfirmButton,
  ListProps,
  useTranslate,
  TopToolbar,
  DateField,
  Pagination,
  PaginationProps
} from "react-admin";
import { RowNetworkName, tableStyles } from "../../components/table";
import { environment } from "../../components/common";

/**
 * @summary The primary component of the wallet list view
 *
 * TODO:  This will need to be rewritten for the new data structure
 *
 * @param props
 * @returns {JSX.Element}
 */
const ValidatorList = ({ staticContext, ...rest }: ListProps) => {
  const classes = tableStyles();
  const translate = useTranslate();
  const RowPagination = (props: PaginationProps) => (
    <Pagination rowsPerPageOptions={[15, 25, 50, 100]} {...props} />
  );

  // Render the toolbar that appears above the standard list component.
  const ListActions = () => (
    <TopToolbar>
      <CreateButton to="create-validator" />
    </TopToolbar>
  );

  return (
    <List
      {...rest}
      sort={{ field: "Name", order: "desc" }}
      bulkActionButtons={false}
      actions={<ListActions />}
      pagination={<RowPagination />}
      perPage={15}
    >
      <Datagrid
        basePath={`/${environment("validators")}`}
        isRowSelectable={() => false}
      >
        <RowNetworkName
          source="Network"
          headerClassName={classes.tableHeader}
          label={translate("pos.table.network")}
        />
        <TextField
          source="Name"
          headerClassName={classes.tableHeader}
          label={translate("pos.table.name")}
        />
        <TextField
          source="Address"
          headerClassName={classes.tableHeader}
          label={translate("pos.table.address")}
        />
        <DateField
          source="StartTime"
          headerClassName={classes.tableHeader}
          label={translate("pos.table.start_time")}
        />
        <DateField
          source="EndTime"
          headerClassName={classes.tableHeader}
          label={translate("pos.table.end_time")}
        />
        <NumberField
          source="Staked"
          headerClassName={classes.tableHeader}
          label={translate("pos.table.staked")}
          align="left"
        />
        <NumberField
          source="Delegated"
          headerClassName={classes.tableHeader}
          label={translate("pos.table.delegated")}
          align="left"
        />
        <NumberField
          source="Uptime"
          headerClassName={classes.tableHeader}
          label={translate("pos.table.uptime")}
          align="left"
        />
        <NumberField
          source="ProjectedStakingRewards"
          headerClassName={classes.tableHeader}
          label={translate("pos.table.staking_rewards")}
          align="left"
        />
        <NumberField
          source="ProjectedDelegationRewards"
          headerClassName={classes.tableHeader}
          label={translate("pos.table.delegation_rewards")}
          align="left"
        />
        <EditButton />
        <DeleteWithConfirmButton
          confirmTitle={translate("pos.table.delete_validator_title")}
        />
      </Datagrid>
    </List>
  );
};

export default ValidatorList;
