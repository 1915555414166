import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteWithConfirmButton,
  ListProps,
  useTranslate,
  ArrayField,
} from "react-admin";
import { tableStyles } from "../../../components/table";

/**
 * @summary Renders the list of prices in a table.
 *
 * @param props
 * @returns {JSX.Element}
 */
const PriceSourceList = (props: ListProps) => {
  const classes = tableStyles();
  const translate = useTranslate();
  return (
    <List
      {...props}
      sort={{ field: "name", order: "desc" }}
      bulkActionButtons={false}
    >
      <Datagrid isRowSelectable={() => false}>
        <TextField
          source="Name"
          headerClassName={classes.tableHeader}
          label={translate("pos.table.name")}
        />
        <TextField
          source="BaseUri"
          label={translate("pos.table.baseUri")}
          headerClassName={classes.tableHeader}
        />
        <ArrayField source="Headers" label={translate("pos.table.headers")}>
          <Datagrid>
            <TextField source="Name" />
            <TextField source="Value" />
          </Datagrid>
        </ArrayField>
        <EditButton basePath="/price_sources" />
        <DeleteWithConfirmButton
          basePath="/prices_sources"
          confirmTitle={translate("pos.table.delete_price_source_name")}
        />
      </Datagrid>
    </List>
  );
};

export default PriceSourceList;
