import { useState, useEffect, useRef, useCallback, ChangeEvent } from 'react'
import {
  TextInput,
  useNotify,
  useDataProvider,
  SimpleForm,
  SelectInput,
  useMutation,
  Create,
  AutocompleteArrayInput,
} from 'react-admin'
import IconContentAdd from '@material-ui/icons/Add'

import Dialog from '@material-ui/core/Dialog'
import walletStyles from '../../components/wallets/walletStyles'

import { validateNewWallet, SelectNetworkName, networkType } from '../../components/form'
import { setCoinConfigState } from '../../components/common'
import { useSelector, useDispatch } from 'react-redux'
import { IDataProvider } from '../../firebase/providers/DataProvider'
import { UserDoc, CoinConfig } from '../types'
import { SelectWithAutoFill } from '../../components/form'
import { environment } from '../../components/common'
import { handleProfileTracking, triggerWalletProcessing } from '../../components/wallets/methods'
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'

function WalletCreateButton({ onChange, updateAfterChange }: any) {
  const [showDialog, setShowDialog] = useState(false)

  const [coinConfig, setCoinConfig] = useState<CoinConfigMap>()
  const [currencySelectors, setCurrencySelectors] = useState<{ id: string; name: JSX.Element }[]>()
  const [networkSelectors, setNetworkSelectors] =
    useState<{ id: string; value: string | Element }[]>()
  const [activeBlockchain, setActiveBlockchain] = useState<CoinConfig>()
  const [network, setNetwork] = useState<string>()

  const cache: any = useSelector((state: any) => state.admin)
  const user: UserDoc = useSelector((state: any) => state.user)
  const trackingRef = useRef(user.Tracking)

  const [mutate] = useMutation()
  const dispatch = useDispatch()
  const notify = useNotify()
  const dataProvider: IDataProvider = useDataProvider()
  const history = useHistory()

  const activeBlockchainRef = useRef<CoinConfig | undefined>(activeBlockchain)
  const mountedRef = useRef(true)
  const isMounted = useCallback(() => mountedRef.current, [mountedRef.current])

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const transform = (data: any) =>
    handleProfileTracking(user, data, activeBlockchainRef, dataProvider, mutate, dispatch)
      .then(({ data, activeBlockchainRef }) => ({
        id: data.Address,
        Address: data.Address,
        Name: activeBlockchainRef.current && activeBlockchainRef.current.Name,
        Alias: data.Alias,
        Symbol: activeBlockchainRef.current && activeBlockchainRef.current.Symbol,
        Network: data.Network,
        Tags: data.Tags ? data.Tags : [],
        Balance: 0,
        Rewards: 0,
        Staked: 0,
        TransactionIds: [],
        Status: 'NEW',
      }))
      .catch(err => {
        notify('pos.wallets.error', { type: 'warning' })

        throw new Error(err.message) // Stop the parent writing an empty object
      })

  useEffect(() => {
    setCoinConfigState(trackingRef.current, cache, dataProvider, setCoinConfig, isMounted)
  }, [])

  useEffect(() => {
    coinConfig &&
      Object.keys(coinConfig).length > 0 &&
      setCurrencySelectors(
        Object.keys(coinConfig).map((key: string) => {
          return {
            id: coinConfig[key].Symbol,
            name: (
              <SelectNetworkName
                name={coinConfig[key].Name}
                symbol={coinConfig[key].Symbol}
                key={`select-${coinConfig[key].Symbol}`}
              />
            ),
          }
        }),
      )
  }, [coinConfig])

  useEffect(() => {
    if (activeBlockchain && coinConfig) {
      activeBlockchainRef.current = activeBlockchain
      setNetworkSelectors(
        activeBlockchain.Networks.map((network: string) => {
          if (network === 'MANUAL') return { id: network, value: 'Manual' }
          if (!coinConfig[network]) return {}
          return {
            id: network,
            value: networkType(
              activeBlockchain.Symbol,
              coinConfig[network].Symbol,
              coinConfig[network].Name,
            ),
          }
        }),
      )
    }
  }, [activeBlockchain, coinConfig])

  useEffect(() => {
    if (activeBlockchain && user.Name) {
      const inputElement = document.getElementById('Alias') as HTMLInputElement
      inputElement.setAttribute('value', `${user.Name.split(' ')[0]}'s ${activeBlockchain.Name}`)
      inputElement.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }, [activeBlockchain, user.Name])

  const handleBlockchainSelect = (evt: ChangeEvent<HTMLInputElement>) => {
    evt.target.value && coinConfig && setActiveBlockchain(coinConfig[evt.target.value])
  }

  const handleNetworkSelect = (evt: ChangeEvent<HTMLInputElement>) => {
    evt.target.value && setNetwork(evt.target.value)
  }

  const classes = walletStyles()

  return (
    <>
      <Button
        onClick={handleClick}
        color='primary'
        startIcon={<IconContentAdd />}
        variant='contained'
        style={{ borderRadius: 20 }}
      >
        Add Wallet
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label='Create post'>
        <Create
          basePath={`/${environment('profiles')}/${user.ActiveProfile}/data/${
            activeBlockchain?.Symbol
          }/${network}`}
          resource={`${environment('profiles')}/${user.ActiveProfile}/data/${
            activeBlockchain?.Symbol
          }/${network}`}
          transform={transform}
          onSuccess={async record => {
            triggerWalletProcessing(user, record.data)
            notify('pos.wallets.created', { type: 'success' })
            handleCloseClick()
            await updateAfterChange()
          }}
          classes={{
            main: classes.formWithCustomToolbar,
            root: classes.root,
            input: classes.root,
            card: classes.root,
          }}
        >
          <SimpleForm
            validate={validateNewWallet}
            mutationMode='pessimistic'
            style={{ width: '100%' }}
          >
            <SelectInput
              source='Symbol'
              label='Currency'
              choices={currencySelectors}
              className={classes.inputField}
              onChange={(evt: ChangeEvent<HTMLInputElement>) => handleBlockchainSelect(evt)}
              translateChoice={false}
              margin='normal'
              fullWidth
              classes={{ input: classes.input }}
            />
            <SelectWithAutoFill
              source='Network'
              className={classes.inputField}
              choices={networkSelectors}
              value={network}
              isRequired={true}
              id='network-select'
              callback={handleNetworkSelect}
            />
            <TextInput source='Alias' className={classes.inputField} margin='normal' fullWidth />
            <TextInput source='Address' className={classes.inputField} margin='normal' fullWidth />
            {user.Tags && user.Tags.length > 0 ? (
              <>
                <AutocompleteArrayInput
                  source='Tags'
                  choices={
                    user.Tags
                      ? user.Tags.map((tag: string, i: number) => ({
                          id: tag,
                          name: tag,
                        }))
                      : []
                  }
                />
                <Button
                  color='primary'
                  onClick={() => history.push(`/${environment('profiles')}/edit`)}
                >
                  Manage Tags
                </Button>
              </>
            ) : (
              <>
                <span>
                  You don't have any strategy tags yet.
                  <Button
                    color='primary'
                    onClick={() => history.push(`/${environment('profiles')}/edit`)}
                    style={{ marginLeft: 15 }}
                    size='small'
                  >
                    Create tags
                  </Button>
                </span>
              </>
            )}
          </SimpleForm>
        </Create>
      </Dialog>
    </>
  )
}

interface CoinConfigMap {
  [key: string]: CoinConfig
}

export default WalletCreateButton
