import { useState, useEffect, useRef, useCallback } from 'react'
import {
  Button,
  TextInput,
  useNotify,
  useDataProvider,
  SimpleForm,
  useMutation,
  Toolbar,
  ToolbarProps,
  SaveButton,
  Edit,
  NumberInput,
  AutocompleteArrayInput,
  SimpleFormIterator,
  DateInput,
  ArrayInput,
} from 'react-admin'
import Dialog from '@material-ui/core/Dialog'
import walletStyles from '../../components/wallets/walletStyles'
import { validateManualWallet } from '../../components/wallets/methods'
import { setCoinConfigState } from '../../components/common'
import { useSelector, useDispatch } from 'react-redux'
import { IDataProvider } from '../../firebase/providers/DataProvider'
import { UserDoc, CoinConfig } from '../types'
import { environment } from '../../components/common'
import { updateUser } from '../../persistence/actions'
import { useHistory } from 'react-router-dom'
import DeleteIcon from '@material-ui/icons/Delete'

interface Props {
  id: string
  open: boolean
  setOpen: (value: React.SetStateAction<boolean>) => void
  updateAfterChange: () => Promise<void>
}

function ManualWalletEditModal({ id, open, setOpen, updateAfterChange }: Props) {
  const [coinConfig, setCoinConfig] = useState<CoinConfigMap>()
  const [loading, setLoading] = useState<boolean>(false)
  const cache: any = useSelector((state: any) => state.admin)
  const user: UserDoc = useSelector((state: any) => state.user)

  const walletRef = useRef(user.Untracked.filter((wallet: any) => wallet.id === id)[0])

  const [mutate] = useMutation()
  const dispatch = useDispatch()
  const notify = useNotify()
  const dataProvider: IDataProvider = useDataProvider()
  const history = useHistory()

  const mountedRef = useRef(true)
  const isMounted = useCallback(() => mountedRef.current, [mountedRef.current])

  const handleCloseClick = () => {
    setOpen(false)
  }

  const save = useCallback(
    values => {
      const errors: any = {}
      const { TokenName, TokenSymbol, PriceHistory, WalletAlias, WalletTags } = values
      const notValid = validateManualWallet(TokenName, TokenSymbol, PriceHistory, WalletAlias)
      if (notValid) return notValid

      const Symbol = TokenSymbol.toUpperCase()
      if (errors.TokenSymbol) return errors

      // Validation passed!
      const wallet = {
        id: walletRef.current.id,
        Alias: WalletAlias,
        Name: TokenName,
        Symbol: Symbol,
        PriceHistory: PriceHistory,
        Tags: WalletTags ? WalletTags : [],
      }
      const updatedUntracked = [...user.Untracked]
      updatedUntracked.push(wallet)

      // Fetch profile first incase anyone else added another token since last check.
      return dataProvider
        .getOne(environment('profiles'), {
          id: user.ActiveProfile,
        })
        .then(async ({ data }) => {
          const updatedUntracked = [...data.Untracked].filter(wallet => wallet.id !== id)
          updatedUntracked.push(wallet)
          try {
            await mutate(
              {
                type: 'update',
                resource: environment('profiles'),
                payload: {
                  id: user.ActiveProfile,
                  data: {
                    Untracked: updatedUntracked,
                  },
                },
              },
              {
                onSuccess: async () => {
                  const updatedProfile: UserDoc = {
                    ...user,
                    Untracked: updatedUntracked,
                  }
                  dispatch(updateUser(updatedProfile))
                  notify('pos.wallets.updated', { type: 'success' })
                  handleCloseClick()
                  await updateAfterChange()
                },
                returnPromise: true,
              },
            )
          } catch (err: any) {
            console.log(err)
            notify('pos.app.save_failed', { type: 'error' })
            return errors
          }
        })
        .catch(err => {
          console.log(err)
          notify('pos.profile.check_failed', { type: 'error' })
          return errors
        })
    },
    [user, coinConfig, mutate, dataProvider],
  )

  const deleteWallet = useCallback(async () => {
    setLoading(true)
    return dataProvider
      .getOne(environment('profiles'), {
        id: user.ActiveProfile,
      })
      .then(async ({ data }) => {
        const updatedUntracked = [...data.Untracked].filter(
          wallet => wallet.id !== walletRef.current.id,
        )
        try {
          await mutate(
            {
              type: 'update',
              resource: environment('profiles'),
              payload: {
                id: user.ActiveProfile,
                data: {
                  Untracked: updatedUntracked,
                },
              },
            },
            {
              onSuccess: async () => {
                const updatedProfile: UserDoc = {
                  ...user,
                  Untracked: updatedUntracked,
                }
                dispatch(updateUser(updatedProfile))
                notify('pos.wallets.deleted', {
                  type: 'error',
                })
                handleCloseClick()
                await updateAfterChange()
              },
              returnPromise: true,
            },
          )
        } catch (err: any) {
          setLoading(false)
          console.log(err)
          notify('pos.app.save_failed', { type: 'error' })
        }
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
        notify('pos.profile.check_failed', { type: 'error' })
      })
  }, [user, coinConfig, mutate, dataProvider])

  useEffect(() => {
    setCoinConfigState(user.Tracking, cache, dataProvider, setCoinConfig, isMounted)
  }, [])

  const classes = walletStyles()

  const SaveBar = (props: ToolbarProps) => (
    <div style={{ position: 'sticky', bottom: 0, boxShadow: '0px 0 5px -2px #888' }}>
      <Toolbar {...props} classes={{ toolbar: classes.manualWalletEditToolbar }}>
        <SaveButton disabled={props.pristine} />
        <Button label='Delete' onClick={() => deleteWallet()} disabled={loading}>
          <DeleteIcon />
        </Button>
      </Toolbar>
    </div>
  )

  return (
    <>
      <div style={{ position: 'relative', height: 200 }}>
        <Dialog fullWidth open={open} onClose={handleCloseClick}>
          <Edit
            basePath={`/${environment('profiles')}`}
            resource={`${environment('profiles')}`}
            id={user.id}
            mutationMode='pessimistic'
            classes={{
              main: classes.formWithCustomToolbar,
              root: classes.root,
              card: classes.root,
            }}
          >
            <SimpleForm
              mutationMode='pessimistic'
              toolbar={<SaveBar />}
              save={save}
              style={{ position: 'relative', maxHeight: '90vh', overflow: 'scroll' }}
            >
              <TextInput
                source='WalletAlias'
                className={classes.inputField}
                margin='normal'
                label='Wallet Alias'
                defaultValue={walletRef?.current?.Alias}
                style={{ zIndex: 1 }}
              />
              <TextInput
                source='TokenName'
                className={classes.inputField}
                margin='normal'
                label='Currency Name'
                defaultValue={walletRef.current.Name}
              />
              <TextInput
                source='TokenSymbol'
                className={`${classes.inputField} ${classes.uppercase}`}
                margin='normal'
                label='Symbol'
                defaultValue={walletRef.current.Symbol}
              />
              {user.Tags && user.Tags.length > 0 ? (
                <>
                  <AutocompleteArrayInput
                    source='WalletTags'
                    defaultValue={walletRef.current.Tags}
                    choices={
                      user.Tags
                        ? user.Tags.map((tag: string, i: number) => ({
                            id: tag,
                            name: tag,
                          }))
                        : []
                    }
                  />
                  <Button
                    color='primary'
                    onClick={() => history.push(`/${environment('profiles')}/edit`)}
                    label='Manage Tags'
                  ></Button>
                </>
              ) : (
                <>
                  <span>
                    You don't have any strategy tags yet.
                    <Button
                      color='primary'
                      onClick={() => history.push(`/${environment('profiles')}/edit`)}
                      style={{ marginLeft: 15 }}
                      label='Create tags'
                      size='small'
                    />
                  </span>
                </>
              )}
              <ArrayInput
                source='PriceHistory'
                label='Wallet History'
                defaultValue={walletRef.current.PriceHistory}
                style={{ zIndex: 0 }}
              >
                <SimpleFormIterator>
                  <NumberInput
                    source='Balance'
                    label='Balance'
                    style={{ marginBottom: 0 }}
                    fullWidth
                    min={0}
                  />
                  <NumberInput
                    source='Quote'
                    label='Price'
                    style={{ marginTop: 0, marginBottom: 0 }}
                    fullWidth
                    min={0}
                  />
                  <NumberInput
                    source='TotalSupply'
                    label='TotalSupply'
                    style={{ marginTop: 0, marginBottom: 0 }}
                    fullWidth
                    min={0}
                  />
                  <NumberInput
                    source='CirculatingSupply'
                    label='CirculatingSupply'
                    style={{ marginTop: 0, marginBottom: 0 }}
                    fullWidth
                    min={0}
                  />
                  <NumberInput
                    source='MaxSupply'
                    label='MaxSupply'
                    style={{ marginTop: 0, marginBottom: 0 }}
                    fullWidth
                    min={0}
                  />
                  <DateInput
                    source='Timestamp'
                    label='From Date'
                    max={new Date().toISOString().substring(0, 10)}
                    style={{ marginTop: 0, marginBottom: 0 }}
                    options={{
                      inputProps: {
                        max: new Date().toISOString().substring(0, 10),
                      },
                    }}
                    fullWidth
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleForm>
          </Edit>
        </Dialog>
      </div>
    </>
  )
}

interface CoinConfigMap {
  [key: string]: CoinConfig
}

export default ManualWalletEditModal
