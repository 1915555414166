import { ThemeName, UserDoc } from "../views/types";

export const CHANGE_THEME = "CHANGE_THEME";

export const changeTheme = (theme: ThemeName) => ({
  type: CHANGE_THEME,
  payload: theme,
});

export const UPDATE_USER = "UPDATE_USER";

export const updateUser = (user: UserDoc | {}) => ({
  type: UPDATE_USER,
  payload: user,
});
