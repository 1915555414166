import {
  Edit,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
} from "react-admin";
import { CreateProps } from "react-admin";
import { environment } from "../../../components/common";

/**
 * @summary Renders the form used to edit existing symbols in the backend.
 *
 * @param props
 * @returns {JSX.Element}
 */
const SymbolEdit = (props: CreateProps) => {
  return (
    <Edit title="Edit Symbol" {...props} actions={<PostEditActions />}>
      <SimpleForm>
        <TextInput
          source="Name"
          label="The project/token logical name."
          placeholder="Bitcoin"
        />
        <TextInput
          source="Symbol"
          label="The ticker of the symbol."
          placeholder="BTC"
        />
        <TextInput
          source="Description"
          label="The description of the symbol."
          placeholder="Bitcoin"
        />
        <TextInput
          source="Id"
          label="The price source internal id of the symbol"
          placeholder="3344"
        />
        <BooleanInput
          source="Manual"
          label="Whether the symbol is manually marked or not."
          placeholder="false"
        />
        <ArrayInput
          source="Networks"
          label="Any networks in which the token exists."
        >
          <SimpleFormIterator>
            <TextInput source="" label="" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput
          source="TokenContracts"
          label="Any token contracts associated with this symbol configuration."
        >
          <SimpleFormIterator>
            <TextInput
              source="Name"
              label="The name of the token contract."
              placeholder="My New Token"
            />
            <TextInput
              source="Network"
              label="The name of the network."
              placeholder="ETH"
            />
            <TextInput
              source="ContractAddress"
              label="The address of the token contract."
              placeholder="0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9"
            />
            <NumberInput
              source="Decimals"
              label="The decimals of the token contract."
              placeholder="18"
            />
          </SimpleFormIterator>
        </ArrayInput>
        <ReferenceInput
          label="Source"
          source="Source"
          reference="price_sources"
        >
          <SelectInput optionText="Name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

const PostEditActions = () => (
  <TopToolbar>
    <ListButton basePath={`/${environment("coin-config")}`} />
  </TopToolbar>
);

export default SymbolEdit;
