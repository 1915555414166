export const acceptedChains: string[] = ["regen", "secret"];

export const chainConfig: StakingMap = {
  REGEN: {
    title: "Regen",
    prefix: "regen",
    valPrefix: "regenvaloper",
    chainId: "regen-1",
    type: "stargate",
    ticker: "REGEN",
    denom: "uregen",
    rpc: "http://3.22.22.248:26657/",
    validators: ["regenvaloper15khamenky6kmmv8npd0mnwme22fhks735zdpk6"],
    decimals: 6,
  },
  SCRT: {
    title: "Secret",
    prefix: "secret",
    valPrefix: "secretvaloper",
    chainId: "secret-4",
    type: "stargate",
    ticker: "SCRT",
    denom: "uscrt",
    rest: "https://lcd-secret.keplr.app",
    rpc: "https://rpc-secret.scrtlabs.com/secret-4/rpc/",
    validators: ["secretvaloper19gaqfv4zc76mkklvuql4p7jjwuw5w0604sej43"],
    decimals: 6,
  },
};

export interface StakingMap {
  [key: string]: StakingConfig;
}

export interface StakingConfig {
  title: string;
  prefix: string;
  valPrefix: string;
  chainId: string;
  type: string;
  ticker: string;
  denom: string;
  rpc?: string;
  rest?: string;
  validators: string[];
  decimals: number;
}
