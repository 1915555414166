import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Layout, useSetLocale } from "react-admin";
import { AppBar } from "../../components/layout";
import Menu from "../../components/layout/Menu";
import { useLocale } from "react-admin";
import { darkTheme, lightTheme } from "../../components/layout/themes";
import { AppState } from "../types";
import { LayoutProps } from "ra-ui-materialui";

/**
 * Sets the theme, menu and locale
 *
 * @param props
 * @returns a JSX component
 */
const LayoutComponent = (props: LayoutProps) => {
  const theme = useSelector((state: AppState) =>
    state.theme === "dark" ? darkTheme : lightTheme
  );
  const setLocale = useSetLocale();
  const locale = useLocale();
  useEffect(() => {
    const storedLocal = localStorage.getItem("locale");
    setLocale(storedLocal ? storedLocal : locale === "fr" ? "fr" : "en");
  }, [setLocale, locale]);
  return <Layout {...props} appBar={AppBar} menu={Menu} theme={theme} />;
};

export default LayoutComponent;
