import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Inbox from "@material-ui/icons/CloudQueue";
import { useTranslate, CreateButton } from "react-admin";

/**
 * @summary Empty page to be displayed in the wallet/profile view when there are no wallets.
 *
 * @param props
 * @returns {JSX.Element}
 */
const Empty = (props: Props) => {
  const { label } = props;
  const classes = useStyles(props);
  const translate = useTranslate();

  const emptyMessage = translate("ra.page.empty", { name: label });

  return (
    <>
      <div className={classes.message}>
        <Inbox className={classes.icon} />
        <Typography variant="h4" paragraph>
          {translate(`resources.${label}.empty`, {
            _: emptyMessage,
          })}
        </Typography>
        <CreateButton
          to="create-wallet"
          variant="contained"
          label={"pos.wallets.create_wallet"}
        />
      </div>
    </>
  );
};

interface Props {
  label: string;
}

const useStyles = makeStyles((theme) => ({
  message: {
    textAlign: "center",
    opacity: theme.palette.type === "light" ? 0.5 : 0.8,
    margin: "0 1em",
    color:
      theme.palette.type === "light" ? "inherit" : theme.palette.text.primary,
  },
  icon: {
    width: "9em",
    height: "9em",
  },
}));

export default Empty;
