import { Grid, Card, Typography } from '@material-ui/core'
import { PortfolioBalancePie } from '../graphs'
import { makeStyles } from '@material-ui/core'
import { Distribution, EquityCurves, PriceHistory } from '../../views/types'
import PortfolioAssetComparison from '../graphs/PortfolioAssetComparison'
import { toCurrencyString } from '../common'
import PercentChange from '../common/PercentChange'
import { useTranslate } from 'react-admin'

/**
 * @summary Renders the top section of the portfolio page containing the balance section and asset comparison.
 *
 * @param props
 * @returns {JSX.Element}
 */
const PortfolioOverview2 = ({
  distribution,
  equityCurves,
  totalPortfolioValue,
  totalSelectionValue,
  priceHistory,
  activeTag,
  totalStake,
  totalPendingRewards,
  totalValueAtRisk,
  totalUlcerIndex,
}: OverviewProps) => {
  const classes = portfolioStyles()
  const translate = useTranslate()

  // Calculate the change and percent of total that is currently being displayed.
  const lastClose =
    equityCurves['Total'].holdingsData.length > 2
      ? equityCurves['Total'].holdingsData[equityCurves['Total'].holdingsData.length - 1].Quote
      : 0
  const prevClose =
    equityCurves['Total'].holdingsData.length > 2
      ? equityCurves['Total'].holdingsData[equityCurves['Total'].holdingsData.length - 2].Quote
      : 0
  const change = ((lastClose - prevClose) / prevClose) * 100
  const percentOfPortfolio = (totalSelectionValue / totalPortfolioValue) * 100

  return (
    <Grid container spacing={2} className={classes.overviewContainer}>
      <Grid item xs={12} lg={4} xl={4}>
        <Card className={classes.card} variant='outlined'>
          <div
            style={{
              padding: '30px 0 0 0',
              width: '100%',
              height: '100%',
              position: 'relative',
            }}
          >
            <PortfolioBalancePie
              distribution={distribution}
              totalValue={totalSelectionValue}
              mobile={true}
            />
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                paddingTop: 5,
              }}
            >
              <div style={{ textAlign: 'center', marginBottom: 6 }}>
                {activeTag === 'All' ? translate('pos.dashboard.total_portfolio') : activeTag}
              </div>
              <div className={classes.balance}>{toCurrencyString(totalSelectionValue)}</div>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <PercentChange change={change} />
              </div>
            </div>
          </div>

          {percentOfPortfolio !== 100 && (
            <div className={classes.percentOfPortfolio}>
              <span>* {percentOfPortfolio.toFixed(2)}% of Total</span>
            </div>
          )}
        </Card>
      </Grid>
      <Grid container item xs={12} lg={8} xl={8}>
        <Card className={classes.graphCard} variant='outlined'>
          <div style={{ paddingTop: 20 }} />
          {Object.keys(equityCurves).length > 0 && (
            <PortfolioAssetComparison
              equityCurves={equityCurves}
              priceHistory={priceHistory}
              activeTag={activeTag}
            />
          )}
        </Card>
      </Grid>

      <Grid container item xs={12} md={3} xl={3}>
        <Card
          style={{
            padding: 20,
            width: '100%',
            justifyContent: 'center',
            textAlign: 'center',
          }}
          variant='outlined'
        >
          <Typography>Total Stake</Typography>
          <Typography variant='h6' style={{ paddingTop: 5, fontWeight: 700 }}>
            {toCurrencyString(totalStake ?? 0)}
          </Typography>
        </Card>
      </Grid>

      <Grid container item xs={12} md={3} xl={3}>
        <Card
          style={{
            padding: 20,
            width: '100%',
            justifyContent: 'center',
            textAlign: 'center',
          }}
          variant='outlined'
        >
          <Typography>Pending Rewards</Typography>
          <Typography variant='h6' style={{ paddingTop: 5, fontWeight: 700 }}>
            {toCurrencyString(totalPendingRewards ?? 0)}
          </Typography>
        </Card>
      </Grid>

      <Grid container item xs={12} md={3} xl={3}>
        <Card
          style={{
            padding: 20,
            width: '100%',
            justifyContent: 'center',
            textAlign: 'center',
          }}
          variant='outlined'
        >
          <Typography>Value at Risk</Typography>
          <Typography variant='h6' style={{ paddingTop: 5, fontWeight: 700 }}>
            {totalValueAtRisk ? Math.abs(totalValueAtRisk * 100).toFixed(2) : 0}%
          </Typography>
        </Card>
      </Grid>

      <Grid container item xs={12} md={3} xl={3}>
        <Card
          style={{
            padding: 20,
            width: '100%',
            justifyContent: 'center',
            textAlign: 'center',
          }}
          variant='outlined'
        >
          <Typography>Ulcer Index</Typography>
          <Typography variant='h6' style={{ paddingTop: 5, fontWeight: 700 }}>
            {totalUlcerIndex ? totalUlcerIndex.toFixed(2) : 0}
          </Typography>
        </Card>
      </Grid>
    </Grid>
  )
}

interface OverviewProps {
  distribution: Distribution[]
  equityCurves: EquityCurves
  totalPortfolioValue: number
  totalSelectionValue: number
  priceHistory: { [key: string]: PriceHistory[] }
  activeTag: string
  totalStake?: number
  totalPendingRewards?: number
  totalValueAtRisk?: number
  totalUlcerIndex?: number
}

const portfolioStyles = makeStyles(theme => ({
  overviewContainer: {
    marginTop: 0,
    [theme.breakpoints.down('xs')]: { marginBottom: 7 },
  },
  card: {
    padding: '0px 0px 15px 0px',
    width: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 0px 5px 0px',
    },
  },
  graphCard: {
    padding: '0px 0px 0px 15px',
    width: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  balance: {
    textAlign: 'center',
    fontSize: 24,
    paddingBottom: 6,
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: { fontSize: 20 },
  },
  breakdownTitle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    marginBottom: 18,
  },
  percentChange: {
    fontSize: 14,
    textAlign: 'right',
    marginTop: 5,
  },
  percentOfPortfolio: {
    position: 'absolute',
    bottom: 19,
    right: 22,
    fontSize: 14,
    [theme.breakpoints.down('xs')]: {
      bottom: 15,
      right: 15,
    },
  },
}))

export default PortfolioOverview2
