import { DataProvider as FirebaseDataProvider, IDataProvider } from './providers/DataProvider'
import { AuthProvider as FirebaseAuthProvider } from './providers/AuthProvider'
import { RAFirebaseOptions } from './providers/options'
import { environment } from '../components/common'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

const cacheDataProviderProxy = (
  dataProvider: IDataProvider,
  duration = 5 * 60 * 1000, // Default cache set to 5 mins.
) =>
  new Proxy(dataProvider, {
    get: (target, name: string) => (resource: string, params: any) => {
      if (name === 'getOne' || name === 'getMany' || name === 'getList') {
        return dataProvider[name](resource, params).then(response => {
          const validUntil = new Date()

          // If request profile leave validity date as now so it always loads fresh.
          if (resource !== environment('profiles')) {
            validUntil.setTime(validUntil.getTime() + duration)
          } else {
            // Set validity of profile to 1 second to avoid react-admin getting stuck in a loop when loading forms.
            validUntil.setTime(validUntil.getTime() + 1000)
          }

          if (!response.validUntil) {
            response.validUntil = validUntil
          }
          return response
        })
      }
      return dataProvider[name](resource, params)
    },
  })

const dataProvider = cacheDataProviderProxy(FirebaseDataProvider(firebaseConfig))
const authProvider = FirebaseAuthProvider(firebaseConfig, {})

export { dataProvider, authProvider }
export type { RAFirebaseOptions }
