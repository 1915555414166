import { useState, useEffect, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { TableHeader, tableStyles } from '../../components/table'
import { ReduxState, useDataProvider } from 'react-admin'
import { Card, useMediaQuery, Theme, Grid, Box, Paper, useTheme, Button } from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/GetApp'
import TimeIcon from '@material-ui/icons/Update'
import { DataGridPro, GridColDef, GridOverlay } from '@mui/x-data-grid-pro'
import { PriceHistoryAMCandle } from '../../components/graphs'
import { CoinConfig, QuoteRecord } from '../types'
import { setCoinConfigState, setQuoteHistoryState } from '../../components/common'
import { IDataProvider } from '../../firebase/providers/DataProvider'
import { Loading } from '..'
import { calculateUlcerIndex, calculateConditionalValueAtRisk } from '../../analytics'
import { sortArray } from '../../firebase/misc'
import { toCurrencyString } from '../../components/common'
import PriceHistoryAMLine from '../../components/graphs/PriceHistoryAMLine'

/**
 * @summary The primary component of the price history view
 *
 * TODO:  Apply pagination without restricting access to the data value sent to the Datagrid
 *
 * @param props
 * @returns {JSX.Element}
 */
const PriceHistory = ({ target }: { target: string }) => {
  const [coinConfig, setCoinConfig] = useState<{ [key: string]: CoinConfig }>()
  const [interval, setInterval] = useState<'hour' | 'day'>('day')
  const [graphDataLoading, setGraphDataLoading] = useState<boolean>(false)
  const [conditionalValueAtRisk, setConditionalValueAtRisk] = useState<number>()
  const [ulcerIndex, setUlcerIndex] = useState<number>()

  const [quoteHistory, setQuoteHistory] = useState<{ [key: string]: QuoteRecord[] }>()

  const [dateRange, setDateRange] = useState<Date[] | [null, null]>([
    new Date('2020-10-20'),
    new Date(),
  ])

  const classes = tableStyles()
  const loading = useSelector<ReduxState>(state => state.admin.loading > 0)
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.between('xs', 'sm'))

  const isMedium = useMediaQuery((theme: Theme) => theme.breakpoints.between('md', 'md'))

  const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

  const mountedRef = useRef(true)
  const isMounted = useCallback(() => mountedRef.current, [mountedRef.current])

  const dataProvider: IDataProvider = useDataProvider()
  const cache: any = useSelector((state: any) => state.admin)

  const theme = useTheme()

  useEffect(() => {
    setQuoteHistoryState(
      [target],
      dateRange[0] ? dateRange[0] : new Date('2020-10-20'),
      dateRange[1] ? dateRange[1] : new Date(),
      interval === 'hour' ? 'hourly' : 'daily',
      cache,
      dataProvider,
      setQuoteHistory,
      isMounted,
    )
    setCoinConfigState([target], cache, dataProvider, setCoinConfig, isMounted)

    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    if (quoteHistory && quoteHistory[target].length > 0) {
      sortArray(quoteHistory[target], 'Timestamp', 'asc')
      const start = quoteHistory[target][0].Timestamp
      const end = quoteHistory[target][quoteHistory[target].length - 1].Timestamp
      setDateRange([new Date(start), new Date(end)])
      setGraphDataLoading(false)

      if (!ulcerIndex && !conditionalValueAtRisk) {
        const ulcerIndex = calculateUlcerIndex(quoteHistory[target].map(d => d.Close)) * 100
        const conValueAtRisk =
          calculateConditionalValueAtRisk(
            quoteHistory[target].map(d => d.Close),
            0.95,
          ) * 100
        setConditionalValueAtRisk(conValueAtRisk)
        setUlcerIndex(ulcerIndex)
      }
    }
  }, [quoteHistory])

  useEffect(() => {
    setGraphDataLoading(true)
    setQuoteHistory(undefined)
    setQuoteHistoryState(
      [target],
      dateRange[0] ? dateRange[0] : new Date('2020-10-20'),
      dateRange[1] ? dateRange[1] : new Date(),
      interval === 'hour' ? 'hourly' : 'daily',
      cache,
      dataProvider,
      setQuoteHistory,
      isMounted,
    )
  }, [interval])

  const NoMarketData = () => (
    <GridOverlay>
      <p>No Data</p>
    </GridOverlay>
  )

  const NoTxData = () => (
    <GridOverlay>
      <p>No Transactions Yet</p>
    </GridOverlay>
  )

  const mdColumns: GridColDef[] = [
    {
      field: 'Timestamp',
      headerName: 'Date',
      type: 'date',
      width: 150,
      minWidth: 100,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'Open',
      headerName: 'Open',
      type: 'number',
      width: 150,
      minWidth: 100,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'Close',
      headerName: 'Close',
      type: 'number',
      width: 150,
      minWidth: 100,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'High',
      headerName: 'High',
      type: 'number',
      width: 150,
      minWidth: 100,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'Low',
      headerName: 'Low',
      type: 'number',
      width: 150,
      minWidth: 100,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'PercentChange',
      headerName: 'Change',
      type: 'number',
      width: 150,
      minWidth: 100,
      flex: 1,
      disableColumnMenu: true,
      renderCell: params => {
        return (
          <p
            style={{
              color:
                params.value && params.value > 0
                  ? theme.palette.success.main
                  : theme.palette.error.main,
            }}
          >
            {params.value ? Number(params.value).toFixed(2) : '0'}%
          </p>
        )
      },
    },
    {
      field: 'Volume',
      headerName: 'Volume',
      type: 'number',
      width: 150,
      flex: 1,
    },
  ]

  if (
    loading ||
    !coinConfig ||
    !coinConfig[target] ||
    !quoteHistory ||
    quoteHistory[target].length < 1
  )
    return <Loading />

  const CandleChart = (
    <Card variant='outlined' style={{ position: 'relative' }}>
      <Box className={classes.candleMobile}>
        {quoteHistory && !graphDataLoading && (
          <PriceHistoryAMCandle data={quoteHistory[target]} interval={interval} />
        )}
      </Box>
    </Card>
  )

  const PriceTable = (
    <Paper variant='outlined'>
      <TableHeader title='Market Data' />
      <Box style={{ height: 615, width: '100%' }}>
        <DataGridPro
          columns={mdColumns}
          rows={
            quoteHistory
              ? quoteHistory[target]
                  .map(d => ({
                    ...d,
                    id: d.Timestamp,
                  }))
                  .reverse()
              : []
          }
          pageSize={15}
          rowsPerPageOptions={[15]}
          components={{ NoRowsOverlay: NoMarketData }}
          sortingOrder={['desc', 'asc']}
          checkboxSelection={false}
          disableSelectionOnClick
        />
      </Box>
    </Paper>
  )

  const TransactionTable = (
    <Paper variant='outlined'>
      <TableHeader title='Recent Transactions' />
      <Box style={{ height: 400, width: '100%' }}>
        <DataGridPro
          columns={txColumns}
          rows={[]}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
          components={{ NoRowsOverlay: NoTxData }}
        />
      </Box>
    </Paper>
  )

  const PirceSummaryTable = (chart: any) => (
    <Paper variant='outlined'>
      <TableHeader
        title={`${coinConfig && coinConfig[target].Name}`}
        icon={`/images/${target.toLowerCase()}.svg`}
      />
      <Box style={{ padding: '10px 20px 20px 20px' }}>
        {chart && <div style={{ paddingBottom: 5 }}>{chart}</div>}
        <table
          style={{
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            opacity: 0.87,
            fontSize: '0.87rem',
          }}
          className={classes.riskTable}
        >
          <tbody>
            <tr>
              <th style={{ textAlign: 'left' }}>Current Price</th>
              <th style={{ textAlign: 'right' }}>{toCurrencyString(coinConfig[target].Quote)}</th>
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>Rank</td>
              <td
                style={{
                  textAlign: 'right',
                }}
              >
                {coinConfig[target].Rank ? coinConfig[target].Rank : 'N/A'}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>24hr Change</td>
              <td
                style={{
                  textAlign: 'right',
                  color:
                    coinConfig[target].PercentChange < 0
                      ? theme.palette.error.main
                      : theme.palette.success.main,
                }}
              >
                {(coinConfig[target].PercentChange ?? 0).toFixed(2)}%
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>7d Change</td>
              <td
                style={{
                  textAlign: 'right',
                  color:
                    coinConfig[target].PercentChange_7d < 0
                      ? theme.palette.error.main
                      : theme.palette.success.main,
                }}
              >
                {(coinConfig[target].PercentChange_7d ?? 0).toFixed(2)}%
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>24hr Volume</td>
              <td style={{ textAlign: 'right' }}>{toCurrencyString(coinConfig[target].Volume)}</td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>7d Av. Volume</td>
              <td style={{ textAlign: 'right' }}>
                {toCurrencyString(coinConfig[target].Volume_7d / 7)}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>30d Av Volume</td>
              <td style={{ textAlign: 'right' }}>
                {toCurrencyString(coinConfig[target].Volume_30d / 30)}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>Volume / Market Cap</td>
              <td
                style={{
                  textAlign: 'right',
                  color:
                    coinConfig[target].Volume / coinConfig[target].MarketCapitalization < 0.01
                      ? theme.palette.error.main
                      : theme.palette.success.main,
                }}
              >
                {(coinConfig[target].Volume / coinConfig[target].MarketCapitalization).toFixed(3)}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>Ulcer Index</td>
              <td
                style={{
                  textAlign: 'right',
                  color:
                    ulcerIndex && ulcerIndex > 5
                      ? theme.palette.error.main
                      : theme.palette.success.main,
                }}
              >
                {ulcerIndex && ulcerIndex.toFixed(3)}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>Con. Value at Risk</td>
              <td
                style={{
                  textAlign: 'right',
                  color:
                    conditionalValueAtRisk && conditionalValueAtRisk < 0
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                }}
              >
                {conditionalValueAtRisk
                  ? `${conditionalValueAtRisk.toFixed(3)}%`
                  : 'Not enough data'}
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    </Paper>
  )

  return (
    <div className={classes.dateSearchHeaderSpacing}>
      <div className={classes.priceHistoryToolbar}>
        {!isXSmall && (
          <>
            <Button
              color='primary'
              size='small'
              startIcon={<TimeIcon />}
              onClick={() => setInterval('hour')}
              disabled={interval === 'hour'}
            >
              1h
            </Button>
            <Button
              color='primary'
              size='small'
              startIcon={<TimeIcon />}
              onClick={() => setInterval('day')}
              disabled={interval === 'day'}
              style={{ marginRight: 'auto' }}
            >
              1d
            </Button>
            {/* <Button
              color="primary"
              size="small"
              startIcon={<TimeIcon />}
              disabled={true}
              style={{ marginRight: "auto" }}
            >
              1w
            </Button> */}
          </>
        )}
        <Button color='primary' size='small' startIcon={<DownloadIcon />} disabled={true}>
          Export
        </Button>
      </div>
      <Grid container spacing={2} style={{ marginTop: isXSmall ? 0 : 0 }}>
        {!isSmall && (
          <Grid item xs={12} lg={8} xl={9}>
            {CandleChart}
            {isLarge && <div style={{ marginTop: theme.spacing(2) }}>{PriceTable}</div>}
          </Grid>
        )}
        {isLarge && (
          <Grid item xs={12} lg={4} xl={3}>
            <div style={{ paddingBottom: theme.spacing(2) }}>{PirceSummaryTable(null)}</div>
            {TransactionTable}
          </Grid>
        )}
        {(isMedium || isSmall) && (
          <Grid item xs={12}>
            {PirceSummaryTable(
              <PriceHistoryAMLine
                data={quoteHistory[target].map(d => ({
                  Quote: d.Close,
                  Timestamp: d.Timestamp,
                }))}
              />,
            )}
          </Grid>
        )}
        {isMedium && (
          <Grid item xs={12}>
            {TransactionTable}
          </Grid>
        )}
        {isMedium && (
          <Grid item xs={12} lg={8} xl={9}>
            {PriceTable}
          </Grid>
        )}
      </Grid>
    </div>
  )
}

const txColumns: GridColDef[] = [
  {
    field: 'transactionID',
    headerName: 'TxID',
    width: 150,
    flex: 1,
    disableColumnMenu: true,
    hideSortIcons: true,
  },
  {
    field: 'timestamp',
    headerName: 'Date',
    type: 'date',
    width: 150,
    flex: 1,
    disableColumnMenu: true,
    hideSortIcons: true,
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    type: 'number',
    width: 150,
    flex: 1,
    disableColumnMenu: true,
    hideSortIcons: true,
  },
  {
    field: 'value',
    headerName: 'Value',
    type: 'number',
    width: 150,
    align: 'left',
    flex: 1,
    disableColumnMenu: true,
    hideSortIcons: true,
  },
]

export default PriceHistory
