import { useRef, useEffect, useState, useCallback } from 'react'
import {
  SimpleForm,
  TextInput,
  ArrayInput,
  DateInput,
  Toolbar,
  ToolbarProps,
  SaveButton,
  useNotify,
  useRedirect,
  Edit,
  CreateProps,
  useMutation,
  useDataProvider,
  SimpleFormIterator,
  Button,
  NumberInput,
  AutocompleteArrayInput,
} from 'react-admin'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import walletStyles from '../../components/wallets/walletStyles'
import { environment } from '../../components/common'
import { Grid } from '@material-ui/core'
import { SanitizedGrid } from '../../components/form'
import { CoinConfig, UserDoc } from '../types'
import { updateUser } from '../../persistence/actions'
import { setCoinConfigState } from '../../components/common'
import { IDataProvider } from '../../firebase/providers/DataProvider'
import { validateManualWallet } from '../../components/wallets/methods'

/**
 * @summary Renders the form used to add a shared profile by profile id.
 *
 * @param props
 * @returns {JSX.Element}
 */
const ManualWalletAdd = (props: CreateProps) => {
  const [coinConfig, setCoinConfig] = useState<{ [key: string]: CoinConfig }>()
  const mountedRef = useRef(true)
  const isMounted = useCallback(() => mountedRef.current, [mountedRef.current])
  const notify = useNotify()
  const dispatch = useDispatch()
  const redirectTo = useRedirect()
  const user = useSelector((state: any) => state.user)
  const cache: any = useSelector((state: any) => state.admin)
  const classes = walletStyles()
  const dataProvider: IDataProvider = useDataProvider()
  const [mutate] = useMutation()
  const history = useHistory()

  useEffect(() => {
    setCoinConfigState(user.Tracking, cache, dataProvider, setCoinConfig, isMounted)
  }, [])

  const save = useCallback(
    values => {
      const errors: any = {}
      const { TokenName, TokenSymbol, PriceHistory, WalletAlias, WalletTags } = values
      const notValid = validateManualWallet(TokenName, TokenSymbol, PriceHistory, WalletAlias)
      if (notValid) return notValid

      const Symbol = TokenSymbol.toUpperCase()

      // Validation passed!
      const wallet = {
        id: new Date().getTime().toString(),
        Alias: WalletAlias,
        Name: TokenName,
        Symbol: Symbol,
        PriceHistory: PriceHistory,
        Tags: WalletTags ? WalletTags : [],
      }

      // Fetch profile first incase anyone else added a token since last check.
      return dataProvider
        .getOne(environment('profiles'), {
          id: user.ActiveProfile,
        })
        .then(async ({ data }) => {
          // Double check no one added the same symbol since last load.
          data.Untracked &&
            data.Untracked.forEach((token: any) => {
              if (token.Symbol === Symbol)
                errors.TokenSymbol = 'The token symbol was added by someone else.'
            })
          if (errors.TokenSymbol) return errors

          // Update the profile.
          const updatedUntracked = user.Untracked ? [...user.Untracked] : []
          updatedUntracked.push(wallet)

          try {
            await mutate(
              {
                type: 'update',
                resource: environment('profiles'),
                payload: {
                  id: user.ActiveProfile,
                  data: {
                    Untracked: updatedUntracked,
                  },
                },
              },
              {
                onSuccess: () => {
                  const updatedProfile: UserDoc = {
                    ...user,
                    Untracked: updatedUntracked,
                  }
                  dispatch(updateUser(updatedProfile))
                  notify('pos.wallets.created_manual_wallet', {
                    type: 'success',
                  })
                  redirectTo('/wallets')
                },
                returnPromise: true,
              },
            )
          } catch (err: any) {
            console.log(err)
            notify('pos.app.save_failed', { type: 'error' })
            return errors
          }
        })
        .catch(err => {
          console.log(err)
          notify('pos.profile.check_failed', { type: 'error' })
          return errors
        })
    },
    [user, coinConfig, mutate, dataProvider],
  )

  const SaveBar = (props: ToolbarProps) => (
    <Toolbar {...props}>
      <SaveButton disabled={props.pristine} />
    </Toolbar>
  )

  return (
    <div style={{ marginTop: 16 }}>
      <Edit
        {...props}
        basePath={`/${environment('profiles')}`}
        resource={`${environment('profiles')}`}
        id={user.id}
        mutationMode='pessimistic'
      >
        <SimpleForm mutationMode='pessimistic' toolbar={<SaveBar />} save={save}>
          <SanitizedGrid container spacing={2} className={classes.formContainer}>
            <Grid item xs={12} className={classes.formRow}>
              <TextInput
                source='WalletAlias'
                className={classes.inputField}
                margin='normal'
                label='Wallet Alias'
              />
              <TextInput
                source='TokenName'
                className={classes.inputField}
                margin='normal'
                label='Currency Name'
              />
              <TextInput
                source='TokenSymbol'
                className={`${classes.inputField} ${classes.uppercase}`}
                margin='normal'
                label='Symbol'
              />
              {user.Tags && user.Tags.length > 0 ? (
                <>
                  <AutocompleteArrayInput
                    source='WalletTags'
                    choices={
                      user.Tags
                        ? user.Tags.map((tag: string, i: number) => ({
                            id: tag,
                            name: tag,
                          }))
                        : []
                    }
                  />
                  <Button
                    color='primary'
                    onClick={() => history.push(`/${environment('profiles')}/edit`)}
                    label='Manage Tags'
                  ></Button>
                </>
              ) : (
                <>
                  <span>
                    You don't have any strategy tags yet.
                    <Button
                      color='primary'
                      onClick={() => history.push(`/${environment('profiles')}/edit`)}
                      style={{ marginLeft: 15 }}
                      label='Create tags'
                      size='small'
                    />
                  </span>
                </>
              )}
              <ArrayInput
                source='PriceHistory'
                label='Wallet History'
                defaultValue={[{ Balance: 0, Quote: 0, Timestamp: new Date() }]}
              >
                <SimpleFormIterator>
                  <NumberInput source='Balance' label='Balance' />
                  <NumberInput source='Quote' label='Price' />
                  <NumberInput source='TotalSupply' label='TotalSupply' />
                  <NumberInput source='CirculatingSupply' label='CirculatingSupply' />
                  <NumberInput source='MaxSupply' label='MaxSupply' />
                  <DateInput
                    source='Timestamp'
                    label='From Date'
                    max={new Date().toISOString().substring(0, 10)}
                    options={{
                      inputProps: {
                        max: new Date().toISOString().substring(0, 10),
                      },
                    }}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
          </SanitizedGrid>
        </SimpleForm>
      </Edit>
    </div>
  )
}

export default ManualWalletAdd
