import { UserDoc, Integration } from "../../views/types";

/**
 * @summary Trigger an update after adding a new integration.
 *
 * @param user
 * @param record
 * @param token
 * @returns {() => void}
 */
export const triggerIntegrationProcessing = (
  user: UserDoc,
  record: Integration,
  token: string
) => {
  const url =
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? "https://radar-proxy.republic.com/Event/integration"
      : "https://radar-proxy.republic-beta.app/Event/integration";
  const body = {
    profileId: user.ActiveProfile,
    integrationId: record.id,
  };
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };

  return fetch(url, options).catch((err) => console.log(err));
};
