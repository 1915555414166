import { forwardRef } from "react";
import { AppBar, MenuItemLink, useTranslate } from "react-admin";
import SettingsIcon from "@material-ui/icons/Settings";
import ShareIcon from "@material-ui/icons/Share";
import CreateIcon from "@material-ui/icons/Create";
import SwapIcon from "@material-ui/icons/SwapHorizRounded";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery, Theme, Divider } from "@material-ui/core";
import Logo from "../../components/layout/Logo";
import {
  AppBarProps,
  MenuItemLinkProps,
  ReferenceInputProps,
  UserMenuProps,
} from "ra-ui-materialui";
import { UserMenu } from ".";
import { useMutation } from "react-admin";
import { useSelector, useDispatch } from "react-redux";
import { UserDoc } from "../../views/types";
import { updateActiveProfile } from "../wallets/methods";
import { environment } from "../common";

const useStyles = makeStyles((theme) => ({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
}));

const ConfigurationMenu = forwardRef<MenuItemLinkProps, ReferenceInputProps>(
  (props, ref) => {
    const translate = useTranslate();
    return (
      <MenuItemLink
        ref={ref}
        to="/configuration"
        primaryText={translate("pos.configuration")}
        leftIcon={<SettingsIcon />}
        onClick={props.onClick}
        sidebarIsOpen
      />
    );
  }
);

const CustomUserMenu = (props: UserMenuProps) => {
  const user: UserDoc = useSelector((state: any) => state.user);
  const [mutate] = useMutation();
  const dispatch = useDispatch();
  const translate = useTranslate();
  return (
    <UserMenu {...props}>
      {user &&
        user.Organizations &&
        user.Organizations.map((o) => {
          if (o.Name && o.ProfileId !== user.ActiveProfile) {
            return (
              <div key={o.ProfileId}>
                <MenuItemLink
                  to="/portfolio"
                  primaryText={o.Name}
                  leftIcon={<SwapIcon />}
                  onClick={() =>
                    updateActiveProfile(user, o.ProfileId, mutate, dispatch)
                  }
                  sidebarIsOpen
                />
              </div>
            );
          } else {
            return (
              <div key={o.ProfileId}>
                <MenuItemLink
                  to="/portfolio"
                  primaryText={user.Name}
                  leftIcon={<SwapIcon />}
                  onClick={() =>
                    updateActiveProfile(user, user.id, mutate, dispatch)
                  }
                  sidebarIsOpen
                />
              </div>
            );
          }
        })}
      {user && user.Organizations && user.Organizations.length > 0 && (
        <Divider style={{ marginTop: 8, marginBottom: 10 }} />
      )}
      <MenuItemLink
        to="/add-portfolio/edit"
        primaryText={translate("pos.add_shared")}
        leftIcon={<ShareIcon />}
        onClick={() => {}}
        sidebarIsOpen
      />
      <MenuItemLink
        to={`/${environment("profiles")}/create`}
        primaryText={translate("pos.create_new")}
        leftIcon={<CreateIcon />}
        onClick={() => {}}
        sidebarIsOpen
      />
      <ConfigurationMenu />
    </UserMenu>
  );
};

const CustomAppBar = (props: AppBarProps) => {
  const classes = useStyles();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  return (
    <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
      <Logo height={isXSmall ? 30 : 35} />
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default CustomAppBar;
