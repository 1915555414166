import { useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery, Theme } from '@material-ui/core'
import classnames from 'classnames'
import { useTranslate, DashboardMenuItem, MenuItemLink } from 'react-admin'
import StorageIcon from '@material-ui/icons/Storage'
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting'
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import TollIcon from '@material-ui/icons/Toll'
import SettingsIcon from '@material-ui/icons/Settings'
import { AppState } from '../../views/types'
import { MenuProps } from 'ra-ui-materialui'
import { ReduxState } from 'react-admin'
import SubMenu from './SubMenu'
import { environment } from '../common'
import Logo from './Logo'

/**
 * @summary Renders the menu shown on the left hand side of the dashboard.
 *
 * TODO: Font, icons, wording of the links... basically the whole thing.
 *
 * @param props
 * @returns {JSX.Element}
 */
type MenuName = 'menuAdmin'
const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuAdmin: true,
  })
  const translate = useTranslate()
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen)
  useSelector((state: AppState) => state.theme) // force rerender on theme change
  const classes = useStyles()
  const user = useSelector((state: any) => state.user)
  const handleToggle = (menu: MenuName) => {
    setState(state => ({ ...state, [menu]: !state[menu] }))
  }
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

  return (
    <div
      className={classnames(classes.root, {
        [classes.open]: open,
        [classes.closed]: !open,
      })}
    >
      {isXSmall && <Logo height={32} className={classes.republicLogo} />}
      <div style={{ height: isXSmall ? 12 : 15 }} />
      <DashboardMenuItem />
      {user.IsAdmin && (
        <SubMenu
          handleToggle={() => handleToggle('menuAdmin')}
          isOpen={state.menuAdmin}
          name='pos.menu.admin'
          icon={<PermDataSettingIcon />}
          dense={dense}
        >
          <MenuItemLink
            to={{
              pathname: '/price_sources',
              state: { _scrollToTop: true },
            }}
            primaryText={translate(`pos.menu.price_sources`, {
              smart_count: 2,
            })}
            leftIcon={<SettingsInputAntennaIcon />}
            dense={dense}
          />
          <MenuItemLink
            to={{
              pathname: `/${environment('coin-config')}`,
              state: { _scrollToTop: true },
            }}
            primaryText={translate(`pos.menu.symbols`, {
              smart_count: 2,
            })}
            leftIcon={<MonetizationOnIcon />}
            dense={dense}
          />
        </SubMenu>
      )}
      <MenuItemLink
        to={{
          pathname: '/market-data',
          state: { _scrollToTop: true },
        }}
        primaryText={translate('pos.menu.market_data', {
          smart_count: 2,
        })}
        leftIcon={<TollIcon />}
        dense={dense}
      />
      <MenuItemLink
        to={{
          pathname: `/validators`,
          state: { _scrollToTop: true },
        }}
        primaryText={translate('pos.menu.validator_performance', {
          smart_count: 2,
        })}
        leftIcon={<StorageIcon />}
        dense={dense}
      />
      <div style={{ height: 30 }} />
      <MenuItemLink
        to={{
          pathname: '/configuration',
          state: { _scrollToTop: true },
        }}
        primaryText={translate('pos.menu.settings', {
          smart_count: 2,
        })}
        leftIcon={<SettingsIcon />}
        dense={dense}
      />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  open: {
    width: 200,
  },
  closed: {
    width: 55,
  },
  republicLogo: { paddingLeft: 16, paddingTop: 10 },
}))

export default Menu
