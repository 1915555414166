/**
 * @summary Trigger symbol backfill after it's added
 *
 * @param symbol
 * @param token
 * @returns {JSX.Element}
 */
export const triggerSymbolBackfill = (symbol: string, token: string) => {
  const url =
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? 'https://radar-proxy.republic.com/feed/symbol'
      : 'https://radar-proxy.republic-beta.app/feed/symbol'

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: `"${symbol}"`,
  }

  return fetch(url, options).catch(err => {
    console.log(err)
  })
}
