import * as React from "react";
import Chip from "@material-ui/core/Chip";

const TextArrayField = ({ record, source }: any) => (
  <>
    {record[source].map((item: string) => (
      <Chip label={item} key={item} />
    ))}
  </>
);
TextArrayField.defaultProps = { addLabel: true };

export default TextArrayField;
