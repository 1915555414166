import React from "react";
import { useLayoutEffect, useEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useTheme, useMediaQuery, Theme } from "@material-ui/core";
import { sortArray } from "../../firebase/misc";
import {
  calculateSMA,
  getChartTheme,
  getDefaultCandleData,
  getCandleData,
} from "./methods";

const PriceHistoryAMCandle = ({ data, interval }: Props) => {
  const theme: Theme = useTheme();
  const chartTheme = getChartTheme(theme);
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  const rootRef = useRef<am5.Root | null>(null);
  const chartRef = useRef<am5xy.XYChart | null>(null);
  const topLegendRef = useRef<am5.Legend | null>(null);
  const bottomLegendRef = useRef<am5.Legend | null>(null);

  useEffect(() => {
    return () => {
      chartRef.current && chartRef.current.dispose();
    };
  }, []);

  useLayoutEffect(() => {
    if (!rootRef.current) {
      rootRef.current = am5.Root.new("candlechartdiv");

      sortArray(data, "Timestamp", "asc");
      const sma = calculateSMA(data, theme);
      const defaultData = getDefaultCandleData(data, sma, theme);
      const processedData = getCandleData(data, sma);
      const { decimals } = defaultData;

      // Set the font and positive/negative colors.
      const myTheme = am5.Theme.new(rootRef.current);
      myTheme.rule("Label").setAll(chartTheme.fontStyle);
      myTheme.rule("InterfaceColors").setAll({
        positive: chartTheme.positive,
        negative: chartTheme.negative,
      });
      rootRef.current.setThemes([
        am5themes_Animated.new(rootRef.current),
        myTheme,
      ]);
      rootRef.current.numberFormatter.setAll({
        numberFormat: "$#,###.##a",
        bigNumberPrefixes: [
          { number: 1e6, suffix: "M" },
          { number: 1e9, suffix: "B" },
        ],
        smallNumberPrefixes: [],
      });

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      chartRef.current = rootRef.current.container.children.push(
        am5xy.XYChart.new(rootRef.current, {
          panX: true,
          panY: false,
          wheelX: "panX",
          wheelY: "zoomX",
          layout: rootRef.current.verticalLayout,
        })
      );
      chartRef.current.zoomOutButton.set("forceHidden", true);
      // Add republic background
      chartRef.current.plotContainer.children.push(
        am5.Picture.new(rootRef.current, {
          width: am5.percent(50),
          x: am5.percent(50),
          y: am5.percent(40),
          centerX: am5.percent(50),
          centerY: am5.percent(50),
          src:
            theme.palette.type === "dark"
              ? "/images/republic-graph-bg-dark.svg"
              : "/images/republic-graph-bg-light.svg",
          layer: 0,
        })
      );

      /// Create volume axis
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      const volumeAxisRenderer = am5xy.AxisRendererY.new(rootRef.current, {
        opposite: true,
      });
      volumeAxisRenderer.labels.template.setAll({
        centerY: am5.percent(50),
        paddingLeft: 8,
        maxPosition: 0.98,
        fontSize: 13,
        opacity: chartTheme.axisLabelOpacity,
        fill: chartTheme.themeFillStroke,
      });
      volumeAxisRenderer.grid.template.setAll({
        stroke: chartTheme.themeFillStroke,
      });
      volumeAxisRenderer.ticks.template.setAll({
        stroke: chartTheme.themeFillStroke,
        location: 0.5,
        multiLocation: 0.5,
        maxPosition: 0.98,
        strokeOpacity: theme.palette.type === "dark" ? 0.3 : 0.5,
        visible: true,
      });
      const volumeAxis = chartRef.current.yAxes.push(
        am5xy.ValueAxis.new(rootRef.current, {
          renderer: volumeAxisRenderer,
          height: am5.percent(30),
          layer: 5,
        })
      );
      volumeAxis.axisHeader.set("paddingTop", 10);
      volumeAxis.axisHeader.set("paddingBottom", 5);
      volumeAxis.axisHeader
        .get("background")!
        .set("fill", am5.color(theme.palette.background.paper));
      volumeAxis.axisHeader.children.push(
        am5.Label.new(rootRef.current, {
          text: "",
          fontWeight: "bold",
          paddingTop: 5,
          paddingBottom: 5,
          fill: chartTheme.themeFillStroke,
        })
      );
      const volumeTooltip = volumeAxis.set(
        "tooltip",
        am5.Tooltip.new(rootRef.current, {})
      );
      volumeTooltip.label.setAll({
        fontSize: 13,
        paddingTop: 0,
        paddingBottom: 0,
      });

      // Create date axis.
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      const dateAxisRenderer = am5xy.AxisRendererX.new(rootRef.current, {
        minGridDistance: 70,
      });
      dateAxisRenderer.labels.template.setAll({
        minPosition: 0.01,
        maxPosition: 0.99,
        location: 0.5,
        multiLocation: 0.5,
        fontSize: 13,
        opacity: chartTheme.axisLabelOpacity,
        fill: chartTheme.themeFillStroke,
      });
      dateAxisRenderer.grid.template.setAll({
        stroke: chartTheme.themeFillStroke,
        location: 0.5,
        strokeOpacity: theme.palette.type === "dark" ? 0.05 : 0.1,
        visible: true,
      });
      dateAxisRenderer.ticks.template.setAll({
        stroke: chartTheme.themeFillStroke,
        location: 0.5,
        multiLocation: 0.5,
        strokeOpacity: 0.3,
        visible: true,
      });
      const dateAxis = chartRef.current.xAxes.push(
        am5xy.DateAxis.new(rootRef.current, {
          baseInterval: { timeUnit: interval, count: 1 },
          maxZoomCount: 250,
          start: 1 - 1 / (data.length / 100),
          renderer: dateAxisRenderer,
        })
      );
      const dateTooltip = dateAxis.set(
        "tooltip",
        am5.Tooltip.new(rootRef.current, {})
      );
      dateTooltip.label.setAll({
        fontSize: 13,
        paddingTop: 0,
        paddingBottom: 0,
      });

      // Create value axis.
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      const valueAxisRenderer = am5xy.AxisRendererY.new(rootRef.current, {
        opposite: true,
        minGridDistance: 30,
      });
      valueAxisRenderer.labels.template.setAll({
        centerY: am5.percent(50),
        maxPosition: 0.98,
        paddingLeft: 8,
        fontSize: 13,
        opacity: chartTheme.axisLabelOpacity,
      });
      valueAxisRenderer.grid.template.setAll({
        stroke: chartTheme.themeFillStroke,
        strokeOpacity: theme.palette.type === "dark" ? 0.05 : 0.075,
      });
      valueAxisRenderer.ticks.template.setAll({
        stroke: chartTheme.themeFillStroke,
        location: 0.5,
        maxPosition: 0.98,
        multiLocation: 0.5,
        strokeOpacity: 0.3,
        visible: true,
      });
      const valueAxis = chartRef.current.yAxes.push(
        am5xy.ValueAxis.new(rootRef.current, {
          renderer: valueAxisRenderer,
          height: am5.percent(70),
          layer: 5,
          maxPrecision: 18,
          extraMax: 0.22,
          extraMin: -0,
        })
      );
      const valueTooltip = valueAxis.set(
        "tooltip",
        am5.Tooltip.new(rootRef.current, {})
      );
      valueTooltip.label.setAll({
        fontSize: 13,
        paddingTop: 0,
        paddingBottom: 0,
      });

      const colorHack = `{PercentChange.formatNumber('[${
        theme.palette.type === "dark"
          ? theme.palette.success.main
          : theme.palette.success.dark
      }]|[${
        theme.palette.type === "dark" ? theme.palette.error.main : "#ff0000"
      }]|[#999999]')}`;

      // Add series
      const valueSeries = chartRef.current.series.push(
        am5xy.CandlestickSeries.new(rootRef.current, {
          fill: defaultData.color,
          stroke: defaultData.color,
          clustered: false,
          calculateAggregates: true,
          name: "",
          xAxis: dateAxis,
          yAxis: valueAxis,
          valueYField: "Close",
          openValueYField: "Open",
          lowValueYField: "Low",
          highValueYField: "High",
          valueXField: "Timestamp",
          lowValueYGrouped: "low",
          highValueYGrouped: "high",
          openValueYGrouped: "open",
          valueYGrouped: "close",
          legendValueText: `Open: ${colorHack}{openValueY.formatNumber('#,###.${decimals}')}[/] Close: ${colorHack}{valueY.formatNumber('#,###.${decimals}')}[/] High: ${colorHack}{highValueY.formatNumber('#,###.${decimals}')}[/] Low: ${colorHack}{lowValueY.formatNumber('#,###.${decimals}')}[/] Change: ${colorHack}{PercentChange.formatNumber('+#,###.##|#,###.##|0')}%[/][fontSize: 20px]\n[fontSize:14px lineHeight: 35px]SMA(7): [${sma.seven.color}]{smaSeven.formatNumber('#,###.${decimals}')}[/] SMA(25): [${sma.twentyFive.color}]{smaTwentyFive.formatNumber('#,###.${decimals}')}[/] SMA(99): [${sma.ninetyNine.color}]{smaNinetyNine.formatNumber('#,###.${decimals}')}[/]`,
          legendRangeValueText: `Open: [${defaultData.textColor}]${defaultData.open}[/] Close: [${defaultData.textColor}]${defaultData.close}[/] High: [${defaultData.textColor}]${defaultData.high}[/] Low: [${defaultData.textColor}]${defaultData.low}[/] Change: [${defaultData.textColor}]${defaultData.percentChange}%[/][fontSize: 20px]\n[fontSize:14px lineHeight: 35px]SMA(7): [${sma.seven.color}]${defaultData.smaSeven}[/] SMA(25): [${sma.twentyFive.color}]${defaultData.smaTwentyFive}[/] SMA(99): [${sma.ninetyNine.color}]${defaultData.smaNinetyNine}[/]`,
          layer: 2,
        })
      );

      valueSeries.columns.template.setAll({
        width: am5.percent(70),
      });

      // Moving averages
      const smaSeriesSeven = chartRef.current.series.push(
        am5xy.SmoothedXLineSeries.new(rootRef.current, {
          name: `SMA (${sma.seven.periods})`,
          xAxis: dateAxis,
          yAxis: valueAxis,
          valueYField: "value",
          valueXField: "timestamp",
          fill: am5.color(sma.seven.color),
          stroke: am5.color(sma.seven.color),
          layer: 1,
          legendValueText: "{valueY.formatNumber('#,###.##')}",
        })
      );
      smaSeriesSeven.data.setAll(sma.seven.points);

      const smaSeriesTwentyFive = chartRef.current.series.push(
        am5xy.SmoothedXLineSeries.new(rootRef.current, {
          name: `SMA (${sma.twentyFive.periods})`,
          xAxis: dateAxis,
          yAxis: valueAxis,
          valueYField: "value",
          valueXField: "timestamp",
          legendValueText: "{valueY.formatNumber('#,###.##')}",
          fill: am5.color(sma.twentyFive.color),
          stroke: am5.color(sma.twentyFive.color),
          layer: 1,
        })
      );
      smaSeriesTwentyFive.data.setAll(sma.twentyFive.points);

      const smaSeriesNinetyNine = chartRef.current.series.push(
        am5xy.SmoothedXLineSeries.new(rootRef.current, {
          name: `SMA (${sma.ninetyNine.periods})`,
          xAxis: dateAxis,
          yAxis: valueAxis,
          valueYField: "value",
          valueXField: "timestamp",
          legendValueText: "{valueY.formatNumber('#,###.##')}",
          fill: am5.color(sma.ninetyNine.color),
          stroke: am5.color(sma.ninetyNine.color),
          layer: 1,
        })
      );
      smaSeriesNinetyNine.data.setAll(sma.ninetyNine.points);

      const volumeSeries = chartRef.current.series.push(
        am5xy.ColumnSeries.new(rootRef.current, {
          name: "",
          clustered: false,
          valueYField: "Volume",
          valueXField: "Timestamp",
          xAxis: dateAxis,
          yAxis: volumeAxis,
          legendValueText: "Vol(USD): {valueY}",
          legendRangeValueText: `Vol(USD): ${defaultData.volume}`,
        })
      );
      volumeSeries.columns.template.setAll({
        width: am5.percent(70),
      });
      volumeSeries.columns.template.states.create("riseFromPrevious", {
        fill: chartTheme.positiveWashed,
        stroke: chartTheme.positiveWashed,
        opacity: theme.palette.type === "dark" ? 1 : 0.85,
      });
      volumeSeries.columns.template.states.create("dropFromPrevious", {
        fill: chartTheme.negativeWashed,
        stroke: chartTheme.negativeWashed,
        opacity: theme.palette.type === "dark" ? 1 : 0.85,
      });

      // Add the value legend (top legend).
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      topLegendRef.current = chartRef.current.plotContainer.children.push(
        am5.Legend.new(rootRef.current, {
          useDefaultMarker: true,
          name: " ",
          paddingTop: 10,
          paddingLeft: 10,
        })
      );
      topLegendRef.current.labels.template.setAll({
        fill: chartTheme.themeFillStroke,
        fontSize: 1,
        width: 0,
      });
      topLegendRef.current.valueLabels.template.setAll({
        fill: chartTheme.themeFillStroke,
        fontSize: 14,
        forceHidden: isXSmall,
      });
      topLegendRef.current.markers.template.setAll({
        width: 10,
        height: 10,
        dy: -10,
      });
      topLegendRef.current.data.setAll([valueSeries]);

      // Add the volume legend (bottom legend).
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      bottomLegendRef.current = volumeAxis.axisHeader.children.push(
        am5.Legend.new(rootRef.current, {
          useDefaultMarker: true,
          layout: rootRef.current.verticalLayout,
          paddingBottom: 5,
        })
      );
      bottomLegendRef.current.markers.template.setAll({
        width: 0,
        height: 0,
      });
      bottomLegendRef.current.labels.template.setAll({
        fill: chartTheme.themeFillStroke,
        fontSize: 13,
        opacity: theme.palette.type === "dark" ? 1 : 0.8,
      });
      bottomLegendRef.current.valueLabels.template.setAll({
        fill: chartTheme.themeFillStroke,
        fontSize: 13,
        opacity: theme.palette.type === "dark" ? 1 : 0.8,
      });
      bottomLegendRef.current.data.setAll([volumeSeries]);

      // Stack axes vertically
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/#Stacked_axes
      chartRef.current.rightAxesContainer.set(
        "layout",
        rootRef.current.verticalLayout
      );

      // Add cursor cross hairs.
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
      chartRef.current.set("cursor", am5xy.XYCursor.new(rootRef.current, {}));
      let cursor = chartRef.current.get("cursor");
      cursor!.lineX.setAll({
        stroke: chartTheme.themeFillStroke,
        strokeOpacity: 0.3,
      });
      cursor!.lineY.setAll({
        stroke: chartTheme.themeFillStroke,
        strokeOpacity: 0.3,
      });

      volumeSeries.data.setAll(processedData);
      valueSeries.data.setAll(processedData);

      // Move to the most recent data on load.
      valueSeries.events.once("datavalidated", function (ev) {
        dateAxis.zoomToValues(
          new Date(data[data.length - 1].Timestamp).getTime(),
          new Date(
            data[data.length > 100 ? data.length - 100 : 0].Timestamp
          ).getTime()
        );
      });

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chartRef.current.appear(1000, 100);
    }
  });

  useEffect(() => {
    if (topLegendRef.current && bottomLegendRef.current) {
      topLegendRef.current.valueLabels.template.setAll({
        forceHidden: isXSmall,
      });
      topLegendRef.current.markers.template.setAll({
        forceHidden: isXSmall,
      });
      bottomLegendRef.current.valueLabels.template.setAll({
        forceHidden: isXSmall,
      });
    }
  }, [isXSmall]);

  return <div id="candlechartdiv" style={{ height: 550 }} />;
};

interface Props {
  data: any;
  interval: "day" | "hour";
}

export default PriceHistoryAMCandle;
