import { useMediaQuery, Theme } from "@material-ui/core";

/**
 * @summary Returns a span containing the appropriate string for the width of the display.
 *
 * React-admin labels (i.e. table headings) cannot be changed after render as they're stored in redux so this handles it for us.
 *
 * @param param0
 * @returns {JSX.Element}
 */
const ResponsiveLabel = ({
  mobile,
  desktop,
}: {
  mobile: string;
  desktop: string;
}) => {
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  return <span>{isXSmall ? mobile : desktop}</span>;
};

export default ResponsiveLabel;
