import {
  Create,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";
import { CreateProps } from "react-admin";

/**
 * @summary Renders the form used to add price sources to the database.
 *
 * @param props
 * @returns {JSX.Element}
 */
const PriceSourceCreate = (props: CreateProps) => {
  return (
    <Create title="Add a PriceSource" {...props} actions={<PostEditActions />}>
      <SimpleForm>
        <TextInput
          source="Name"
          label="The name of the price source."
          placeholder="CoinMarketCap"
        />
        <TextInput
          source="BaseUri"
          label="The base uri of the source API."
          placeholder="https://my-price-source/"
        />
        <ArrayInput source="Headers" label="Any HTTP Headers to use.">
          <SimpleFormIterator>
            <TextInput
              source="Name"
              label="Header name."
              placeholder="X-CMC_PRO_API_KEY"
            />
            <TextInput source="Value" label="Header value." />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

const PostEditActions = () => (
  <TopToolbar>
    <ListButton basePath={"/price_sources"} />
  </TopToolbar>
);

export default PriceSourceCreate;
