import { useEffect, useState, useRef, ChangeEvent, useCallback } from 'react'
import {
  SimpleForm,
  SelectInput,
  TextInput,
  TopToolbar,
  ListButton,
  useNotify,
  useMutation,
  useRedirect,
  useDataProvider,
  useAuthProvider,
  Create,
  Button,
  AutocompleteArrayInput,
  ReduxState,
} from 'react-admin'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CoinConfig } from '../types'
import Loading from '../layout/Loading'
import { useSelector } from 'react-redux'
import { handleProfileTracking, triggerWalletProcessing } from '../../components/wallets/methods'
import { IDataProvider } from '../../firebase/providers/DataProvider'
import walletStyles from '../../components/wallets/walletStyles'
import { environment } from '../../components/common'
import { Grid } from '@material-ui/core'
import { SelectWithAutoFill } from '../../components/form'
import {
  SelectNetworkName,
  SanitizedGrid,
  networkType,
  validateNewWallet,
} from '../../components/form'
import AddIcon from '@material-ui/icons/Add'
import { setCoinConfigState } from '../../components/common'
import { UserDoc } from '../types'

/**
 * @summary The primary component of the wallet create view
 *
 * TODO:  This will need to be updated for the new dataset and will most likely need to be custom component
 *
 * @param props
 * @returns {JSX.Element}
 */
const WalletCreate = () => {
  const [coinConfig, setCoinConfig] = useState<CoinConfigMap>()
  const [currencySelectors, setCurrencySelectors] = useState<{ id: string; name: JSX.Element }[]>()
  const [networkSelectors, setNetworkSelectors] =
    useState<{ id: string; value: string | Element }[]>()
  const [activeBlockchain, setActiveBlockchain] = useState<CoinConfig>()
  const [network, setNetwork] = useState<string>()
  const [token, setToken] = useState<string>()

  const notify = useNotify()
  const [mutate] = useMutation()
  const dispatch = useDispatch()
  const dataProvider: IDataProvider = useDataProvider()
  const authProvider = useAuthProvider()
  const redirectTo = useRedirect()
  const history = useHistory()

  const user: UserDoc = useSelector((state: any) => state.user)
  const cache: any = useSelector((state: any) => state.admin)
  const loading = useSelector<ReduxState>(state => state.admin.loading > 0)
  const classes = walletStyles()

  const activeBlockchainRef = useRef<CoinConfig | undefined>(activeBlockchain)

  const trackingRef = useRef(user.Tracking)
  const mountedRef = useRef(true)
  const isMounted = useCallback(() => mountedRef.current, [mountedRef.current])

  useEffect(() => {
    authProvider.getJWTToken().then((token: string) => {
      setToken(token)
    })
    setCoinConfigState(trackingRef.current, cache, dataProvider, setCoinConfig, isMounted)
  }, [])

  useEffect(() => {
    coinConfig &&
      Object.keys(coinConfig).length > 0 &&
      setCurrencySelectors(
        Object.keys(coinConfig).map((key: string) => {
          return {
            id: coinConfig[key].Symbol,
            name: (
              <SelectNetworkName
                name={coinConfig[key].Name}
                symbol={coinConfig[key].Symbol}
                key={`select-${coinConfig[key].Symbol}`}
              />
            ),
          }
        }),
      )
  }, [coinConfig])

  useEffect(() => {
    if (activeBlockchain && coinConfig) {
      activeBlockchainRef.current = activeBlockchain
      setNetworkSelectors(
        activeBlockchain.Networks.map((network: string) => {
          if (network === 'MANUAL') return { id: network, value: 'Manual' }
          if (!coinConfig[network]) return {}
          return {
            id: network,
            value: networkType(
              activeBlockchain.Symbol,
              coinConfig[network].Symbol,
              coinConfig[network].Name,
            ),
          }
        }),
      )
    }
  }, [activeBlockchain, coinConfig])

  useEffect(() => {
    if (activeBlockchain && user.Name) {
      const inputElement = document.getElementById('Alias') as HTMLInputElement
      inputElement.setAttribute('value', `${user.Name.split(' ')[0]}'s ${activeBlockchain.Name}`)
      inputElement.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }, [activeBlockchain, user.Name])

  const transform = (data: any) =>
    handleProfileTracking(user, data, activeBlockchainRef, dataProvider, mutate, dispatch)
      .then(({ data, activeBlockchainRef }) => ({
        id: data.Address,
        Address: data.Address,
        Name: activeBlockchainRef.current && activeBlockchainRef.current.Name,
        Alias: data.Alias,
        Symbol: activeBlockchainRef.current && activeBlockchainRef.current.Symbol,
        Network: data.Network,
        Tags: data.Tags ? data.Tags : [],
        Balance: 0,
        Rewards: 0,
        Staked: 0,
        TransactionIds: [],
        Status: 'NEW',
      }))
      .catch(err => {
        notify('pos.wallets.error', { type: 'warning' })

        throw new Error(err.message) // Stop the parent writing an empty object
      })

  const handleBlockchainSelect = (evt: ChangeEvent<HTMLInputElement>) => {
    evt.target.value && coinConfig && setActiveBlockchain(coinConfig[evt.target.value])
  }

  const handleNetworkSelect = (evt: ChangeEvent<HTMLInputElement>) => {
    evt.target.value && setNetwork(evt.target.value)
  }

  // Log error if loaded without having all the data to display the page.
  if (!loading && (!coinConfig || Object.keys(coinConfig).length === 0 || !token)) {
    console.error(
      !coinConfig || Object.keys(coinConfig).length === 0
        ? 'No coin snapshot data!'
        : 'No Firebase JWT token!',
    )
  }

  if (!coinConfig || Object.keys(coinConfig).length === 0 || !token) return <Loading />

  const Topbar = () => (
    <TopToolbar>
      <Button onClick={() => redirectTo('add-untracked/create')} label='Unsupported Token'>
        <AddIcon />
      </Button>
      <ListButton to='wallets' />
    </TopToolbar>
  )

  return (
    <>
      <Topbar />
      <Create
        basePath={`/${environment('profiles')}/${user.ActiveProfile}/data/${
          activeBlockchain?.Symbol
        }/${network}`}
        resource={`${environment('profiles')}/${user.ActiveProfile}/data/${
          activeBlockchain?.Symbol
        }/${network}`}
        transform={transform}
        onSuccess={record => {
          triggerWalletProcessing(user, record.data)
          notify('pos.wallets.created', { type: 'success' })
          redirectTo('wallets')
        }}
        classes={{ main: classes.formWithCustomToolbar }}
      >
        <SimpleForm validate={validateNewWallet} mutationMode='pessimistic'>
          <SanitizedGrid container spacing={2} className={classes.formContainer}>
            <Grid item xs={12} sm={6} className={classes.formRow}>
              <SelectInput
                source='Symbol'
                label='Currency'
                choices={currencySelectors}
                className={classes.inputField}
                onChange={(evt: ChangeEvent<HTMLInputElement>) => handleBlockchainSelect(evt)}
                translateChoice={false}
                margin='normal'
                classes={{ input: classes.input }}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.formRow}>
              <SelectWithAutoFill
                source='Network'
                className={classes.inputField}
                choices={networkSelectors}
                value={network}
                isRequired={true}
                id='network-select'
                callback={handleNetworkSelect}
              />
            </Grid>
            <Grid item xs={12} className={classes.formRow}>
              <TextInput source='Alias' className={classes.inputField} margin='normal' />
            </Grid>
            <Grid item xs={12} className={classes.formRow}>
              <TextInput source='Address' className={classes.inputField} margin='normal' />
              {user.Tags && user.Tags.length > 0 ? (
                <>
                  <AutocompleteArrayInput
                    source='Tags'
                    choices={
                      user.Tags
                        ? user.Tags.map((tag: string, i: number) => ({
                            id: tag,
                            name: tag,
                          }))
                        : []
                    }
                  />
                  <Button
                    color='primary'
                    onClick={() => history.push(`/${environment('profiles')}/edit`)}
                    label='Manage Tags'
                  ></Button>
                </>
              ) : (
                <>
                  <span>
                    You don't have any strategy tags yet.
                    <Button
                      color='primary'
                      onClick={() => history.push(`/${environment('profiles')}/edit`)}
                      style={{ marginLeft: 15 }}
                      label='Create tags'
                      size='small'
                    />
                  </span>
                </>
              )}
            </Grid>
          </SanitizedGrid>
        </SimpleForm>
      </Create>
    </>
  )
}

interface CoinConfigMap {
  [key: string]: CoinConfig
}

export default WalletCreate
