import { useEffect, useState } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  BooleanInput,
  SelectInput,
  ReferenceInput,
  CreateProps,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  useAuthProvider,
} from "react-admin";
import { triggerSymbolBackfill } from "../../../components/admin/methods";
import { environment } from "../../../components/common";
import Loading from "../../layout/Loading";

/**
 * @summary Renders the form used to add token symbols to the backend.
 *
 * @param props
 * @returns {JSX.Element}
 */
const SymbolCreate = (props: CreateProps) => {
  const [token, setToken] = useState<string>();
  const authProvider = useAuthProvider();
  
  useEffect(() => {
    authProvider.getJWTToken().then((token: string) => {
      setToken(token);
    });
  }, []);

  if (!token)
    return <Loading />;
  
  return (
    <Create
      title="Add a Symbol"
      {...props}
      actions={<PostEditActions />}
      transform={(data) => ({ ...data, id: data.Symbol })}
      onSuccess={(record) => {
        triggerSymbolBackfill(record.data.Symbol, token);
      }}
    >
      <SimpleForm>
        <TextInput
          source="Name"
          label="The name of the project/token."
          placeholder="Bitcoin"
        />
        <TextInput
          source="Symbol"
          label="The ticker of the symbol."
          placeholder="BTC"
        />
        <TextInput
          source="Description"
          label="The description of the symbol."
          placeholder="Bitcoin"
        />
        <TextInput
          source="Id"
          label="The price source internal id of the symbol"
          placeholder="3344"
        />
        <BooleanInput
          source="Manual"
          label="Whether the symbol is manually marked or not."
          placeholder="false"
        />
        <ArrayInput
          source="Networks"
          label="Any networks in which the token exists."
        >
          <SimpleFormIterator>
            <TextInput
              source=""
              label="The name of the network."
              placeholder="ETH"
            />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput
          source="TokenContracts"
          label="Any token contracts associated with this symbol configuration."
        >
          <SimpleFormIterator>
            <TextInput
              source="Name"
              label="The name of the token contract."
              placeholder="My New Token"
            />
            <TextInput
              source="Network"
              label="The name of the network."
              placeholder="ETH"
            />
            <TextInput
              source="Contract"
              label="The address of the token contract."
              placeholder="0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9"
            />
            <NumberInput
              source="Decimals"
              label="The decimals of the token contract."
              placeholder="18"
            />
          </SimpleFormIterator>
        </ArrayInput>
        <ReferenceInput
          label="Source"
          source="Source"
          reference="price_sources"
        >
          <SelectInput optionText="Name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

const PostEditActions = () => (
  <TopToolbar>
    <ListButton basePath={`/${environment("coin-config")}`} />
  </TopToolbar>
);

export default SymbolCreate;
