import * as React from "react";
import { Children, cloneElement, isValidElement, useState } from "react";
import PropTypes from "prop-types";
import { useTranslate, useGetIdentity } from "ra-core";
import { useSelector } from "react-redux";
import {
  Tooltip,
  IconButton,
  Menu,
  Button,
  Avatar,
  PopoverOrigin,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { UserDoc } from "../../views/types";

import { ClassesOverride } from "react-admin";

const defaultIcon = <AccountCircle />;

const AnchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "right",
};

const TransformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};

const UserMenu = (props: UserMenuProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const translate = useTranslate();
  const { loaded, identity } = useGetIdentity();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("xs"));
  const classes = useStyles(props);
  const user: UserDoc = useSelector((state: any) => state.user);

  const {
    children,
    label = "ra.auth.user_menu",
    icon = defaultIcon,
    logout,
  } = props;

  if (!logout && !children) return null;
  const open = Boolean(anchorEl);

  const handleMenu = (event: any) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const displayName =
    user.id !== user.ActiveProfile
      ? user.Organizations.filter((o) => o.ProfileId === user.ActiveProfile)[0]
          .Name
      : user.Name;

  return (
    <div className={classes.user}>
      {loaded && identity?.fullName ? (
        <Button
          aria-label={label && translate(label, { _: label })}
          className={classes.userButton}
          color="inherit"
          startIcon={
            identity.avatar ? (
              user.ActiveProfile === user.id ? (
                <Avatar
                  className={classes.avatar}
                  src={identity.avatar}
                  style={{ pointerEvents: "none" }} // Fix IOS click issue
                  alt={identity.fullName}
                />
              ) : (
                <Avatar
                  className={classes.avatar}
                  src="/images/republic-icon.svg"
                  style={{ pointerEvents: "none" }} // Fix IOS click issue
                  alt={identity.fullName}
                />
              )
            ) : (
              icon
            )
          }
          onClick={handleMenu}
        >
          {!isSmall && displayName}
        </Button>
      ) : (
        <Tooltip title={label && translate(label, { _: label })}>
          <IconButton
            aria-label={label && translate(label, { _: label })}
            aria-owns={open ? "menu-appbar" : ""}
            aria-haspopup={true}
            color="inherit"
            onClick={handleMenu}
          >
            {icon}
          </IconButton>
        </Tooltip>
      )}
      <Menu
        id="menu-appbar"
        disableScrollLock
        anchorEl={anchorEl}
        anchorOrigin={AnchorOrigin}
        transformOrigin={TransformOrigin}
        getContentAnchorEl={null}
        open={open}
        onClose={handleClose}
      >
        {Children.map(children, (menuItem) =>
          isValidElement(menuItem)
            ? cloneElement<any>(menuItem, {
                onClick: handleClose,
              })
            : null
        )}
        {logout}
      </Menu>
    </div>
  );
};

const useStyles = makeStyles(
  (theme) => ({
    user: {
      [theme.breakpoints.down("xs")]: {
        width: 42,
        paddingRight: 0,
      },
    },
    userButton: {
      textTransform: "none",
      fontSize: "1rem",
      [theme.breakpoints.down("xs")]: {
        width: 42,
        paddingRight: 0,
      },
    },
    avatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  }),
  { name: "RaUserMenu" }
);

UserMenu.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  label: PropTypes.string,
  logout: PropTypes.element,
  icon: PropTypes.node,
};

export interface UserMenuProps {
  children?: React.ReactNode;
  classes?: ClassesOverride<typeof useStyles>;
  label?: string;
  logout?: React.ReactNode;
  icon?: React.ReactNode;
}

export default UserMenu;
