import { Box, Typography } from "@material-ui/core";
import { tableStyles } from ".";

/**
 * @summary Returns the MUI 5 style table header bar.
 *
 * @param title
 * @returns {JSX.Element}
 */
const TableHeader = ({ title, icon }: { title: string; icon?: string }) => {
  const classes = tableStyles();
  return (
    <Box className={classes.tableHeaderContainer}>
      <Typography
        variant="body1"
        align="center"
        className={classes.tableHeaderTitle}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {icon && (
          <img src={icon} style={{ height: 30, paddingRight: 10 }} alt={" "} />
        )}
        {title}
      </Typography>
    </Box>
  );
};

export default TableHeader;
