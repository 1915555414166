import { Theme } from "@material-ui/core";
import * as am5 from "@amcharts/amcharts5";

/**
 * @summary Returns the SMA graph points for 7, 25 and 99 periods along with their theme color.
 *
 * @param data
 * @param theme
 * @returns {Object}
 */
export const calculateSMA = (data: any, theme: Theme) => {
  const sma: {
    [key: string]: {
      periods: number;
      points: any[];
      values: number[];
      color: string;
    };
  } = {
    seven: {
      periods: 7,
      points: [],
      values: [],
      color: theme.palette.type === "dark" ? "#C96EDA" : "#7836EF",
    },
    twentyFive: {
      periods: 25,
      points: [],
      values: [],
      color: theme.palette.type === "dark" ? "#0ADAC7" : "#07D1B9",
    },
    ninetyNine: {
      periods: 99,
      points: [],
      values: [],
      color: theme.palette.type === "dark" ? "#FBE739" : "#BF8900",
    },
  };

  for (let i = 0; i < data.length; i++) {
    Object.keys(sma).forEach((key) => {
      sma[key].values.push(data[i].Close);
      if (sma[key].values.length === sma[key].periods) {
        const total = [...sma[key].values].reduce((a, b) => a + b, 0);
        const average = total / sma[key].periods;
        sma[key].points.push({
          value: average,
          timestamp: new Date(data[i].Timestamp).getTime(),
        });
        sma[key].values.shift();
      }
    });
  }

  return sma;
};

/**
 * @summary Returns an object of styles for use in amcharts for easier cohesive theming.
 *
 * @param theme
 * @returns {Object}
 */
export const getChartTheme = (theme: Theme) => {
  const themeData = {
    fontStyle: {
      fill: am5.color(theme.palette.type === "dark" ? "#fff" : "#333"),
      fontFamily: "IBM Plex Sans",
    },
    positive:
      theme.palette.type === "dark"
        ? am5.color(theme.palette.success.dark)
        : am5.color(theme.palette.success.main),
    negative:
      theme.palette.type === "dark"
        ? am5.color(theme.palette.error.dark)
        : am5.color(theme.palette.error.main),
    positiveWashed:
      theme.palette.type === "dark"
        ? am5.color("#254A2D")
        : am5.color(theme.palette.success.main),
    negativeWashed:
      theme.palette.type === "dark"
        ? am5.color("#622529")
        : am5.color(theme.palette.error.main),
    axisLabelOpacity: theme.palette.type === "dark" ? 0.8 : 0.6,
    themeFillStroke: am5.color(theme.palette.type === "dark" ? "#fff" : "#333"),
  };

  return themeData;
};

/**
 * @summary Returns the default data for use in the candle chart legend when the cursor is outside the viewport.
 *
 * @param data
 * @param sma
 * @param theme
 * @returns
 */
export const getDefaultCandleData = (data: any, sma: any, theme: Theme) => {
  const formatNumberUS = (value: number, decimals: number) =>
    new Intl.NumberFormat("en-US", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(Number(value));

  const change =
    (data[data.length - 1].Close / data[data.length - 1].Open - 1) * 100;

  const decimals =
    data[data.length - 1].Close < 0.0009999
      ? "00000"
      : data[data.length - 1].Close < 0.00999999
      ? "0000"
      : data[data.length - 1].Close < 0.099999
      ? "000"
      : "00";

  const lastPriceData = {
    open: formatNumberUS(data[data.length - 1].Open, decimals.length),
    close: formatNumberUS(data[data.length - 1].Close, decimals.length),
    high: formatNumberUS(data[data.length - 1].High, decimals.length),
    low: formatNumberUS(data[data.length - 1].Low, decimals.length),
    volume: Intl.NumberFormat("en", { notation: "compact" }).format(
      data[data.length - 1].Volume
    ),
    smaSeven: sma.seven.points[sma.seven.points.length - 1]
      ? formatNumberUS(
          sma.seven.points[sma.seven.points.length - 1].value,
          decimals.length
        )
      : NaN,
    smaTwentyFive: sma.twentyFive.points[sma.twentyFive.points.length - 1]
      ? formatNumberUS(
          sma.twentyFive.points[sma.twentyFive.points.length - 1].value,
          decimals.length
        )
      : NaN,
    smaNinetyNine: sma.ninetyNine.points[sma.ninetyNine.points.length - 1]
      ? formatNumberUS(
          sma.ninetyNine.points[sma.ninetyNine.points.length - 1].value,
          decimals.length
        )
      : NaN,
    percentChange: `${change > 0 ? "+" : ""}${change.toFixed(2)}`,
    color:
      change < 0
        ? theme.palette.type === "dark"
          ? am5.color(theme.palette.error.dark)
          : am5.color(theme.palette.error.main)
        : theme.palette.type === "dark"
        ? am5.color(theme.palette.success.dark)
        : am5.color(theme.palette.success.main),
    textColor:
      change < 0
        ? theme.palette.type === "dark"
          ? am5.color(theme.palette.error.dark)
          : am5.color(theme.palette.error.main)
        : theme.palette.type === "dark"
        ? am5.color(theme.palette.success.dark)
        : am5.color(theme.palette.success.main),
    decimals,
  };

  return lastPriceData;
};

/**
 * @summary Returns the candle stick data points in the format required by the graph.
 *
 * @param data
 * @param sma
 * @returns {Array}
 */
export const getCandleData = (data: any, sma: any) => {
  return data.map((d: any, i: number) => {
    return {
      ...d,
      PercentChange: (d.Close / d.Open - 1) * 100,
      Timestamp: new Date(d.Timestamp).getTime(),
      smaSeven: i > 6 ? sma.seven.points[i - 6].value : "N/A",
      smaTwentyFive: i > 24 ? sma.twentyFive.points[i - 24].value : "N/A",
      smaNinetyNine: i > 98 ? sma.ninetyNine.points[i - 98].value : "N/A",
    };
  });
};
