import { useRef, useEffect, useState, useCallback } from 'react'
import {
  SimpleForm,
  TextInput,
  ArrayInput,
  DateInput,
  Toolbar,
  ToolbarProps,
  SaveButton,
  Button,
  useNotify,
  useRedirect,
  Edit,
  CreateProps,
  useMutation,
  useDataProvider,
  SimpleFormIterator,
  AutocompleteArrayInput,
  NumberInput,
} from 'react-admin'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import walletStyles from '../../components/wallets/walletStyles'
import { environment } from '../../components/common'
import { Grid } from '@material-ui/core'
import { SanitizedGrid } from '../../components/form'
import { CoinConfig, UserDoc } from '../types'
import { updateUser } from '../../persistence/actions'
import { setCoinConfigState } from '../../components/common'
import { IDataProvider } from '../../firebase/providers/DataProvider'
import { validateManualWallet } from '../../components/wallets/methods'
import DeleteIcon from '@material-ui/icons/Delete'
import { sortArray } from '../../firebase/misc'

interface Props extends CreateProps {
  symbol: string
}

/**
 * @summary Renders the form used to add a shared profile by profile id.
 *
 * @param props
 * @returns {JSX.Element}
 */
const ManualWalletEdit = ({ symbol, ...props }: Props) => {
  const [coinConfig, setCoinConfig] = useState<{ [key: string]: CoinConfig }>()
  const [loading, setLoading] = useState<boolean>(false)
  const notify = useNotify()
  const dispatch = useDispatch()
  const redirectTo = useRedirect()
  const user = useSelector((state: any) => state.user)
  const cache: any = useSelector((state: any) => state.admin)
  const classes = walletStyles()
  const dataProvider: IDataProvider = useDataProvider()
  const [mutate] = useMutation()
  const history = useHistory()

  const walletRef = useRef(user.Untracked.filter((wallet: any) => wallet.Symbol === symbol)[0])
  sortArray(walletRef.current.PriceHistory, 'Timestamp', 'desc')

  const mountedRef = useRef(true)
  const isMounted = useCallback(() => mountedRef.current, [mountedRef.current])

  useEffect(() => {
    setCoinConfigState(user.Tracking, cache, dataProvider, setCoinConfig, isMounted)
  }, [])

  const save = useCallback(
    values => {
      const errors: any = {}
      const { TokenName, TokenSymbol, PriceHistory, WalletAlias, WalletTags } = values
      const notValid = validateManualWallet(TokenName, TokenSymbol, PriceHistory, WalletAlias)
      if (notValid) return notValid

      const Symbol = TokenSymbol.toUpperCase()

      // If the user changed the symbol make sure it is not a duplicate.
      user.Untracked &&
        user.Untracked.forEach((token: any) => {
          if (token.Symbol === Symbol)
            errors.TokenSymbol = 'This token symbol already exists in the portfolio.'
        })
      if (errors.TokenSymbol) return errors

      // Validation passed!
      const wallet = {
        Alias: WalletAlias,
        Name: TokenName,
        Symbol: Symbol,
        PriceHistory: PriceHistory,
        Tags: WalletTags ? WalletTags : [],
      }
      const updatedUntracked = [...user.Untracked]
      updatedUntracked.push(wallet)

      // Fetch profile first incase anyone else added another token since last check.
      return dataProvider
        .getOne(environment('profiles'), {
          id: user.ActiveProfile,
        })
        .then(async ({ data }) => {
          const updatedUntracked = [...data.Untracked]
          updatedUntracked.push(wallet)
          try {
            await mutate(
              {
                type: 'update',
                resource: environment('profiles'),
                payload: {
                  id: user.ActiveProfile,
                  data: {
                    Untracked: updatedUntracked,
                  },
                },
              },
              {
                onSuccess: () => {
                  const updatedProfile: UserDoc = {
                    ...user,
                    Untracked: updatedUntracked,
                  }
                  dispatch(updateUser(updatedProfile))
                  notify('pos.wallets.updated', { type: 'success' })
                  redirectTo('/wallets')
                },
                returnPromise: true,
              },
            )
          } catch (err: any) {
            console.log(err)
            notify('pos.app.save_failed', { type: 'error' })
            return errors
          }
        })
        .catch(err => {
          console.log(err)
          notify('pos.profile.check_failed', { type: 'error' })
          return errors
        })
    },
    [user, coinConfig, mutate, dataProvider],
  )

  const deleteWallet = useCallback(async () => {
    setLoading(true)
    return dataProvider
      .getOne(environment('profiles'), {
        id: user.ActiveProfile,
      })
      .then(async ({ data }) => {
        const updatedUntracked = [...data.Untracked].filter(
          wallet => wallet.id !== walletRef.current.id,
        )
        try {
          await mutate(
            {
              type: 'update',
              resource: environment('profiles'),
              payload: {
                id: user.ActiveProfile,
                data: {
                  Untracked: updatedUntracked,
                },
              },
            },
            {
              onSuccess: () => {
                const updatedProfile: UserDoc = {
                  ...user,
                  Untracked: updatedUntracked,
                }
                dispatch(updateUser(updatedProfile))
                notify('pos.wallets.deleted', {
                  type: 'error',
                })
                redirectTo('/wallets')
              },
              returnPromise: true,
            },
          )
        } catch (err: any) {
          setLoading(false)
          console.log(err)
          notify('pos.app.save_failed', { type: 'error' })
        }
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
        notify('pos.profile.check_failed', { type: 'error' })
      })
  }, [user, coinConfig, mutate, dataProvider])

  const SaveBar = (props: ToolbarProps) => (
    <Toolbar {...props} classes={{ toolbar: classes.manualWalletEditToolbar }}>
      <SaveButton disabled={props.pristine} />
      <Button label='Delete' onClick={() => deleteWallet()} disabled={loading}>
        <DeleteIcon />
      </Button>
    </Toolbar>
  )

  return (
    <div style={{ marginTop: 16 }}>
      <Edit
        {...props}
        basePath={`/${environment('profiles')}`}
        resource={`${environment('profiles')}`}
        id={user.id}
        mutationMode='pessimistic'
      >
        <SimpleForm mutationMode='pessimistic' toolbar={<SaveBar />} save={save}>
          <SanitizedGrid container spacing={2} className={classes.formContainer}>
            <Grid item xs={12} className={classes.formRow}>
              <TextInput
                source='WalletAlias'
                className={classes.inputField}
                margin='normal'
                label='Wallet Alias'
                defaultValue={walletRef.current.Alias}
              />
              <TextInput
                source='TokenName'
                className={classes.inputField}
                margin='normal'
                label='Currency Name'
                defaultValue={walletRef.current.Name}
              />
              <TextInput
                source='TokenSymbol'
                className={`${classes.inputField} ${classes.uppercase}`}
                margin='normal'
                label='Symbol'
                defaultValue={walletRef.current.Symbol}
              />
              {user.Tags && user.Tags.length > 0 ? (
                <>
                  <AutocompleteArrayInput
                    source='WalletTags'
                    defaultValue={walletRef.current.Tags}
                    choices={
                      user.Tags
                        ? user.Tags.map((tag: string, i: number) => ({
                            id: tag,
                            name: tag,
                          }))
                        : []
                    }
                  />
                  <Button
                    color='primary'
                    onClick={() => history.push(`/${environment('profiles')}/edit`)}
                    label='Manage Tags'
                  ></Button>
                </>
              ) : (
                <>
                  <span>
                    You don't have any strategy tags yet.
                    <Button
                      color='primary'
                      onClick={() => history.push(`/${environment('profiles')}/edit`)}
                      style={{ marginLeft: 15 }}
                      label='Create tags'
                      size='small'
                    />
                  </span>
                </>
              )}
              <ArrayInput
                source='PriceHistory'
                label='Wallet History'
                defaultValue={walletRef.current.PriceHistory}
              >
                <SimpleFormIterator>
                  <NumberInput source='Balance' label='Balance' />
                  <NumberInput source='Quote' label='Price' />
                  <NumberInput source='TotalSupply' label='TotalSupply' />
                  <NumberInput source='CirculatingSupply' label='CirculatingSupply' />
                  <NumberInput source='MaxSupply' label='MaxSupply' />
                  <DateInput
                    source='Timestamp'
                    label='From Date'
                    options={{
                      inputProps: {
                        max: new Date().toISOString().substring(0, 10),
                      },
                    }}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
          </SanitizedGrid>
        </SimpleForm>
      </Edit>
    </div>
  )
}

export default ManualWalletEdit
