import { formStyles } from ".";

/**
 * @summary Return a span containing the network icon and name for display as selection options.
 *
 * @returns {JSX.Element}
 */
const SelectNetworkName = ({
  name,
  symbol,
}: {
  name: string;
  symbol: string;
}) => {
  const classes = formStyles();
  return (
    <div className={classes.inlineSelectText}>
      <div className={classes.selectIcon}>
        <img
          src={`/images/${symbol ? symbol.toLowerCase() : ""}.svg`}
          alt={""}
        />
      </div>

      <div className={classes.selectNetworkNameContainer}>
        <span>{name}</span>
      </div>
    </div>
  );
};

export default SelectNetworkName;
