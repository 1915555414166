import { useState, useEffect } from "react";
import { ListProps, useNotify } from "react-admin";
import { toArray } from "lodash";
import { ValidatorCard } from "../../components/validator";
import { Grid } from "@material-ui/core";
import { tableStyles } from "../../components/table";
import { useGetList } from "react-admin";
import { Validator } from "../types";
import Loading from "../layout/Loading";
import { environment } from "../../components/common";

/**
 * @summary The primary component for the validator list view
 *
 * TODO: Handle error state.
 *
 * @param props
 * @returns {JSX.Element}
 */
const ValidatorCardList = (props: ListProps) => {
  const classes = tableStyles();
  const [validators, setValidators] = useState<Validator[]>();

  const notify = useNotify();

  const { data: validatorsObj, loading: validatorsLoading } =
    useGetList<Validator>(
      `${environment("validators")}`,
      { page: 1, perPage: 1000 },
      { field: "Name", order: "DESC" }
    );

  useEffect(() => {
    //@ts-ignore
    if (!window.keplr) {
      notify("Keplr wallet not found", { type: "info" });
    } else {
      notify("Keplr wallet connected", { type: "success" });
    }
  }, []);

  useEffect(() => {
    Object.keys(validatorsObj).length > 0 &&
      setValidators(toArray(validatorsObj));
  }, [validatorsObj]);

  if (validatorsLoading) return <Loading />;
  return (
    <div className={classes.validatorHeaderSpacing}>
      <Grid container spacing={2}>
        {validators &&
          validators.map((validator: Validator) => (
            <ValidatorCard validator={validator} key={validator.id} />
          ))}
      </Grid>
    </div>
  );
};

export default ValidatorCardList;
