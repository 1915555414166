import { Grid } from "@material-ui/core";

/**
 * @summary Returns a material ui grid container for use in react-admin forms.
 *
 * @param props
 * @returns {JSX.Element}
 */
const SanitizedGrid = ({ basePath, ...props }: any) => {
  return <Grid {...props} />;
};

export default SanitizedGrid;
