import { useEffect, useState } from "react";
import {
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  useAuthProvider,
  Create,
  CreateProps,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import { triggerValidatorProcessing } from "../../components/validator/methods";
import { environment } from "../../components/common";
import Loading from "../layout/Loading";

/**
 * @summary A form for editing adding validators to the validator list view.
 *
 * @param props
 * @returns {JSX.Element}
 */
const ValidatorCreate = (props: CreateProps) => {
  const redirectTo = useRedirect();
  const [token, setToken] = useState<string>();
  const authProvider = useAuthProvider();
  const notify = useNotify();

  useEffect(() => {
    authProvider.getJWTToken().then((token: string) => {
      setToken(token);
    });
  }, []);

  if (!token) return <Loading />;

  return (
    <Create
      {...props}
      title="Add a Validator"
      basePath={`/${environment("validators")}`}
      resource={`/${environment("validators")}`}
      onSuccess={(record) => {
        redirectTo("validators");
        notify("pos.validators.created", { type: "success" });
        triggerValidatorProcessing(record.data, token);
      }}
    >
      <SimpleForm>
        <TextInput
          source="Name"
          label="The name of the validator."
          placeholder="Republic AVAX-1"
        />
        <TextInput
          source="Address"
          label="The id/address of the validator."
          placeholder="abcd1234efgh5678"
        />
        <ReferenceInput
          label="Symbol"
          source="Symbol"
          reference={`${environment("coin-config")}`}
          sort={{ field: "id", order: "ASC" }}
          filterToQuery={(searchText) => ({ title: searchText })}
          perPage={1000}
        >
          <AutocompleteInput optionText="Symbol" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default ValidatorCreate;
