import { StakingConfig } from "./chainConfig";

const keplrInit = async (updateAccounts: any, chain: StakingConfig) => {
  // Keplr extension injects the offline signer that is compatible with cosmJS.
  // You can get this offline signer from `window.getOfflineSigner(chainId:string)` after load event.
  // And it also injects the helper function to `window.keplr`.
  // If `window.getOfflineSigner` or `window.keplr` is null, Keplr extension may be not installed on browser.

  //@ts-ignore
  if (!window.getOfflineSigner || !window.keplr) {
    alert("Please install keplr extension");
    return;
  }

  const chainId = chain.chainId;

  // You should request Keplr to enable the wallet.
  // This method will ask the user whether or not to allow access if they haven't visited this website.
  // Also, it will request user to unlock the wallet if the wallet is locked.
  // If you don't request enabling before usage, there is no guarantee that other methods will work.

  //@ts-ignore
  await window.keplr.enable(chainId);

  //@ts-ignore
  const offlineSigner = window.getOfflineSigner(chainId);

  // You can get the address/public keys by `getAccounts` method.
  // It can return the array of address/public key.
  // But, currently, Keplr extension manages only one address/public key pair.
  // XXX: This line is needed to set the sender address for SigningCosmosClient.
  const accounts = await offlineSigner.getAccounts();
  updateAccounts(accounts);
};

export default keplrInit;
