import { useState, useEffect, useRef, useCallback } from 'react'
import { useMediaQuery, useTheme, Theme } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useDataProvider } from 'react-admin'
import {
  calculateEquityCurves,
  calculateTotalValueAndDistribution,
} from '../../components/portfolio/methods'
import {
  Wallet,
  CoinConfig,
  PriceHistory,
  UserDoc,
  BalanceRecord,
  EquityCurves,
} from '../../views/types'
import Loading from '../../views/layout/Loading'
import {
  setBalanceHistoryState,
  setCoinConfigState,
  setPriceHistoryState,
} from '../../components/common'
import { DataGridPro, GridColDef, GridRowData } from '@mui/x-data-grid-pro'
import { PortfolioSummaryListTopbar } from '.'
import { generateRows, combineAssetRows } from './portfolioSummaryRowGenerator'
import GenerateColumns from './PortfolioSummaryColumnGenerator'
import { IDataProvider } from '../../firebase/providers/DataProvider'
import { Paper, Box } from '@material-ui/core'
import { sortArray } from '../../firebase/misc'
import { tagMap } from '../../Resources'
import { makeStyles } from '@material-ui/styles'

/**
 * @summary The Big Bertha of the portfolio view.
 *
 * @returns {JSX.Element}
 */
const PortfolioPositionSummaryUI = ({ profile }: any) => {
  // Data from cache/API.
  const [coinConfig, setCoinConfig] = useState<{ [key: string]: CoinConfig }>()
  const [wallets, setWallets] = useState<Wallet[]>()
  const [balanceHistory, setBalanceHistory] = useState<{ [key: string]: BalanceRecord[] }>()
  const [priceHistory, setPriceHistory] = useState<{ [key: string]: PriceHistory[] }>()

  // Calculated values.
  const [totalPortfolioValue, setTotalPortfolioValue] = useState<number>(0)
  const [equityCurves, setEquityCurves] = useState<EquityCurves>()
  const [data, setData] = useState<GridRowData[]>()
  const [assetData, setAssetData] = useState<GridRowData[]>()

  // Data grid settings.
  const [loading, setLoading] = useState<boolean>(true)
  const [columns, setColumns] = useState<GridColDef[]>()
  const [dataView, setDataView] = useState<'summary' | 'risk' | 'all' | 'custom'>('summary')
  const [viewChanged, setViewChanged] = useState<boolean>(false)
  const [density, setDensity] = useState<'compact' | 'standard' | 'comfortable'>('compact')
  // const [endDate, setEndDate] = useState<Date>(new Date());
  // const [startDate, setStartDate] = useState<Date>(
  //   new Date(new Date().setMonth(new Date().getMonth() - 1))
  // );
  const endDate = new Date()
  const startDate = new Date(new Date().setMonth(new Date().getMonth() - 1))

  // Refs to avoid rerenders.
  const walletRef = useRef(profile.Wallets)
  const trackingRef = useRef(profile.Tracking)
  const mountedRef = useRef(true)
  const isMounted = useCallback(() => mountedRef.current, [mountedRef.current])

  // Global state data
  const user: UserDoc = useSelector((state: any) => state.user)
  const cache: any = useSelector((state: any) => state.admin)

  // Style data.
  const theme = useTheme()
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const classes = useStyles()

  const dataProvider: IDataProvider = useDataProvider()

  /**
   * @summary Initiate fetch from cache/api on first mount.
   */
  const getList = useCallback(
    (resource: string, params: { [kay: string]: any }) => {
      return new Promise(async resolve => {
        const { data }: any = await dataProvider.getList(resource, {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'id', order: 'DESC' },
          filter: params,
        })

        // Cancel request if the user changed page before it complete.
        if (!mountedRef.current) return null
        resolve(data)
      })
    },
    [dataProvider]
  )

  const fetchWalletData = useCallback(async () => {
    const data: any = await getList('wallets', {
      profileId: user.ActiveProfile,
    })
    const walletData = data

    walletData.forEach((wallet: Wallet) => {
      const tags = wallet.Tags ? [...wallet.Tags] : []
      if (coinConfig![wallet.Symbol] && coinConfig![wallet.Symbol].Tags) {
        coinConfig![wallet.Symbol].Tags.forEach((tag: string) => {
          if (tagMap[tag]) {
            tags.push(tagMap[tag].name)
          }
        })
      }
      wallet.Tags = tags
    })

    if (user.Untracked && user.Untracked.length > 0) {
      user.Untracked.forEach((wallet: any) => {
        const walletHistory = [...wallet.PriceHistory].map(wallet => {
          return {
            ...wallet,
            Timestamp: new Date(wallet.Timestamp),
            id: new Date(wallet.Timestamp).toISOString().substring(0, 10),
          }
        })

        sortArray(walletHistory, 'Timestamp', 'asc')
        walletData.push({
          id: wallet.Symbol,
          Name: wallet.Name,
          Alias: wallet.Alias,
          Symbol: wallet.Symbol,
          Tags: wallet.Tags,
          Balance: walletHistory[walletHistory.length - 1].Balance,
          Staked: 0,
          Rewards: 0,
          Value:
            walletHistory[walletHistory.length - 1].Balance *
            walletHistory[walletHistory.length - 1].Quote,
          PriceHistory: walletHistory,
          Manual: true,
        })
      })
    }

    setWallets(walletData)
  }, [coinConfig])

  useEffect(() => {
    setColumns(GenerateColumns(isXSmall, density, dataView, theme))
    setCoinConfigState(trackingRef.current, cache, dataProvider, setCoinConfig, isMounted)
    setPriceHistoryState(
      trackingRef.current,
      startDate,
      endDate,
      cache,
      dataProvider,
      setPriceHistory,
      isMounted
    )
    setBalanceHistoryState(
      user.ActiveProfile,
      startDate,
      endDate,
      walletRef.current,
      cache,
      dataProvider,
      setBalanceHistory,
      isMounted
    )
    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    if (coinConfig && Object.keys(coinConfig).length > 0) fetchWalletData()
  }, [coinConfig])

  /**
   * @summary Calculate the total portfolio value and distribution.
   */
  useEffect(() => {
    if (wallets && coinConfig) {
      const { totalValue } = calculateTotalValueAndDistribution(wallets, coinConfig)
      setTotalPortfolioValue(totalValue)
    }
  }, [coinConfig, wallets])

  /**
   * @summary Set the equity curves for each asset.
   */
  useEffect(() => {
    if (coinConfig && wallets && priceHistory && balanceHistory) {
      setEquityCurves(calculateEquityCurves(wallets, priceHistory, balanceHistory, coinConfig))
    }
  }, [coinConfig, wallets, priceHistory, balanceHistory])

  /**
   * @summary Set the row data and set loading to false.
   */
  useEffect(() => {
    if (coinConfig && wallets && totalPortfolioValue && equityCurves) {
      setData(generateRows(wallets, equityCurves, totalPortfolioValue, coinConfig))
      setLoading(false)
    }
  }, [coinConfig, wallets, totalPortfolioValue, equityCurves])

  useEffect(() => {
    if (coinConfig && data && data.length > 0) {
      setAssetData(combineAssetRows(data, coinConfig))
    }
  }, [data, coinConfig])
  /**
   * @summary Reset the columns when the data view is changed.
   */
  useEffect(() => {
    setColumns(GenerateColumns(isXSmall, density, dataView, theme))
  }, [dataView])

  /**
   * @summary Update the local state when a user changes the density setting.
   */
  const handleDensityChange = useCallback(
    (state: any) => {
      if (state.density.value !== density) {
        setDensity(state.density?.value)
      }
    },
    [density]
  )

  if (loading || !columns || !data || !assetData) return <Loading />

  return (
    <div className={classes.topSpacer}>
      <Paper variant='outlined' style={{ flexGrow: 1 }}>
        <Box style={{ height: 'calc(90vh - 80px)', minHeight: 300, width: '100%' }}>
          <DataGridPro
            rows={assetData}
            columns={columns}
            components={{
              Toolbar: PortfolioSummaryListTopbar,
            }}
            componentsProps={{
              toolbar: {
                setDataView: setDataView,
                currentView: dataView,
                viewChanged: viewChanged,
              },
            }}
            loading={loading}
            checkboxSelection={false}
            disableSelectionOnClick
            density='compact'
            // autoPageSize
            hideFooter
            onStateChange={(state: any) => handleDensityChange(state)}
            onColumnVisibilityChange={() => setViewChanged(true)}
          />
        </Box>
      </Paper>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  topSpacer: {
    marginTop: 70,
    [theme.breakpoints.down('xs')]: {
      marginTop: 65,
    },
  },
}))

export default PortfolioPositionSummaryUI
