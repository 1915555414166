import { useState, useEffect, useRef, useCallback, MouseEvent } from 'react'
import { Grid, useMediaQuery, Theme, Divider, Box } from '@material-ui/core'
import FilterIcon from '@material-ui/icons/CallSplit'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useSelector } from 'react-redux'
import { useDataProvider, TopToolbar, ListButton, useTranslate } from 'react-admin'
import {
  calculateEquityCurves,
  calculateTotalValueAndDistribution,
} from '../../components/portfolio/methods'
import { PortfolioWalletCard, PortfolioOverview, portfolioStyles } from '../../components/portfolio'
import {
  Wallet,
  CoinConfig,
  PriceHistory,
  UserDoc,
  BalanceRecord,
  EquityCurves,
  Distribution,
} from '../../views/types'
import Loading from '../../views/layout/Loading'
import { proxyMapToMapState, proxyArrayToMapState, cacheIsValid } from '../../components/common'
import { sortArray } from '../../firebase/misc'
import { tagMap } from '../../Resources'
import { useTheme } from '@material-ui/core'
import { setCoinConfigState } from '../../components/common'
import { IDataProvider } from '../../firebase/providers/DataProvider'

/**
 * @summary The Big Bertha of the portfolio view.
 *
 * @returns {JSX.Element}
 */
const PortfolioUI = ({ profile }: any) => {
  // Data from API.
  const [coinConfig, setCoinConfig] = useState<{ [key: string]: CoinConfig }>()
  const [wallets, setWallets] = useState<Wallet[]>()
  const [filteredWallets, setFilteredWallets] = useState<Wallet[]>()
  const [tags, setTags] = useState<{ name: string; type: string }[]>()
  const [activeTag, setActiveTag] = useState<string>('All')
  const [balanceHistory, setBalanceHistory] = useState<{ [key: string]: BalanceRecord[] }>()
  const [priceHistory, setPriceHistory] = useState<{ [key: string]: PriceHistory[] }>()
  const theme = useTheme()

  // Calculated values.
  const [totalPortfolioValue, setTotalPortfolioValue] = useState<number>(0)
  const [totalSelectionValue, setTotalSelectionValue] = useState<number>(0)
  const [equityCurves, setEquityCurves] = useState<EquityCurves>()
  const [totalDistribution, setTotalDistribution] = useState<Distribution[]>()

  // The dates for balance/price history.
  // const [endDate, setEndDate] = useState<Date>(new Date());
  // const [startDate, setStartDate] = useState<Date>(
  //   new Date(new Date().setMonth(new Date().getMonth() - 3))
  // );
  const endDate = new Date()
  const startDate = new Date(new Date().setMonth(new Date().getMonth() - 3))
  const mountedRef = useRef(true)
  const isMounted = useCallback(() => mountedRef.current, [mountedRef.current])

  // Set true to hide loading screen.
  const [loading, setLoading] = useState<boolean>(true)

  // Init hooks.
  const dataProvider: IDataProvider = useDataProvider()
  const user: UserDoc = useSelector((state: any) => state.user)
  const cache: any = useSelector((state: any) => state.admin)
  const classes = portfolioStyles()
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const translate = useTranslate()

  // Set refs to avoid refresh.
  const userRef = useRef(profile)
  const walletRef = useRef(profile.Wallets)
  const trackingRef = useRef(
    profile.Tracking.includes('BTC') ? profile.Tracking : [...profile.Tracking, 'BTC'],
  )

  useEffect(() => {
    setCoinConfigState(trackingRef.current, cache, dataProvider, setCoinConfig, isMounted)
    fetchPriceData()
    fetchBalanceData()
    fetchWalletData()

    return () => {
      mountedRef.current = false
    }
  }, [])

  const getList = useCallback(
    (resource: string, params: { [kay: string]: any }) => {
      return new Promise(async resolve => {
        const { data }: any = await dataProvider.getList(resource, {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'id', order: 'DESC' },
          filter: params,
        })

        // Cancel request if the user changed page before it complete.
        if (!mountedRef.current) return null
        resolve(data)
      })
    },
    [dataProvider],
  )

  const fetchPriceData = useCallback(async () => {
    if (cacheIsValid(trackingRef.current, cache?.resources?.['price-history']?.validity)) {
      setPriceHistory(proxyMapToMapState(cache.resources['price-history'].data))
    } else {
      const data: any = await getList('price-history', {
        symbols: trackingRef.current,
        startDate: startDate,
        endDate: endDate,
      })
      setPriceHistory(proxyArrayToMapState(data))
    }
  }, [])

  const fetchBalanceData = useCallback(async () => {
    if (cacheIsValid(walletRef.current, cache?.resources?.['balance-history']?.validity)) {
      setBalanceHistory(proxyMapToMapState(cache.resources['balance-history'].data))
    } else {
      const data: any = await getList('balance-history', {
        profileId: user.ActiveProfile,
        startDate: startDate,
        endDate: endDate,
      })
      setBalanceHistory(proxyArrayToMapState(data))
    }
  }, [])

  const fetchWalletData = useCallback(async () => {
    const data: any = await getList('wallets', {
      profileId: user.ActiveProfile,
    })
    const walletData = data

    if (userRef.current.Untracked && userRef.current.Untracked.length > 0) {
      userRef.current.Untracked.forEach((wallet: any) => {
        const walletHistory = [...wallet.PriceHistory].map(wallet => ({
          ...wallet,
          Timestamp: new Date(wallet.Timestamp),
          id: new Date(wallet.Timestamp).toISOString().substring(0, 10),
        }))
        sortArray(walletHistory, 'Timestamp', 'asc')
        walletData.push({
          id: wallet.id ? wallet.id : new Date().getTime().toString(),
          Name: wallet.Name,
          Alias: wallet.Alias,
          Symbol: wallet.Symbol,
          Tags: wallet.Tags,
          Balance: walletHistory[walletHistory.length - 1].Balance,
          Value:
            walletHistory[walletHistory.length - 1].Balance *
            walletHistory[walletHistory.length - 1].Quote,
          PriceHistory: walletHistory,
          Manual: true,
        })
      })
    }

    const activeTags: { name: string; type: string }[] = [{ name: 'All', type: 'Default' }]

    walletData.forEach((wallet: Wallet) => {
      if (wallet.Tags && wallet.Tags.length > 0) {
        wallet.Tags.forEach((tag: string) => {
          if (!activeTags.find(t => t.name === tag)) activeTags.push({ name: tag, type: 'User' })
        })
      }
    })

    setWallets(walletData)
    setTags(activeTags)
    setFilteredWallets(walletData)
  }, [userRef.current])

  useEffect(() => {
    if (wallets && coinConfig && tags) {
      const walletData: Wallet[] = [...wallets]
      const tagData = [...tags]
      wallets.forEach((wallet: Wallet, i: number) => {
        if (!wallet.Manual) {
          coinConfig[wallet.Symbol].Tags &&
            coinConfig[wallet.Symbol].Tags.forEach((tag: string) => {
              if (tagMap[tag] && !tagData.find(t => t.name === tagMap[tag].name))
                tagData.push(tagMap[tag])

              if (!walletData[i].Tags) {
                walletData[i].Tags = []
              }
              if (tagMap[tag]) walletData[i].Tags.push(tagMap[tag].name)
            })
        }
      })
      setTags(tagData)
      setWallets(walletData)
    }
    if (filteredWallets && coinConfig) {
      const { totalValue, distribution } = calculateTotalValueAndDistribution(
        filteredWallets,
        coinConfig,
        theme.palette.type,
      )

      !totalPortfolioValue && setTotalPortfolioValue(totalValue)
      setTotalSelectionValue(totalValue)
      setTotalDistribution(
        Object.keys(distribution).map(key => {
          return distribution[key]
        }),
      )
    }
  }, [coinConfig, filteredWallets])

  useEffect(() => {
    if (filteredWallets && balanceHistory && priceHistory && coinConfig) {
      setEquityCurves(
        calculateEquityCurves(
          filteredWallets,
          priceHistory,
          balanceHistory,
          coinConfig,
          startDate,
          endDate,
          theme.palette.type,
        ),
      )
      setLoading(false)
    }
  }, [priceHistory, filteredWallets, balanceHistory, coinConfig])

  useEffect(() => {
    if (wallets && activeTag) {
      setLoading(true)
      const filtered =
        activeTag === 'All'
          ? wallets
          : wallets.filter((wallet: Wallet) => wallet.Tags && wallet.Tags.includes(activeTag))
      setFilteredWallets(filtered)
    }
  }, [activeTag])

  if (loading) return <Loading />

  return (
    <>
      <TopToolbar>
        <FilterMenu tags={tags} setActiveTag={setActiveTag} activeTag={activeTag} />
        <ListButton basePath={'/position-summary'} label={translate('pos.profile.table_view')} />
      </TopToolbar>
      <div className={classes.headerSpacing}>
        {isXSmall &&
          equityCurves &&
          totalDistribution &&
          priceHistory &&
          totalSelectionValue > 0 &&
          totalDistribution.length > 0 && (
            <>
              <PortfolioOverview
                distribution={totalDistribution}
                equityCurves={equityCurves}
                totalPortfolioValue={totalPortfolioValue}
                totalSelectionValue={totalSelectionValue}
                priceHistory={priceHistory}
                activeTag={activeTag}
              />
            </>
          )}

        <div style={{ marginBottom: 10 }}>
          {!isXSmall &&
            equityCurves &&
            totalDistribution &&
            priceHistory &&
            totalSelectionValue > 0 &&
            totalDistribution.length > 0 && (
              <PortfolioOverview
                distribution={totalDistribution}
                equityCurves={equityCurves}
                totalPortfolioValue={totalPortfolioValue}
                totalSelectionValue={totalSelectionValue}
                priceHistory={priceHistory}
                activeTag={activeTag}
              />
            )}
        </div>
        <Grid container spacing={2}>
          {filteredWallets &&
            filteredWallets.map((wallet: Wallet) => (
              <PortfolioWalletCard
                wallet={wallet}
                coinConfig={coinConfig![wallet.Symbol]}
                user={user}
                key={wallet.id}
              />
            ))}
        </Grid>
      </div>
    </>
  )
}

function FilterMenu({
  tags,
  activeTag,
  setActiveTag,
}: {
  tags: { name: string; type: string }[] | undefined
  activeTag: string
  setActiveTag: any
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [defaultTags, setDefaultTags] = useState<{ name: string; type: string }[]>()
  const [userTags, setUserTags] = useState<{ name: string; type: string }[]>()
  const [utilityTags, setUtilityTags] = useState<{ name: string; type: string }[]>()
  const [ecosystemTags, setEcosystemTags] = useState<{ name: string; type: string }[]>()

  const classes = portfolioStyles()

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (tag: string) => {
    setActiveTag(tag)
    handleClose()
  }

  useEffect(() => {
    setDefaultTags(tags?.filter(t => t.type === 'Default'))
    setUserTags(tags?.filter(t => t.type === 'User'))
    setUtilityTags(tags?.filter(t => t.type === 'Utility'))
    setEcosystemTags(tags?.filter(t => t.type === 'Ecosystem'))
  }, [tags])

  return (
    <div>
      <Button
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
        size='small'
        color='primary'
        startIcon={<FilterIcon />}
        className={classes.filterButton}
      >
        {activeTag}
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {defaultTags?.map(tag => {
          return (
            <MenuItem
              onClick={() => handleChange(tag.name)}
              disabled={tag.name === activeTag}
              key={tag.name}
            >
              {tag.name}
            </MenuItem>
          )
        })}

        {userTags && userTags.length > 0 && (
          <Box>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <Box style={{ paddingLeft: 15, paddingBottom: 5 }}>
              <small style={{ opacity: 0.6 }}>Strategies</small>
            </Box>
          </Box>
        )}

        {userTags?.map(tag => {
          return (
            <MenuItem
              onClick={() => handleChange(tag.name)}
              disabled={tag.name === activeTag}
              key={tag.name}
            >
              {tag.name}
            </MenuItem>
          )
        })}

        {utilityTags && (
          <Box>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <Box style={{ paddingLeft: 15, paddingBottom: 5 }}>
              <small style={{ opacity: 0.6 }}>Utility</small>
            </Box>
          </Box>
        )}

        {utilityTags?.map(tag => {
          return (
            <MenuItem
              onClick={() => handleChange(tag.name)}
              disabled={tag.name === activeTag}
              key={tag.name}
            >
              {tag.name}
            </MenuItem>
          )
        })}

        {ecosystemTags && (
          <Box>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <Box style={{ paddingLeft: 15, paddingBottom: 5 }}>
              <small style={{ opacity: 0.6 }}>Ecosystem</small>
            </Box>
          </Box>
        )}

        {ecosystemTags?.map(tag => {
          return (
            <MenuItem
              onClick={() => handleChange(tag.name)}
              disabled={tag.name === activeTag}
              key={tag.name}
            >
              {tag.name}
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}

export default PortfolioUI
