import { Validator } from "../../views/types";

/**
 * @summary Trigger an update after adding a new validator.
 *
 * @param user
 * @param record
 * @param token
 * @returns {JSX.Element}
 */
export const triggerValidatorProcessing = (
  record: Validator,
  token: string
) => {
  const url =
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? "https://radar-proxy.republic.com/Event/validator"
      : "https://radar-proxy.republic-beta.app/Event/validator";
  const body = {
    validatorId: record.id,
  };
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };

  return fetch(url, options).catch((err) => {
    console.log(err);
  });
};
