import { IDataProvider } from '../../firebase/providers/DataProvider'
import { Dispatch } from 'redux'
import { UserDoc } from '../../views/types'
import { updateUser } from '../../persistence/actions'
import { environment } from '../../components/common'
import { AuthProvider } from 'react-admin'

/**
 * @summary Fetch the users profile and create one if none exists.
 *
 * @param dataProvider
 * @param authProvider
 * @param user
 * @param dispatch
 * @param history
 */
export const fetchProfile = (
  dataProvider: IDataProvider,
  authProvider: AuthProvider,
  user: UserDoc,
  dispatch: Dispatch,
  history: { push: (url: string) => void },
) => {
  dataProvider
    .getOne<UserDoc>(`${environment('profiles')}`, {
      id: user.uid,
    })
    .then(userProfile => {
      const defaultProfile = userProfile.data.ActiveProfile

      // Fetch the default profile if using a shared profile.
      if (user.uid !== defaultProfile) {
        dataProvider
          .getOne<UserDoc>(`${environment('profiles')}`, {
            id: defaultProfile,
          })
          .then(sharedProfile => {
            // Merge the portfolio values with the user's.
            const profile = {
              ...userProfile.data,
              Tracking: sharedProfile.data.Tracking,
              Untracked: sharedProfile.data.Untracked ? sharedProfile.data.Untracked : [],
              Wallets: sharedProfile.data.Wallets,
            }

            // Update state and redirect.
            dispatch(updateUser(profile))
            history.push('/#/')
          })
          .catch(err => {
            throw new Error(err)
          })
      } else {
        // Update state and redirect.
        dispatch(updateUser(userProfile.data))
        history.push('/#/')
      }
    })
    .catch(err => {
      // create doc if it didn't exist
      if (err.status === 200) {
        createProfile(dataProvider, authProvider, user, dispatch, history)
      }
      console.log(err.message)
    })
}

/**
 * @summary Generate a new profile on first sign-in.
 *
 * @param dataProvider
 * @param authProvider
 * @param user
 * @param dispatch
 * @param history
 */
export const createProfile = (
  dataProvider: IDataProvider,
  authProvider: AuthProvider,
  user: UserDoc,
  dispatch: Dispatch,
  history: { push: (url: string) => void },
) => {
  const newUserDoc: UserDoc = {
    id: user.uid,
    Name: user.displayName,
    Email: user.email,
    ActiveProfile: user.uid,
    Organizations: [],
    Tracking: [],
    Wallets: [],
    Notifications: [],
    IsAdmin: false,
  }

  dataProvider
    .create<UserDoc>(`${environment('profiles')}`, {
      data: newUserDoc,
    })
    .then(result => {
      dispatch(updateUser(result.data))
      authProvider.getJWTToken().then((token: string) => {
        triggerProfileProcessing(result.data, token, history, '/#/')
      })
    })
    .catch(err => {
      console.log(err.message)
    })
}

/**
 * @summary Trigger profile processing after first signup.
 *
 * @param user
 * @param token
 * @param history
 * @param url
 */
export const triggerProfileProcessing = (
  user: UserDoc,
  token: string,
  history: { push: (url: string) => void },
  forwardURL: string,
) => {
  const url =
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? 'https://radar-proxy.republic.com/Event/profile'
      : 'https://radar-proxy.republic-beta.app/Event/profile'
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: user.ActiveProfile,
  }

  return fetch(url, options)
    .then(() => {
      if (forwardURL) {
        history.push(forwardURL)
      }
    })
    .catch(err => {
      if (forwardURL) {
        history.push(forwardURL)
      }
      console.log(err)
    })
}
