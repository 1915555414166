import { useRef, useCallback } from "react";
import {
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  SaveButton,
  useNotify,
  useRedirect,
  Edit,
  CreateProps,
  useMutation,
  ArrayInput,
  SimpleFormIterator,
  useDataProvider,
} from "react-admin";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import walletStyles from "../../components/wallets/walletStyles";
import { environment } from "../../components/common";
import { Grid } from "@material-ui/core";
import { SanitizedGrid } from "../../components/form";
import { UserDoc } from "../types";
import { updateUser } from "../../persistence/actions";

/**
 * @summary Renders the form used to add a shared profile by profile id.
 *
 * @param props
 * @returns {JSX.Element}
 */
const ProfilesEdit = (props: CreateProps) => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const redirectTo = useRedirect();
  const user = useSelector((state: any) => state.user);
  const classes = walletStyles();
  const dataProvider = useDataProvider();
  const [mutate] = useMutation();

  const userRef = useRef(user);

  const save = useCallback(
    (values) => {
      const errors: any = {};
      const { Name, Tags } = values;
      if (!Name) errors.Name = "ra.validation.required";

      if (Tags) {
        Tags.forEach((tag: string) => {
          const exists = userRef.current.Tags
            ? userRef.current.Tags.find((t: string) => t === tag)
            : false;

          if (exists) {
            errors.ProfileId = "pos.profile.already_exists";
            return errors;
          }
        });
      }

      return dataProvider
        .getOne(environment("profiles"), {
          id: userRef.current.ActiveProfile,
        })
        .then(async () => {
          try {
            await mutate(
              {
                type: "update",
                resource: environment("profiles"),
                payload: {
                  id: user.ActiveProfile,
                  data: {
                    Name: Name,
                    Tags: Tags,
                  },
                },
              },
              {
                onSuccess: ({ data }) => {
                  const updatedUser: UserDoc = {
                    ...userRef.current,
                    Name: data.Name,
                    Tags: data.Tags,
                  };
                  dispatch(updateUser(updatedUser));
                  notify("pos.profile.updated", { type: "success" });
                  redirectTo("/wallets");
                },
                returnPromise: true,
              }
            );
          } catch (error: any) {
            errors.ProfileName = "pos.app.save_failed";
            errors.ProfileId = "pos.app.save_failed";
            return errors;
          }
        })
        .catch((err) => {
          if (err.status === 200) {
            errors.ProfileId = "pos.profile.not_found";
            return errors;
          } else {
            errors.ProfileId = "pos.profile.check_failed";
            return errors;
          }
        });
    },
    [mutate, dataProvider]
  );

  const SaveBar = (props: ToolbarProps) => (
    <Toolbar {...props}>
      <SaveButton disabled={props.pristine} />
    </Toolbar>
  );

  return (
    <div style={{ marginTop: 16 }}>
      <Edit
        {...props}
        basePath={`/${environment("profiles")}`}
        resource={`${environment("profiles")}`}
        id={user.ActiveProfile}
        mutationMode="pessimistic"
      >
        <SimpleForm
          mutationMode="pessimistic"
          toolbar={<SaveBar />}
          save={save}
        >
          <SanitizedGrid
            container
            spacing={2}
            className={classes.formContainer}
          >
            <Grid item xs={12} className={classes.formRow}>
              <TextInput
                source="Name"
                className={classes.inputField}
                margin="normal"
                label="Name"
              />
              <TextInput
                source="id"
                className={classes.inputField}
                margin="normal"
                label="Profile Key"
                disabled={true}
              />
              <ArrayInput
                source="Tags"
                label="Asset Tags"
                defaultValue={user.Tags}
              >
                <SimpleFormIterator>
                  <TextInput
                    source=""
                    className={classes.inputField}
                    margin="normal"
                    label="Tag"
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
          </SanitizedGrid>
        </SimpleForm>
      </Edit>
    </div>
  );
};

export default ProfilesEdit;
