import React from "react";
import { ListProps } from "react-admin";
import {
  List,
  Datagrid,
  DateInput,
  NumberField,
  FunctionField,
  DateField,
} from "react-admin";
import { RowNetworkName, tableStyles } from "../../components/table";
import { useTranslate } from "react-admin";
import { Empty } from "../layout";

const RewardsList = (props: ListProps) => {
  const classes = tableStyles();
  const translate = useTranslate();
  const filters = [
    <DateInput source="Timestamp_date_gte" label="Start date" alwaysOn />,
    <DateInput source="Timestamp_date_lte" label="End date" alwaysOn />,
  ];
  return (
    <div className={classes.dateSearchHeaderSpacing}>
      <List
        {...props}
        perPage={25}
        filters={filters}
        sort={{ field: "Timestamp", order: "desc" }}
        empty={<Empty label="rewards" />}
        bulkActionButtons={false}
      >
        <Datagrid isRowSelectable={() => false}>
          <DateField
            source="Timestamp"
            label={translate("pos.table.date")}
            headerClassName={classes.tableHeader}
          />
          <RowNetworkName
            source="Name"
            headerClassName={classes.tableHeader}
            label={translate("pos.table.name")}
          />
          <FunctionField
            label={translate("pos.table.destination_address")}
            render={(val: any) =>
              `${val.Destination.slice(0, 12)}...${val.Destination.slice(
                val.Destination.length - 7,
                val.Destination.length
              )}`
            }
            headerClassName={classes.tableHeader}
          />
          <NumberField
            source="Quantity"
            label={translate("pos.table.quantity")}
            headerClassName={classes.tableHeader}
          />
          <NumberField
            source="VOA"
            label={translate("pos.table.voa")}
            options={{ style: "currency", currency: "USD" }}
            headerClassName={classes.tableHeader}
          />
        </Datagrid>
      </List>
    </div>
  );
};

export default RewardsList;
