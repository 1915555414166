import { makeStyles, Theme } from "@material-ui/core";

const formStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    width: "100%",
    maxWidth: 950,
    paddingTop: 15,
    paddingBottom: 15,
    margin: 0,
  },
  formRow: {
    paddingTop: "0px!important",
    paddingBottom: "0px!important",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "3px!important",
      paddingBottom: "3px!important",
    },
  },
  inputField: { width: "100%", marginTop: 5 },
  selectIcon: {
    height: 19,
    width: 19,
    marginBottom: -1,
  },
  inlineSelectText: {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    height: 19,
  },
  selectNetworkNameContainer: {
    marginLeft: 8,
    display: "flex",
    maxWidth: 200,
    minWidth: 0,
    marginBottom: -1,
    [theme.breakpoints.down("xl")]: {
      maxWidth: 350,
    },
    [theme.breakpoints.down("lg")]: {
      maxWidth: 275,
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: 150,
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: 105,
    },
  },
  networkName: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  input: {
    height: 100,
  },
  customFormControl: { margin: "5px 0px 4px 0px", width: "100%" },
}));

export default formStyles;
