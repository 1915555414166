import { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDataProvider } from "react-admin";
import { Empty } from "../layout";
import { PortfolioPositionSummaryUI } from "../../components/portfolio";
import { UserDoc } from "../types";
import { updateUser } from "../../persistence/actions";
import Loading from "../layout/Loading";
import { useAuthenticated } from "react-admin";
import { IDataProvider } from "../../firebase/providers/DataProvider";
import { environment } from "../../components/common";

/**
 * @summary The top level component of the position summary.
 *
 * Handles authentication and displays a separate view if no wallets are present.
 *
 * @returns {JSX.Element}
 */
const Portfolio = () => {
  const [profile, setProfile] = useState<UserDoc>();
  const [loading, setLoading] = useState<boolean>(true);
  const user = useSelector((state: any) => state.user);
  const dataProvider: IDataProvider = useDataProvider();
  const dispatch = useDispatch();
  useAuthenticated();

  const mountedRef = useRef(true);

  const updateProfile = useCallback(async () => {
    const { data } = await dataProvider.getOne<UserDoc>(
      `${environment("profiles")}`,
      { id: user.ActiveProfile }
    );

    // Cancel request if the user changed page before it finished.
    if (!mountedRef.current) return null;

    const { Tracking, Wallets } = data;
    dispatch(updateUser({ ...user, Tracking, Wallets }));
    setProfile(data);
    data && setLoading(false);
  }, []);

  useEffect(() => {
    updateProfile();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  if (loading) return <Loading />;

  return profile?.Tracking && profile.Tracking.length > 0 ? (
    <PortfolioPositionSummaryUI profile={profile} />
  ) : (
    <Empty label="data" />
  );
};

export default Portfolio;
