//@ts-nocheck

import React, { useLayoutEffect, useRef, useEffect } from 'react'
import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core'
import { coinInfo } from '../../Resources'
import { getChartTheme } from './methods'
import { makeStyles } from '@material-ui/core'
import { sortArray } from '../../firebase/misc'
import { useTranslate } from 'react-admin'

const PortfolioAssetComparison = ({ equityCurves, priceHistory, activeTag }: Props) => {
  const theme = useTheme()
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const chartTheme = getChartTheme(theme)
  const translate = useTranslate()

  const rootRef = useRef<am5.Root | null>(null)
  const xAxisRef = useRef<am5xy.ValueAxis<AxisRenderer> | null>(null)
  const yAxisRef = useRef<am5xy.ValueAxis<AxisRenderer> | null>(null)
  const chartRef = useRef<am5xy.XYChart | null>(null)
  const legendRef = useRef<am5.Legend | null>(null)

  const classes = pieStyle()

  useEffect(() => {
    return () => {
      chartRef.current && chartRef.current.dispose()
    }
  }, [])

  useLayoutEffect(() => {
    if (!rootRef.current) {
      rootRef.current = am5.Root.new('assetcomparechart')

      // Set the font and positive/negative colors.
      const myTheme = am5.Theme.new(rootRef.current)
      myTheme.rule('Label').setAll(chartTheme.fontStyle)
      myTheme.rule('InterfaceColors').setAll({
        positive: chartTheme.positive,
        negative: chartTheme.negative,
      })
      rootRef.current.setThemes([am5themes_Animated.new(rootRef.current), myTheme])

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      chartRef.current = rootRef.current.container.children.push(
        am5xy.XYChart.new(rootRef.current, {
          panX: true,
          panY: false,
          wheelX: 'panX',
          wheelY: 'zoomX',
          layout: rootRef.current.verticalLayout,
        })
      )
      chartRef.current.zoomOutButton.set('forceHidden', true)

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/

      // Y axis #1
      var valueAxisRenderer = am5xy.AxisRendererY.new(rootRef.current, {
        opposite: true,
      })

      valueAxisRenderer.labels.template.setAll({
        fill: am5.color(theme.palette.type === 'dark' ? 0xfffffff : 0x000000),
        fontSize: '0.87rem',
        paddingLeft: 8,
      })

      valueAxisRenderer.grid.template.setAll({
        stroke: chartTheme.themeFillStroke,
        location: 0.5,
        strokeOpacity: theme.palette.type === 'dark' ? 0.05 : 0.1,
        visible: true,
      })

      valueAxisRenderer.ticks.template.setAll({
        stroke: chartTheme.themeFillStroke,
        location: 0.5,
        multiLocation: 0.5,
        strokeOpacity: 0.3,
        visible: true,
      })

      yAxisRef.current = chartRef.current.yAxes.push(
        am5xy.ValueAxis.new(rootRef.current, {
          renderer: valueAxisRenderer,
          maxDeviation: 0.01,
          extraMin: 0.2, // gives some extra space
          fontSize: isXSmall ? 11 : '0.87rem',
        })
      )

      // Y axis #2
      var volumeAxisRenderer = am5xy.AxisRendererY.new(rootRef.current, {
        opposite: true,
      })

      volumeAxisRenderer.labels.template.setAll({
        forceHidden: true,
        fontSize: '0.87rem',
      })

      volumeAxisRenderer.grid.template.setAll({
        forceHidden: true,
      })

      var volumeAxis = chartRef.current.yAxes.push(
        am5xy.ValueAxis.new(rootRef.current, {
          renderer: volumeAxisRenderer,
          height: am5.percent(25),
          layer: 5,
          centerY: am5.p100,
          y: am5.p100,
        })
      )

      volumeAxis.axisHeader.set('paddingTop', 10)

      // X axis
      var dateAxisRenderer = am5xy.AxisRendererX.new(rootRef.current, {
        minGridDistance: 50,
      })

      dateAxisRenderer.labels.template.setAll({
        minPosition: 0.01,
        maxPosition: 0.99,
        location: 0.5,
        multiLocation: 0.5,
        fontSize: '0.87rem',
        fill: am5.color(theme.palette.type === 'dark' ? 0xfffffff : 0x000000),
      })

      dateAxisRenderer.grid.template.setAll({
        stroke: chartTheme.themeFillStroke,
        location: 0.5,
        strokeOpacity: theme.palette.type === 'dark' ? 0.05 : 0.1,
        visible: true,
      })

      xAxisRef.current = chartRef.current.xAxes.push(
        am5xy.DateAxis.new(rootRef.current, {
          groupData: true,
          baseInterval: {
            timeUnit: 'day',
            count: 1,
          },
          fontSize: isXSmall ? 11 : '0.87rem',
          renderer: dateAxisRenderer,
          maxDeviation: 0.01,
        })
      )

      const xAxisTooltip = xAxisRef.current.set(
        'tooltip',
        am5.Tooltip.new(rootRef.current, {
          themeTags: ['axis'],
        })
      )
      xAxisTooltip.label.setAll({
        fontSize: 13,
        paddingTop: 0,
        paddingBottom: 0,
      })

      const yAxisTooltip = yAxisRef.current.set(
        'tooltip',
        am5.Tooltip.new(rootRef.current, {
          themeTags: ['axis'],
        })
      )
      yAxisTooltip.label.setAll({
        fontSize: 13,
        paddingTop: 0,
        paddingBottom: 0,
      })

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      var portfolioSeries = chartRef.current.series.push(
        am5xy.LineSeries.new(rootRef.current, {
          name: activeTag === 'All' ? translate('pos.graph.portfolio') : activeTag,
          valueYField: 'portfolioEQ',
          calculateAggregates: true,
          valueYShow: 'valueYChangeSelectionPercent',
          valueXField: 'date',
          xAxis: xAxisRef.current,
          yAxis: yAxisRef.current,
          legendValueText: "{valueY.formatNumber('$#,###.##')}",
          fill: am5.color(equityCurves['Total'].color),
          stroke: am5.color(equityCurves['Total'].color),
        })
      )

      var bitcoinSeries = chartRef.current.series.push(
        am5xy.LineSeries.new(rootRef.current, {
          name: 'Bitcoin',
          valueYField: 'bitcoinEQ',
          calculateAggregates: true,
          valueYShow: 'valueYChangeSelectionPercent',
          valueXField: 'date',
          xAxis: xAxisRef.current,
          yAxis: yAxisRef.current,
          legendValueText: "{valueY.formatNumber('$#,###.##')}",
          fill: am5.color(coinInfo['BTC'].color),
          stroke: am5.color(coinInfo['BTC'].color),
        })
      )

      var ethereumSeries = chartRef.current.series.push(
        am5xy.LineSeries.new(rootRef.current, {
          name: 'Ethereum',
          valueYField: 'ethereumEQ',
          calculateAggregates: true,
          valueYShow: 'valueYChangeSelectionPercent',
          valueXField: 'date',
          xAxis: xAxisRef.current,
          yAxis: yAxisRef.current,
          legendValueText: "{valueY.formatNumber('$#,###.##')}",
          fill: am5.color(coinInfo['ETH'].color),
          stroke: am5.color(coinInfo['ETH'].color),
        })
      )

      // Add series tooltips
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Tooltips
      var portfolioTooltip = portfolioSeries.set(
        'tooltip',
        am5.Tooltip.new(rootRef.current, {
          getFillFromSprite: false,
          getStrokeFromSprite: true,
          getLabelFillFromSprite: true,
          autoTextColor: false,
          pointerOrientation: 'horizontal',
          paddingTop: 5,
          paddingBottom: 8,
          labelText:
            /* eslint-disable no-template-curly-in-string */
            `[fontSize: 13px]\${valueY}[/] {valueYChangePercent.formatNumber('[${theme.palette.success.dark} fontSize: 12px]+#,###.##|[${theme.palette.error.dark} fontSize: 12px]#,###.##|[#999999 fontSize: 12px]0')}%`,
        })
      )
      portfolioTooltip.get('background').set('fill', am5.color(theme.palette.background.paper))

      var bitcoinTooltip = bitcoinSeries.set(
        'tooltip',
        am5.Tooltip.new(rootRef.current, {
          getFillFromSprite: false,
          getStrokeFromSprite: true,
          getLabelFillFromSprite: true,
          autoTextColor: false,
          pointerOrientation: 'horizontal',
          paddingTop: 5,
          paddingBottom: 8,
          labelText:
            /* eslint-disable no-template-curly-in-string */
            `[fontSize: 13px]\${valueY}[/] {valueYChangePercent.formatNumber('[${theme.palette.success.dark} fontSize: 12px]+#,###.##|[${theme.palette.error.dark} fontSize: 12px]#,###.##|[#999999 fontSize: 12px]0')}%`,
        })
      )
      bitcoinTooltip.get('background').set('fill', am5.color(theme.palette.background.paper))
      bitcoinSeries.strokes.template.setAll({
        strokeDasharray: [8, 4],
      })

      var ethereumTooltip = ethereumSeries.set(
        'tooltip',
        am5.Tooltip.new(rootRef.current, {
          getFillFromSprite: false,
          getStrokeFromSprite: true,
          getLabelFillFromSprite: true,
          autoTextColor: false,
          pointerOrientation: 'horizontal',
          paddingTop: 5,
          paddingBottom: 8,
          labelText:
            /* eslint-disable no-template-curly-in-string */
            `[fontSize: 13px]\${valueY}[/] {valueYChangePercent.formatNumber('[${theme.palette.success.dark} fontSize: 12px]+#,###.##|[${theme.palette.error.dark} fontSize: 12px]#,###.##|[#999999 fontSize: 12px]0')}%`,
        })
      )
      ethereumTooltip.get('background').set('fill', am5.color(theme.palette.background.paper))
      ethereumSeries.strokes.template.setAll({
        strokeDasharray: [8, 4],
      })

      var firstColor = am5.color(theme.palette.type === 'dark' ? '#636466' : '#A7A7A7')
      var volumeSeries = chartRef.current.series.push(
        am5xy.ColumnSeries.new(rootRef.current, {
          name: 'XTD',
          fill: firstColor,
          stroke: firstColor,
          valueYField: 'quantity',
          valueXField: 'date',
          valueYGrouped: 'sum',
          xAxis: xAxisRef.current,
          yAxis: volumeAxis,
        })
      )
      volumeSeries.columns.template.setAll({
        width: am5.percent(40),
        strokeWidth: 0.2,
        strokeOpacity: 1,
        stroke: am5.color(0xffffff),
      })

      // Add legend to axis header
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/axis-headers/
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      legendRef.current = chartRef.current.plotContainer.children.push(
        am5.Legend.new(rootRef.current, {
          useDefaultMarker: true,
          layout: rootRef.current.verticalLayout,
        })
      )
      legendRef.current.labels.template.setAll({
        fill: am5.color(theme.palette.type === 'dark' ? 0xfffffff : 0x000000),
        fontSize: '13px',
      })
      legendRef.current.valueLabels.template.setAll({
        fill: am5.color(theme.palette.type === 'dark' ? 0xfffffff : 0x000000),
        fontSize: '13px',
      })
      legendRef.current.data.setAll([portfolioSeries, bitcoinSeries, ethereumSeries])

      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
      const cursor = chartRef.current.set('cursor', am5xy.XYCursor.new(rootRef.current, {}))
      cursor!.lineX.setAll({
        stroke: chartTheme.themeFillStroke,
        strokeOpacity: 0.3,
      })
      cursor!.lineY.setAll({
        stroke: chartTheme.themeFillStroke,
        strokeOpacity: 0.3,
      })

      // Add scrollbar
      // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
      var scrollbar = chartRef.current.set(
        'scrollbarX',
        am5xy.XYChartScrollbar.new(rootRef.current, {
          orientation: 'horizontal',
          height: 35,
        })
      )

      let scrollbarX = chartRef.current.get('scrollbarX')

      theme.palette.type === 'dark' &&
        scrollbarX.get('background').setAll({
          fill: am5.color('#161b22'),
          fillOpacity: 0.2,
          cornerRadiusTR: 0,
          cornerRadiusBR: 0,
          cornerRadiusTL: 0,
          cornerRadiusBL: 0,
        })

      theme.palette.type === 'dark' &&
        scrollbarX?.startGrip.get('background')?.setAll({
          fillOpacity: 0.4,
        })

      theme.palette.type === 'dark' &&
        scrollbarX?.endGrip.get('background')?.setAll({
          fillOpacity: 0.4,
        })

      var sbDateAxis = scrollbar.chart.xAxes.push(
        am5xy.DateAxis.new(rootRef.current, {
          groupData: true,
          groupIntervals: [
            {
              timeUnit: 'week',
              count: 1,
            },
          ],
          baseInterval: {
            timeUnit: 'day',
            count: 1,
          },
          renderer: am5xy.AxisRendererX.new(rootRef.current, {}),
        })
      )

      var sbValueAxis = scrollbar.chart.yAxes.push(
        am5xy.ValueAxis.new(rootRef.current, {
          renderer: am5xy.AxisRendererY.new(rootRef.current, {}),
        })
      )

      var sbSeries = scrollbar.chart.series.push(
        am5xy.LineSeries.new(rootRef.current, {
          valueYField: 'portfolioEQ',
          valueXField: 'date',
          xAxis: sbDateAxis,
          yAxis: sbValueAxis,
        })
      )

      sbSeries.fills.template.setAll({
        visible: true,
        fillOpacity: 0.3,
      })

      // BTC compare
      const portfolioData = equityCurves['Total'].holdingsData
      const btcComparison = priceHistory['BTC']
      const ethComparison = priceHistory['ETH']

      // Normalize data by showing the the same amount of X the total balance could purchase.
      const hypotheticalBTCBalance =
        equityCurves['Total'].holdingsData[0].Quote / priceHistory['BTC'][0].quote
      sortArray(btcComparison, 'timestamp', 'asc')

      const hypotheticalETHBalance =
        equityCurves['Total'].holdingsData[0].Quote / priceHistory['ETH'][0].quote
      sortArray(ethComparison, 'timestamp', 'asc')

      var data = []
      let j = 0
      for (let i = 0; i < portfolioData.length; i++) {
        if (!btcComparison[j] || !ethComparison[j]) continue
        const portfolioDate = new Date(portfolioData[i].Timestamp).toISOString().substring(0, 10)
        const btcComparisonDate = new Date(
          btcComparison[j].id === 'latest' ? btcComparison[j].timestamp : btcComparison[j].id
        )
          .toISOString()
          .substring(0, 10)

        if (portfolioDate === btcComparisonDate) {
          const record = {
            date: new Date(
              btcComparison[j].id === 'latest' ? btcComparison[j].timestamp : btcComparison[j].id
            ).getTime(),
            portfolioEQ: Math.round(portfolioData[i].Quote),
            bitcoinEQ: Math.round(btcComparison[j].quote * hypotheticalBTCBalance),
            ethereumEQ: Math.round(ethComparison[j].quote * hypotheticalETHBalance),
            quantity: Math.round(btcComparison[j].volume_24hr),
          }
          data.push(record)
          j++
        } else {
          console.log('Data is patchy!')
          if (portfolioDate > btcComparisonDate) {
            i--
            j++
            continue
          }
        }
      }
      portfolioSeries.data.setAll(data)
      bitcoinSeries.data.setAll(data)
      ethereumSeries.data.setAll(data)
      volumeSeries.data.setAll(data)
      sbSeries.data.setAll(data)

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chartRef.current.appear(1000, 100)
    }
  })

  useEffect(() => {
    if (xAxisRef.current && yAxisRef.current && legendRef.current) {
      xAxisRef.current.get('renderer').labels.template.setAll({
        fontSize: isXSmall ? 11 : 13,
      })

      yAxisRef.current.get('renderer').labels.template.setAll({
        fontSize: isXSmall ? 11 : 13,
      })

      legendRef.current.labels.template.setAll({
        fontSize: isXSmall ? 13 : 15,
      })

      legendRef.current.valueLabels.template.setAll({
        fontSize: isXSmall ? 13 : 15,
      })
    }
  }, [isXSmall])

  return <div id='assetcomparechart' className={classes.container} />
}

const pieStyle = makeStyles(theme => ({
  container: {
    height: 370,
    marginTop: -20,
    [theme.breakpoints.down('lg')]: {
      height: 348,
    },
    [theme.breakpoints.down('md')]: {
      height: 380,
    },
  },
}))

interface Props {
  equityCurves: EquityCurves
  priceHistory: { [key: string]: PriceHistory[] }
  activeTag: string
}

export default PortfolioAssetComparison
