import React, { useState } from 'react'
import { Grid, Card, CardHeader } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { IconButton, Menu, MenuItem, useMediaQuery, Theme } from '@material-ui/core'
import { PortfolioAMLine, PortfolioBalancePie } from '../graphs'
import { makeStyles } from '@material-ui/core'
import { Distribution, EquityCurves, PriceHistory } from '../../views/types'
import PortfolioAssetComparison from '../graphs/PortfolioAssetComparison'
import { toCurrencyString } from '../common'
import PercentChange from '../common/PercentChange'
import { useTranslate } from 'react-admin'

/**
 * @summary Renders the lower section of the portfolio page containing the line/pie charts.
 *
 * @param props
 * @returns {JSX.Element}
 */
const PortfolioOverview = ({
  distribution,
  equityCurves,
  totalPortfolioValue,
  totalSelectionValue,
  priceHistory,
  activeTag,
}: OverviewProps) => {
  const classes = portfolioStyles()
  const [lineChartView, setLineChartView] = useState<'portfolio' | 'holdings'>('holdings')
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const translate = useTranslate()

  const lastClose =
    equityCurves['Total'].holdingsData.length > 2
      ? equityCurves['Total'].holdingsData[equityCurves['Total'].holdingsData.length - 1].Quote
      : 0

  const prevClose =
    equityCurves['Total'].holdingsData.length > 2
      ? equityCurves['Total'].holdingsData[equityCurves['Total'].holdingsData.length - 2].Quote
      : 0

  const change = ((lastClose - prevClose) / prevClose) * 100

  const percentOfPortfolio = (totalSelectionValue / totalPortfolioValue) * 100

  return (
    <Grid container spacing={2} className={classes.overviewContainer}>
      <Grid item xs={12} lg={4} xl={4}>
        <Card className={classes.card} variant='outlined'>
          {!isXSmall && (
            <CardHeader
              title={translate('pos.dashboard.total_portfolio_value')}
              titleTypographyProps={{ variant: 'body2' }}
              subheader={translate('pos.dashboard.based_on_value')}
              subheaderTypographyProps={{ variant: 'body2' }}
              style={{ userSelect: 'none' }}
            />
          )}
          <div
            style={{
              padding: '0px',
              width: '100%',
              height: '100%',
              position: 'relative',
            }}
          >
            <PortfolioBalancePie
              distribution={distribution}
              totalValue={totalSelectionValue}
              mobile={true}
            />
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                paddingTop: 5,
              }}
            >
              <div style={{ textAlign: 'center', marginBottom: 6 }}>
                {activeTag === 'All' ? translate('pos.dashboard.total_portfolio') : activeTag}
              </div>
              <div className={classes.balance}>{toCurrencyString(totalSelectionValue)}</div>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <PercentChange change={change} />
              </div>
            </div>
          </div>

          {percentOfPortfolio !== 100 && (
            <div className={classes.percentOfPortfolio}>
              <span>* {percentOfPortfolio.toFixed(2)}% of Total</span>
            </div>
          )}
        </Card>
      </Grid>
      <Grid container item xs={12} lg={8} xl={8}>
        <Card className={classes.graphCard} variant='outlined'>
          <CardHeader
            title={translate('pos.dashboard.performance_comparison')}
            titleTypographyProps={{ variant: 'body2' }}
            subheader={translate('pos.dashboard.compared_to_bitcoin')}
            subheaderTypographyProps={{
              variant: 'body2',
            }}
            style={{ userSelect: 'none' }}
          />
          {Object.keys(equityCurves).length > 0 && (
            <PortfolioAssetComparison
              equityCurves={equityCurves}
              priceHistory={priceHistory}
              activeTag={activeTag}
            />
          )}
        </Card>
      </Grid>
      <Grid container item xs={12}>
        <Card className={classes.graphCard} variant='outlined'>
          <CardHeader
            title={
              lineChartView === 'portfolio'
                ? translate('pos.dashboard.portfolio_performance')
                : translate('pos.dashboard.current_position')
            }
            titleTypographyProps={{ variant: 'body2' }}
            subheader={
              lineChartView === 'portfolio'
                ? translate('pos.dashboard.based_on_history')
                : translate('pos.dashboard.based_on_balances')
            }
            subheaderTypographyProps={{ variant: 'body2' }}
            action={<LineChartMenu view={lineChartView} setLineChartView={setLineChartView} />}
          />
          {Object.keys(equityCurves).length > 0 && (
            <PortfolioAMLine equityCurves={equityCurves} view={lineChartView} />
          )}
        </Card>
      </Grid>
    </Grid>
  )
}

/**
 * @summary Renders the animated pop out menu on the portfolio line chart.
 *
 * @param props
 * @returns {JSX.Element}
 */
function LineChartMenu({ view, setLineChartView }: LineChartMenuProps) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const changeView = () => {
    setLineChartView(view === 'portfolio' ? 'holdings' : 'portfolio')
    handleClose()
  }

  return (
    <div>
      <IconButton aria-label='settings' aria-haspopup='true' onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => changeView()} disabled={view === 'portfolio'}>
          Portfolio Performance
        </MenuItem>
        <MenuItem onClick={() => changeView()} disabled={view === 'holdings'}>
          Current Holdings
        </MenuItem>
      </Menu>
    </div>
  )
}

interface LineChartMenuProps {
  view: 'portfolio' | 'holdings'
  setLineChartView: React.Dispatch<React.SetStateAction<'portfolio' | 'holdings'>>
}

interface OverviewProps {
  distribution: Distribution[]
  equityCurves: EquityCurves
  totalPortfolioValue: number
  totalSelectionValue: number
  priceHistory: { [key: string]: PriceHistory[] }
  activeTag: string
}

const portfolioStyles = makeStyles(theme => ({
  overviewContainer: {
    marginTop: 0,
    [theme.breakpoints.down('xs')]: { marginBottom: 7 },
  },
  card: {
    padding: '0px 0px 15px 0px',
    width: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 0px 5px 0px',
    },
  },
  graphCard: {
    padding: '0px 0px 0px 15px',
    width: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  balance: {
    textAlign: 'center',
    fontSize: 24,
    paddingBottom: 6,
    fontWight: 700,
    [theme.breakpoints.down('xs')]: { fontSize: 20 },
  },
  breakdownTitle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    marginBottom: 18,
  },
  percentChange: {
    fontSize: 14,
    textAlign: 'right',
    marginTop: 5,
  },
  percentOfPortfolio: {
    position: 'absolute',
    bottom: 19,
    right: 22,
    fontSize: 14,
    [theme.breakpoints.down('xs')]: {
      bottom: 15,
      right: 15,
    },
  },
}))

export default PortfolioOverview
