import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid-pro'
import { useHistory } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import { useMediaQuery, Theme } from '@material-ui/core'
import { Button } from 'react-admin'
import { makeStyles } from '@material-ui/core'

/**
 * @summary Renders the left right orientated settings menu above the wallet list table.
 *
 * @returns {JSX.Element}
 */
const WalletListTopbar = () => {
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const history = useHistory()
  const classes = toolbarStyles()
  return (
    <div className={classes.container}>
      <GridToolbarContainer className={classes.flexContainer}>
        <div style={{ marginRight: 'auto' }}>
          {!isXSmall && <GridToolbarColumnsButton />}
          {!isXSmall && <GridToolbarDensitySelector />}
          {!isXSmall && <GridToolbarFilterButton />}
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button label='Add Wallet' size='small' onClick={() => history.push('create-wallet')}>
            <AddIcon />
          </Button>
          <GridToolbarExport
            csvOptions={{
              fileName: `Wallet List ${new Date().toDateString()}`,
              allColumns: isXSmall,
            }}
          />
        </div>
      </GridToolbarContainer>
    </div>
  )
}

const toolbarStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'absolute',
    top: -53,
    left: 0,
    zIndex: 10,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      top: -61,
    },
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 5,
    paddingBottom: 12,
  },
}))

export default WalletListTopbar
