import { useRef, useCallback } from "react";
import {
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  SaveButton,
  useNotify,
  useRedirect,
  Edit,
  CreateProps,
  useMutation,
  useDataProvider,
} from "react-admin";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import walletStyles from "../../components/wallets/walletStyles";
import { environment } from "../../components/common";
import { Grid } from "@material-ui/core";
import { SanitizedGrid } from "../../components/form";
import { UserDoc } from "../types";
import { updateUser } from "../../persistence/actions";

/**
 * @summary Renders the form used to add a shared profile by profile id.
 *
 * @param props
 * @returns {JSX.Element}
 */
const ProfilesAdd = (props: CreateProps) => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const redirectTo = useRedirect();
  const user = useSelector((state: any) => state.user);
  const classes = walletStyles();
  const dataProvider = useDataProvider();
  const [mutate] = useMutation();

  const userRef = useRef(user);

  const save = useCallback(
    (values) => {
      const errors: any = {};
      const { ProfileName, ProfileId } = values;
      if (!ProfileName) errors.ProfileName = "ra.validation.required";
      if (!ProfileId) errors.ProfileId = "ra.validation.required";
      if (errors.ProfileName || errors.ProfileId) return errors;

      const exists = userRef.current.Organizations.find(
        (o: any) => o.ProfileId === ProfileId
      );
      if (exists) {
        errors.ProfileId = "pos.profile.already_exists";
        return errors;
      }

      return dataProvider
        .getOne(environment("profiles"), {
          id: ProfileId,
        })
        .then(async () => {
          const organizations = [...userRef.current.Organizations];
          organizations.push({ Name: ProfileName, ProfileId: ProfileId });
          try {
            await mutate(
              {
                type: "update",
                resource: environment("profiles"),
                payload: {
                  id: user.ActiveProfile,
                  data: {
                    ActiveProfile: ProfileId,
                    Organizations: organizations,
                  },
                },
              },
              {
                onSuccess: ({ data }) => {
                  const updatedUser: UserDoc = {
                    ...userRef.current,
                    ActiveProfile: data.ActiveProfile,
                    Organizations: data.Organizations,
                  };
                  dispatch(updateUser(updatedUser));
                  notify("pos.profile.switching", { type: "success" });
                  redirectTo("/portfolio");
                },
                returnPromise: true,
              }
            );
          } catch (error: any) {
            errors.ProfileName = "pos.app.save_failed";
            errors.ProfileId = "pos.app.save_failed";
            return errors;
          }
        })
        .catch((err) => {
          if (err.status === 200) {
            errors.ProfileId = "pos.profile.not_found";
            return errors;
          } else {
            errors.ProfileId = "pos.profile.check_failed";
            return errors;
          }
        });
    },
    [mutate, dataProvider]
  );

  const SaveBar = (props: ToolbarProps) => (
    <Toolbar {...props}>
      <SaveButton disabled={props.pristine} />
    </Toolbar>
  );

  return (
    <div style={{ marginTop: 16 }}>
      <Edit
        {...props}
        basePath={`/${environment("profiles")}`}
        resource={`${environment("profiles")}`}
        id={user.id}
        mutationMode="pessimistic"
      >
        <SimpleForm
          mutationMode="pessimistic"
          toolbar={<SaveBar />}
          save={save}
        >
          <SanitizedGrid
            container
            spacing={2}
            className={classes.formContainer}
          >
            <Grid item xs={12} className={classes.formRow}>
              <TextInput
                source="ProfileName"
                className={classes.inputField}
                margin="normal"
                label="Name"
              />
              <TextInput
                source="ProfileId"
                className={classes.inputField}
                margin="normal"
                label="Portfolio Key"
              />
            </Grid>
          </SanitizedGrid>
        </SimpleForm>
      </Edit>
    </div>
  );
};

export default ProfilesAdd;
