interface CoinInfo {
  [key: string]: {
    color: string
  }
}

const coinInfo: CoinInfo = {
  '1INCH': {
    color: '#5412E2',
  },
  ASTR: {
    color: '#5412E2',
  },
  AAVE: {
    color: '#AA4A94',
  },
  ACA: {
    color: '#FE4A3C',
  },
  ACNT: {
    color: '#8F12FC',
  },
  ADA: {
    color: '#0033AD',
  },
  AGLET: {
    color: '#8F12FC',
  },
  ALGO: {
    color: '#010000', // Black
  },
  APT: {
    color: '#010000',
  },
  ARCC: {
    color: '#6835C2',
  },
  ATLAS: {
    color: '#181C1C', // Black
  },
  ATOM: {
    color: '#2E3148',
  },
  AVAX: {
    color: '#E84142',
  },
  AAVAX: {
    color: '#E84142',
  },
  AXS: {
    color: '#0055D5',
  },
  ARCD: {
    color: '#1C07E7',
  },
  BCH: {
    color: '#0AC18E',
  },
  BNB: {
    color: '#F3BA2F',
  },
  BNT: {
    color: '#0FBCD5',
  },
  BNTX: {
    color: '#0FBCD5',
  },
  BSN: {
    color: '#2C79F2',
  },
  BTC: {
    color: '#F79317',
  },
  BLOKZ: {
    color: '#00CB8C',
  },
  BUSD: {
    color: '#F0B90B',
  },
  CAKE: {
    color: '#47CFD8',
  },
  CBSN: {
    color: '#2C79F2',
  },
  CEEK: {
    color: '#0230F3',
  },
  CERE: {
    color: '#B70F93',
  },
  CRAFT: {
    color: '#55F217',
  },
  CRO: {
    color: '#103F68',
  },
  DAI: {
    color: '#F5AC37',
  },
  DES: {
    color: '#FAE231',
  },
  DODO: {
    color: '#1B1B15', // Black (change to yellow)
  },
  DOGE: {
    color: '#BA9F33',
  },
  DOT: {
    color: '#E6007A',
  },
  DPR: {
    color: '#56B8E5',
  },
  DRIP: {
    color: '#EA0001',
  },
  DRX: {
    color: '#F3EC2B',
  },
  EGLD: {
    color: '#0D022B', // Black
  },
  EOS: {
    color: '#33427D',
  },
  ENTRP: {
    color: '#DD1438',
  },
  EPIX: {
    color: '#0D093C',
  },
  EST: {
    color: '#0A090B', // Black
  },
  ETH: {
    color: '#4C8AE2',
  },
  EQ: {
    color: '#251F55',
  },
  FIL: {
    color: '#0090FF',
  },
  FIO: {
    color: '#3AB4E5',
  },
  FLOW: {
    color: '#00EF8B',
  },
  FRESH: {
    color: '#000000', // Black
  },
  FTM: {
    color: '#13B5EC',
  },
  FTT: {
    color: '#02A6C2',
  },
  GALA: {
    color: '#0E0D0C', // Black
  },
  GARI: {
    color: '#F1663D',
  },
  GDT: {
    color: '#041A29',
  },
  GPS: {
    color: '#01E6AE',
  },
  GG: {
    color: '#01E6AE',
  },
  GRT: {
    color: '#6747ED',
  },
  HBAR: {
    color: '#000000', // Black
  },
  HHOG: {
    color: '#0AB395',
  },
  HMND: {
    color: '#FF100D',
  },
  HNT: {
    color: '#38A2FF',
  },
  MNRY: {
    color: '#2C393C',
  },
  NST: {
    color: '#055E71',
  },
  ICP: {
    color: '#121110', // Black
  },
  INK: {
    color: '#1961F5',
  },
  JET: {
    color: '#59CBD0',
  },
  KAR: {
    color: '#E53E3E',
  },
  KBT: {
    color: '#0169ED',
  },
  KLAY: {
    color: '#4F473B',
  },
  LEO: {
    color: '#F07B2D',
  },
  LINK: {
    color: '#2A5ADA',
  },
  LRC: {
    color: '#1C60FF',
  },
  LTC: {
    color: '#345D9D',
  },
  LUNA: {
    color: '#172852',
  },
  LUNC: {
    color: '#172852',
  },
  MANA: {
    color: '#FF3355',
  },
  MATIC: {
    color: '#8247E5',
  },
  MINA: {
    color: '#000000', // Black
  },
  MIOTA: {
    color: '#000000',
  },
  MOC: {
    color: '#04364F',
  },
  MOJ: {
    color: '#5306A0',
  },
  MMG: {
    color: '#F3C329',
  },
  MWM: {
    color: '#E2E205',
  },
  NEAR: {
    color: '#000000', // Black
  },
  NIBI: {
    color: '#AF8CCD',
  },
  NODL: {
    color: '#18AE82',
  },
  OAS: {
    color: '#1F746A',
  },
  ORE: {
    color: '#1D1D1D',
  },
  OST: {
    color: '#1E222A',
  },
  PEG: {
    color: '#0673BA',
  },
  PNTM: {
    color: '#B231A5',
  },
  PONT: {
    color: '#B231A5',
  },
  PODS: {
    color: '#C91C4D',
  },
  POKT: {
    color: '#1D8AED',
  },
  POLIS: {
    color: '#34F6F7',
  },
  PORTAL: {
    color: '#00CAB5',
  },
  PUSD: {
    color: '#1B3B5F',
  },
  PYTH: {
    color: '#8246FA',
  },
  PXL: {
    color: '#F418F8',
  },
  QUILL: {
    color: '#1961F5',
  },
  RADAR: {
    color: '#0366F4',
  },
  RARI: {
    color: '#EF6EE2',
  },
  RATIO: {
    color: '#ED74D0',
  },
  REACH: {
    color: '#000000', // Black
  },
  REGEN: {
    color: '#52B675',
  },
  RISE: {
    color: '#FF0DEE',
  },
  RGU: {
    color: '#06ACFF',
  },
  RUON: {
    color: '#00C9C6',
  },
  SDELY: {
    color: '#FAE231',
  },
  SAND: {
    color: '#0090FF',
  },
  SBR: {
    color: '#6767FB',
  },
  SCRT: {
    color: '#141919', // Black
  },
  SGPS: {
    color: '#01E6AE',
  },
  SHIB: {
    color: '#F00500',
  },
  SMWM: {
    color: '#E2E205',
  },
  SOL: {
    color: '#000001', // Black
  },
  STATE: {
    color: '#5197D9',
  },
  SWITCH: {
    color: '#D32D79',
  },
  SWNG: {
    color: '#22BA79',
  },
  SUPRA: {
    color: 'rgb(221,20,56)',
  },
  THETA: {
    color: '#1B1F2B',
  },
  TRX: {
    color: '#FF060A',
  },
  UFIT: {
    color: '#C0272E',
  },
  UNI: {
    color: '#FF007A',
  },
  USDC: {
    color: '#2775CA',
  },
  USDT: {
    color: '#50AF95',
  },
  VET: {
    color: '#18B0ED',
  },
  VGX: {
    color: '#504EE0',
  },
  VYGVF: {
    color: '#504EE0',
  },
  WTHR: {
    color: '#11A7D9',
  },
  XAR: {
    color: '#066DC2',
  },
  XLM: {
    color: '#0E121B', // Black
  },
  XMR: {
    color: '#F26822',
  },
  XRP: {
    color: '#23292F', // Black
  },
  XTZ: {
    color: '#2C7DF7',
  },
  ZBC: {
    color: '945DB9',
  },
  ZED: {
    color: 'rgb(63,167,38)',
  },
  ZKL: {
    color: '#03D498',
  },
}

// Temp workaround to color issue
export const blackCoins = [
  'XZT',
  'XLM',
  'SOL',
  'SCRT',
  'REACH',
  'NEAR',
  'ICP',
  'HBAR',
  'GALA',
  'FRESH',
  'EGLD',
  'DODO',
  'ALGO',
  'ATLAS',
  'THETA',
  'NEAR',
  'XRP',
  'LUNA',
  'LUNC',
  'ATOM',
  'APT',
  'MINA',
]

// Alternative colors for manual/unprocessed tokens.
export const republicBlues = [
  '#0AA2F2',
  '#08AEF1',
  '#07BBEF',
  '#05C8EE',
  '#03D5EC',
  '#02E2EB',
  '#00EFE9',
  '#1261FA',
  '#106EF8',
  '#0F7BF7',
  '#0D88F5',
  '#0B95F4',
]

// Alternative blacks for tokens with an icon too dark for dark mode.
export const alternativeBlacks = [
  '#696969',
  '#707070',
  '#787878',
  '#808080',
  '#888888',
  '#909090',
  '#989898',
  '#A0A0A0',
  '#A8A8A8',
  '#A9A9A9',
  '#B0B0B0',
  '#B8B8B8',
  '#BEBEBE',
  '#C0C0C0',
  '#C8C8C8',
  '#D0D0D0',
  '#D3D3D3',
]

export const tagMap: { [key: string]: { name: string; type: string } } = {
  defi: {
    name: 'Defi',
    type: 'Utility',
  },
  dao: {
    name: 'DAOs',
    type: 'Utility',
  },
  gaming: {
    name: 'Gaming',
    type: 'Utility',
  },
  'lending-borowing': {
    name: 'Finance',
    type: 'Utility',
  },
  amm: {
    name: 'AMMs',
    type: 'Utility',
  },
  'collectibles-nfts': {
    name: 'NFTs',
    type: 'Utility',
  },
  'smart-contracts': {
    name: 'Smart Contracts',
    type: 'Utility',
  },
  platform: {
    name: 'Platforms',
    type: 'Utility',
  },
  payments: {
    name: 'Payments',
    type: 'Utility',
  },
  derivatives: {
    name: 'Derivatives',
    type: 'Utility',
  },
  oracles: {
    name: 'Oracles',
    type: 'Utility',
  },
  marketplace: {
    name: 'Marketplaces',
    type: 'Utility',
  },
  analytics: {
    name: 'Analytics',
    type: 'Utility',
  },
  scaling: {
    name: 'Scaling',
    type: 'Utility',
  },
  privacy: {
    name: 'Privacy',
    type: 'Utility',
  },
  mineable: {
    name: 'Mining',
    type: 'Utility',
  },
  'ai-big-data': {
    name: 'AI/Big Data',
    type: 'Utility',
  },
  'play-to-earn': {
    name: 'Play to Earn',
    type: 'Utility',
  },
  'centralized-exchange': {
    name: 'Central Exchanges',
    type: 'Utility',
  },
  'content-creation': {
    name: 'Content Creation',
    type: 'Utility',
  },
  stablecoin: {
    name: 'Stable Coins',
    type: 'Utility',
  },
  metaverse: {
    name: 'Metaverse',
    type: 'Utility',
  },
  'arbitrum-ecosytem': {
    name: 'Arbitrum Ecosystem',
    type: 'Ecosystem',
  },
  'binance-smart-chain': {
    name: 'Binance Ecosystem',
    type: 'Ecosystem',
  },
  'solana-ecosystem': {
    name: 'Solana Ecosystem',
    type: 'Ecosystem',
  },
  'cosmos-ecosystem': {
    name: 'Cosmos Ecosystem',
    type: 'Ecosystem',
  },
  'moonriver-ecosystem': {
    name: 'Moonriver Ecosystem',
    type: 'Ecosystem',
  },
  'polkadot-ecosystem': {
    name: 'Polkadot Ecosystem',
    type: 'Ecosystem',
  },
  'ethereum-ecosystem': {
    name: 'Ethereum Ecosystem',
    type: 'Ecosystem',
  },
  'cardano-ecosystem': {
    name: 'Cardano Ecosystem',
    type: 'Ecosystem',
  },
  'terra-ecosystem': {
    name: 'Terra Ecosystem',
    type: 'Ecosystem',
  },
  'coinbase-ventures-portfolio': {
    name: 'Coinbase Ventures',
    type: 'Portfolio',
  },
  'alameda-research-portfolio': {
    name: 'Alameda Research',
    type: 'Portfolio',
  },
  'pantera-capital-portfolio': {
    name: 'Pantera Capital',
    type: 'Portfolio',
  },
  'parafi-capital': {
    name: 'ParaFi Capital',
    type: 'Portfolio',
  },
  'paradigm-portfolio': {
    name: 'Paradigm',
    type: 'Portfolio',
  },
  'fenbushi-capital-portfolio': {
    name: 'Fenbushi Capital',
    type: 'Portfolio',
  },
  'hashkey-capital-portfolio': {
    name: 'HashKey Capital',
    type: 'Portfolio',
  },
  'electric-capital-portfolio': {
    name: 'Electric Capital',
    type: 'Portfolio',
  },
  'galaxy-digital-portfolio': {
    name: 'Galaxy Digital',
    type: 'Portfolio',
  },
  'a16z-portfolio': {
    name: 'a16z Capital',
    type: 'Portfolio',
  },
  'polychain-capital-portfolio': {
    name: 'Polychain Capital',
    type: 'Portfolio',
  },
  'winklevoss-capital-portfolio': {
    name: 'Winklevoss Capital',
    type: 'Portfolio',
  },
}

export { coinInfo }
