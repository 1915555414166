import { useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardContent,
  Button,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import {
  useTranslate,
  useMutation,
  useLocale,
  useSetLocale,
  useNotify,
  useRedirect,
  Title,
  useDataProvider,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { changeTheme } from "../../persistence/actions";
import { AppState, Integration } from "../types";
import { useAuthenticated } from "react-admin";
import { removeProfile } from "../../components/wallets/methods";
import { environment } from "../../components/common";
import { Loading } from "..";

/**
 * @summary Renders the settings list.
 *
 * TODO: This page needs proper styling.
 *
 * @returns {JSX.Element}
 */
const Configuration = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [working, setWorking] = useState<boolean>(false);
  const [integrations, setIntegrations] = useState<Integration[]>();
  const translate = useTranslate();
  const locale = useLocale();
  const setLocale = useSetLocale();
  const classes = useStyles();
  const theme = useSelector((state: AppState) => state.theme);
  const user = useSelector((state: any) => state.user);
  const notify = useNotify();
  const dispatch = useDispatch();
  const redirectTo = useRedirect();
  const dataProvider = useDataProvider();
  const [mutate] = useMutation();
  useAuthenticated();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  useEffect(() => {
    dataProvider
      .getList<Integration>(
        `${environment("profiles")}&${user.ActiveProfile}&integrations`,
        {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: "id", order: "DESC" },
          filter: {},
        }
      )
      .then(({ data }) => {
        setIntegrations(data);
        setLoading(false);
      });
  }, []);

  const leaveShared = useCallback(
    (profileId) => {
      setWorking(true);
      removeProfile(user, profileId, mutate, dispatch)
        .then(() => {
          setWorking(false);
          notify("pos.profile.removed", { type: "success" });
        })
        .catch(() => {
          setWorking(false);
          notify("pos.profile.delete_failed", { type: "error" });
        });
    },
    [user, mutate, dispatch]
  );

  if (loading) return <Loading />;

  return (
    <Card className={classes.card}>
      <Title title={translate("pos.configuration")} />
      <CardContent className={classes.cardContent}>
        <div className={classes.label}>{translate("pos.share_keys")}</div>
        <table style={{ width: "100%", maxWidth: 550, paddingTop: 18 }}>
          <tbody>
            <tr>
              <td style={{ paddingBottom: 5 }}>Personal</td>
              {!isXSmall ? (
                <td style={{ paddingBottom: 5, maxWidth: 220 }}>{user.id}</td>
              ) : (
                <td>
                  <Button
                    size="small"
                    onClick={() => {}}
                    color="primary"
                    disabled={true}
                  >
                    {translate("pos.copy")}
                  </Button>
                </td>
              )}
            </tr>
            {user.Organizations &&
              user.Organizations.map(
                (o: { Name: string; ProfileId: string }) => (
                  <tr key={o.ProfileId}>
                    <td style={{ paddingBottom: 5 }}>{o.Name}</td>
                    {!isXSmall ? (
                      <td style={{ paddingBottom: 5, maxWidth: 220 }}>
                        {o.ProfileId}
                      </td>
                    ) : (
                      <td>
                        <Button
                          size="small"
                          onClick={() => {}}
                          color="primary"
                          disabled={true}
                        >
                          {translate("pos.copy")}
                        </Button>
                      </td>
                    )}
                    <td style={{ paddingBottom: 1 }}>
                      <Button
                        size="small"
                        onClick={() => leaveShared(o.ProfileId)}
                        disabled={working}
                        color="primary"
                      >
                        {translate("pos.remove")}
                      </Button>
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </table>
        <Button
          size="small"
          onClick={() => redirectTo("add-portfolio/edit")}
          color="primary"
          style={{ marginTop: 10, marginRight: 5 }}
        >
          {translate("pos.add_key")}
        </Button>
        <Button
          size="small"
          onClick={() => redirectTo(`${environment("profiles")}/create`)}
          color="primary"
          style={{ marginTop: 10 }}
        >
          {translate("pos.create_new")}
        </Button>
      </CardContent>
      <CardContent className={classes.cardContent}>
        <div className={classes.label}>{translate("pos.integration")}</div>
        <div>
          <table style={{ width: "100%", maxWidth: 400, paddingTop: 15 }}>
            <tbody>
              {integrations && integrations.length > 0 ? (
                integrations.map((integration: Integration) => (
                  <tr key={integration.id}>
                    <td>{integration.Name}</td>
                    {!isXSmall && <td>{integration.Type}</td>}
                    <td>
                      <Button
                        size="small"
                        onClick={() =>
                          redirectTo(
                            `${environment("profiles")}&${
                              user.ActiveProfile
                            }&integrations/${integration.id}`
                          )
                        }
                        color="primary"
                      >
                        {translate("pos.edit")}
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>
                    <i>None</i>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Button
            size="small"
            onClick={() =>
              redirectTo(
                `${environment("profiles")}&${
                  user.ActiveProfile
                }&integrations/create`
              )
            }
            color="primary"
            style={{ marginTop: 10 }}
          >
            {translate("pos.add_new")}
          </Button>
        </div>
      </CardContent>
      <CardContent className={classes.cardContent}>
        <div className={classes.label}>{translate("pos.theme.name")}</div>
        <Button
          variant="contained"
          className={classes.button}
          color={theme === "light" ? "primary" : "default"}
          onClick={() => dispatch(changeTheme("light"))}
        >
          {translate("pos.theme.light")}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color={theme === "dark" ? "primary" : "default"}
          onClick={() => dispatch(changeTheme("dark"))}
        >
          {translate("pos.theme.dark")}
        </Button>
      </CardContent>
      <CardContent className={classes.cardContent}>
        <div className={classes.label} style={{ paddingTop: 20 }}>
          {translate("pos.language")}
        </div>
        <Button
          variant="contained"
          className={classes.button}
          color={locale === "en" ? "primary" : "default"}
          onClick={() => {
            localStorage.setItem("locale", "en");
            setLocale("en");
          }}
        >
          en
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color={locale === "fr" ? "primary" : "default"}
          onClick={() => {
            localStorage.setItem("locale", "fr");
            setLocale("fr");
          }}
        >
          fr
        </Button>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    width: "7em",
    display: "inline-block",
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: { width: "5em" },
  },
  button: {
    margin: "0 1em 0 1em",
    [theme.breakpoints.down("xs")]: { margin: "0 0px 0 10px" },
  },
  card: {
    marginTop: "calc(1em + 16px)",
    paddingLeft: 25,
    paddingTop: 10,
    [theme.breakpoints.down("xs")]: { paddingLeft: 5, paddingRight: 5 },
  },
  cardContent: {
    [theme.breakpoints.down("xs")]: { paddingLeft: 10, paddingRight: 10 },
  },
}));

export default Configuration;
