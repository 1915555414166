import { Button, ToolbarProps } from '@material-ui/core'
import {
  Edit,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  Toolbar,
  SaveButton,
  ReduxState,
  AutocompleteArrayInput,
} from 'react-admin'
import { CreateProps } from 'react-admin'
import { makeStyles, Grid } from '@material-ui/core'
import { WalletDeleteButton } from '../../components/wallets'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Loading } from '..'
import { useState } from 'react'
import { SanitizedGrid } from '../../components/form'
import walletStyles from '../../components/wallets/walletStyles'
import { environment } from '../../components/common'

/**
 * @summary The primary component of the wallet update view
 *
 * TODO:  Will need to be rewritten for the new data structure
 *
 * @param props
 * @returns {JSX.Element}
 */
const WalletEdit = (props: CreateProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const parentLoading = useSelector<ReduxState>(state => state.admin.loading > 0)
  const user = useSelector((state: any) => state.user)
  const classes = walletStyles()
  const history = useHistory()

  if (parentLoading || loading) return <Loading />

  return (
    <Edit title='Edit Wallet' {...props} actions={<TopBar />} undoable={false} basePath='/wallets'>
      <SimpleForm undoable={false} toolbar={<BottomBar setLoading={setLoading} />}>
        <SanitizedGrid container spacing={2} className={classes.formContainer}>
          <Grid item xs={12} className={classes.formRow}>
            <TextInput source='Alias' label='Alias' className={classes.inputField} />
            <TextInput
              source='Name'
              label='Currency'
              className={classes.inputField}
              disabled={true}
            />
            <TextInput
              source='Network'
              label='Network'
              className={classes.inputField}
              disabled={true}
            />
            <TextInput
              source='Address'
              label='Address'
              className={classes.inputField}
              disabled={true}
            />
            {user.Tags && user.Tags.length > 0 ? (
              <>
                <AutocompleteArrayInput
                  source='Tags'
                  choices={
                    user.Tags
                      ? user.Tags.map((tag: string, i: number) => ({
                          id: tag,
                          name: tag,
                        }))
                      : []
                  }
                />
                <Button
                  color='primary'
                  onClick={() => history.push(`/${environment('profiles')}/edit`)}
                >
                  Manage Tags
                </Button>
              </>
            ) : (
              <>
                <span>
                  You don't have any strategy tags yet.
                  <Button
                    color='primary'
                    onClick={() => history.push(`/${environment('profiles')}/edit`)}
                    style={{ marginLeft: 15 }}
                    size='small'
                  >
                    Create Tags
                  </Button>
                </span>
              </>
            )}
          </Grid>
        </SanitizedGrid>
      </SimpleForm>
    </Edit>
  )
}

const TopBar = () => (
  <TopToolbar>
    <ListButton basePath='/wallets' />
  </TopToolbar>
)

const BottomBar = ({ setLoading, ...rest }: EditBottomBar) => {
  const user = useSelector((state: any) => state.user)
  const { record } = rest
  const docRef = `${record.Symbol}/${record.Network}/${record.Address}`
  return (
    <Toolbar {...rest} classes={useStyles()}>
      <SaveButton />
      <WalletDeleteButton user={user} docRef={docRef} record={record} setLoading={setLoading} />
    </Toolbar>
  )
}

interface EditBottomBar extends ToolbarProps {
  record?: any
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

export default WalletEdit
