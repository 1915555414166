import { TextFieldProps, useRecordContext } from "react-admin";
import { useMediaQuery, Typography, Theme } from "@material-ui/core";
import { tableStyles } from ".";

/**
 * @summary Position the network icon and name inline
 *
 * TODO:  Consider another way to manage image icons as this could be error prone
 *
 * @returns {JSX.Element}
 */
const NetworkName = ({ source, label }: TextFieldProps) => {
  const record = useRecordContext();
  const classes = tableStyles();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  // Temp fix for case change
  const symbol = record["symbol"]
    ? record["symbol"]
    : record["Symbol"]
    ? record["Symbol"]
    : "N/K";

  return (
    <div
      className={classes.inlineText}
      // @ts-ignore
      label={label} // required for react-admin
    >
      <div className={classes.icon}>
        <img src={`/images/${symbol.toLowerCase()}.svg`} alt={""} />
      </div>
      <div className={classes.networkNameContainer}>
        <Typography variant="body2" className={classes.networkName}>
          <span>
            <b>{record && source && record[source]}</b> {!isXSmall && symbol}
          </span>
        </Typography>
      </div>
    </div>
  );
};

export default NetworkName;
