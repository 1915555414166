import { log } from '../../misc'
import { validForMins, dbProxyURL } from '../../../components/common'
import * as ra from '../../misc/react-admin-models'
import { authProvider } from '../..'
import { Identifier } from 'react-admin'

/**
 * @summary Custom dataProvider method to fetch balance history.
 *
 * @param resourceName
 * @param params
 * @returns
 */
export async function GetPriceList<T extends ra.Record>(
  resourceName: string,
  params: ra.GetListParams,
): Promise<ra.GetListResult<T>> {
  log('GetList', { resourceName, params })

  const { symbols, startDate, endDate } = params.filter

  const url = dbProxyURL('pricehistory', false)
  const symbolsQuery = symbols.map((symbol: string) => `symbols=${symbol}`).join('&')
  const startDateStr = startDate.toISOString().substring(0, 10)
  const endDataStr = endDate.toISOString().substring(0, 10)
  const query = `?${symbolsQuery}&startDate=${startDateStr}&endDate=${endDataStr}`

  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${await authProvider.getJWTToken()}`,
    },
  }

  const res = await fetch(`${url}${query}`, options)
  const json = await res.json()

  return {
    ...json,
    total: Object.keys(json.data).length,
    validUntil: validForMins(30),
  }
}

/**
 * @summary Custom dataProvider method to fetch balance history.
 *
 * @param resourceName
 * @param params
 * @returns {Object}
 */
export async function GetBalanceList<T extends ra.Record>(
  resourceName: string,
  params: ra.GetListParams,
): Promise<ra.GetListResult<T>> {
  log('GetList', { resourceName, params })

  const { profileId, startDate, endDate } = params.filter

  const url = dbProxyURL('balancehistory', false)
  const startDateStr = startDate.toISOString().substring(0, 10)
  const endDateStr = endDate.toISOString().substring(0, 10)
  const query = `?profileId=${profileId}&startDate=${startDateStr}&endDate=${endDateStr}`

  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${await authProvider.getJWTToken()}`,
    },
  }

  const res = await fetch(`${url}${query}`, options)
  const json = await res.json()

  return {
    ...json,
    total: Object.keys(json.data).length,
    validUntil: validForMins(5),
  }
}

/**
 * @summary Custom dataProvider method to fetch a users wallets.
 *
 * @param resourceName
 * @param params
 * @returns {Object}
 */
export async function GetWalletList<T extends ra.Record>(
  resourceName: string,
  params: ra.GetListParams,
): Promise<ra.GetListResult<T>> {
  log('GetList', { resourceName, params })

  const { profileId } = params.filter
  const url = dbProxyURL('wallets', false)
  const query = `?profileId=${profileId}`

  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${await authProvider.getJWTToken()}`,
    },
  }

  const res = await fetch(`${url}${query}`, options)
  const json = await res.json()

  const data: any[] = []

  json.data.map((grouped: any) =>
    Object.keys(grouped).forEach((key: string) => {
      grouped[key].forEach((wallet: any) => {
        wallet.Path && data.push({ id: wallet.Path.split('/data/')[1], ...wallet })
      })
    }),
  )

  return {
    data,
    total: Object.keys(json.data).length,
    validUntil: validForMins(0),
  }
}

/**
 * @summary Request market history from the api and parse to react-admin.
 *
 * @param resourceName
 * @param params
 * @returns {Object}
 */
export async function GetMarketData<T extends ra.Record>(
  resourceName: string,
  params: ra.GetListParams,
): Promise<ra.GetListResult<T>> {
  log('GetList', { resourceName, params })

  const { symbols, startDate, endDate } = params.filter

  const url = dbProxyURL('marketdata', false)
  const symbolsQuery = symbols.map((symbol: string) => `symbols=${symbol}`).join('&')
  const startDateStr = startDate.toISOString().substring(0, 10)
  const endDataStr = endDate.toISOString().substring(0, 10)
  const query = `?${symbolsQuery}&start=${startDateStr}&end=${endDataStr}`

  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${await authProvider.getJWTToken()}`,
    },
  }

  const res = await fetch(`${url}${query}`, options)
  const json = await res.json()
  const data: any[] = Object.keys(json.data).map((key: Identifier) => {
    return {
      id: key,
      data: json.data[key],
    }
  })

  return {
    data: data,
    total: Object.keys(json.data).length,
    validUntil: validForMins(0),
  }
}

/**
 * @summary Request quote history from the api and parse to react-admin.
 *
 * @param resourceName
 * @param params
 * @returns {Object}
 */
export async function GetQuoteList<T extends ra.Record>(
  resourceName: string,
  params: ra.GetListParams,
): Promise<ra.GetListResult<T>> {
  log('GetList', { resourceName, params })

  const { symbols, startDate, endDate } = params.filter

  const url = dbProxyURL('ohlc', false)
  const symbolsQuery = symbols.map((symbol: string) => `symbols=${symbol}`).join('&')
  const startDateStr = startDate.toISOString().substring(0, 10)
  const endDataStr = endDate.toISOString().substring(0, 10)
  const query = `?${symbolsQuery}&start=${startDateStr}&end=${endDataStr}&interval=daily`

  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${await authProvider.getJWTToken()}`,
    },
  }

  const res = await fetch(`${url}${query}`, options)
  const json = await res.json()
  const data: any[] = json.data.map((currency: any) => {
    const symbol = Object.keys(currency)[0]
    return symbol
      ? {
          id: symbol,
          data: currency[symbol].map((d: any) => ({ ...d, id: d.Timestamp })),
        }
      : {}
  })

  return {
    data: data,
    total: data.length,
    validUntil: validForMins(120),
  }
}

/**
 * @summary Request quote history from the api and parse to react-admin.
 *
 * @param resourceName
 * @param params
 * @returns {Object}
 */
export async function GetQuoteListHourly<T extends ra.Record>(
  resourceName: string,
  params: ra.GetListParams,
): Promise<ra.GetListResult<T>> {
  log('GetList', { resourceName, params })

  const { symbols, startDate, endDate } = params.filter

  const url = dbProxyURL('ohlc', false)
  const symbolsQuery = symbols.map((symbol: string) => `symbols=${symbol}`).join('&')
  const startDateStr = startDate.toISOString().substring(0, 10)
  const endDataStr = endDate.toISOString().substring(0, 10)
  const query = `?${symbolsQuery}&start=${startDateStr}&end=${endDataStr}&interval=hourly`

  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${await authProvider.getJWTToken()}`,
    },
  }

  const res = await fetch(`${url}${query}`, options)
  const json = await res.json()
  const data: any[] = json.data.map((currency: any) => {
    const symbol = Object.keys(currency)[0]
    return symbol
      ? {
          id: symbol,
          data: currency[symbol].map((d: any) => ({ ...d, id: d.Timestamp })),
        }
      : {}
  })

  return {
    data: data,
    total: data.length,
    validUntil: validForMins(60),
  }
}

/**
 * @summary Request quote history from the api and parse to react-admin.
 *
 * @param resourceName
 * @param params
 * @returns {Object}
 */
export async function GetSymbolSnapshot<T extends ra.Record>(
  resourceName: string,
  params: ra.GetListParams,
): Promise<ra.GetListResult<T>> {
  log('GetList', { resourceName, params })

  const url = dbProxyURL('symbolsnapshot', false)

  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${await authProvider.getJWTToken()}`,
    },
  }

  const res = await fetch(url, options)
  const json = await res.json()
  const data: any[] = json.map((snapshot: any) => ({
    ...snapshot,
    id: snapshot.Id,
    Symbol: snapshot.Id,
  }))

  return {
    data: data,
    total: data.length,
    validUntil: validForMins(5),
  }
}
