import { createTheme, Theme } from "@material-ui/core";

const theme: Theme = createTheme();

const fonts = {
  typography: {
    fontFamily: [
      "IBM Plex Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    [theme.breakpoints.up("xl")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: 15,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
    },
    // [theme.breakpoints.down("xs")]: {
    //   fontSize: 12,
    // },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: "IBM Plex Sans";
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
};

const themeOverrides = {
  MuiTypography: {
    body1: {
      fontSize: "1rem",
    },
    body2: {
      [theme.breakpoints.down("lg")]: {
        fontSize: 15,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 13,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 13,
      },
    },
  },
  MuiTableCell: {
    sizeSmall: {
      fontWeight: 700,
      [theme.breakpoints.down("md")]: {
        paddingLeft: 5,
        paddingRight: 5,
        fontSize: 13,
      },
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 5,
        paddingRight: 5,
        "& .MuiTypography-body2": {
          fontSize: 12,
        },
      },
    },
  },
  MuiTableSortLabel: {
    iconDirectionDesc: {
      [theme.breakpoints.down("xs")]: {
        fontSize: 11,
      },
    },
  },
  RaListToolbar: {
    toolbar: {
      [theme.breakpoints.down("xs")]: {
        backgroundColor: "transparent!important",
        paddingLeft: 8,
        paddingBottom: 5,
      },
    },
    actions: {
      [theme.breakpoints.down("xs")]: {
        backgroundColor: "transparent!important",
      },
    },
  },
  RaTopToolbar: {
    root: {
      [theme.breakpoints.down("xs")]: {
        backgroundColor: "transparent!important",
        paddingLeft: 8,
      },
    },
  },
  RaLayout: {
    content: {
      overflow: "hidden",
      minHeight: "100%",
      [theme.breakpoints.up("md")]: {
        marginLeft: 10,
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: 5,
        marginRight: 5,
        marginBottom: 15,
      },
    },
  },
  RaAutocompleteArrayInput: {
    container: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      flexBasis: "100%",
    },
  },
};

export const darkTheme = {
  palette: {
    primary: {
      main: "#157EFF",
    },
    secondary: {
      main: "#1560FA",
      contrastText: "#fff",
    },
    background: {
      default: "#0e1117",
      paper: "#161b22",
    },
    error: {
      main: "#f44336",
      light: "#e57373",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    success: {
      main: "#66bb6a",
      light: "#81c784",
      dark: "#388e3c",
      contrastText: "#fff",
    },
    type: "dark" as "dark", // Switching the dark mode on is a single property value change.
  },
  shape: {
    borderRadius: 10,
  },
  sidebar: {
    width: 200,
  },
  ...fonts,
  overrides: {
    MuiDataGrid: {
      root: {
        border: 0,
        [theme.breakpoints.down("sm")]: {
          fontSize: 13,
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: 13,
        },
        "& .MuiDataGrid-main": {
          backgroundColor: "#161b22",
          borderRadius: "8px 8px 5px 5px",
        },
        "&& .MuiGridPanel-root": {
          top: 0,
          right: " 0px!important",
          left: 500,
        },
        "& .MuiDataGrid-cell": {
          [theme.breakpoints.down("sm")]: {
            paddingLeft: 5,
            paddingRight: 5,
          },
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          // fontSize: "0.6rem"
        },
        "& .MuiDataGrid-cell:focus": {
          outline: "none",
        },
      },
    },
    MuiAppBar: {
      colorSecondary: {
        color: "#ffffffb3",
        backgroundColor: "#161b22",
      },
    },
    MuiToolbar: {
      dense: {
        height: "56px",
        minHeight: "56px",
      },
    },
    MuiButtonBase: {
      root: {
        "&:hover:active::after": {
          // recreate a static ripple color
          // use the currentColor to make it work both for outlined and contained buttons
          // but to dim the background without dimming the text,
          // put another element on top with a limited opacity
          content: '""',
          display: "block",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          right: 0,
          backgroundColor: "currentColor",
          opacity: 0.3,
          borderRadius: "inherit",
          color: "white",
        },
      },
    },
    MuiPickersDay: {
      root: {
        color: "white",
      },
    },
    MuiPaper: {
      root: {
        border: "none", // 1px solid #000",
        backgroundClip: "padding-box",
      },
    },
    MuiCardHeader: {
      content: {
        overflow: "hidden",
      },
    },
    ...themeOverrides,
  },
  props: {
    MuiButtonBase: {
      // disable ripple for perf reasons
      disableRipple: true,
    },
  },
};

export const lightTheme = {
  palette: {
    primary: {
      main: "#157EFF",
    },
    secondary: {
      light: "#5f5fc4",
      main: "#1560FA",
      dark: "#001064",
      contrastText: "#000",
    },
    background: {
      default: "#fcfcfe",
    },
    type: "light" as "light",
  },
  shape: {
    borderRadius: 10,
  },
  sidebar: {
    width: 200,
  },
  ...fonts,
  overrides: {
    RaMenuItemLink: {
      root: {
        borderLeft: "3px solid #fff",
      },
      active: {
        borderLeft: "3px solid #1560FA",
      },
    },
    MuiDataGrid: {
      root: {
        border: 0,
        [theme.breakpoints.down("sm")]: {
          fontSize: 14,
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: 13,
        },
        "& .MuiDataGrid-main": {
          backgroundColor: "#fff",
          borderRadius: "8px 8px 5px 5px",
          // border: "1px solid #e0e0e3",
        },
        "&& .MuiGridPanel-root": {
          top: 0,
          right: " 0px!important",
          left: 500,
        },
        "& .MuiDataGrid-cell": {
          [theme.breakpoints.down("sm")]: {
            paddingLeft: 5,
            paddingRight: 5,
          },
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          // fontSize: "0.6rem"
        },
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
      root: {
        border: "1px solid #e0e0e3",
        backgroundClip: "padding-box",
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: "#fff",
        color: "#1560FA",
        boxShadow: "none",
      },
    },
    MuiButtonBase: {
      root: {
        "&:hover:active::after": {
          // recreate a static ripple color
          // use the currentColor to make it work both for outlined and contained buttons
          // but to dim the background without dimming the text,
          // put another element on top with a limited opacity
          content: '""',
          display: "block",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          right: 0,
          backgroundColor: "currentColor",
          opacity: 0.3,
          borderRadius: "inherit",
        },
      },
    },
    MuiAppBar: {
      colorSecondary: {
        color: "#808080",
        backgroundColor: "#fff",
      },
    },
    MuiToolbar: {
      dense: {
        height: "56px",
        minHeight: "56px",
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: "#f5f5f5",
      },
      barColorPrimary: {
        backgroundColor: "#d7d7d7",
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        "&$disabled": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        border: "none",
      },
    },
    MuiCardHeader: {
      content: {
        overflow: "hidden",
      },
    },
    ...themeOverrides,
  },
  props: {
    MuiButtonBase: {
      // disable ripple for perf reasons
      disableRipple: true,
    },
    MuiPopover: {
      elevation: 1,
    },
  },
};
