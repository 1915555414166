import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteWithConfirmButton,
  ListProps,
  useTranslate,
  BooleanField,
  ArrayField,
  NumberField,
} from "react-admin";
import { tableStyles } from "../../../components/table";
import { TextArrayField } from "../../../components/layout";
import { environment } from "../../../components/common";

/**
 * @summary Renders a list of all symbols existing in the backend in table.
 *
 * @param props
 * @returns {JSX.Element}
 */
const SymbolList = (props: ListProps) => {
  const classes = tableStyles();
  const translate = useTranslate();
  return (
    <List
      {...props}
      sort={{ field: "name", order: "desc" }}
      bulkActionButtons={false}
    >
      <Datagrid isRowSelectable={() => false}>
        <TextField
          source="Name"
          headerClassName={classes.tableHeader}
          label={translate("pos.table.name")}
        />
        <TextField
          source="Symbol"
          headerClassName={classes.tableHeader}
          label={translate("pos.table.symbol")}
        />
        <TextField
          source="Description"
          label={translate("pos.table.description")}
          headerClassName={classes.tableHeader}
        />
        <TextField
          source="Id"
          label={translate("pos.table.id")}
          headerClassName={classes.tableHeader}
        />
        <BooleanField
          source="Manual"
          label={translate("pos.table.manual")}
          headerClassName={classes.tableHeader}
        />
        <TextArrayField
          source="Networks"
          label={translate("pos.table.networks")}
          headerClassName={classes.tableHeader}
        />
        <ArrayField
          source="TokenContracts"
          label={translate("pos.table.token_contracts")}
        >
          <Datagrid>
            <TextField
              source="Name"
              label={translate("pos.table.name")}
              headerClassName={classes.tableHeader}
            />
            <TextField
              source="Network"
              label={translate("pos.table.network")}
              headerClassName={classes.tableHeader}
            />
            <TextField
              source="ContractAddress"
              label={translate("pos.table.contract_address")}
              headerClassName={classes.tableHeader}
            />
            <NumberField
              source="Decimals"
              label={translate("pos.table.decimals")}
              headerClassName={classes.tableHeader}
            />
          </Datagrid>
        </ArrayField>
        <TextField
          source="Source"
          label={translate("pos.table.source")}
          headerClassName={classes.tableHeader}
        />

        <EditButton basePath={`${environment("coin-config")}`} />
        <DeleteWithConfirmButton
          basePath={`/${environment("coin-config")}`}
          confirmTitle={translate("pos.table.delete_price_source_name")}
        />
      </Datagrid>
    </List>
  );
};

export default SymbolList;
