import {
  Wallet,
  PriceHistory,
  CoinConfig,
  Transaction,
  Quote,
  EquityCurves,
  BalanceRecord,
  Distribution,
} from '../../views/types'
import { coinInfo } from '../../Resources'
import { sortArray } from '../../firebase/misc'
import { alternativeBlacks, republicBlues, blackCoins } from '../../Resources'

/**
 * @summary Compares two timestamps a returns true if they are the same date
 *
 * @param d1
 * @param d2
 * @returns {Boolean}
 */
export const isSameDay = (d1: Date, d2: Date) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  )
}

/**
 * @summary Compares two timestamps a returns true if d1 is an earlier date than d2
 *
 * @param d1
 * @param d2
 * @returns {Boolean}
 */
export const isBefore = (d1: Date, d2: Date) => {
  return (
    d1.getFullYear() < d2.getFullYear() ||
    (d1.getFullYear() === d2.getFullYear() && d1.getMonth() < d2.getMonth()) ||
    (d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() < d2.getDate())
  )
}

function getDifferenceInDays(date1: any, date2: any) {
  const diffInMs = Math.abs(date2 - date1)
  return diffInMs / (1000 * 60 * 60 * 24)
}

/**
 * @summary Returns an array containing the value of a wallet by day based on the tx history and quote.
 *
 * @param wallet
 * @param priceHistory
 * @param balanceHistory
 * @returns {Object}
 */
export const getAssetEquityCurve = (
  wallet: Wallet,
  priceHistory: PriceHistory[] | undefined,
  balanceHistory: BalanceRecord[] | undefined,
  startDate?: Date,
  endDate?: Date,
) => {
  if (!priceHistory) throw new Error('getAssetEquityCurve: Price history is not available')

  let portfolioEquityCurve = []
  let currentHoldingsCurve = [] // The performance of current holdings based on current balances.
  let balance = 0
  let j = 0

  // Future sanity note: manual wallets are pre-sorted and processed in ascending order.
  balanceHistory && sortArray(balanceHistory, 'timestamp', 'desc')
  wallet.Manual
    ? sortArray(priceHistory, 'Timestamp', 'asc')
    : sortArray(priceHistory, 'timestamp', 'desc')

  for (let i = 0; i < priceHistory.length; i++) {
    const quoteDate =
      priceHistory[i].id === 'latest'
        ? new Date(priceHistory[i].timestamp).toISOString().substring(0, 10)
        : priceHistory[i].id

    // Fill in any missing days with the previous record.
    const prevRecord: any = portfolioEquityCurve[portfolioEquityCurve.length - 1] ?? {
      Timestamp: quoteDate,
    }

    const missingDays = getDifferenceInDays(new Date(prevRecord.Timestamp), new Date(quoteDate))

    // Add zero balance before first manual record.
    if (wallet.Manual && i === 0) {
      const date = new Date(quoteDate)
      const prevDate = new Date(date.setDate(date.getDate() - 1))
      const record: any = {
        Quote: 0,
        Timestamp: prevDate,
        Volume: 0,
      }
      portfolioEquityCurve.push(record)
      currentHoldingsCurve.push(record)
    }

    // Add days between manual records.
    if (wallet.Manual && missingDays > 1) {
      for (let k = 0; k < missingDays - 2; k++) {
        const lastDate = new Date(prevRecord.Timestamp)
        const missingDate = new Date(lastDate.setDate(lastDate.getDate() + k + 1))
          .toISOString()
          .substring(0, 10)
        const record: any = {
          Quote: prevRecord.Quote,
          Timestamp: new Date(missingDate),
          Volume: prevRecord.Volume,
        }
        portfolioEquityCurve.push(record)
        currentHoldingsCurve.push({
          Quote: priceHistory[priceHistory.length - 1].Balance * priceHistory[i - 1].Quote,
          Timestamp: new Date(missingDate),
          Volume: prevRecord.Volume,
        })
      }
    }

    // Populate the current holdings curve which is the current balances mapped with historical prices.
    let quote = wallet.Manual
      ? priceHistory[priceHistory.length - 1].Balance * priceHistory[i].Quote
      : (wallet.Balance + wallet.Staked + wallet.Rewards) * priceHistory[i].quote
    if (quote < 0) quote = 0

    const record = {
      Quote: quote,
      Timestamp: priceHistory[i].id === 'latest' ? new Date() : new Date(quoteDate),
      Volume: wallet.Manual ? 0 : priceHistory[i].volume_24hr,
    }

    currentHoldingsCurve.push(record)

    // Handle current price for automated records.
    if (priceHistory[i].id === 'latest') {
      portfolioEquityCurve.push(record)
      j++
      // Zero if there's only the latest price record.
      if (priceHistory.length === 1) {
        const date = new Date(quoteDate)
        const prevDate = new Date(date.setDate(date.getDate() - 1))
        const record: any = {
          Quote: 0,
          Timestamp: prevDate,
          Volume: 0,
        }
        portfolioEquityCurve.push(record)
      }

      continue
    }

    if (balanceHistory?.[j] || (wallet.Manual && priceHistory[i])) {
      // Update balance from automated history.
      if (balanceHistory && balanceHistory[j]?.id === priceHistory[i].id) {
        balance = balanceHistory[j].balanceHigh + balanceHistory[j].stakedHigh
        if (balance < 0) balance = 0
        j++
      } else {
        // Handle no quote for the balance record.
        if (balanceHistory?.[j]?.id && balanceHistory[j]?.id > quoteDate) {
          j++
          i--
          // Zero if that's all we had.
          if (j === balanceHistory.length) {
            const date = new Date(quoteDate)
            const prevDate = new Date(date.setDate(date.getDate() - 1))
            const record: any = {
              Quote: 0,
              Timestamp: prevDate,
              Volume: 0,
            }
            portfolioEquityCurve.push(record)
          }

          continue
        }
      }

      // Update balance from manual record.
      if (wallet.Manual && priceHistory[i]) {
        balance = priceHistory[i].Balance
        if (balance < 0) balance = 0
      }

      // Add record based on history.
      const record = {
        Quote: wallet.Manual ? balance * priceHistory[i].Quote : balance * priceHistory[i].quote,
        Timestamp: new Date(quoteDate),
        Volume: wallet.Manual ? 0 : priceHistory[i].volume_24hr,
      }

      portfolioEquityCurve.push(record)

      // Add a zero value if we're out of data.
      if (balanceHistory && j === balanceHistory.length) {
        const date = new Date(quoteDate)
        const prevDate = new Date(date.setDate(date.getDate() - 1))
        const record: any = {
          Quote: 0,
          Timestamp: prevDate,
          Volume: 0,
        }
        portfolioEquityCurve.push(record)
      }

      // Fill in gaps in manual wallets from the last record till today.
      if (wallet.Manual && i === priceHistory.length - 1) {
        const today = new Date().toISOString().substring(0, 10)
        const missingDays = getDifferenceInDays(
          new Date(`${quoteDate}`.substring(0, 10)),
          new Date(today),
        )

        if (missingDays > 0) {
          for (let i = 0; i < missingDays; i++) {
            const lastDate = new Date(quoteDate)
            const missingDate = new Date(lastDate.setDate(lastDate.getDate() + i + 1))
              .toISOString()
              .substring(0, 10)
            const newRecord = {
              ...record,
              Timestamp: new Date(missingDate),
            }
            portfolioEquityCurve.push(newRecord)
            currentHoldingsCurve.push(newRecord)
          }
        }
      }
    }
  }

  sortArray(portfolioEquityCurve, 'Timestamp', 'asc')
  sortArray(currentHoldingsCurve, 'Timestamp', 'asc')

  // Filter out manual points that are out of the search range.
  if (startDate) {
    portfolioEquityCurve = portfolioEquityCurve.filter(record => record.Timestamp >= startDate)
    currentHoldingsCurve = currentHoldingsCurve.filter(record => record.Timestamp >= startDate)
  }
  if (endDate) {
    portfolioEquityCurve = portfolioEquityCurve.filter(
      record =>
        new Date(record.Timestamp.toISOString().substring(0, 10)) <=
        new Date(endDate.toISOString().substring(0, 10)),
    )
    currentHoldingsCurve = currentHoldingsCurve.filter(
      record =>
        new Date(record.Timestamp.toISOString().substring(0, 10)) <=
        new Date(endDate.toISOString().substring(0, 10)),
    )
  }

  return { portfolioEquityCurve, currentHoldingsCurve }
}

/**
 * @summary Returns an object containing an equity curve for each asset/total assets in the form an array.
 *
 * @param wallets
 * @param priceHistory
 * @param balanceHistory
 * @param coinConfig
 * @returns {Object}
 */
export const calculateEquityCurves = (
  wallets: Wallet[],
  priceHistory: { [key: string]: PriceHistory[] | undefined },
  balanceHistory: { [key: string]: BalanceRecord[] } | undefined,
  coinConfig: { [key: string]: CoinConfig },
  startDate?: Date,
  endDate?: Date,
  theme?: 'light' | 'dark',
) => {
  if (!balanceHistory || !priceHistory)
    throw new Error('calculateEquityCurves: Balance history or price history is not available')

  // Calculate the equity curve for each wallet
  const equityCurves: EquityCurves = {}

  let blueIndex = 0
  let blackIndex = 0

  wallets.forEach(wallet => {
    if (!wallet.Manual && !coinConfig[wallet.Symbol]) {
      alert('Missing symbol: ' + wallet.Symbol)
    }
    // if (wallet.Manual) return;
    const quoteHistory = [
      ...(wallet.Manual ? wallet.PriceHistory : priceHistory[wallet.Symbol] ?? []),
    ]
    const { portfolioEquityCurve, currentHoldingsCurve } = getAssetEquityCurve(
      wallet,
      quoteHistory,
      wallet.Manual ? undefined : balanceHistory[wallet.id],
      startDate ? startDate : undefined,
      endDate ? endDate : undefined,
    )

    const color = () => {
      if (wallet.Manual) {
        blueIndex++
        if (blueIndex === republicBlues.length - 1) blueIndex = 1
        return republicBlues[blueIndex - 1]
      }
      if (theme === 'dark' && blackCoins.includes(wallet.Symbol)) {
        blackIndex++
        if (blackIndex === alternativeBlacks.length - 1) blackIndex = 1
        return alternativeBlacks[blackIndex - 1]
      }
      if (coinInfo[wallet.Symbol]?.color) {
        return coinInfo[wallet.Symbol]?.color
      } else {
        blueIndex++
        if (blueIndex === republicBlues.length - 1) blueIndex = 1
        return republicBlues[blueIndex - 1]
      }
    }

    equityCurves[wallet.id] = {
      name: wallet.Name,
      alias: wallet.Alias,
      symbol: wallet.Symbol,
      network: wallet.Network,
      color: color(),
      portfolioData: portfolioEquityCurve,
      holdingsData: currentHoldingsCurve,
    }
  })

  // Combine assets to create the total portfolio equity curve.
  const totalPortfolioCurve: {
    [key: string]: Quote
  } = {}

  // Combined asset curve based only on the current holdings.
  const currentHoldingsCurve: {
    [key: string]: Quote
  } = {}

  Object.keys(equityCurves).forEach((key: string) => {
    // Combine values that are based on historical balances.
    equityCurves[key].portfolioData.forEach((record: Quote, i: number) => {
      const dateKey =
        i === equityCurves[key].portfolioData.length - 1
          ? 'latest'
          : record.Timestamp.toDateString()

      if (!totalPortfolioCurve[dateKey]) {
        totalPortfolioCurve[dateKey] = { ...record }
      } else {
        totalPortfolioCurve[dateKey].Quote += record.Quote
      }
    })

    // Combine values that are based on current balances.
    equityCurves[key].holdingsData.forEach((record: Quote, i: number) => {
      const dateKey =
        i === equityCurves[key].holdingsData.length - 1 ? 'latest' : record.Timestamp.toDateString()

      if (!currentHoldingsCurve[dateKey]) {
        currentHoldingsCurve[dateKey] = { ...record }
      } else {
        currentHoldingsCurve[dateKey].Quote += record.Quote
      }
    })
  })

  // Convert to arrays and sort for charts.
  const totalEquityCurve = Object.keys(totalPortfolioCurve).map((key: string) => {
    return totalPortfolioCurve[key]
  })
  const totalHoldingsCurve = Object.keys(currentHoldingsCurve).map((key: string) => {
    return currentHoldingsCurve[key]
  })
  sortArray(totalEquityCurve, 'Timestamp', 'asc')
  sortArray(totalHoldingsCurve, 'Timestamp', 'asc')

  equityCurves['Total'] = {
    name: 'Total',
    color: '#0B9DFF', // Republic blue
    portfolioData: totalEquityCurve,
    holdingsData: totalHoldingsCurve,
  }

  return equityCurves
}

/**
 * @summary Returns an array containing the peak balance for each day where activity exists.
 *
 * @param wallet
 * @param transactionsObj
 * @returns {Array}
 */
export const balanceMap = (wallet: Wallet, transactionsObj: any) => {
  const transactions: Transaction[] = []
  wallet.transactionIds.map((txId: string) => {
    return transactions.push(transactionsObj[txId])
  })

  // Start with the current balance and todays date.
  let balance = wallet.Balance
  let date = new Date()
  const balanceHistory = [{ Balance: balance, Timestamp: date }]

  transactions.map(tx => {
    if (tx && (tx.Type === 'Import' || tx.Type === 'Export')) {
      balance = balance - tx.Change
      const txDate = new Date(tx.Timestamp)

      // If record is for a new day always push it forward.
      if (!!!isSameDay(date, txDate)) {
        const balanceEvent = {
          Balance: balance,
          Timestamp: tx.Timestamp,
        }
        balanceHistory.push(balanceEvent)
        date = txDate
      }
      // Otherwise only update it if the balance was higher.
      else if (balance > balanceHistory[balanceHistory.length - 1].Balance) {
        const balanceEvent = {
          Balance: balance,
          Timestamp: tx.Timestamp,
        }
        balanceHistory[balanceHistory.length - 1] = balanceEvent
      }
    }
    return false
  })
  return balanceHistory
}

/**
 * @summary Returns the total value of a portfolio as a number and an object containing the total amount per token symbol.
 *
 * TODO:  Break down by tags/strategies when available.
 *
 * @param wallets
 * @param coinConfig
 * @returns {Object}
 */
export const calculateTotalValueAndDistribution = (
  wallets: Wallet[],
  coinConfig: { [key: string]: CoinConfig },
  theme?: 'light' | 'dark',
) => {
  let totalValue: number = 0
  let totalStake: number = 0
  let totalPendingRewards: number = 0

  const distribution: { [key: string]: Distribution } = {}

  let blueIndex = 0
  let blackIndex = 0

  const color = (wallet: Wallet) => {
    if (!coinInfo[wallet.Symbol]?.color) {
      blueIndex++
      if (blueIndex === republicBlues.length - 1) blueIndex = 1
      return republicBlues[blueIndex - 1]
    }
    if (theme === 'dark' && blackCoins.includes(wallet.Symbol)) {
      blackIndex++
      if (blackIndex === alternativeBlacks.length - 1) blackIndex = 1
      return alternativeBlacks[blackIndex - 1]
    }
    if (coinInfo[wallet.Symbol]?.color) {
      return coinInfo[wallet.Symbol]?.color
    } else {
      blueIndex++
      if (blueIndex === republicBlues.length - 1) blueIndex = 1
      return republicBlues[blueIndex - 1]
    }
  }

  for (let i = 0; i < wallets.length; i++) {
    if (!wallets[i].Manual && !coinConfig[wallets[i].Symbol]) {
      alert('Missing symbol: ' + wallets[i].Symbol)
    }

    if (!!!distribution[wallets[i].Symbol])
      distribution[wallets[i].Symbol] = {
        name: wallets[i].Name,
        alias: wallets[i].Alias,
        symbol: wallets[i].Symbol,
        color: color(wallets[i]),
        value: 0,
      }

    const balance = wallets[i].Balance
    const staked = wallets[i].Staked ? wallets[i].Staked : 0
    if (!isNaN(staked) && staked > 0) {
      totalStake += coinConfig[wallets[i].Symbol]?.Quote
        ? staked * coinConfig[wallets[i].Symbol].Quote
        : 0
    }

    const pendingRewards = wallets[i].Rewards ? wallets[i].Rewards : 0
    if (!isNaN(pendingRewards) && pendingRewards > 0) {
      totalPendingRewards += coinConfig[wallets[i].Symbol]?.Quote
        ? pendingRewards * coinConfig[wallets[i].Symbol].Quote
        : 0
    }

    const quote = !coinConfig[wallets[i].Symbol]?.Quote ? 0 : coinConfig[wallets[i].Symbol].Quote

    const walletUSDValue = !wallets[i].Manual
      ? (balance + staked + pendingRewards) * quote
      : coinInfo[wallets[i].Symbol]
      ? wallets[i].Balance * quote
      : wallets[i].Value
    distribution[wallets[i].Symbol].value += walletUSDValue
    totalValue += walletUSDValue
  }

  return { totalValue, distribution, totalStake, totalPendingRewards }
}
