import { makeStyles } from "@material-ui/core";

const validatorStyles = makeStyles({
  label: { width: "10em", display: "inline-block" },
  button: { margin: "1em" },
  headerSpacing: { marginTop: "calc(1em + 16px)" },
  coinCardData: { marginBottom: "10px", fontSize: 14 },
  cardSectionLabel: { marginBottom: "10px", fontSize: 14, fontWeight: "bold" },
  priceChange: { marginBottom: "0px", fontSize: 14 },
  networksHeading: { marginTop: 0, marginBottom: "0.5em" },
  portfolioOverviewHeading: { marginTop: 20, marginLeft: 10 },
  cardHeaderTitle: {
    width: "180px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  card: {
    width: "100%",
  },
});

export default validatorStyles;
