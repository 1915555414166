import { useState, useEffect, useRef, useCallback } from 'react'
import {
  TextInput,
  useNotify,
  useDataProvider,
  SimpleForm,
  useMutation,
  Toolbar,
  ToolbarProps,
  SaveButton,
  Edit,
  NumberInput,
  AutocompleteArrayInput,
  SimpleFormIterator,
  DateInput,
  ArrayInput,
} from 'react-admin'
import IconContentAdd from '@material-ui/icons/Add'
import Dialog from '@material-ui/core/Dialog'
import walletStyles from '../../components/wallets/walletStyles'
import { validateManualWallet } from '../../components/wallets/methods'
import { setCoinConfigState } from '../../components/common'
import { useSelector, useDispatch } from 'react-redux'
import { IDataProvider } from '../../firebase/providers/DataProvider'
import { UserDoc, CoinConfig } from '../types'
import { environment } from '../../components/common'
import { updateUser } from '../../persistence/actions'
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'

function ManualWalletCreateButton({ onChange, updateAfterChange }: any) {
  const [showDialog, setShowDialog] = useState(false)

  const [coinConfig, setCoinConfig] = useState<CoinConfigMap>()

  const cache: any = useSelector((state: any) => state.admin)
  const user: UserDoc = useSelector((state: any) => state.user)

  const [mutate] = useMutation()
  const dispatch = useDispatch()
  const notify = useNotify()
  const dataProvider: IDataProvider = useDataProvider()
  const history = useHistory()

  // const activeBlockchainRef = useRef<CoinConfig | undefined>(activeBlockchain)
  const mountedRef = useRef(true)
  const isMounted = useCallback(() => mountedRef.current, [mountedRef.current])

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const save = useCallback(
    values => {
      const errors: any = {}
      const { TokenName, TokenSymbol, PriceHistory, WalletAlias, WalletTags } = values
      const notValid = validateManualWallet(TokenName, TokenSymbol, PriceHistory, WalletAlias)
      if (notValid) {
        alert(JSON.stringify(notValid))
        return notValid
      }

      const Symbol = TokenSymbol.toUpperCase()

      if (errors.TokenSymbol) return errors

      // Validation passed!
      const wallet = {
        id: new Date().getTime().toString(),
        Alias: WalletAlias,
        Name: TokenName,
        Symbol: Symbol,
        PriceHistory: PriceHistory,
        Tags: WalletTags ? WalletTags : [],
      }

      // Fetch profile first incase anyone else added a token since last check.
      return dataProvider
        .getOne(environment('profiles'), {
          id: user.ActiveProfile,
        })
        .then(async ({ data }) => {
          // Update the profile.
          const updatedUntracked = user.Untracked ? [...user.Untracked] : []
          updatedUntracked.push(wallet)

          try {
            await mutate(
              {
                type: 'update',
                resource: environment('profiles'),
                payload: {
                  id: user.ActiveProfile,
                  data: {
                    Untracked: updatedUntracked,
                  },
                },
              },
              {
                onSuccess: async () => {
                  const updatedProfile: UserDoc = {
                    ...user,
                    Untracked: updatedUntracked,
                  }
                  dispatch(updateUser(updatedProfile))
                  notify('pos.wallets.created_manual_wallet', {
                    type: 'success',
                  })
                  handleCloseClick()
                  await updateAfterChange()
                },
                returnPromise: true,
              },
            )
          } catch (err: any) {
            console.log(err)
            notify('pos.app.save_failed', { type: 'error' })
            return errors
          }
        })
        .catch(err => {
          console.log(err)
          notify('pos.profile.check_failed', { type: 'error' })
          return errors
        })
    },
    [user, coinConfig, mutate, dataProvider],
  )

  useEffect(() => {
    setCoinConfigState(user.Tracking, cache, dataProvider, setCoinConfig, isMounted)
  }, [])

  const classes = walletStyles()

  const SaveBar = (props: ToolbarProps) => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  )

  return (
    <>
      <Button
        onClick={handleClick}
        color='primary'
        startIcon={<IconContentAdd />}
        variant='contained'
        style={{ marginLeft: 10, borderRadius: 20 }}
      >
        Untracked Asset
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick}>
        <Edit
          basePath={`/${environment('profiles')}`}
          resource={`${environment('profiles')}`}
          id={user.id}
          mutationMode='pessimistic'
          classes={{
            main: classes.formWithCustomToolbar,
            root: classes.root,
            input: classes.root,
            card: classes.root,
          }}
        >
          <SimpleForm mutationMode='pessimistic' toolbar={<SaveBar />} save={save}>
            <TextInput
              source='WalletAlias'
              className={classes.inputField}
              margin='normal'
              label='Wallet Alias'
            />
            <TextInput
              source='TokenName'
              className={classes.inputField}
              margin='normal'
              label='Currency Name'
            />
            <TextInput
              source='TokenSymbol'
              className={`${classes.inputField} ${classes.uppercase}`}
              margin='normal'
              label='Symbol'
            />
            {user.Tags && user.Tags.length > 0 ? (
              <>
                <AutocompleteArrayInput
                  source='WalletTags'
                  choices={
                    user.Tags
                      ? user.Tags.map((tag: string, i: number) => ({
                          id: tag,
                          name: tag,
                        }))
                      : []
                  }
                />
                <Button
                  color='primary'
                  onClick={() => history.push(`/${environment('profiles')}/edit`)}
                >
                  Manage Tags
                </Button>
              </>
            ) : (
              <>
                <span>
                  You don't have any strategy tags yet.
                  <Button
                    color='primary'
                    onClick={() => history.push(`/${environment('profiles')}/edit`)}
                    style={{ marginLeft: 15 }}
                    size='small'
                  >
                    Create tags
                  </Button>
                </span>
              </>
            )}
            <ArrayInput source='PriceHistory' label='Wallet History' defaultValue={[]}>
              <SimpleFormIterator>
                <NumberInput source='Balance' label='Balance' />
                <NumberInput source='Quote' label='Price' />
                <NumberInput source='TotalSupply' label='TotalSupply' />
                <NumberInput source='CirculatingSupply' label='CirculatingSupply' />
                <NumberInput source='MaxSupply' label='MaxSupply' />
                <DateInput
                  source='Timestamp'
                  label='From Date'
                  max={new Date().toISOString().substring(0, 10)}
                  options={{
                    inputProps: {
                      max: new Date().toISOString().substring(0, 10),
                    },
                  }}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleForm>
        </Edit>
      </Dialog>
    </>
  )
}

interface CoinConfigMap {
  [key: string]: CoinConfig
}

export default ManualWalletCreateButton
