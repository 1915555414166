import { useLayoutEffect, useEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useTheme, useMediaQuery, Theme } from "@material-ui/core";
import { sortArray } from "../../firebase/misc";
import { AxisRenderer } from "@amcharts/amcharts5/.internal/charts/xy/axes/AxisRenderer";
import { getChartTheme } from "./methods";

/**
 * @summary Renders the portfolio summary line chart visible on the portfolio page.
 *
 * TODO:  Handle dynamic updating of data (not needed right now but might be later)
 *
 * @param props
 * @returns
 */
function PriceHistoryAMLine({ data }: Props) {
  const rootRef = useRef<am5.Root | null>(null);
  const chartRef = useRef<am5xy.XYChart | null>(null);
  const xAxisRef = useRef<am5xy.ValueAxis<AxisRenderer> | null>(null);
  const yAxisRef = useRef<am5xy.ValueAxis<AxisRenderer> | null>(null);
  const seriesRef = useRef<am5xy.LineSeries | null>(null);
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const theme = useTheme();
  const chartTheme = getChartTheme(theme);

  useEffect(() => {
    return () => {
      chartRef.current && chartRef.current.dispose();
    };
  }, []);

  useLayoutEffect(() => {
    sortArray(data, "Timestamp", "asc");
    if (!rootRef.current && !seriesRef.current) {
      rootRef.current = am5.Root.new("pricehistoryline");

      // Set the font and positive/negative colors.
      const myTheme = am5.Theme.new(rootRef.current);
      myTheme.rule("Label").setAll(chartTheme.fontStyle);
      myTheme.rule("InterfaceColors").setAll({
        positive: chartTheme.positive,
        negative: chartTheme.negative,
      });
      rootRef.current.setThemes([
        am5themes_Animated.new(rootRef.current),
        myTheme,
      ]);

      // Set the chart type.
      chartRef.current = rootRef.current.container.children.push(
        am5xy.XYChart.new(rootRef.current, {
          focusable: true,
          panX: true,
          panY: true,
          wheelX: "panX",
          wheelY: "zoomX",
        })
      );

      chartRef.current.setAll({
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 15,
      });

      // Format numbers to show short form (i.e. 1,000,000 => $1M)
      rootRef.current.numberFormatter.setAll({
        numberFormat: "$#,###.##a",
        bigNumberPrefixes: [
          { number: 1e6, suffix: "M" },
          { number: 1e9, suffix: "B" },
        ],
        smallNumberPrefixes: [],
      });

      const xAxisTooltip = am5.Tooltip.new(rootRef.current, {});
      xAxisTooltip.label.setAll({
        fontSize: 13,
        paddingTop: 0,
        paddingBottom: 0,
      });

      // Set the x axis.
      xAxisRef.current = chartRef.current.xAxes.push(
        am5xy.DateAxis.new(rootRef.current, {
          maxDeviation: 0.1,
          groupData: false,
          baseInterval: {
            timeUnit: "day",
            count: 1,
          },
          renderer: am5xy.AxisRendererX.new(rootRef.current, {
            minGridDistance: 50,
          }),
          tooltip: xAxisTooltip,
        })
      );

      // Set the y axis.
      yAxisRef.current = chartRef.current.yAxes.push(
        am5xy.ValueAxis.new(rootRef.current, {
          maxDeviation: 0.1,
          renderer: am5xy.AxisRendererY.new(rootRef.current, {}),
        })
      );

      let xRenderer = xAxisRef.current.get("renderer");
      xRenderer.labels.template.setAll({
        fill: am5.color(theme.palette.type === "dark" ? 0xfffffff : 0x000000),
        paddingTop: 10,
        fontSize: 13,
        opacity: 0.8,
      });

      xRenderer.grid.template.setAll({
        stroke: am5.color(theme.palette.type === "dark" ? 0xfffffff : 0x000000),
        opacity: 0.25,
      });

      let yRednerer = yAxisRef.current.get("renderer");
      yRednerer.labels.template.setAll({
        fill: am5.color(theme.palette.type === "dark" ? 0xfffffff : 0x000000),
        fontSize: 13,
        opacity: 0.8,
      });

      yRednerer.grid.template.setAll({
        stroke: am5.color(theme.palette.type === "dark" ? 0xfffffff : 0x000000),
        opacity: 0.25,
      });

      // Loop to generate separate line for each data set.
      //   for (const key in data) {
      const lineConfig: any = {
        // name: data[key].name,
        minBulletDistance: 10,
        xAxis: xAxisRef.current,
        yAxis: yAxisRef.current,
        valueYField: "Quote",
        valueXField: "Timestamp",
      };

      if (data.length > 0 && data[0].Quote > data[data.length - 1].Quote) {
        lineConfig.fill = am5.color("#FF0000"); // red
        lineConfig.stroke = am5.color("#FF0000");
      } else {
        lineConfig.fill = am5.color("#008000"); // green
        lineConfig.stroke = am5.color("#008000");
      }

      seriesRef.current = chartRef.current.series.push(
        am5xy.SmoothedXLineSeries.new(rootRef.current, lineConfig)
      );

      // Set format
      seriesRef.current.data.processor = am5.DataProcessor.new(
        rootRef.current,
        {
          dateFormat: "yyyy-MM-dd",
          dateFields: ["Timestamp"],
          numericFields: ["Quote"],
        }
      );
      seriesRef.current.data.setAll(data);

      // On hover text over points
      const tooltip = am5.Tooltip.new(rootRef.current, {
        pointerOrientation: "horizontal",
      });
      tooltip.label.set("text", `{valueY}`);
      tooltip.label.setAll({
        fontSize: 13,
      });
      seriesRef.current.set("tooltip", tooltip);

      // Fade in animation
      seriesRef.current.appear(1000, 100);
      // }

      // Handle cursor events
      const cursor = chartRef.current.set(
        "cursor",
        am5xy.XYCursor.new(rootRef.current, {
          xAxis: xAxisRef.current,
        })
      );
      cursor!.lineX.setAll({
        stroke: chartTheme.themeFillStroke,
        strokeOpacity: 0.3,
      });
      cursor!.lineY.setAll({
        stroke: chartTheme.themeFillStroke,
        strokeOpacity: 0.3,
      });
      chartRef.current.appear(1000, 100);
    } else {
      // If the graph already exists delete previous data and redraw the new line.
      if (chartRef.current && seriesRef.current && rootRef.current) {
        seriesRef.current.data.removeIndex(0);

        // Reset the color.
        let color: am5.Color | undefined;
        if (data.length > 0 && data[0].Quote > data[data.length - 1].Quote) {
          color = am5.color(
            theme.palette.type === "dark" ? "#f85149" : "#FF0000"
          ); // red
        } else {
          color = am5.color(
            theme.palette.type === "dark" ? "#3fb950" : "#008000"
          ); // green
        }
        seriesRef.current.data.setAll(data);
        seriesRef.current.setAll({
          stroke: color,
          fill: color,
        });
      }
    }
  });

  useEffect(() => {
    if (xAxisRef.current && yAxisRef.current) {
      xAxisRef.current.get("renderer").labels.template.setAll({
        fontSize: isXSmall ? 11 : 13,
      });

      yAxisRef.current.get("renderer").labels.template.setAll({
        fontSize: isXSmall ? 11 : 13,
      });
    }
  }, [isXSmall]);

  return (
    <div id="pricehistoryline" style={{ width: "100%", height: 230 }}></div>
  );
}

interface Props {
  data: any;
}

export default PriceHistoryAMLine;
