import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useRecordContext, NumberFieldProps } from "react-admin";

/**
 * @summary Sets the styles for the percent change table cells
 *
 * TODO:  Set colors from the theme (global up and down for light and dark theme)
 *        Add up/down arrow svg icons
 *
 * @returns {JSX.Element}
 */
const RowPercentChange = ({ source }: NumberFieldProps) => {
  const classes = useStyles();
  const record = useRecordContext();
  const data = record && source ? record[source] : 0;
  return (
    <Typography variant="body2">
      <span className={data > 0 ? classes.up : classes.down}>{`${data.toFixed(
        2
      )}%`}</span>
    </Typography>
  );
};

const useStyles = makeStyles((theme: any) => ({
  up: { color: theme.palette.type === "dark" ? "#3fb950" : "green" },
  down: { color: theme.palette.type === "dark" ? "#f85149" : "#ea3943" },
}));

export default RowPercentChange;
