import { useEffect } from "react";
import { useInput, useTranslate } from "react-admin";
import { formStyles } from ".";
import {
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  InputProps,
} from "@material-ui/core";

const SelectWithAutoFill = ({
  id,
  choices,
  isRequired,
  callback,
  ...rest
}: SelectWithAutoFillProps) => {
  const {
    input,
    meta: { touched, error },
  } = useInput(rest);
  const { onChange, ...sanitizedInput } = input;
  const translate = useTranslate();
  const classes = formStyles();

  useEffect(() => {
    if (choices && choices[0] && choices[0].id) {
      const inputElement = document.getElementById(id) as HTMLInputElement;
      inputElement.focus();
      inputElement.value = choices[0].id;
      inputElement.dispatchEvent(new Event("change", { bubbles: true }));
    } else {
      const inputElement = document.getElementById(id) as HTMLInputElement;
      inputElement.value = "";
      inputElement.blur();
      inputElement.dispatchEvent(new Event("change", { bubbles: true }));
    }
  }, [choices]);

  return (
    <FormControl
      variant="filled"
      margin="normal"
      className={classes.customFormControl}
    >
      <InputLabel
        required={isRequired}
        error={!!touched && !!error}
        htmlFor={id}
      >
        {input.name}
      </InputLabel>
      <Select
        native
        error={!!touched && !!error}
        inputProps={{
          name: input.name,
          id,
        }}
        disabled={!choices || choices.length === 1}
        onChange={(evt) => {
          if (callback) callback(evt);
          onChange(evt);
        }}
        {...sanitizedInput}
      >
        {choices &&
          choices.map((choice: any) => {
            if (!choice.id) return null;
            else {
              return (
                <option value={choice.id} key={choice.id}>
                  {choice.value}
                </option>
              );
            }
          })}
      </Select>
      <FormHelperText error={!!touched && !!error}>
        {!!touched && !!error ? translate(error) : " "}
      </FormHelperText>
    </FormControl>
  );
};

interface SelectWithAutoFillProps extends InputProps {
  id: string;
  source: string;
  choices: { id: string; value: string | Element }[] | undefined;
  isRequired: boolean;
  [key: string]: any;
}

export default SelectWithAutoFill;
