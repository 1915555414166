/**
 * @summary Compute the conditional value at risk (cVAR) as a percentage from an array of portfolio values at a given confidence level
 *
 * @param portfolioEquityCurve
 * @param alpha
 * @returns {number | NaN}
 */
const calculateConditionalValueAtRisk = (
  portfolioEquityCurve: number[],
  alpha: number
) => {
  const returns = arithmeticReturns(portfolioEquityCurve).slice(1); // remove first element as theres no returns on day one
  returns.sort((a: number, b: number) => a - b);
  const calpha = 1 - alpha;
  const w = Math.floor(calpha * returns.length);
  if (w === 0) return NaN; // if w is 0 the confidence is too high for the length of the dataset
  const valAtRisk = -returns[w - 1];

  return valAtRisk;
};

/**
 * @summary Compute the period to period arithmetic returns for a given array of portfolio values
 *
 * @param portfolioEquityCurve
 * @returns {number[]}
 */
const arithmeticReturns = (portfolioEquityCurve: number[]) => {
  const returns = [...portfolioEquityCurve];
  returns[0] = NaN; // remove first element as theres no returns on day one
  for (let i = 1; i < portfolioEquityCurve.length; ++i) {
    returns[i] =
      (portfolioEquityCurve[i] - portfolioEquityCurve[i - 1]) /
      portfolioEquityCurve[i - 1];
  }

  return returns;
};

/**
 * @summary Calculate the GPR as the the sum of the returns divided by the sum of the absolute values of the negative returns
 *
 * @param portfolioEquityCurve
 * @returns {number}
 */
const calculateGainToPainRatio = (portfolioEquityCurve: number[]) => {
  const returns = arithmeticReturns(portfolioEquityCurve).slice(1); // First value is NaN
  if (returns.length === 0) return NaN; // exit if no values
  const numerator = mean_(returns);
  const denominator = lpm_(returns, 1, 0);
  if (denominator === 0) {
    return NaN;
  } else {
    return numerator / denominator;
  }
};

/**
 * @summary Calculate the corrected mean value of an array of numbers
 *
 * @param x an array of values
 * @returns {number}
 */
const mean_ = (x: number[]) => {
  const nn = x.length;
  let tmpMean = 0;
  let sum = 0;
  let sumDiff = 0;

  // Compute the mean of the values of the input numeric array
  for (let i = 0; i < nn; ++i) {
    sum += x[i];
  }
  tmpMean = sum / nn;

  // Compute the correction factor
  for (let i = 0; i < nn; ++i) {
    sumDiff += x[i] - tmpMean;
  }

  return (sum + sumDiff) / nn;
};

/**
 * @summary Returns the n-th order lower partial moment of an array of values with respect to a threshold t
 *
 * @param x an array of values
 * @param n n the order of the lower partial moment
 * @param t t the threshold of the lower partial moment
 * @returns {number}
 */
const lpm_ = (x: number[], n: number, t: number) => {
  const nn = x.length;
  let tmpMean = 0;
  let sum = 0;
  let sum2 = 0;

  // First pass of the mean computation
  for (let i = 0; i < nn; ++i) {
    sum += Math.pow(Math.max(0, t - x[i]), n);
  }
  tmpMean = sum / nn;

  // Second pass of the mean computation
  for (let i = 0; i < nn; ++i) {
    sum2 += Math.pow(Math.max(0, t - x[i]), n) - tmpMean;
  }

  return (sum + sum2) / nn;
};

/**
 * @summary Compute the ulcer index from a portfolio equity curve.
 *
 * @param equityCurve
 * @returns {number}
 */
const calculateUlcerIndex = (equityCurve: number[]) => {
  // Compute the drawdown function
  const ddFunc = drawdownFunction(equityCurve);
  let sumSquares = 0;

  // Total the sum of squares
  for (let i = 0; i < ddFunc.length; ++i) {
    sumSquares += ddFunc[i] * ddFunc[i];
  }

  return Math.sqrt(sumSquares / ddFunc.length);
};

/**
 * @summary Calculate the drawdown function/portfolio underwater equity curve.
 *
 * @param equityCurve
 * @returns {number[]}
 */
const drawdownFunction = (equityCurve: number[]) => {
  let highWaterMark = -Infinity;
  const ddVector = [...equityCurve];

  for (let i = 0; i < equityCurve.length; ++i) {
    // Increase highWaterMark if the value is the new high for the set
    if (equityCurve[i] > highWaterMark) {
      highWaterMark = equityCurve[i];
    }
    ddVector[i] = (highWaterMark - equityCurve[i]) / highWaterMark;
  }

  return ddVector;
};

/**
 * @summary Calculate the log normal returns from an equity curve.
 *
 * @param portfolioEquityCurve
 * @returns {number[]}
 */
const logNormalReturns = (portfolioEquityCurve: number[]) => {
  const returns = [...portfolioEquityCurve];
  returns[0] = NaN; // remove first element as theres no returns on day one
  for (let i = 1; i < portfolioEquityCurve.length; ++i) {
    returns[i] = Math.log(
      portfolioEquityCurve[i] / portfolioEquityCurve[i - 1]
    );
  }

  return returns;
};

/**
 * @summary Returns the standard deviation of an array of numbers
 *
 * @param data
 * @returns {number}
 */
const standardDeviation = (data: number[]) => {
  const n = data.length;
  const mean = mean_(data);

  let variance = 0;
  let v1 = 0;
  let v2 = 0;
  let stddev = 0;

  if (n !== 1) {
    for (let i = 0; i < n; i++) {
      v1 = v1 + (data[i] - mean) * (data[i] - mean);
      v2 = v2 + (data[i] - mean);
    }

    v2 = (v2 * v2) / n;
    variance = (v1 - v2) / (n - 1);
    if (variance < 0) {
      variance = 0;
    }
    stddev = Math.sqrt(variance);
  }

  return stddev;
};

/**
 * @summary Calculates the Value at Risk at 95% confidence.
 *
 * @param portfolioEquityCurve
 * @returns {number}
 */
const calculateValueAtRisk = (portfolioEquityCurve: number[]) => {
  const lnReturns = logNormalReturns(portfolioEquityCurve);
  const mean = mean_(lnReturns.slice(1));
  const std = standardDeviation(lnReturns.slice(1));

  const valueAtRisk = mean - 1.645 * std;
  return valueAtRisk;
};

const calculateAnnualizedVolatility = (portfolioEquityCurve: number[]) => {
  const lnReturns = logNormalReturns(portfolioEquityCurve);
  const std = standardDeviation(lnReturns.slice(1));
  const annualizedVolatility = std * Math.sqrt(365);

  return annualizedVolatility;
};

export {
  calculateConditionalValueAtRisk,
  calculateGainToPainRatio,
  calculateUlcerIndex,
  calculateValueAtRisk,
  calculateAnnualizedVolatility,
  logNormalReturns,
  standardDeviation,
  mean_,
};
