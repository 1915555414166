import { persistStore, persistReducer, createTransform } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { adminReducer } from "react-admin";
import themeReducer from "./themeReducer";
import userReducer from "./userReducer";
import { createHashHistory } from "history";
import { routerMiddleware, connectRouter } from "connected-react-router";

const history = createHashHistory();

const resetLoading = createTransform(
  (inboundState, key) => {
    return inboundState;
  },
  // transform state being rehydrated
  (outboundState, key) => {
    //@ts-ignore
    return { ...outboundState, loading: undefined };
  },
  { whitelist: ["admin"] }
);

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["admin", "theme", "router", "user"],
  transforms: [resetLoading],
};

const rootReducer = combineReducers({
  admin: adminReducer,
  router: connectRouter(history),
  theme: themeReducer,
  user: userReducer,
});

const composeEnhancers =
  (process.env.NODE_ENV === "development" &&
    typeof window !== "undefined" &&
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
let store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
);
let persistor = persistStore(store);

export { store, persistor, sagaMiddleware };
