import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid-pro'
import WalletIcon from '@material-ui/icons/HorizontalSplit'
import { useMediaQuery, Theme } from '@material-ui/core'
import { Button } from 'react-admin'
import { makeStyles } from '@material-ui/core'

/**
 * @summary Renders the left right orientated settings menu above the wallet list table.
 *
 * @returns {JSX.Element}
 */
const PortfolioSummaryListTopbar = ({
  currentView,
  viewChanged,
  setDataView,
  setGrouped,
  isGrouped,
}: SummaryToolbarProps) => {
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const classes = toolbarStyles()
  return (
    <div className={classes.container}>
      <GridToolbarContainer className={classes.flexContainer}>
        <div style={{ marginRight: 'auto' }}>
          {!isXSmall && <GridToolbarColumnsButton />}
          {!isXSmall && <GridToolbarDensitySelector />}
          {!isXSmall && <GridToolbarFilterButton />}
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            label={isGrouped ? 'Show Wallets' : ' Group By Asset'}
            size='small'
            onClick={() => setGrouped(!isGrouped)}
          >
            <WalletIcon />
          </Button>
          {/* <Button
            label='Summary'
            size='small'
            onClick={() => setDataView('summary')}
            disabled={currentView === 'summary'}
          >
            <SummaryIcon />
          </Button>
          <Button
            label='Risk'
            size='small'
            onClick={() => setDataView('risk')}
            disabled={currentView === 'risk'}
          >
            <RiskIcon />
          </Button>
          <Button
            label='All Data'
            size='small'
            onClick={() => setDataView('all')}
            disabled={currentView === 'all'}
          >
            <AllDataIcon />
          </Button> */}
          <GridToolbarExport
            csvOptions={{
              fileName: `Portfolio Summary ${new Date().toDateString()}`,
              allColumns: isXSmall,
            }}
          />
        </div>
      </GridToolbarContainer>
    </div>
  )
}

const toolbarStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'absolute',
    top: -53,
    left: 0,
    zIndex: 10,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      top: -61,
    },
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 5,
    paddingBottom: 12,
  },
}))

interface SummaryToolbarProps {
  currentView: 'summary' | 'risk' | 'all'
  viewChanged: boolean
  setDataView: React.Dispatch<React.SetStateAction<'summary' | 'risk' | 'all'>>
  setGrouped: React.Dispatch<React.SetStateAction<boolean>>
  isGrouped: boolean
}

export default PortfolioSummaryListTopbar
