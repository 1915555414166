import { useLayoutEffect, useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {
  useTheme,
  useMediaQuery,
  Theme,
  Button,
  Grid,
} from "@material-ui/core";
import { EquityCurves } from "../../views/types";
import { AxisRenderer } from "@amcharts/amcharts5/.internal/charts/xy/axes/AxisRenderer";
import { getChartTheme } from "./methods";
import { blackCoins, alternativeBlacks, republicBlues } from "../../Resources";
import { Collapse } from "@material-ui/core";
import { useTranslate } from "react-admin";

/**
 * @summary Renders the portfolio summary line chart visible on the portfolio page.
 *
 * TODO:  Handle dynamic updating of data (not needed right now but might be later)
 *
 * @param props
 * @returns
 */
function PortfolioAMLine({ equityCurves, view }: Props) {
  const [legendKey, setLegendKey] = useState<{
    [key: string]: {
      name?: string;
      visible: boolean;
      color: string | undefined;
    };
  }>();
  const [showLegend, setShowLegend] = useState<boolean>(false);
  const rootRef = useRef<am5.Root | null>(null);
  const chartRef = useRef<am5xy.XYChart | null>(null);
  const seriesRef = useRef<am5xy.LineSeries | null>(null);
  const xAxisRef = useRef<am5xy.ValueAxis<AxisRenderer> | null>(null);
  const yAxisRef = useRef<am5xy.ValueAxis<AxisRenderer> | null>(null);
  const dataRef = useRef<EquityCurves | null>(null);
  const viewRef = useRef<"portfolio" | "holdings" | null>(null);
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const theme = useTheme();
  const chartTheme = getChartTheme(theme);
  const translate = useTranslate();

  const hideAll = () => {
    const legendData: {
      [key: string]: {
        name?: string;
        visible: boolean;
        color: string | undefined;
      };
    } = {};
    Object.keys(equityCurves).map(
      (key: string) =>
        (legendData[key] = {
          name: equityCurves[key].alias
            ? equityCurves[key].alias
            : equityCurves[key].name,
          visible: false,
          //@ts-ignore
          color: equityCurves[key].color ? equityCurves[key].color : "#e0e0e0",
        })
    );
    setLegendKey(legendData);
  };

  const showAll = () => {
    const legendData: {
      [key: string]: {
        name?: string;
        visible: boolean;
        color: string | undefined;
      };
    } = {};
    Object.keys(equityCurves).map(
      (key: string) =>
        (legendData[key] = {
          name: equityCurves[key].alias
            ? equityCurves[key].alias
            : equityCurves[key].name,
          visible: true,
          //@ts-ignore
          color: equityCurves[key].color ? equityCurves[key].color : "#e0e0e0",
        })
    );
    setLegendKey(legendData);
  };

  useEffect(() => {
    const legendData: {
      [key: string]: {
        name?: string;
        visible: boolean;
        color: string | undefined;
      };
    } = {};
    Object.keys(equityCurves).map(
      (key: string) =>
        (legendData[key] = {
          name: equityCurves[key].alias
            ? equityCurves[key].alias
            : equityCurves[key].name,
          visible: true,
          //@ts-ignore
          color: equityCurves[key].color ? equityCurves[key].color : "#e0e0e0",
        })
    );
    setLegendKey(legendData);

    return () => {
      chartRef.current && chartRef.current.dispose();
    };
  }, []);

  useLayoutEffect(() => {
    if (!rootRef.current && !seriesRef.current) {
      rootRef.current = am5.Root.new("linediv");
      // legendRef.current = am5.Root.new("linelegenddiv");
      dataRef.current = equityCurves;

      // Set the font and positive/negative colors.
      const myTheme = am5.Theme.new(rootRef.current);
      myTheme.rule("Label").setAll(chartTheme.fontStyle);
      myTheme.rule("InterfaceColors").setAll({
        positive: chartTheme.positive,
        negative: chartTheme.negative,
      });
      rootRef.current.setThemes([
        am5themes_Animated.new(rootRef.current),
        myTheme,
      ]);

      // Set the chart type.
      chartRef.current = rootRef.current.container.children.push(
        am5xy.XYChart.new(rootRef.current, {
          focusable: true,
          panX: true,
          wheelX: "panX",
          wheelY: "zoomX",
          maxTooltipDistance: 0,
        })
      );
      chartRef.current.zoomOutButton.set("forceHidden", true);

      chartRef.current.setAll({
        paddingLeft: 10,
        paddingRight: 28,
        paddingBottom: 35,
      });

      // Format numbers to show short form (i.e. 1,000,000 => $1M)
      rootRef.current.numberFormatter.setAll({
        numberFormat: "$#,###.##a",
        bigNumberPrefixes: [
          { number: 1e6, suffix: "M" },
          { number: 1e9, suffix: "B" },
        ],
        smallNumberPrefixes: [],
      });

      const xAxisTooltip = am5.Tooltip.new(rootRef.current, {});
      xAxisTooltip.label.setAll({
        fontSize: 13,
        paddingTop: 0,
        paddingBottom: 0,
      });

      // Set the x axis.
      xAxisRef.current = chartRef.current.xAxes.push(
        am5xy.DateAxis.new(rootRef.current, {
          maxDeviation: 0.01,
          groupData: false,
          baseInterval: {
            timeUnit: "day",
            count: 1,
          },
          renderer: am5xy.AxisRendererX.new(rootRef.current, {
            minGridDistance: 50,
          }),
          tooltip: xAxisTooltip,
        })
      );

      // Set the y axis.
      yAxisRef.current = chartRef.current.yAxes.push(
        am5xy.ValueAxis.new(rootRef.current, {
          maxDeviation: 0.1,
          renderer: am5xy.AxisRendererY.new(rootRef.current, {}),
        })
      );

      let xRenderer = xAxisRef.current.get("renderer");
      xRenderer.labels.template.setAll({
        fill: am5.color(theme.palette.type === "dark" ? 0xfffffff : 0x000000),
        paddingTop: 8,
        fontSize: "0.87rem",
      });
      xRenderer.grid.template.setAll({
        stroke: chartTheme.themeFillStroke,
        location: 0.5,
        strokeOpacity: theme.palette.type === "dark" ? 0.05 : 0.1,
        visible: true,
      });
      xRenderer.ticks.template.setAll({
        stroke: chartTheme.themeFillStroke,
        location: 0.5,
        multiLocation: 0.5,
        strokeOpacity: 0.3,
        visible: true,
      });

      let yRednerer = yAxisRef.current.get("renderer");
      yRednerer.labels.template.setAll({
        fill: am5.color(theme.palette.type === "dark" ? 0xfffffff : 0x000000),
        paddingRight: 8,
        fontSize: "0.87rem",
      });
      yRednerer.grid.template.setAll({
        stroke: chartTheme.themeFillStroke,
        location: 0.5,
        strokeOpacity: theme.palette.type === "dark" ? 0.05 : 0.1,
        visible: true,
      });
      yRednerer.ticks.template.setAll({
        stroke: chartTheme.themeFillStroke,
        location: 0.5,
        multiLocation: 0.5,
        strokeOpacity: 0.3,
        visible: true,
      });
    } else if (viewRef.current !== view) {
      // Reset the data if the gview changed.
      chartRef!.current!.series.clear();
      dataRef.current = equityCurves;
    }

    let blueIndex = 0;
    let blackIndex = 0;

    // Render the data on load and view change.
    if (viewRef.current !== view) {
      viewRef.current = view;

      for (const key in equityCurves) {
        // Set color.
        let color = equityCurves[key].color;
        if (
          blackCoins.includes(key.split("/")[0]) &&
          theme.palette.type === "dark"
        ) {
          color = alternativeBlacks[blackIndex];
          blackIndex++;
          if (blackIndex === alternativeBlacks.length - 1) blackIndex = 0;
        }
        if (!color) {
          color = republicBlues[blueIndex];
          blueIndex++;
          if (blueIndex === republicBlues.length - 1) blueIndex = 0;
        }

        const lineConfig: any = {
          name: equityCurves[key].name,
          minBulletDistance: 10,
          xAxis: xAxisRef.current,
          yAxis: yAxisRef.current,
          valueYField: "Quote",
          valueXField: "Timestamp",
          tooltip: am5.Tooltip.new(rootRef!.current!, {
            pointerOrientation: "horizontal",
            paddingTop: 5,
            paddingBottom: 8,
            labelText: `[fontSize: 13px]${
              equityCurves[key].symbol ? equityCurves[key].symbol : key
            }  {valueY}[/]`,
          }),
          layer: 2,
          //@ts-ignore
          fill: am5.color(color),
          //@ts-ignore
          stroke: am5.color(color),
        };

        seriesRef.current = chartRef!.current!.series.push(
          am5xy.SmoothedXLineSeries.new(rootRef!.current!, lineConfig)
        );

        key !== "Total" &&
          seriesRef.current.strokes.template.setAll({
            strokeDasharray: [8, 4],
          });

        seriesRef.current.strokes.template.setAll({
          //@ts-ignore
          // fill: am5.color(equityCurves[key].color),
          //@ts-ignore
          stroke: am5.color(color),
        });

        // Set format
        seriesRef.current.data.processor = am5.DataProcessor.new(
          rootRef!.current!,
          {
            dateFormat: "yyyy-MM-dd",
            dateFields: ["Timestamp"],
            numericFields: ["Quote"],
          }
        );
        seriesRef.current.data.setAll(
          view === "portfolio"
            ? equityCurves[key].portfolioData
            : equityCurves[key].holdingsData
        );

        // The points you see on zoom
        key === "Total" &&
          seriesRef.current.bullets.push(function () {
            return am5.Bullet.new(rootRef.current!, {
              sprite: am5.Circle.new(rootRef.current!, {
                radius: 5,
                fill: seriesRef.current!.get("fill"),
                stroke: rootRef.current!.interfaceColors.get("background"),
                strokeWidth: 2,
              }),
            });
          });

        if (legendKey && legendKey[key].visible) {
          seriesRef.current.appear(1000, 100);
        } else {
          seriesRef.current.hide();
        }
      }

      // Handle cursor events
      const cursor = chartRef!.current!.set(
        "cursor",
        am5xy.XYCursor.new(rootRef!.current!, {
          xAxis: xAxisRef!.current!,
        })
      );
      cursor!.lineX.setAll({
        stroke: chartTheme.themeFillStroke,
        strokeOpacity: 0.3,
      });
      cursor!.lineY.setAll({
        stroke: chartTheme.themeFillStroke,
        strokeOpacity: 0.3,
      });

      // Render
      chartRef!.current!.appear(1000, 100);
    }
  });

  // Change the font size based on screen width.
  useEffect(() => {
    if (xAxisRef.current && yAxisRef.current) {
      xAxisRef.current.get("renderer").labels.template.setAll({
        fontSize: isXSmall ? 11 : 13,
      });

      yAxisRef.current.get("renderer").labels.template.setAll({
        fontSize: isXSmall ? 11 : 13,
      });
    }
  }, [isXSmall, xAxisRef.current, yAxisRef.current]);

  useEffect(() => {
    if (legendKey) {
      Object.keys(equityCurves).forEach((key, i) => {
        const series = chartRef!.current!.series.getIndex(i);
        if (!legendKey[key]?.visible) {
          series?.hide();
        } else {
          series?.show(1000);
        }
      });
    }
  }, [legendKey]);

  return (
    <>
      <div
        id="linediv"
        style={{ width: "100%", height: isXSmall ? 290 : 400 }}
      ></div>
      <Collapse in={showLegend}>
        <div style={{ paddingLeft: 5 }}>
          <Button
            onClick={() => hideAll()}
            style={{
              color:
                theme.palette.type === "dark"
                  ? "#e0e0e0"
                  : theme.palette.primary.main,
              fontSize: "0.87rem",
            }}
          >
            {translate("pos.graph.hide_all")}
          </Button>
          <Button
            onClick={() => showAll()}
            style={{
              color:
                theme.palette.type === "dark"
                  ? "#e0e0e0"
                  : theme.palette.primary.main,
              fontSize: "0.87rem",
            }}
          >
            {translate("pos.graph.show_all")}
          </Button>
        </div>

        <Grid container spacing={2} style={{ padding: "15px 15px 25px 15px" }}>
          {legendKey &&
            Object.keys(legendKey)
              .reverse()
              .map((key) => {
                return (
                  <Grid
                    item
                    xs={6}
                    md={3}
                    lg={2}
                    style={{
                      padding: "2px 6px 2px 6px",
                      textOverflow: "ellipsis",
                      flexWrap: "nowrap",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      userSelect: "none",
                    }}
                    key={key}
                  >
                    <a
                      onClick={() =>
                        setLegendKey((state) => ({
                          ...state,
                          [key]: {
                            ...state![key],
                            visible: !state![key].visible,
                          },
                        }))
                      }
                      style={{
                        opacity: !legendKey[key].visible ? 0.5 : 1,
                        minWidth: 0,
                        width: "calc(100%)",
                        color: legendKey[key].color
                          ? legendKey[key].color
                          : "#e0e0e0",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        textAlign: "left",
                        fontSize: "0.87rem",
                        cursor: "pointer",
                      }}
                    >
                      {legendKey[key].name}
                    </a>
                  </Grid>
                );
              })}
        </Grid>
      </Collapse>
      <Button
        onClick={() => setShowLegend(!showLegend)}
        style={{ marginBottom: 10, marginLeft: 5 }}
        color="primary"
      >
        {showLegend
          ? translate("pos.graph.hide_legend")
          : translate("pos.graph.show_legend")}
      </Button>
    </>
  );
}

interface Props {
  equityCurves: EquityCurves;
  view: "portfolio" | "holdings";
}

export default PortfolioAMLine;
