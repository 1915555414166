import { useEffect, useState } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  SelectInput,
  useAuthProvider,
  useNotify,
  useRedirect,
} from "react-admin";
import { CreateProps } from "react-admin";
import { Grid } from "@material-ui/core";
import { SanitizedGrid } from "../../components/form";
import walletStyles from "../../components/wallets/walletStyles";
import { triggerIntegrationProcessing } from "../../components/integration/methods";
import { useSelector } from "react-redux";
import Loading from "../layout/Loading";

/**
 * @summary The primary component of the wallet update view
 *
 * TODO:  Will need to be rewritten for the new data structure
 *
 * @param props
 * @returns {JSX.Element}
 */
const IntegrationCreate = (props: CreateProps) => {
  const classes = walletStyles();
  const user = useSelector((state: any) => state.user);
  const [token, setToken] = useState<string>();
  const authProvider = useAuthProvider();
  const notify = useNotify();
  const redirectTo = useRedirect();

  useEffect(() => {
    authProvider.getJWTToken().then((token: string) => {
      setToken(token);
    });
  }, []);

  if (!token) return <Loading />;

  return (
    <Create
      title="Edit Integration"
      {...props}
      actions={<TopBar />}
      basePath="/configuration"
      onSuccess={(record) => {
        triggerIntegrationProcessing(user, record.data, token);
        notify("pos.integrations.created", { type: "success" });
        redirectTo("/configuration");
      }}
    >
      <SimpleForm undoable={false}>
        <SanitizedGrid container spacing={2} className={classes.formContainer}>
          <Grid item xs={12} className={classes.formRow}>
            <SelectInput
              source="Type"
              choices={[{ id: "TALOS", name: "Talos" }]}
              className={classes.inputField}
            />
            <TextInput source="Name" className={classes.inputField} />
            <TextInput
              source="ApiAddress"
              label="API Address"
              className={classes.inputField}
            />
            <TextInput
              source="ApiKey"
              label="API Key"
              className={classes.inputField}
            />
            <TextInput
              source="ApiSecret"
              label="API Secret"
              className={classes.inputField}
            />
          </Grid>
        </SanitizedGrid>
      </SimpleForm>
    </Create>
  );
};

const TopBar = () => (
  <TopToolbar>
    <ListButton basePath="/configuration" />
  </TopToolbar>
);

export default IntegrationCreate;
