import { MutableRefObject } from 'react'
import { updateUser } from '../../persistence/actions'
import { CoinConfig, UserDoc, Wallet, Mutate } from '../../views/types'
import { Dispatch, downloadCSV, RecordMap, Record } from 'react-admin'
import { trackingList, getNetworkKeys } from '../common'
import { IDataProvider } from '../../firebase/providers/DataProvider'
import { environment } from '../common'
import { authProvider } from '../../firebase'

//@ts-ignore
import jsonExport from 'jsonexport/dist'

/**
 * @summary Updates list of symbols to track and addresses to fetch within the profile.
 *
 * TODO: We should fetch the profile first incase someone else already updated it.
 *
 * @param user
 * @param data
 * @param activeBlockchainRef
 * @returns {Promise}
 */
export const handleProfileTracking = (
  user: UserDoc,
  data: Wallet,
  activeBlockchainRef: MutableRefObject<CoinConfig | undefined>,
  dataProvider: IDataProvider,
  mutate: Mutate,
  dispatch: Dispatch<any>,
) => {
  return new Promise<any>(resolve => {
    if (!activeBlockchainRef.current)
      throw new Error('Active chain not set when attempting to create a wallet.')

    // Update the list of wallet references in the profile.
    const walletRef = `${data.Symbol}/${data.Network}/${data.Address}`
    const newWalletObj = user.Wallets && user.Wallets.length > 0 ? [...user.Wallets] : []
    if (!newWalletObj.includes(walletRef)) {
      newWalletObj.push(walletRef)
    } else {
      // This should be found before here but added for future sanity.
      throw new Error('The wallet already exists in the profile')
    }

    const newTrackingObj = trackingList(newWalletObj)
    const networkKeys = getNetworkKeys(data.Symbol, newWalletObj)

    Promise.all([
      updateProfileRefs(user, newWalletObj, newTrackingObj, mutate, dispatch),
      updateWalletNetworkKeys(user, data.Symbol, networkKeys, dataProvider, mutate),
    ])
      .then(() => {
        resolve({ data, activeBlockchainRef })
      })
      .catch(err => {
        // Rejecting the promise would cause react-admin to write an empty doc.
        throw new Error(err.message)
      })
  })
}

const updateProfileRefs = (
  user: UserDoc,
  walletRefs: string[],
  trackingRefs: string[],
  mutate: Mutate,
  dispatch: Dispatch<any>,
) => {
  return new Promise((resolve, reject) => {
    mutate(
      {
        type: 'update',
        resource: `${environment('profiles')}`,
        payload: {
          id: user.ActiveProfile,
          data: {
            Tracking: trackingRefs,
            Wallets: walletRefs,
          },
        },
      },
      {
        onSuccess: ({
          data: updatedFields,
        }: {
          data: {
            id: string
            Tracking: string[]
            Wallets: string[]
          }
        }) => {
          // Update the profile in local state
          const updatedUser: UserDoc = {
            ...user,
            Tracking: updatedFields.Tracking,
            Wallets: updatedFields.Wallets,
          }
          dispatch(updateUser(updatedUser))
          resolve(true)
        },
        onFailure: (err: any) => {
          reject(err)
        },
      },
    )
  })
}

/**
 * @summary Enables a component to change the users active profile id both remotely and locally.
 *
 * @param user
 * @param profileId
 * @param mutate
 * @param dispatch
 * @returns {Promise}
 */
export const updateActiveProfile = (
  user: UserDoc,
  profileId: string,
  mutate: Mutate,
  dispatch: Dispatch<any>,
) => {
  return new Promise((resolve, reject) => {
    mutate(
      {
        type: 'update',
        resource: `${environment('profiles')}`,
        payload: {
          id: user.id,
          data: {
            ActiveProfile: profileId,
          },
        },
      },
      {
        onSuccess: ({
          data: updatedFields,
        }: {
          data: {
            id: string
            ActiveProfile: string
          }
        }) => {
          const updatedUser: UserDoc = {
            ...user,
            ActiveProfile: updatedFields.ActiveProfile,
          }
          dispatch(updateUser(updatedUser))
          resolve(true)
        },
        onFailure: (err: any) => {
          console.log(err)
          reject(err)
        },
      },
    )
  })
}

/**
 * @summary Add a new profile to the users profile and set as active.
 *
 * @param user
 * @param profileId
 * @param name
 * @param mutate
 * @param dispatch
 * @returns {Promise}
 */
export const addProfile = (
  user: UserDoc,
  profileId: string,
  name: string,
  mutate: Mutate,
  dispatch: Dispatch<any>,
) => {
  return new Promise((resolve, reject) => {
    const organizations = [...user.Organizations]
    organizations.push({
      Name: name,
      ProfileId: profileId,
    })

    mutate(
      {
        type: 'update',
        resource: `${environment('profiles')}`,
        payload: {
          id: user.id,
          data: {
            ActiveProfile: profileId,
            Organizations: organizations,
          },
        },
      },
      {
        onSuccess: ({
          data: updatedFields,
        }: {
          data: {
            id: string
            ActiveProfile: string
            Organizations: { Name: string; ProfileId: string }[]
          }
        }) => {
          const updatedUser: UserDoc = {
            ...user,
            Name: name,
            ActiveProfile: updatedFields.ActiveProfile,
            Organizations: updatedFields.Organizations,
          }
          dispatch(updateUser(updatedUser))
          resolve(true)
        },
        onFailure: (err: any) => {
          reject(err)
        },
      },
    )
  })
}

/**
 * @summary Remove a portfolio from the users profile.
 *
 * @param user
 * @param profileId
 * @param mutate
 * @param dispatch
 * @returns {Promise}
 */
export const removeProfile = (
  user: UserDoc,
  profileId: string,
  mutate: Mutate,
  dispatch: Dispatch<any>,
) => {
  return new Promise((resolve, reject) => {
    const organizations = [...user.Organizations].filter(o => o.ProfileId !== profileId)
    const activeProfile = user.ActiveProfile === profileId ? user.id : user.ActiveProfile

    mutate(
      {
        type: 'update',
        resource: `${environment('profiles')}`,
        payload: {
          id: user.id,
          data: {
            ActiveProfile: activeProfile,
            Organizations: organizations,
          },
        },
      },
      {
        onSuccess: ({
          data: updatedFields,
        }: {
          data: {
            id: string
            ActiveProfile: string
            Organizations: { Name: string; ProfileId: string }[]
          }
        }) => {
          const updatedUser: UserDoc = {
            ...user,
            ActiveProfile: updatedFields.ActiveProfile,
            Organizations: updatedFields.Organizations,
          }
          dispatch(updateUser(updatedUser))
          resolve(true)
        },
        onFailure: (err: any) => {
          reject(err)
        },
      },
    )
  })
}

/**
 * @summary Creates/updates the list of network symbols in the top level wallet document.
 *
 * @param user
 * @param symbol
 * @param networkKeys
 * @param dataProvider
 * @param mutate
 * @returns {Promise}
 */
const updateWalletNetworkKeys = (
  user: UserDoc,
  symbol: string,
  networkKeys: string[],
  dataProvider: IDataProvider,
  mutate: Mutate,
) => {
  return new Promise((resolve, reject) => {
    const colRef = `${environment('profiles')}/${user.ActiveProfile}/data`

    // Check it the collection already exists to know if we need to create or update.
    dataProvider
      .getOne(colRef, {
        id: symbol,
      })
      .then(() => {
        // Already exists
        mutate(
          {
            type: 'update',
            resource: colRef,
            payload: {
              id: symbol,
              data: {
                Networks: networkKeys,
              },
            },
          },
          {
            onSuccess: () => {
              resolve(true)
            },
            onFailure: (err: any) => {
              reject(err)
            },
          },
        )
      })
      .catch(err => {
        if (err.status === 200) {
          dataProvider
            .create(colRef, {
              data: { id: symbol, Networks: networkKeys },
            })
            .then(() => {
              resolve(true)
            })
            .catch(err => {
              reject(err)
            })
        } else {
          reject(err)
        }
      })
  })
}

/**
 * @summary Removes the wallet ref and tracking symbol from a users profile (if no other wallets of type exist).
 *
 * @param user
 * @param ref
 * @param mutate
 * @param dispatch
 * @param notify
 * @returns {Promise}
 */
export const removeWalletRef = (
  user: UserDoc,
  ref: string,
  mutate: Mutate,
  dispatch: Dispatch<any>,
  dataProvider: IDataProvider,
) => {
  return new Promise((resolve, reject) => {
    // Update the list of wallet references in the profile.
    if (!user.Wallets.includes(ref)) {
      throw new Error("The wallet doesn't exist")
    } else {
      const symbol = ref.split('/')[0]
      const newWalletObj = [...user.Wallets].filter(v => v !== ref)
      const newTrackingObj = trackingList(newWalletObj)
      const networkKeys = getNetworkKeys(symbol, newWalletObj)

      Promise.all([
        updateProfileRefs(user, newWalletObj, newTrackingObj, mutate, dispatch),
        updateWalletNetworkKeys(user, symbol, networkKeys, dataProvider, mutate),
      ])
        .then(() => {
          resolve(true)
        })
        .catch(err => {
          reject(err)
        })
    }
  })
}

/**
 * @summary Converts a record map of wallets to a csv file and initiates the downland to emulate the standard functionality.
 *
 * @param data
 */
export const walletExport = (data: RecordMap<Record>) => {
  const output = Object.keys(data).map((key: string, i: number) => {
    const { Name, Symbol, Address, Balance, Staked } = data[key]
    return {
      Name,
      Symbol,
      Address,
      Balance,
      Staked,
    }
  })

  jsonExport(
    output,
    {
      headers: ['Name', 'Symbol', 'Address', 'Balance', 'Staked'],
    },
    (err: any, csv: any) => {
      downloadCSV(csv, `Wallets-${new Date().toDateString()}`)
    },
  )
}

/**
 * @summary Trigger an update after adding a new wallet.
 *
 * @param user
 * @param record
 * @param token
 * @returns {JSX.Element}
 */
export const triggerWalletProcessing = async (user: UserDoc, record: Wallet) => {
  const url =
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? 'https://radar-proxy.republic.com/Event/wallet'
      : 'https://radar-proxy.republic-beta.app/Event/wallet'
  const body = {
    profileId: user.ActiveProfile,
    address: record.Address,
    symbol: record.Symbol,
    network: record.Network,
  }
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await authProvider.getJWTToken()}`,
    },
    body: JSON.stringify(body),
  }

  return await fetch(url, options).catch(err => {
    console.log(err)
  })
}

/**
 * @summary Basic form validation for the create/edit manual wallet forms.
 *
 * @param TokenName
 * @param TokenSymbol
 * @param PriceHistory
 * @param WalletAlias
 * @returns {Object | false}
 */
export const validateManualWallet = (
  TokenName: string,
  TokenSymbol: string,
  PriceHistory: any[],
  WalletAlias: string,
) => {
  const errors: any = {}

  // Check values aren't empty.
  if (!TokenName) errors.TokenName = 'ra.validation.required'
  if (!WalletAlias) errors.WalletAlias = 'ra.validation.required'
  if (!TokenSymbol) errors.TokenSymbol = 'ra.validation.required'
  if (!PriceHistory || PriceHistory.length === 0) errors.PriceHistory = 'ra.validation.required'
  PriceHistory &&
    PriceHistory.forEach((record: any) => {
      if (!record || !record.Balance || !record.Timestamp)
        errors.PriceHistory = 'ra.validation.required'
      if (record.hasOwnProperty('Quote')) {
        if (record.Quote === null || record.Quote === undefined) {
          errors.PriceHistory = 'ra.validation.required'
        }
      } else {
        errors.PriceHistory = 'ra.validation.required'
      }
    })
  if (errors.TokenName || errors.TokenSymbol || errors.PriceHistory) return errors

  // Check names are greater than 3, less than 20 characters and contains no special characters.
  if (!/^[a-zA-Z0-9\s]{3,25}$/.test(TokenName))
    errors.TokenName = 'Must be 3-25 characters with no special characters.'
  if (!/^[a-zA-Z0-9\s]{3,25}$/.test(WalletAlias))
    errors.WalletAlias = 'Must be 3-25 characters with no special characters.'

  // Check symbol is the correct format and doesn't already exist
  const Symbol = TokenSymbol.toUpperCase()
  if (!/^[A-Z]{2,10}$/.test(Symbol)) {
    errors.TokenSymbol = 'The symbol must be between 2 and 10 characters A-Z.'
  }

  if (Object.keys(errors).length > 0) {
    return errors
  } else {
    return false
  }
}
