import { useState, useEffect, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useDataProvider } from 'react-admin'
import { Empty } from '../layout'
import { PortfolioPositionSummaryUI2 } from '../../components/portfolio2'
import { UserDoc } from '../types'
import { updateUser } from '../../persistence/actions'
import Loading from '../layout/Loading'
import { useAuthenticated } from 'react-admin'
import { IDataProvider } from '../../firebase/providers/DataProvider'
import { environment } from '../../components/common'

/**
 * @summary The top level component of the position summary.
 *
 * Handles authentication and displays a separate view if no wallets are present.
 *
 * @returns {JSX.Element}
 */
const Portfolio2 = () => {
  const [profile, setProfile] = useState<UserDoc>()
  const [loading, setLoading] = useState<boolean>(true)
  const user = useSelector((state: any) => state.user)
  const [profileId, setProfileId] = useState<string>()
  const dataProvider: IDataProvider = useDataProvider()
  const dispatch = useDispatch()
  useAuthenticated()

  const mountedRef = useRef(true)

  const updateProfile = useCallback(async () => {
    setLoading(true)
    const { data } = await dataProvider.getOne<UserDoc>(`${environment('profiles')}`, {
      id: user.ActiveProfile,
    })

    if (!mountedRef.current) return null

    const { Tracking, Untracked, Wallets, Tags } = data
    dispatch(updateUser({ ...user, Tracking, Untracked, Wallets, Tags }))
    setProfile(data)
    data && setLoading(false)
  }, [user])

  useEffect(() => {
    setProfileId(user.ActiveProfile)
    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    if (user && user.ActiveProfile !== profileId) {
      setProfileId(user.ActiveProfile)
    }
  }, [user])

  useEffect(() => {
    updateProfile()
  }, [profileId])

  if (loading) return <Loading />

  return profile?.Tracking && profile.Tracking.length > 0 ? (
    <PortfolioPositionSummaryUI2 profile={profile} updateProfile={updateProfile} />
  ) : (
    <Empty label='data' />
  )
}

export default Portfolio2
