import { useEffect, useState, useRef, useCallback } from 'react'
import { useDataProvider } from 'react-admin'
import { DataGridPro, GridColDef, GridRowData } from '@mui/x-data-grid-pro'
import { useHistory } from 'react-router-dom'
import { UserDoc } from '../../views/types'
import { useMediaQuery, Theme, Paper, Box } from '@material-ui/core'
import { Loading } from '../../views/layout'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { coinInfo } from '../../Resources'
import {
  setWalletState,
  setCoinConfigState,
  environment,
  toCurrencyString,
} from '../../components/common'
import { WalletListTopbar } from '.'
import { IDataProvider } from '../../firebase/providers/DataProvider'

/**
 * @summary The primary component of the wallet list view.
 *
 * @param props
 * @returns {JSX.Element}
 */
const WalletListUI = ({ profile }: any) => {
  const [wallets, setWallets] = useState<any[]>()
  const [coinConfig, setCoinConfig] = useState<any>()
  const [data, setData] = useState<GridRowData[]>()
  const [loading, setLoading] = useState<boolean>(true)
  const [density, setDensity] = useState<'compact' | 'standard' | 'comfortable'>('compact')
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const dataProvider: IDataProvider = useDataProvider()
  const user: UserDoc = useSelector((state: any) => state.user)
  const cache: any = useSelector((state: any) => state.admin)

  const trackingRef = useRef(profile.Tracking)
  const mountedRef = useRef(true)
  const isMounted = useCallback(() => mountedRef.current, [mountedRef.current])
  const classes = useStyles()

  const history = useHistory()

  useEffect(() => {
    if (mountedRef.current) {
      setCoinConfigState(trackingRef.current, cache, dataProvider, setCoinConfig, isMounted)
      setWalletState(user.ActiveProfile, dataProvider, setWallets, isMounted)
    }
    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    if (wallets && coinConfig) {
      const rows = wallets.map(wallet => ({
        ...wallet,
        Value: wallet.Balance * coinConfig[wallet.Symbol].Quote,
        Currency: coinConfig[wallet.Symbol].Name,
        IconURL: coinInfo[wallet.Symbol]
          ? `/images/${wallet.Symbol?.toLowerCase()}.svg`
          : '/images/republic-icon.svg',
      }))

      user.Untracked &&
        user.Untracked.forEach((wallet: any) => {
          rows.push({
            id: wallet.Symbol,
            IconURL: `/images/republic-icon.svg`,
            Address: 'Untracked',
            Alias: wallet.Alias,
            Currency: wallet.Name,
            Balance: wallet.PriceHistory[0].Balance,
            Value: wallet.PriceHistory[0].Balance * wallet.PriceHistory[0].Quote,
          })
        })

      setData(rows)
      setLoading(false)
    }
  }, [wallets, coinConfig])

  const handleRowClick = (evt: any) => {
    const walletPath = evt ? `${evt.id}`.split('/') : ''
    const isManual = !walletPath[1]
    if (!isManual) {
      history.push(
        `/${environment('profiles')}&${user.ActiveProfile}&data&${walletPath[0]}&${walletPath[1]}/${
          walletPath[2]
        }`,
      )
    } else {
      history.push(`/edit-untracked&${evt.id}`)
    }
  }

  const handleDensityChange = (state: any) => {
    if (state.density.value !== density) {
      setDensity(state.density?.value)
    }
  }

  // The columns that will be displayed in the table.
  const columns: GridColDef[] = [
    {
      field: 'IconURL',
      headerName: ' ',
      renderHeader: () => null,
      disableColumnMenu: true,
      width: density === 'compact' ? 20 : 26,
      minWidth: density === 'compact' ? (isXSmall ? 30 : 40) : 40,
      sortable: false,
      disableExport: true,
      renderCell: (params: any) => {
        return (
          <img
            src={params.value}
            style={{
              height: density === 'compact' ? 20 : 26,
              width: density === 'compact' ? 20 : 26,
            }}
            alt={' '}
          />
        )
      },
    },
    {
      field: 'Alias',
      headerName: 'Name',
      disableColumnMenu: isXSmall,
      hideSortIcons: isXSmall,
      flex: 1,
      minWidth: 100,
      editable: true,
    },
    {
      field: 'Currency',
      headerName: 'Currency',
      disableColumnMenu: isXSmall,
      hideSortIcons: isXSmall,
      flex: 1,
      minWidth: isXSmall ? 80 : 90,
      hide: isXSmall,
    },
    {
      field: 'Address',
      headerName: 'Address',
      disableColumnMenu: isXSmall,
      hideSortIcons: isXSmall,
      flex: 1,
      minWidth: 90,
      hide: isXSmall,
    },
    {
      field: 'Balance',
      headerName: 'Balance',
      disableColumnMenu: isXSmall,
      hideSortIcons: isXSmall,
      type: 'number',
      flex: 1,
      valueFormatter: ({ value }: any) => new Intl.NumberFormat('en-US').format(value),
      minWidth: isXSmall ? 80 : 100,
    },
    {
      field: 'Value',
      headerName: 'Value',
      disableColumnMenu: isXSmall,
      hideSortIcons: isXSmall,
      type: 'number',
      flex: 1,
      minWidth: 100,
      valueFormatter: ({ value }: any) => toCurrencyString(value),
    },
  ]

  if (loading || !data) return <Loading />

  return (
    <div className={classes.topSpacer}>
      <Paper variant='outlined' style={{ flexGrow: 1 }}>
        {/* <TableHeader title="Active Wallets" /> */}
        <Box
          style={{
            height: 'calc(90vh - 80px)',
            minHeight: 300,
            width: '100%',
          }}
        >
          <DataGridPro
            rows={data}
            columns={columns}
            components={{
              Toolbar: WalletListTopbar,
            }}
            loading={loading}
            checkboxSelection={false}
            disableSelectionOnClick
            onRowClick={handleRowClick}
            density='compact'
            // autoPageSize
            hideFooter
            onStateChange={(state: any) => handleDensityChange(state)}
            classes={{ row: classes.clickableRow }}
          />
        </Box>
      </Paper>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  topSpacer: {
    marginTop: 70,
    [theme.breakpoints.down('xs')]: {
      marginTop: 65,
    },
  },
  clickableRow: {
    cursor: 'pointer',
  },
}))

export default WalletListUI
