import {
  List,
  Datagrid,
  NumberField,
  Pagination,
  SearchInput,
} from "react-admin";
import {
  RowNetworkName,
  RowPercentChange,
  ResponsiveLabel,
  tableStyles,
} from "../../components/table";
import { Loading } from "../layout";
import { ListProps, PaginationProps } from "react-admin";
import { useTranslate } from "react-admin";
import { useMediaQuery, Theme } from "@material-ui/core";

/**
 * @summary The primary component of the coin list view
 *
 * TODO:  Improve initial load using the loading start (maybe utilise the overlay used in PriceHistory)
 *
 * @param props
 * @returns {JSX.Element}
 */
const PriceList = (props: ListProps) => {
  const classes = tableStyles();
  const translate = useTranslate();
  const filters = [<SearchInput source="Name" alwaysOn />];
  const RowPagination = (props: PaginationProps) => (
    <Pagination rowsPerPageOptions={[15, 25, 50, 100]} {...props} />
  );

  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const isXLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up("xl"));

  return (
    <List
      {...props}
      sort={{ field: "MarketCapitalization", order: "DESC" }}
      bulkActionButtons={false}
      pagination={<RowPagination />}
      perPage={15}
      filters={filters}
      empty={<Loading />}
    >
      <Datagrid
        isRowSelectable={() => false}
        rowClick={(_id, _basePath, record) => `/${record["Symbol"]}`}
      >
        {!isSmall && (
          <NumberField
            source="Rank"
            label={translate("pos.table.rank")}
            // options={{ style: "currency", currency: "USD" }}
            headerClassName={`${classes.tableHeader} ${classes.priceHistoryRankCell}`}
            cellClassName={classes.priceHistoryRankCell}
            locales="en-US"
            textAlign="left"
          />
        )}
        <RowNetworkName
          source="Name"
          headerClassName={classes.priceHistoryNameCell}
          cellClassName={classes.priceHistoryNameCell}
          label={translate("pos.table.name")}
        />
        <NumberField
          source="Quote"
          label={translate("pos.table.price")}
          options={{ style: "currency", currency: "USD" }}
          headerClassName={classes.tableHeader}
          locales="en-US"
        />
        <RowPercentChange
          source="PercentChange_24hr"
          label="24h %"
          headerClassName={classes.tableHeader}
          textAlign="right"
        />
        {!isXSmall && (
          <RowPercentChange
            source="PercentChange_7d"
            label="7d %"
            headerClassName={classes.tableHeader}
            textAlign="right"
          />
        )}
        <NumberField
          source="Volume"
          label={
            <ResponsiveLabel
              mobile={translate("pos.table.volume_24_short")}
              desktop={translate("pos.table.volume_24")}
            />
          }
          options={{
            style: "currency",
            currency: "USD",
            notation: isSmall ? "compact" : undefined,
          }}
          headerClassName={classes.tableHeader}
          locales="en-US"
        />
        <NumberField
          source="MarketCapitalization"
          label={
            <ResponsiveLabel
              mobile={translate("pos.table.market_cap_short")}
              desktop={translate("pos.table.market_cap")}
            />
          }
          options={{
            style: "currency",
            currency: "USD",
            notation: isSmall ? "compact" : undefined,
          }}
          headerClassName={classes.tableHeader}
          locales="en-US"
        />
        {!isSmall && (
          <NumberField
            source="FullyDilutedMarketCapitalization"
            label={translate("pos.table.diluted_market_cap")}
            options={{
              style: "currency",
              currency: "USD",
              notation: isSmall ? "compact" : undefined,
            }}
            headerClassName={classes.tableHeader}
            locales="en-US"
          />
        )}
        {isXLarge && (
          <NumberField
            source="TotalSupply"
            label={translate("pos.table.total_supply")}
            headerClassName={classes.tableHeader}
            locales="en-US"
          />
        )}
        {isXLarge && (
          <NumberField
            source="MaxSupply"
            label={translate("pos.table.max_supply")}
            headerClassName={classes.tableHeader}
            locales="en-US"
          />
        )}
      </Datagrid>
    </List>
  );
};

export default PriceList;
