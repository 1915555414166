import { Reducer } from "redux";
import { UPDATE_USER, updateUser } from "./actions";

type State = {};
type Action =
  | ReturnType<typeof updateUser>
  | { type: "OTHER_ACTION"; payload?: any }; // add other actions as required

const userReducer: Reducer<State, Action> = (state = {}, action) => {
  if (action.type === UPDATE_USER) {
    return action.payload;
  }
  return state;
};

export default userReducer;
