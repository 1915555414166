import { useMemo } from 'react'
import { Theme } from '@material-ui/core'
import { GridColDef } from '@mui/x-data-grid-pro'
import { toCurrencyString } from '../common'

/**
 * @summary Returns the columns (and renders custom cells) for the data grid.
 *
 * @param isXSmall
 * @param density
 * @param dataView
 * @param theme
 * @returns {Array}
 */
const GenerateColumns = (
  isXSmall: boolean,
  density: 'compact' | 'standard' | 'comfortable',
  dataView: 'summary' | 'risk' | 'all' | 'custom',
  theme: Theme
) => {
  const columns: GridColDef[] = [
    {
      field: 'IconURL',
      headerName: ' ',
      renderHeader: () => null,
      disableColumnMenu: true,
      width: density === 'compact' ? 20 : 26,
      minWidth: density === 'compact' ? (isXSmall ? 30 : 40) : 40,
      sortable: false,
      disableExport: true,
      renderCell: (params: any) => {
        return (
          // <img
          //   src={params.value}
          //   style={{
          //     height: density === 'compact' ? 20 : 26,
          //     width: density === 'compact' ? 20 : 26,
          //   }}
          //   alt={' '}
          // />
          AddIcon(params.value)
        )
      },
    },
    {
      field: 'Alias',
      headerName: 'Name',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      flex: 1,
      minWidth: 100,
      editable: true,
    },
    {
      field: 'Currency',
      headerName: 'Currency',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      width: isXSmall ? 80 : 110,
      hide: isXSmall || dataView === 'risk' || (dataView === 'all' && false),
    },
    {
      field: 'Symbol',
      headerName: 'Ticker',
      disableColumnMenu: true,
      hideSortIcons: true,
      width: 80,
      hide: isXSmall || (dataView === 'all' && false),
    },
    {
      field: 'Balance',
      headerName: 'Liquid',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      valueFormatter: ({ value }: any) =>
        value > 0 ? new Intl.NumberFormat('en-US').format(value) : '...',
      width: 130,
      hide: dataView === 'risk' || (dataView === 'all' && false),
    },
    {
      field: 'Staked',
      headerName: 'Staked',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      valueFormatter: ({ value }: any) =>
        value > 0 ? new Intl.NumberFormat('en-US').format(value) : '...',
      minWidth: isXSmall ? 80 : 110,
      hide: dataView === 'risk' || (dataView === 'all' && false),
    },
    {
      field: 'Rewards',
      headerName: 'Rewards',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      valueFormatter: ({ value }: any) =>
        value > 0 ? new Intl.NumberFormat('en-US').format(value) : '...',
      minWidth: isXSmall ? 80 : 110,
      hide: dataView === 'risk' || (dataView === 'all' && false),
    },
    {
      field: 'Price',
      headerName: 'Price',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      width: 120,
      valueFormatter: ({ value }: any) => toCurrencyString(value),
    },
    {
      field: 'Value',
      headerName: 'Position',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      minWidth: 140,
      valueFormatter: ({ value }: any) => toCurrencyString(value),
    },

    {
      field: 'Change24hPercent',
      headerName: '24h%',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      flex: 1,
      minWidth: 100,
      renderCell: params => AddDirectionColor(params.value, theme, 'percent'),
      hide: dataView === 'risk' || (dataView === 'all' && false),
    },
    {
      field: 'Change24hValue',
      headerName: '24h$',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      flex: 1,
      minWidth: 120,
      renderCell: params => AddDirectionColor(params.value, theme, 'currency'),
      hide: dataView !== 'all',
    },
    {
      field: 'Change7dPercent',
      headerName: '7d%',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      flex: 1,
      minWidth: 100,
      renderCell: params => AddDirectionColor(params.value, theme, 'percent'),
      hide: dataView === 'risk' || (dataView === 'all' && false),
    },
    {
      field: 'Change7dValue',
      headerName: '7d$',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      flex: 1,
      minWidth: 120,
      renderCell: params => AddDirectionColor(params.value, theme, 'currency'),
      hide: dataView !== 'all',
    },
    {
      field: 'Change30dPercent',
      headerName: '30d%',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      flex: 1,
      minWidth: 100,
      renderCell: params => AddDirectionColor(params.value, theme, 'percent'),
      hide: dataView === 'risk' || (dataView === 'all' && false),
    },
    {
      field: 'Change30dValue',
      headerName: '30d$',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      flex: 1,
      minWidth: 120,
      renderCell: params => AddDirectionColor(params.value, theme, 'currency'),
      hide: dataView !== 'all',
    },
    {
      field: 'PercentOfTotal',
      headerName: 'Weight',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      flex: 1,
      valueFormatter: ({ value }: any) => `${new Intl.NumberFormat('en-US').format(value)}%`,
      minWidth: isXSmall ? 80 : 100,
      hide: dataView === 'risk' || (dataView === 'all' && false),
    },
    {
      field: 'AnnualizedVol',
      headerName: '30d Ann. Vol',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }: any) => {
        const absValue = Math.abs(value)
        if (isNaN(absValue) || absValue === 0) return 'N/A'
        return `${new Intl.NumberFormat('en-US').format(Math.abs(value))}%`
      },
      hide: dataView === 'summary' || (dataView === 'all' && false),
    },
    {
      field: 'KnipperIndex',
      headerName: 'Liquidity Coverage',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      flex: 1,
      valueFormatter: ({ value }: any) => {
        const absValue = Math.abs(value)
        if (isNaN(absValue)) return 'N/A'
        return `${new Intl.NumberFormat('en-US').format(Math.abs(value))}`
      },
      minWidth: isXSmall ? 80 : 100,
      hide: dataView === 'summary' || (dataView === 'all' && false),
    },
    {
      field: 'ValueAtRiskPercentPosition',
      headerName: ' %VAR',
      description: '95% VAR as Percentage',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      flex: 1,
      valueFormatter: ({ value }: any) => {
        const absValue = Math.abs(value)
        if (isNaN(absValue) || absValue === 0) return 'N/A'
        return `${new Intl.NumberFormat('en-US').format(Math.abs(value))}%`
      },
      minWidth: isXSmall ? 80 : 100,
      hide: dataView === 'summary' || (dataView === 'all' && false),
    },
    {
      field: 'ValueAtRisk',
      headerName: '$VAR',
      description: '95% VAR of Position',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }: any) => {
        const absValue = Math.abs(value)
        if (isNaN(absValue)) return 'N/A'
        return toCurrencyString(absValue)
      },
      hide: dataView === 'summary' || (dataView === 'all' && false),
    },
    {
      field: 'ValueAtRiskPercentTotal',
      headerName: '% of Total VAR',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      flex: 1,
      renderCell: params =>
        // useMemo(
        //   () => (
        //     <span
        //       style={{
        //         color:
        //           params.value && params.value > 5
        //             ? theme.palette.error.main
        //             : params.value && params.value < 1
        //             ? theme.palette.success.main
        //             : theme.palette.type === 'dark'
        //             ? 'white'
        //             : 'black',
        //       }}
        //     >
        //       {`${new Intl.NumberFormat('en-US').format(params.value ? Number(params.value) : 0)}%`}
        //     </span>
        //   ),
        //   []
        // ),
        AddValueAtRisk(params.value, theme),
      minWidth: isXSmall ? 80 : 100,
      hide: dataView === 'summary' || (dataView === 'all' && false),
    },
    {
      field: 'ConValueAtRiskPercentPosition',
      headerName: '%cVAR',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      flex: 1,
      valueFormatter: ({ value }: any) => {
        const absValue = Math.abs(value)
        if (isNaN(absValue) || absValue === 0) return 'N/A'
        return `${new Intl.NumberFormat('en-US').format(Math.abs(value))}%`
      },
      minWidth: isXSmall ? 80 : 100,
      hide: dataView === 'summary' || (dataView === 'all' && false),
    },
    {
      field: 'ConValueAtRisk',
      headerName: '$cVAR',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }: any) => {
        const absValue = Math.abs(value)
        if (isNaN(absValue) || absValue === 0) return 'N/A'
        return toCurrencyString(absValue)
      },
      hide: dataView === 'summary' || (dataView === 'all' && false),
    },
    {
      field: 'ConValueAtRiskPercentTotal',
      headerName: '% of Total cVAR',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      flex: 1,
      renderCell: params =>
        // <span
        //   style={{
        //     color:
        //       params.value && params.value > 5
        //         ? theme.palette.error.main
        //         : params.value && params.value < 1
        //         ? theme.palette.success.main
        //         : theme.palette.type === 'dark'
        //         ? 'white'
        //         : 'black',
        //   }}
        // >
        //   {`${new Intl.NumberFormat('en-US').format(params.value ? Number(params.value) : 0)}%`}
        // </span>
        AddValueAtRisk(params.value, theme),
      minWidth: isXSmall ? 80 : 100,
      hide: dataView === 'summary' || (dataView === 'all' && false),
    },
    {
      field: 'UlcerIndex',
      headerName: 'Ulcer Index',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'number',
      flex: 1,
      renderCell: params =>
        // <span
        //   style={{
        //     color:
        //       params.value && params.value > 5
        //         ? theme.palette.error.main
        //         : theme.palette.success.main,
        //   }}
        // >
        //   {`${new Intl.NumberFormat('en-US').format(params.value ? Number(params.value) : 0)}`}
        // </span>
        AddValueAtRisk(params.value, theme),
      minWidth: isXSmall ? 80 : 100,
      hide: dataView === 'summary' || (dataView === 'all' && false),
    },
    {
      field: 'Tags',
      headerName: 'Tags',
      disableColumnMenu: isXSmall,
      hideSortIcons: true,
      type: 'string',
      flex: 1,
      minWidth: 120,
      disableExport: true,
      valueFormatter: ({ value }: any) => value,
    },
  ]

  return columns
}

const AddIcon = (iconURL: string) => {
  return useMemo(
    () => (
      <img
        src={iconURL}
        style={{
          height: 26,
          width: 26,
        }}
        alt={' '}
      />
    ),
    [iconURL]
  )
}

const AddValueAtRisk = (value: any, theme: Theme) => {
  return useMemo(
    () => (
      <span
        style={{
          color:
            value && value > 5
              ? theme.palette.error.main
              : value && value < 1
              ? theme.palette.success.main
              : theme.palette.type === 'dark'
              ? 'white'
              : 'black',
        }}
      >
        {`${new Intl.NumberFormat('en-US').format(value ? Number(value) : 0)}%`}
      </span>
    ),
    [value, theme]
  )
}

// const AddUlcerValue = (value: any, theme: Theme) => {
//   return useMemo(
//     () => (
//       <span
//         style={{
//           color:
//             value && value > 5
//               ? theme.palette.error.main
//               : value && value < 1
//               ? theme.palette.success.main
//               : theme.palette.type === 'dark'
//               ? 'white'
//               : 'black',
//         }}
//       >
//         {`${new Intl.NumberFormat('en-US').format(value ? Number(value) : 0)}%`}
//       </span>
//     ),
//     []
//   )
// }

const AddDirectionColor = (value: any, theme: Theme, type: 'number' | 'percent' | 'currency') => {
  // if (typeof value === 'undefined') return null
  // if (isNaN(Number(value)) || value === 0) return useMemo(() => <span>...</span>, [])
  return useMemo(
    () => (
      <span
        style={{
          color: value && value < 0 ? theme.palette.error.main : theme.palette.success.main,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        {isNaN(Number(value)) || (value === 0 && '--')}
        {type === 'number' && value && `${new Intl.NumberFormat('en-US').format(Number(value))}`}
        {type === 'percent' && value && `${new Intl.NumberFormat('en-US').format(Number(value))}%`}
        {type === 'currency' && value && `${toCurrencyString(Number(value))}`}
      </span>
    ),
    [value, theme]
  )
}

export default GenerateColumns
