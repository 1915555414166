import firebase from "firebase/compat/app";
import { IDataProvider } from "../../firebase/providers/DataProvider";
import { Dispatch } from "redux";
import { SetStateAction } from "react";
import { AuthProvider } from "react-admin";
import { createProfile, fetchProfile } from "../../components/login";

const config = (
  dataProvider: IDataProvider,
  authProvider: AuthProvider,
  history: { push: (url: string) => void },
  dispatch: Dispatch,
  setLoading: React.Dispatch<SetStateAction<boolean>>
) => {
  return {
    signInFlow: "popup",
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      // Run this after successful login (cant use async await here).
      signInSuccessWithAuthResult: (authResult: any) => {
        const user = authResult.user;
        const isNewUser = authResult.additionalUserInfo.isNewUser;

        // start loading spinner
        setLoading(true);

        // run the appropriate route
        if (isNewUser) {
          createProfile(dataProvider, authProvider, user, dispatch, history);
        } else {
          fetchProfile(dataProvider, authProvider, user, dispatch, history);
        }

        // return false to stop default action
        return false;
      },
      uiShown: () => {
        // hide the loading spinner once the ui is shown (only visible on slow internet)
        setLoading(false);
      },
    },
  };
};

export { config };
