import {
  Edit,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  SelectInput,
} from "react-admin";
import { CreateProps } from "react-admin";
import { Grid } from "@material-ui/core";
import { SanitizedGrid } from "../../components/form";
import walletStyles from "../../components/wallets/walletStyles";

/**
 * @summary The primary component of the wallet update view
 *
 * TODO:  Will need to be rewritten for the new data structure
 *
 * @param props
 * @returns {JSX.Element}
 */
const IntegrationEdit = (props: CreateProps) => {
  const classes = walletStyles();
  return (
    <Edit
      title="Edit Wallet"
      {...props}
      actions={<TopBar />}
      undoable={false}
      basePath="/configuration"
    >
      <SimpleForm
        undoable={false}
        // toolbar={<BottomBar setLoading={setLoading} />}
      >
        <SanitizedGrid container spacing={2} className={classes.formContainer}>
          <Grid item xs={12} className={classes.formRow}>
            <SelectInput
              source="Type"
              choices={[{ id: "TALOS", name: "Talos" }]}
              className={classes.inputField}
            />
            <TextInput source="Name" className={classes.inputField} />
            <TextInput
              source="ApiAddress"
              label="API Address"
              className={classes.inputField}
            />
            <TextInput
              source="ApiKey"
              label="API Key"
              className={classes.inputField}
            />
            <TextInput
              source="ApiSecret"
              label="API Secret"
              className={classes.inputField}
            />
          </Grid>
        </SanitizedGrid>
      </SimpleForm>
    </Edit>
  );
};

const TopBar = () => (
  <TopToolbar>
    <ListButton basePath="/configuration" />
  </TopToolbar>
);

export default IntegrationEdit;
