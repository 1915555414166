import { makeStyles, Theme } from '@material-ui/core'

const walletStyles = makeStyles((theme: Theme) => ({
  chain_select: { display: 'inline-block' },
  alias_input: { display: 'inline-block', marginLeft: 32 },
  address_input: { width: 544 },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 16,
    backgroundColor: theme.palette.type === 'light' ? '#f5f5f5' : 'rgba(255, 255, 255, 0.045)',
  },
  table: { marginBottom: 35 },
  formContainer: {
    width: '100%',
    maxWidth: 950,
    paddingTop: 15,
    paddingBottom: 15,
    margin: 0,
  },
  formWithCustomToolbar: {
    marginTop: 0,
    border: 'none',
  },
  formRow: {
    paddingTop: '0px!important',
    paddingBottom: '0px!important',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '3px!important',
      paddingBottom: '3px!important',
    },
  },
  inputField: { width: '100%', marginTop: 5 },
  input: {
    minWidth: theme.spacing(20),
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  uppercase: {
    '& input': {
      textTransform: 'uppercase',
    },
  },
  manualWalletEditToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    opacity: 1,
    zIndex: 10,
  },
  root: {
    position: 'relative',
    border: 'none',
    zIndex: 1,
  },
  main: {
    border: 'none',
    position: 'absolute',
    zIndex: 1,
  },
}))

export default walletStyles
