import { IDataProvider } from '../../firebase/providers/DataProvider'
import { BalanceRecord, CoinConfig, PriceHistory, QuoteRecord, Wallet } from '../../views/types'

/**
 * @summary Formats a number to currency (i.e. 1234.567 => $1,234.57)
 *
 * @param value
 * @returns {string}
 */
const toCurrencyString = (value: number | string) => {
  if (!value) return '--'
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })
}

/**
 * @summary Converts any standard date/date time string to format DD-MM-YYYY
 *
 * @param date
 * @returns {string}
 */
const toDateString = (date: string) => {
  return new Date(date).toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })
}

/**
 * @summary Return the max value of an array with option to specify the key of a pair for objects
 *
 * @param data
 * @param key
 * @returns {number}
 */
const max = (data: any[], key?: string): number => {
  return Math.max.apply(
    Math,
    data.map(record => {
      return key ? record[key] : record
    })
  )
}

/**
 * @summary Return the minimum value of an array with option to specify the key of a pair for objects
 *
 * @param data
 * @param key
 * @returns {number}
 */
const min = (data: any[], key?: string): number => {
  return Math.min.apply(
    Math,
    data.map(record => {
      return key ? record[key] : record
    })
  )
}

/**
 * @summary Returns the symbol network and address from the a wallet document reference.
 *
 * @param docRef
 * @returns {Object}
 */
const walletInfo = (docRef: string) => {
  const arr = docRef.split(/\//)
  const token = {
    symbol: arr[0],
    network: arr[1],
    address: arr[2],
  }
  return token
}

/**
 * @summary Converts the list of wallet docRefs from a profile to a non-repeating list of token/network symbols for use when fetching wallet collections.
 *
 * @param docRefs
 * @returns {Array}
 */
const walletCollections = (docRefs: string[]) => {
  const collections: { symbol: string; network: string }[] = []
  docRefs.forEach((docRef: string) => {
    const { symbol, network } = walletInfo(docRef)
    collections.findIndex(d => d.symbol === symbol && d.network === network) === -1 &&
      collections.push({ symbol, network })
  })

  return collections
}

/**
 * @summary Derives a profile tracking object from a list of wallet docRefs.
 *
 * @param docRefs
 * @returns {Array}
 */
const trackingList = (docRefs: string[]) => {
  const trackingList: string[] = []
  docRefs.forEach((docRef: string) => {
    const { symbol } = walletInfo(docRef)
    trackingList.findIndex(d => d === symbol) === -1 && trackingList.push(symbol)
  })

  return trackingList
}

/**
 * @summary Returns the network key/sub collection map required for the top level wallet.
 *
 * @param targetSymbol
 * @param docRefs
 * @returns {Array}
 */
const getNetworkKeys = (targetSymbol: string, docRefs: string[]) => {
  const networkKeys: string[] = []
  docRefs.forEach((docRef: string) => {
    const { symbol, network } = walletInfo(docRef)
    if (targetSymbol === symbol) {
      networkKeys.findIndex(d => d === network) === -1 && networkKeys.push(network)
    }
  })

  return networkKeys
}

/**
 * @summary Returns a date x mins in the future for use when validating cache data.
 *
 * @param mins
 * @returns {Date}
 */
export const validForMins = (mins: number) => {
  const currentDate = new Date()
  const validUntilDate = new Date(currentDate.getTime() + mins * 60000)

  return validUntilDate
}

/**
 * @summary Returns the correct URL for our firebase proxy based on the environment
 *
 * @param endpoint
 * @param event
 * @returns {string}
 */
export const dbProxyURL = (endpoint: string, event: boolean) => {
  return process.env.REACT_APP_ENVIRONMENT === 'production'
    ? `https://radar-proxy.republic.com/${event ? 'Event/' : ''}${endpoint}`
    : `https://radar-proxy.republic-beta.app/${event ? 'Event/' : ''}${endpoint}`
}

/**
 * @summary Converts a react-admin cache/specialized hook record map to a an object of format {id: { data }, id: {data}}.
 *
 * @param data
 * @returns {Object}
 */
const proxyMapToMapState = (data: any) => {
  const newState: any = {}
  Object.keys(data).forEach(key => {
    newState[key] = data[key].data ? data[key].data : data[key]
  })
  return newState
}

/**
 * @summary Converts a react-admin dataProvider response array to an object of format {id: { data }, id: {data}}.
 *
 * @param data
 * @returns {Object}
 */
const proxyArrayToMapState = (data: any[]) => {
  const newState: any = {}
  data.forEach((record: any) => {
    newState[record.id] = record.data ? record.data : record
  })
  return newState
}

/**
 * @summary Converts a react-admin cache/specialized hook record map to and array of format [{ id, ...data }, { id, ...data }].
 *
 * @param data
 * @returns {Array}
 */
const proxyMapToArrayState = (data: any) => {
  const newData = proxyMapToMapState(data)
  const newState: any[] = Object.keys(newData).map((key: string) => {
    return { ...newData[key][0], id: key }
  })
  return newState
}

/**
 * @summary Converts a react-admin dataProvider response array to an array of format [{ id, ...data }, { id, ...data }].
 *
 * @param data
 * @returns {Array}
 */
const proxyArrayToArrayState = (data: any[]) => {
  const newData = proxyArrayToMapState(data)
  const newState: any[] = Object.keys(newData).map((key: string) => {
    return { ...newData[key][0], id: key }
  })
  return newState
}

/**
 * @summary Provided the validity key from react-admin cache resource the function will return true if all ids exist and all are still valid.
 *
 * @param ids
 * @param cacheValidity
 * @returns {boolean}
 */
const cacheIsValid = (ids: string[], cacheValidity: { [key: string]: Date }) => {
  const now = new Date()
  if (!cacheValidity) return false
  for (let i = 0; i < ids.length; i++) {
    if (!cacheValidity[ids[i]]) {
      return false
    }
    if (cacheValidity[ids[i]] < now) {
      return false
    }
  }
  return true
}

/**
 * @summary Returns the appropriate url slug for the environment.
 *
 * @param slug
 * @returns {string}
 */
const environment = (slug: string) => {
  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    return slug
  } else {
    return `${slug}-dev`
  }
}

/**
 * @summary Fetch and format data from the cache or the API dependant on validity.
 *
 * Pass ids/cache/formatCacheData as false to bypass the cache check.
 *
 * @param resource
 * @param params
 * @param ids
 * @param cache
 * @param formatCacheData
 * @param formatApiResponse
 * @param dataProvider
 * @returns {Object | Array}
 */
const fetchData = async (
  resource: string,
  params: { [key: string]: any },
  ids: string[] | false,
  cache: { resources: { [key: string]: any } } | false,
  formatCacheData: ((data: any) => any) | false,
  formatApiResponse: ((data: any) => any | any[]) | undefined,
  dataProvider: IDataProvider
) => {
  const getList = (resource: string, params: { [key: string]: any }) => {
    return new Promise(async resolve => {
      const { data }: any = await dataProvider.getList(resource, {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'id', order: 'DESC' },
        filter: params,
      })
      resolve(data)
    })
  }

  if (ids && cache && formatCacheData && cacheIsValid(ids, cache.resources?.[resource]?.validity)) {
    return formatCacheData(cache.resources[resource].data)
  } else {
    const data: any = await getList(resource, params)
    return formatApiResponse ? formatApiResponse(data) : data
  }
}

/**
 * @summary Fetch the users wallets and set the parent state.
 *
 * @param profileId
 * @param dataProvider
 * @param setWallets
 * @param isMounted
 */
const setWalletState = async (
  profileId: string,
  dataProvider: IDataProvider,
  setWallets: React.Dispatch<React.SetStateAction<Wallet[] | undefined>>,
  isMounted: () => boolean
) => {
  const data = await fetchData(
    'wallets',
    { profileId },
    false,
    false,
    false,
    undefined,
    dataProvider
  )
  if (isMounted()) setWallets(data)
}

/**
 * @summary Fetch the coin config and set the parent state.
 *
 * @param ids
 * @param cache
 * @param dataProvider
 * @param setCoinConfig
 * @param isMounted
 */
const setCoinConfigState = async (
  ids: string[] | undefined,
  cache: any,
  dataProvider: IDataProvider,
  setCoinConfig: React.Dispatch<React.SetStateAction<{ [key: string]: CoinConfig } | undefined>>,
  isMounted: () => boolean
) => {
  const data = await fetchData(
    'symbol-snapshot',
    {},
    ids ? ids : false,
    cache ? cache : false,
    proxyMapToMapState,
    proxyArrayToMapState,
    dataProvider
  )
  if (isMounted()) setCoinConfig(data)
}

/**
 * @summary Fetch the price history and set the parent state.
 *
 * @param ids
 * @param startDate
 * @param endDate
 * @param cache
 * @param dataProvider
 * @param setPriceHistory
 * @param isMounted
 */
const setPriceHistoryState = async (
  ids: string[],
  startDate: Date,
  endDate: Date,
  cache: any,
  dataProvider: IDataProvider,
  setPriceHistory: React.Dispatch<
    React.SetStateAction<{ [key: string]: PriceHistory[] } | undefined>
  >,
  isMounted: () => boolean
) => {
  const data = await fetchData(
    'price-history',
    {
      symbols: ids,
      startDate,
      endDate,
    },
    ids,
    cache,
    proxyMapToMapState,
    proxyArrayToMapState,
    dataProvider
  )
  if (isMounted()) setPriceHistory(data)
}

/**
 * @summary Fetch a users balance history and update the parent state.
 *
 * @param profileId
 * @param startDate
 * @param endDate
 * @param ids
 * @param cache
 * @param dataProvider
 * @param setBalanceHistory
 * @param isMounted
 */
const setBalanceHistoryState = async (
  profileId: string,
  startDate: Date,
  endDate: Date,
  ids: string[],
  cache: any,
  dataProvider: IDataProvider,
  setBalanceHistory: React.Dispatch<
    React.SetStateAction<{ [key: string]: BalanceRecord[] } | undefined>
  >,
  isMounted: () => boolean
) => {
  const data = await fetchData(
    'balance-history',
    {
      profileId,
      startDate,
      endDate,
    },
    ids,
    cache,
    proxyMapToMapState,
    proxyArrayToMapState,
    dataProvider
  )
  if (isMounted()) setBalanceHistory(data)
}

/**
 * @summary Fetch quote history and parse to state.
 *
 * @param ids
 * @param startDate
 * @param endDate
 * @param interval
 * @param cache
 * @param dataProvider
 * @param setPriceHistory
 * @param isMounted
 */
const setQuoteHistoryState = async (
  ids: string[],
  startDate: Date,
  endDate: Date,
  interval: 'hourly' | 'daily',
  cache: any,
  dataProvider: IDataProvider,
  setQuoteHistory: React.Dispatch<
    React.SetStateAction<{ [key: string]: QuoteRecord[] } | undefined>
  >,
  isMounted: () => boolean
) => {
  const data = await fetchData(
    interval === 'hourly' ? 'quotes-hourly' : 'quotes',
    {
      symbols: ids,
      startDate,
      endDate,
      interval,
    },
    ids,
    cache,
    proxyMapToMapState,
    proxyArrayToMapState,
    dataProvider
  )
  if (isMounted()) setQuoteHistory(data)
}

export {
  environment,
  walletInfo,
  walletCollections,
  trackingList,
  getNetworkKeys,
  toCurrencyString,
  toDateString,
  proxyMapToMapState,
  proxyArrayToMapState,
  proxyMapToArrayState,
  proxyArrayToArrayState,
  cacheIsValid,
  fetchData,
  setWalletState,
  setBalanceHistoryState,
  setCoinConfigState,
  setPriceHistoryState,
  setQuoteHistoryState,
  max,
  min,
}
